import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { profitReport } from "./profit_report";
import { nounQuiz } from "./noun_quiz";
import { deviceHub } from "./device_hub";
import { settings } from "./settings";
import { globalIcon } from "./global-icon";
import { settingsIcon } from "./settings-icon";
import { trainingBadge } from "./training-badge";
import { starBadgeOrange } from "./star-badge-orange";
import { starBadgeRed } from "./star-badge-red";
import { starBadgeGreen } from "./star-badge-green";
import { infoIcon } from "./info-icon";
import { backButton } from "./back-button";
import { correctIcon } from "./correct-icon";
import { incorrectIcon } from "./incorrect-icon";
import { correctPartlyIcon } from "./correctPartly-icon";
import { exit } from "./exit.svg";
import { drawer } from "./drawer";
import { graphClock } from "./graph/graph-clock";
import { graphLoop } from "./graph/graph-loop";
import { graphFlag } from "./graph/graph-flag";
import { graphListener } from "./graph/graph-listener";
import { graphQuiz } from "./graph/graph-quiz";
import { graphFlagStart } from "./graph/graph-flag-start";
import { graphFlagFinish } from "./graph/graph-flag-finish";
import { graphQuestion } from "./graph/graph-question";
import { graphInfo } from "./graph/graph-info";
import { graphIf } from "./graph/graph-if";
import { finishedIcon } from "./finished-icon";
import { closeIcon } from "./close-icon";
import { done } from "./done";
import { cancel } from "./cancel";
import { goTo } from "./go-to";
import { networkMenu } from "./training/network";
import { networkMenuActive } from "./training/network-active";
import { menuInfo } from "./training/menu-info";
import { menuChat } from "./training/menu-chat";
import { menuGroup } from "./training/menu-group";
import { menuList } from "./training/menu-list";
import { menuMission } from "./training/menu-mission";
import { menuNotifications } from "./training/menu-notifications";
import { menuInfoActive } from "./training/menu-info-active";
import { menuChatActive } from "./training/menu-chat-active";
import { menuGroupActive } from "./training/menu-group-active";
import { menuListActive } from "./training/menu-list-active";
import { menuMissionActive } from "./training/menu-mission-active";
import { menuNotificationsActive } from "./training/menu-notifications-active";
import { menuTimelineActive } from "./training/menu-timeline-active";
import { menuTimeline } from "./training/menu-timeline";
import { menuLeaderboardActive } from "./training/menu-leaderboard-active";
import { menuLeaderboard } from "./training/menu-leaderboard";
import { hourglass } from "./training/hourglass";
import { hourglassRed } from "./training/hourglass-red";
import { trainingCloseIcon } from "./training/close-icon";
import { sendMessage } from "./training/send-message";
import { attachment } from "./training/attachment";
import { pinkAttachment } from "./training/pink-attachment";
import { popupInfo } from "./training/popup-info";
import { feedback } from "./training/feedback";
import { positiveEmoji } from "./training/positive-emoji";
import { negativeEmoji } from "./training/negative-emoji";
import { timelineSettingsIcon } from "./training/settings-icon";
import { squareTrophy } from "./training/timeline/square-trophy";
import { orangeHat } from "./training/timeline/orange-hat";
import { greenShield } from "./training/timeline/green-shield";
import { orangeShield } from "./training/timeline/orange-shield";
import { pinkShield } from "./training/timeline/pink-shield";

import { eventsCircle } from "./training/events/events-circle";
import { goldQuestionMark } from "./training/events/gold-question-mark";
import { goldTrophy } from "./training/events/gold-trophy";
import { greenTarget } from "./training/events/green-target";
import { redHat } from "./training/events/red-hat";
import { pinkMission } from "./training/events/pink-mission";
import { redQuestionMark } from "./training/events/red-question-mark";
import { redTarget } from "./training/events/red-target";
import { redTrophy } from "./training/events/red-trophy";

import { leaderboardSilverStar } from "./training/leaderboard/leaderboard-silver-star";
import { leaderboardBronzeStar } from "./training/leaderboard/leaderboard-bronze-star";
import { leaderboardBadge } from "./training/leaderboard/leaderboard-badge";
import { leaderboardTrophy } from "./training/leaderboard/leaderboard-trophy";
import { leaderboardSilverStarFrame } from "./training/leaderboard/leaderboard-silver-star-frame";
import { leaderboardBronzeStarFrame } from "./training/leaderboard/leaderboard-bronze-star-frame";
import { leaderboardBadgeFrame } from "./training/leaderboard/leaderboard-badge-frame";
import { leaderboardTrophyFrame } from "./training/leaderboard/leaderboard-trophy-frame";
import { avatarUser } from "./training/leaderboard/avatar-user";
import { leaderGoldBadge } from "./training/leaderboard/badges/leader-gold-badge";
import { fastestRunBadge } from "./training/leaderboard/badges/fastest-run-badge";
import { leaderBronzeBadge } from "./training/leaderboard/badges/leader-bronze-badge";
import { leaderSilverBadge } from "./training/leaderboard/badges/leader-silver-badge";
import { perfectRunBadge } from "./training/leaderboard/badges/perfect-run-badge";

import {
  cilPeople,
  cilUserFollow,
  cilUserUnfollow,
  cilBasket,
  cilChartPie,
  cilSpeedometer,
  cilSpeech,
  cilBell,
  cilEnvelopeOpen,
  cilTask,
  cilCommentSquare,
  cilUser,
  cilSettings,
  cilCreditCard,
  cilFile,
  cilLockLocked,
  cilLightbulb,
  cilMagnifyingGlass,
} from "@coreui/icons";

export const icons = Object.assign(
  {
    sygnet,
    logo,
    profitReport,
    nounQuiz,
    deviceHub,
    settings,
    globalIcon,
    settingsIcon,
    trainingBadge,
    starBadgeOrange,
    starBadgeRed,
    starBadgeGreen,
    infoIcon,
    backButton,
    finishedIcon,
    closeIcon,
    done,
    cancel,
    goTo,
    correctIcon,
    incorrectIcon,
    correctPartlyIcon,
    exit,
    drawer,
  },
  {
    graphClock,
    graphFlag,
    graphListener,
    graphQuiz,
    graphFlagStart,

    graphFlagFinish,
    graphQuestion,
    graphInfo,
    graphLoop,
    graphIf,
  },
  {
    menuInfo,
    menuChat,
    menuGroup,
    menuList,
    menuMission,
    menuNotifications,
    menuInfoActive,
    menuChatActive,
    menuGroupActive,
    menuListActive,
    menuMissionActive,
    menuNotificationsActive,
    menuTimelineActive,
    menuTimeline,
    networkMenu,
    networkMenuActive,
    menuLeaderboardActive,
    menuLeaderboard,
    hourglass,
    hourglassRed,
    trainingCloseIcon,
    timelineSettingsIcon,
    squareTrophy,
    orangeHat,
    greenShield,
    orangeShield,
    pinkShield,
    sendMessage,
    attachment,
    pinkAttachment,
    popupInfo,
    feedback,
    positiveEmoji,
    negativeEmoji,
  },
  {
    eventsCircle,
    goldQuestionMark,
    goldTrophy,
    greenTarget,
    redHat,
    pinkMission,
    redQuestionMark,
    redTarget,
    redTrophy,
  },
  {
    leaderboardSilverStar,
    leaderboardBronzeStar,
    leaderboardBadge,
    leaderboardTrophy,
    leaderboardSilverStarFrame,
    leaderboardBronzeStarFrame,
    leaderboardBadgeFrame,
    leaderboardTrophyFrame,
    avatarUser,
    fastestRunBadge,
    leaderBronzeBadge,
    leaderGoldBadge,
    leaderSilverBadge,
    perfectRunBadge,
  },
  {
    cilPeople,
    cilUserFollow,
    cilUserUnfollow,
    cilBasket,
    cilChartPie,
    cilSpeedometer,
    cilSpeech,
    cilBell,
    cilEnvelopeOpen,
    cilTask,
    cilCommentSquare,
    cilUser,
    cilSettings,
    cilCreditCard,
    cilFile,
    cilLockLocked,
    cilLightbulb,
    cilMagnifyingGlass,
  }
);
