export const positiveEmoji = [
  "22 22",
  `
  <defs>
  <filter id="0vco29of8a">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.125490 0 0 0 0 0.764706 0 0 0 0 0.505882 0 0 0 1.000000 0"/>
  </filter>
  <filter id="p5byy3142b" width="115%" height="115%" x="-7.5%" y="-7.5%" filterUnits="objectBoundingBox">
      <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5"/>
      <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.933333333 0 0 0 0 0.188235294 0 0 0 0 0.258823529 0 0 0 1 0"/>
  </filter>
  <filter id="6hklvr7f5d" width="218.8%" height="218.8%" x="-59.4%" y="-59.4%" filterUnits="objectBoundingBox">
      <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5"/>
      <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.933333333 0 0 0 0 0.188235294 0 0 0 0 0.258823529 0 0 0 1 0"/>
  </filter>
  <filter id="20pnx0yq5f" width="218.8%" height="218.8%" x="-59.4%" y="-59.4%" filterUnits="objectBoundingBox">
      <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5"/>
      <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.933333333 0 0 0 0 0.188235294 0 0 0 0 0.258823529 0 0 0 1 0"/>
  </filter>
  <filter id="vbf9rg70vh" width="133.3%" height="215.9%" x="-16.7%" y="-57.9%" filterUnits="objectBoundingBox">
      <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5"/>
      <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.933333333 0 0 0 0 0.188235294 0 0 0 0 0.258823529 0 0 0 1 0"/>
  </filter>
  <circle id="148bziehee" cx="13.946" cy="8.133" r="1.263"/>
  <circle id="26nsl34iqg" cx="6.054" cy="8.133" r="1.263"/>
  <path id="st32fid95c" d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18.889c-4.901 0-8.889-3.988-8.889-8.889S5.1 1.111 10 1.111 18.889 5.1 18.889 10 14.9 18.889 10 18.889z"/>
  <path id="xbyngsg6ai" d="M13.577 12.001c-2.006 1.788-5.148 1.788-7.154 0-.229-.204-.58-.184-.784.045-.204.23-.184.58.045.785 1.21 1.078 2.763 1.618 4.316 1.618s3.106-.54 4.316-1.618c.23-.204.25-.555.045-.785-.204-.229-.555-.249-.784-.045z"/>
</defs>
<g fill="none" fill-rule="evenodd">
  <g>
      <g>
          <g filter="url(#0vco29of8a)" transform="translate(-848 -270) translate(409 153) translate(90 118)">
              <g fill-rule="nonzero">
                  <g transform="translate(350)">
                      <use fill="#000" filter="url(#p5byy3142b)" xlink:href="#st32fid95c"/>
                      <use fill="#FFF" fill-opacity=".952" xlink:href="#st32fid95c"/>
                  </g>
                  <g transform="translate(350)">
                      <use fill="#000" filter="url(#6hklvr7f5d)" xlink:href="#148bziehee"/>
                      <use fill="#FFF" fill-opacity=".952" xlink:href="#148bziehee"/>
                  </g>
                  <g transform="translate(350)">
                      <use fill="#000" filter="url(#20pnx0yq5f)" xlink:href="#26nsl34iqg"/>
                      <use fill="#FFF" fill-opacity=".952" xlink:href="#26nsl34iqg"/>
                  </g>
                  <g transform="translate(350)">
                      <use fill="#000" filter="url(#vbf9rg70vh)" xlink:href="#xbyngsg6ai"/>
                      <use fill="#FFF" fill-opacity=".952" xlink:href="#xbyngsg6ai"/>
                  </g>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
