import React, { useState, useMemo, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendMessage } from "../../../store/actions/chat";
import { removeInformer } from "../../../store/actions/informer";
import { decrementPopupCount } from "../../../store/actions/ui";
import FloatingPopup from "../shared/FloatingPopup";
import { finishedMission } from "../../../store/actions/missions";

function InformerPopup({
  username,
  informer,
  defaultPosition,
  setDefaultPosition,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [attachment, setAttachment] = useState({});
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);

  const { rangerName } = useSelector((state) => state.trainingReducer);
  const { email } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();

  const informerSliderRef = useRef(null);

  const id = informer?.id;

  let myValue = useMemo(() => {
    if (!informer) return "";
    if (!informer || !informer?.data) return "";
    if (informer) {
      return informer.data;
    }
    return "";
  }, [informer]);

  let myRichText = [[{ type: "text", value: myValue }]];
  let informerDetails = {};

  informerDetails = JSON.parse(informer.text);
  myRichText = [[{ type: "text", value: myValue }]];
  for (var page of informerDetails.content) {
    for (var chap of page) {
      if (chap.type === "text") {
        chap.value = [{ type: "text", text: chap.value }];
      }
    }
  }
  myRichText = informerDetails.content;
  const informerAsButton = !myRichText[0] ? true : false;

  const sendApprove = useCallback(() => {
    const obj = {
      data: { id },
      username,
      email,
      family: "continue_btn",
      api_type: "continue_btn",
      to: rangerName || "",
      from: email,
    };

    const stringObj = JSON.stringify(obj);

    dispatch(sendMessage(stringObj));
    dispatch(removeInformer(id));
    dispatch(finishedMission(id));
    dispatch(decrementPopupCount("info", 1));
  }, [username, id]);

  const openAttachment = useCallback(
    (name, type, value, fullScreen = false) => {
      setModalOpen(true);
      setAttachment({ name, type, value });
      setFullScreen(true);
    },
    []
  );

  const closeAttachment = () => {
    setModalOpen(false);
    setAttachment({});
  };

  const dotClick = (value) => {
    if (
      informerSliderRef?.current !== undefined &&
      informerSliderRef?.current !== null
    ) {
      informerSliderRef.current.slickGoTo(value);
    }
  };

  return !informer ? null : (
    <FloatingPopup
      handle="informer"
      data={{ ...informer, RichText: myRichText }}
      approveClick={sendApprove}
      modalOpen={modalOpen}
      attachment={attachment}
      closeAttachment={closeAttachment}
      openAttachment={openAttachment}
      dotClick={dotClick}
      currentSlide={currentSlide}
      setCurrentSlide={setCurrentSlide}
      sliderRef={informerSliderRef}
      defaultPosition={defaultPosition}
      setDefaultPosition={setDefaultPosition}
      ButtonTxt={informerDetails?.ButtonTxt}
      informerAsButton={informerAsButton}
      fullScreen={fullScreen}
    />
  );
}

export default InformerPopup;
