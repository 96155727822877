import React, { useState, useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";

import TakeHintPopup from "./../popupMessages/TakeHintPopup";
import HintPopup from "./../popupMessages/HintPopup";
import { sendMessage } from "./../../../store/actions/chat";
import { removeHint } from "./../../../store/actions/hint";
import ModalContainer from "./../../base/modals/ModalContainer";
import FileView from "./FileViewer";

function HintPopupContainer() {
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [attachment, setAttachment] = useState({});
  const [currentMissionId, setCurrentMissionId] = useState(null);
  const [isOpenNewTab, setIsOpenNewTab] = useState(null);

  const classes = useStyles();

  const { username, email } = useSelector((state) => state.userReducer);

  const { hint, missionId } = useSelector((state) => state.hintReducer);
  const { hints } = useSelector((state) => state.missionsReducer);
  const { rangerName } = useSelector((state) => state.trainingReducer);


  useEffect(() => {
    if (missionId) {
      const openNewTab = hints[missionId].hints[0].openNewTab;
      setCurrentMissionId(missionId);
      setIsOpenNewTab(openNewTab);
    }
  }, [missionId, hint]);


  let filteredHints = {};
  if (hints && hints[missionId]) {
    filteredHints = hints[missionId];
  }

  const { currentHint, totalHints } = filteredHints;
  const price = filteredHints?.hints?.[currentHint]?.price || 0;
  const HintID = filteredHints?.hints?.[currentHint]?.HintID || 0;
  // const myHints = filteredHints?.hints?.[currentHint] || [];

  const dispatch = useDispatch();

  const resetHint = () => {
    dispatch(removeHint());
  };

  const getHint = () => {
    const obj = {
      data: { hintId: `${HintID}`, missionId },
      username,
      email,
      family: "ask_hint",
      api_type: "ask_hint",
      to: rangerName || "",
      from: email,
    };

    const stringObj = JSON.stringify(obj);

    dispatch(sendMessage(stringObj));
  };

  const cancelHint = () => {
    resetHint();
  };

  const openAttachment = (name, type, value) => {
    if (isOpenNewTab) {
      window.open(value, "_blank", "noopener,noreferrer");
      return;
    }
    setModalType("attachment");
    setAttachment({ name, type, value });
    setOpen(true);
  };

  const closeAttachment = () => {
    resetState();
  };

  const resetState = () => {
    setOpen(false);
    setModalType("");
    setAttachment({});
  };

  const modalChildren = () => {
    if (modalType === "attachment") {
      return <FileView closeClick={closeAttachment} {...attachment} />;
    }
    return <div />;
  };

  const hintBody = useMemo(() => {
    if (Object.keys(hint).length > 0) {
      return (
        <HintPopup
          hint={hint?.text}
          currentHint={currentHint}
          totalHints={totalHints}
          confirm={cancelHint}
          id={missionId}
          setModalOpen={setOpen}
          openAttachment={openAttachment}
        />
      );
    }
    return (
      <TakeHintPopup
        getHint={getHint}
        hintCost={price}
        currentHint={currentHint}
        cancelHint={cancelHint}
        id={missionId}
      />
    );
  }, [hint, currentHint, totalHints, price]);

  return (
    <>
      {missionId !== undefined && missionId !== "" ? (
        <motion.div
          className={classes.motionModal}
          initial={{
            position: "fixed",
          }}
          layoutId={`get-hint-background-${missionId}`}
        >
          <motion.div
            layoutId={`get-hint-${missionId}`}
            initial={false}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.8 }}
          >
            {hintBody}
          </motion.div>
        </motion.div>
      ) : null}
      <ModalContainer open={open} position="center">
        {modalChildren()}
      </ModalContainer>
    </>
  );
}

const useStyles = makeStyles({
  motionModal: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    position: "fixed",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.56)",
  },
});

export default HintPopupContainer;
