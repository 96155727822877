export const SET_MODAL_STATE = "SET_MODAL_STATE";
export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";
export const SET_ACTIVE_HALL = "SET_ACTIVE_HALL";
export const INCREMENT_POPUP_COUNT = "INCREMENT_POPUP_COUNT";
export const DECREMENT_POPUP_COUNT = "DECREMENT_POPUP_COUNT";
export const UPDATE_SCORE = "UPDATE_SCORE";
export const UPDATE_TIME = "UPDATE_TIME";
export const UPDATE_RINGS = "UPDATE_RINGS";
export const INCREMENT_FONT_SIZE = "INCREMENT_FONT_SIZE";
export const DECREMENT_FONT_SIZE = "DECREMENT_FONT_SIZE";
export const RESET_TRAINING_STATE = "RESET_TRAINING_STATE";
export const RESET_POPUP_COUNT = "RESET_POPUP_COUNT";
export const SET_THEME = "SET_THEME";
