import axios from "axios";

const myBaseURL =
  process.env.REACT_APP_ENVIRONMENT.trim() === "DEV"
    ? process.env.REACT_APP_BASE_URL_LOCAL_DEV
    : process.env.REACT_APP_BASE_URL_CLOUD;

const axiosConfig = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export function sendScreenshot(image, rangeId, token) {
  // console.log(image);
  const obj = {
    b64: image,
    RangeID: rangeId, // should be trainingID - type: String
    token,
  };
  const postData = JSON.stringify(obj);

  // console.log("sendScreenshot request", postData);

  return axios
    .post(`${myBaseURL}/Training/ScreenShot`, postData, axiosConfig)
    .then((res) => {
      // console.log("sendScreenshot response", res);
      return res;
    })
    .catch((err) => {
      // console.log("sendScreenshot ERROR: ", err);
    });
}

export function sendTrainingDone(rangeId, token) {
  const obj = {
    RangeID: rangeId,
    token,
  };
  const postData = JSON.stringify(obj);

  // console.log("sendTrainingDone request", postData);

  return axios
    .post(`${myBaseURL}/Training/ScreenShot`, postData, axiosConfig)
    .then((res) => {
      // console.log("sendTrainingDone response", res);
      return res;
    })
    .catch((err) => {
      // console.log("sendTrainingDone ERROR: ", err);
    });
}

// export function ScheduleNewTraining(trainingDetails, token) {
//   const defaultTermination =
//     trainingDetails.terminationTimer.days ||
//     trainingDetails.terminationTimer.hours ||
//     trainingDetails.terminationTimer.minutes
//       ? false
//       : "-1";
//   const obj = {
//     seats: "99",
//     selectedScenarioId: trainingDetails.selectedScenarioId + "",
//     selectedTime: trainingDetails.selectedTime + "",
//     selectedUsers: JSON.stringify(trainingDetails.selectedUsers),
//     duration: trainingDetails.duration + "",
//     rangeid: trainingDetails.selectedRanger + "" || "-1",
//     terminationTimer: defaultTermination
//       ? defaultTermination
//       : {
//           days: String(trainingDetails.terminationTimer.days || 0),
//           hours: String(trainingDetails.terminationTimer.hours || 0),
//           minutes: String(trainingDetails.terminationTimer.minutes || 0),
//         },
//     isTeam: trainingDetails.isTeam,
//     token,
//   };
//   // console.log(obj);
//   const postData = JSON.stringify(JSON.stringify(obj));

//   let axiosConfig = {
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//   };

//   // console.log("ScheduleNewTraining request", postData);

//   return axios
//     .post(`${myBaseURL}/Identity/ScheduleNewTraining`, postData, axiosConfig)
//     .then((res) => {
//       // console.log("ScheduleNewTraining response", res);
//       return res;
//     })
//     .catch((err) => {
//       // console.log("ScheduleNewTraining ERROR: ", err);
//     });
// }

// export function UpdateTraining(trainingDetails, token) {
//   const obj = {
//     scheduledID: String(trainingDetails.selectedTrainingId),
//     selectedScenarioId: trainingDetails.selectedScenarioId + "",
//     selectedTime: trainingDetails.selectedTime + "",
//     selectedUsers: JSON.stringify(trainingDetails.selectedUsers),
//     duration: trainingDetails.duration + "",
//     rangeid: trainingDetails.rangeid + "",
//     seats: "99",
//     isTeam: trainingDetails.isTeam,
//     // rangeid: "4", // TODO - sebd real range id instead of static
//     token,
//   };
//   // console.log(obj);
//   const postData = JSON.stringify(JSON.stringify(obj));

//   let axiosConfig = {
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//   };

//   // console.log("UpdateTraining request", postData);

//   return axios
//     .post(`${myBaseURL}/Identity/UpdateTraining`, postData, axiosConfig)
//     .then((res) => {
//       // console.log("UpdateTraining response", res);
//       return res;
//     })
//     .catch((err) => {
//       // console.log("UpdateTraining ERROR: ", err);
//     });
// }

export async function AddTraineeToScheduledTraining(
  trainingId,
  userId,
  trainingDetails
) {
  try {
    const response = await axios.put(
      `${myBaseURL}/ScheduleTraining/${trainingId}/${userId}`,
      trainingDetails,
      axiosConfig
    );
    return response;
  } catch (err) {
    console.warn("Add trainee to ScheduleTraining API ERROR: ", err);
  }
}

export function GetGroupedScheduleObject(scheduledID) {
  return axios
    .get(`${myBaseURL}/ScheduleTraining/${scheduledID}/Grouped`, axiosConfig)
    .then((res) => {
      if (res.status === 200) {
        console.log("GetGroupedScheduleObject response", res);
        return res.data;
      }
    })
    .catch((err) => {
      console.log("GetGroupedScheduleObject ERROR: ", err);
    });
}

export function StartScenarioByTrainee(token) {
  const obj = {
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("StartScenario request", postData);

  return axios
    .post(`${myBaseURL}/Training/StartScenarioByTrainee`, postData, axiosConfig)
    .then((res) => {
      // console.log("StartScenario response", res);
      return res;
    })
    .catch((err) => {
      // console.log("StartScenario ERROR: ", err);
    });
}

export function FetchScheduled(id) {
  return axios
    .get(`${myBaseURL}/Users/${id}/scheduled`, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("FetchScheduled ERROR: ", err);
    });
}

export function GetDefaultRanger() {
  return axios
    .post(`${myBaseURL}/rangers/GetDefaultRanger`, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("GetDefaultRanger ERROR: ", err);
    });
}

export async function CancelScheduledTraining(scheduledTrainingId) {
  try {
    const response = await axios.delete(
      `${myBaseURL}/ScheduleTraining/${scheduledTrainingId}`,
      axiosConfig
    );
    return response;
  } catch (err) {
    console.warn("Delete ScheduleTraining API ERROR: ", err);
  }
}

export async function CancelMultiScheduledTrainings(scheduledTrainingIDs) {
  try {
    const configWithData = {
      ...axiosConfig,
      data: scheduledTrainingIDs,
    };
    const response = await axios.delete(
      `${myBaseURL}/ScheduleTraining`,
      configWithData
    );
    return response;
  } catch (err) {
    console.warn("Delete ScheduleTraining API ERROR: ", err);
  }
}

export async function UpdateScheduledTraining(
  scheduledTrainingId,
  trainingDetails
) {
  try {
    const response = await axios.put(
      `${myBaseURL}/ScheduleTraining/${scheduledTrainingId}`,
      trainingDetails,
      axiosConfig
    );
    return response;
  } catch (err) {
    console.warn("Update ScheduleTraining API ERROR: ", err);
  }
}

export async function CreateScheduledTraining(trainingDetails) {
  try {
    const response = await axios.post(
      `${myBaseURL}/ScheduleTraining`,
      trainingDetails,
      axiosConfig
    );
    return response;
  } catch (err) {
    console.warn("Create ScheduleTraining API ERROR: ", err);
  }
}

export function DeleteSelfPacedStatus(userID) {
  return axios
    .delete(`${myBaseURL}/Users/${userID}/SelfPaced`, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("DeleteSelfPacedStatus ERROR: ", err);
    });
}
