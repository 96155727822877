import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CIcon from "@coreui/icons-react";

import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import OrangeTooltip from "../OrangeTooltip";

function LeaderboardBadge({ badge, description, size = "30px", setOpen }) {
  const classes = useStyles();

  if (!badge || badge === "") return <div className={classes.badgeWrapper} />;
  const ringNames = [
    "fastestRunBadge",
    "leaderBronzeBadge",
    "leaderGoldBadge",
    "leaderSilverBadge",
    "perfectRunBadge",
  ];

  if (badge === "plus-badge") {
    return (
      <IconButton
        className={classes.badgeWrapper}
        onClick={setOpen}
        aria-describedby={badge}
      >
        <AddIcon className={classes.addIcon} />
      </IconButton>
    );
  }

  const badgeName = ringNames.includes(badge) ? badge : "fastestRunBadge";

  return (
    <OrangeTooltip title={description}>
      <div>
        <CIcon
          name={badgeName}
          width={size}
          height={size}
          className={classes.iconBadge}
        />
      </div>
    </OrangeTooltip>
  );
}

const useStyles = makeStyles({
  badgeWrapper: {
    border: "solid 0.5px rgba(255, 255, 255, 0.1)",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    margin: "5px",
  },
  addIcon: {
    color: "white",
  },
  iconBadge: {
    margin: "5px",
  },
});

export default LeaderboardBadge;
