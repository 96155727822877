import axios from "axios";

const myBaseURL =
  process.env.REACT_APP_ENVIRONMENT.trim() === "DEV"
    ? process.env.REACT_APP_BASE_URL_LOCAL_DEV
    : process.env.REACT_APP_BASE_URL_CLOUD;

const axiosConfig = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export function registerApi(credentials, token) {
  const obj = {
    credentials: { ...credentials },
    token,
  };

  const postData = JSON.stringify(JSON.stringify(credentials));

  // console.log("registerApi request", postData);

  return axios
    .post(`${myBaseURL}/Auth/Register`, postData, axiosConfig)
    .then((res) => {
      // console.log("registerApi response", res);
      return res;
    })
    .catch((err) => {
      // console.log("registerApi ERROR: ", err);
    });
}

export function addTraineeApi(credentials) {
  const postData = JSON.stringify(JSON.stringify(credentials));

  // console.log("addTraineeApi request", postData);

  return axios
    .post(`${myBaseURL}/Identity/AddTrainee`, postData, axiosConfig)
    .then((res) => {
      // console.log("addTraineeApi response", res);
      return res;
    })
    .catch((err) => {
      // console.log("addTraineeApi ERROR: ", err);
    });
}

export function loginApi(credentials) {
  const postData = JSON.stringify(JSON.stringify(credentials));

  // console.log("loginApi request", postData);

  return axios
    .post(`${myBaseURL}/Auth/LoginAll`, postData, axiosConfig)
    .then((res) => {
      // console.log("loginApi response", res);
      return res;
    })
    .catch((err) => {
      // console.log("loginApi ERROR: ", err);
    });
}

export function loginWith2faApi(credentials) {
  const postData = JSON.stringify(JSON.stringify(credentials));

  // console.log("loginWith2faApi request", postData);

  return axios
    .post(`${myBaseURL}/Auth/LoginWithConfirmationCode`, postData, axiosConfig)
    .then((res) => {
      // console.log("loginWith2faApi response", res);
      return res;
    })
    .catch((err) => {
      // console.log("loginWith2faApi ERROR: ", err);
    });
}

export function loginWithToken(email, token) {
  const obj = {
    email,
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
      ClientEmail: `${email}`,
    },
  };

  return axios
    .post(`${myBaseURL}/Auth/loginWithToken`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("loginWithToken ERROR: ", err);
    });
}

export function loginWithAuth0Token(email, token) {
  // const postData = `${token}`;

  const obj = {
    email,
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("sent to server: ", postData);

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  // console.log("loginWithAuth0Token request", postData);

  return axios

    .post(`${myBaseURL}/Auth/loginWithAuth0Token`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("loginWithAuth0Token ERROR: ", err);
    });
}

export function personalHallApi() {
  return axios
    .post(`${myBaseURL}/Identity/GetProfile`, {}, axiosConfig)
    .then((res) => {
      // console.log("personalHallApi response", res);
      return res;
    })
    .catch((err) => {
      // console.log("personalHallApi ERROR: ", err);
    });
}

export function getReviewApi(tid) {
  const obj = {
    tid,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("getReviewApi request", postData);

  return axios
    .post(`${myBaseURL}/MotherShip/GetReview`, postData, axiosConfig)
    .then((res) => {
      // console.log("getReviewApi response", res);
      return res;
    })
    .catch((err) => {
      // console.log("getReviewApi ERROR: ", err);
    });
}

export function getReviewOfTraineeApi(tid, uid, token) {
  const obj = {
    tid: String(tid),
    uid: String(uid),
    token: String(token),
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  return axios
    .post(`${myBaseURL}/MotherShip/GetReviewOfTrainee`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.warn("getReviewApi ERROR: ", err);
    });
}

export function getReviewsHistory(id) {
  const postData = JSON.stringify(id.toString());

  // console.log("getReviewsHistory request", postData);

  return axios
    .post(
      `${myBaseURL}/MotherShip/GetAllPrevieusHistory`,
      postData,
      axiosConfig
    )
    .then((res) => {
      // console.log("getReviewsHistory response", res);
      return res;
    })
    .catch((err) => {
      // console.log("getReviewsHistory ERROR: ", err);
    });
}

export function scheduleApi(id, credentials, type) {
  const scheduleObj = {
    EntityID: id + "",
    EntityType: type,
    ScheduledTime: credentials["timestamp"],
    Message: credentials["message"],
    ScnID: credentials["scnID"],
  };

  const obj = {
    scnobj: JSON.stringify(scheduleObj),
    token: "emptyToken",
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("scheduleApi request", postData);

  return axios
    .post(`${myBaseURL}/Identity/ScheduleScenario`, postData, axiosConfig)
    .then((res) => {
      // console.log("scheduleApi response", res);
      return res;
    })
    .catch((err) => {
      // console.log("scheduleApi ERROR: ", err);
    });
}

export function getScheduledScenarios(id) {
  const obj = {
    id: id.toString(),
    type: "trainee",
    token: "emptyToken",
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("GetScheduledScenarios request", postData);

  const myUrl = `${myBaseURL}/Identity/GetScheduledScenarios`;

  return axios
    .post(myUrl, postData, axiosConfig)
    .then((res) => {
      // console.log("GetScheduledScenarios response", res);
      return res;
    })
    .catch((err) => {
      // console.log("GetScheduledScenarios ERROR: ", err);
    });
}
export function getUserMachineDetailsApi() {
  return fetch("https://ipapi.co/json/", {
    headers: {
      // Add any other headers you want to include here
    },
  })
    .then((response) => {
      // Check if the response is successful
      if (!response.ok) {
        throw new Error("Request failed");
      }
      return response.json();
    })
    .then((data) => {
      // Process the retrieved data
      return data;
    })
    .catch((error) => {
      // Handle the error
      console.error(error);
    });
}

export function getUserMachineDetailsApi2() {
  return axios
    .get("https://ipapi.co/json/", {
      headers: {
        // Add any other headers you want to include here
      },
    })
    .then((response) => {
      let data = response.data;
      // console.log("getUserMachineDetailsApi response", response);
      return data;
    })
    .catch((error) => {
      // console.log(error);
    });
}
export function getEula() {
  const obj = {};

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("getEula request", postData);

  const myUrl = `${myBaseURL}/Identity/GetEULA`;

  return axios
    .post(myUrl, postData, axiosConfig)
    .then((res) => {
      // console.log("getEula response", res);
      return res;
    })
    .catch((err) => {
      // console.log("getEula ERROR: ", err);
    });
}

export function confirmEula(token) {
  const obj = { token };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("ConfirmEULA request", postData);

  const myUrl = `${myBaseURL}/Identity/ConfirmEULA`;

  return axios
    .post(myUrl, postData, axiosConfig)
    .then((res) => {
      // console.log("ConfirmEULA response", res);
      return res;
    })
    .catch((err) => {
      // console.log("ConfirmEULA ERROR: ", err);
    });
}

export function GetAlltraineesAvatars(token) {
  const obj = { token };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("GetAlltraineesAvatars request", postData);

  const myUrl = `${myBaseURL}/Content/GetAlltraineesAvatars`;

  return axios
    .post(myUrl, postData, axiosConfig)
    .then((res) => {
      // console.log("GetAlltraineesAvatars response", res);
      return res;
    })
    .catch((err) => {
      // console.log("GetAlltraineesAvatars ERROR: ", err);
    });
}

export function GetAllAvatars(token) {
  const obj = { token };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("GetAllAvatars request", postData);

  const myUrl = `${myBaseURL}/Content/GetAllAvatars`;

  return axios
    .post(myUrl, postData, axiosConfig)
    .then((res) => {
      // console.log("GetAllAvatars response", res);
      return res;
    })
    .catch((err) => {
      // console.log("GetAllAvatars ERROR: ", err);
    });
}
export function GetAllTrainerAvatars(token) {
  const obj = { token };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("GetAllTrainerAvatars request", postData);

  const myUrl = `${myBaseURL}/Content/GetAllTrainerAvatars`;

  return axios
    .post(myUrl, postData, axiosConfig)
    .then((res) => {
      // console.log("GetAllTrainerAvatars response", res);
      return res;
    })
    .catch((err) => {
      // console.log("GetAllTrainerAvatars ERROR: ", err);
    });
}

export function GetAvatarByID(token, AvatarID) {
  const obj = { token, AvatarID: AvatarID + "" };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("GetAvatarByID request", postData);

  const myUrl = `${myBaseURL}/Content/GetAvatarByID`;

  return axios
    .post(myUrl, postData, axiosConfig)
    .then((res) => {
      // console.log("GetAvatarByID response", res);
      return res;
    })
    .catch((err) => {
      // console.log("GetAvatarByID ERROR: ", err);
    });
}

export function SetScnAvatar(ScnID, token, AvatarID) {
  const obj = {
    token,
    AvatarID: AvatarID + "",
    ScnID: ScnID + "",
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("SetScnAvatar request", postData);

  const myUrl = `${myBaseURL}/Content/SetScnAvatar`;

  return axios
    .post(myUrl, postData, axiosConfig)
    .then((res) => {
      // console.log("SetScnAvatar response", res);
      return res;
    })
    .catch((err) => {
      // console.log("SetScnAvatar ERROR: ", err);
    });
}

export function SaveFeedback(
  token,
  trainingId,
  feedbackScore,
  feedbackText,
  scheduledID
) {
  const obj = {
    token,
    TrainingID: trainingId + "",
    feedbackScore: feedbackScore + "",
    feedbackText: feedbackText + "",
    ScheduledID: scheduledID ? String(scheduledID) : "-1",
  };

  const postData = JSON.stringify(JSON.stringify(obj));


  const myUrl = `${myBaseURL}/Reviews/SaveFeedback`;

  return axios
    .post(myUrl, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("SaveFeedback ERROR: ", err);
    });
}

export function updateUserApi(credentials, id, token) {
  const obj = {
    TraineeID: id.toString(),
    trainee_object: JSON.stringify(credentials),
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("updateUserApi request", postData);

  const myUrl = `${myBaseURL}/Identity/UpdateTrainee`;

  return axios
    .post(myUrl, postData, axiosConfig)
    .then((res) => {
      // console.log("updateUserApi response", res);
      return res;
    })
    .catch((err) => {
      // console.log("updateUserApi ERROR: ", err);
      throw new Error(err);
    });
}
export function updateMyProfile(credentials) {
  const myUrl = `${myBaseURL}/Users`;

  return axios
    .put(myUrl, credentials, axiosConfig)
    .then((res) => {
      // console.log("UpdateMyProfile response", res);
      return res;
    })
    .catch((err) => {
      // console.log("UpdateMyProfile ERROR: ", err);
      throw new Error(err);
    });
}

export function ChangeMyAvatar(token, avatarId) {
  const obj = {
    token,
    avatarId: avatarId + "",
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("ChangeMyAvatar request", postData);

  const myUrl = `${myBaseURL}/Identity/ChangeMyAvatar`;

  return axios
    .post(myUrl, postData, axiosConfig)
    .then((res) => {
      // console.log("ChangeMyAvatar response", res);
      return res;
    })
    .catch((err) => {
      // console.log("ChangeMyAvatar ERROR: ", err);
      throw new Error(err);
    });
}

export function IsRanger(token) {
  const obj = {
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("IsRanger request", postData);

  const myUrl = `${myBaseURL}/RangerHall/IsRanger`;

  return axios
    .post(myUrl, postData, axiosConfig)
    .then((res) => {
      // console.log("IsRanger response", res);
      return res;
    })
    .catch((err) => {
      // console.log("IsRanger ERROR: ", err);
      throw new Error(err);
    });
}

export function GetTraineeHall(token, TraineeId) {
  const obj = {
    token: token + "",
    TraineeId: TraineeId + "",
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("GetTraineeHall request", postData);

  const myUrl = `${myBaseURL}/Identity/GetTraineeHall`;

  return axios
    .post(myUrl, postData, axiosConfig)
    .then((res) => {
      // console.log("GetTraineeHall response", res);
      return res;
    })
    .catch((err) => {
      // console.log("GetTraineeHall ERROR: ", err);
    });
}

export function GetUsers() {
  //Ranger - Organization
  //Admin - All
  return axios
    .get(`${myBaseURL}/Users`, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
}

export const PostOnDemandTrainings = async (onDemandDetails) => {
  return axios
    .post(
      `${myBaseURL}/Users/ScenarioPermissionBulk`,
      onDemandDetails,
      axiosConfig
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const UpdateOnDemandTrainings = async (onDemandDetails) => {
  return axios
    .put(
      `${myBaseURL}/Users/ScenarioPermissionBulk`,
      onDemandDetails,
      axiosConfig
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const DeleteOnDemandTrainings = async (scenarioPermissionIDs) => {
  const configWithData = {
    ...axiosConfig,
    data: scenarioPermissionIDs,
  };
  return axios
    .delete(`${myBaseURL}/Users/ScenarioPermissionBulk`, configWithData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const updateUserScenarioPermission = async (
  userId,
  updatePermission
) => {
  return axios
    .put(
      `${myBaseURL}/Users/${userId}/ScenarioPermission/`,
      updatePermission,
      axiosConfig
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const deleteUserScenarioPermission = async (
  userScenarioPermissionId
) => {
  return axios
    .delete(
      `${myBaseURL}/Users/ScenarioPermission/${userScenarioPermissionId}`,
      axiosConfig
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const deleteUserScenarioPermissionByScenarioId = async (
  userId,
  scenarioId,
  start_time,
  end_time
) => {
  const axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    params: {
      start_time,
      end_time,
    },
  };

  return axios
    .delete(
      `${myBaseURL}/Users/${userId}/ScenarioPermission/Scenario/${scenarioId}`,
      axiosConfig
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const updateBulkUserScenarioPermission = async (updatePermission) => {
  return axios
    .put(
      `${myBaseURL}/Users/ScenarioPermission/Bulk`,
      updatePermission,
      axiosConfig
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
};
