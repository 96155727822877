export const squareTrophy = [
  "48 42",
  `
  <defs>
        <filter id="j8jtekl0gb" width="160.6%" height="181%" x="-30.3%" y="-40.5%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
        </filter>
        <filter id="1bsqk73src" width="160.6%" height="181%" x="-30.3%" y="-40.5%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
        </filter>
        <filter id="gu0mfaortd" width="160.6%" height="181%" x="-30.3%" y="-40.5%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
        </filter>
        <filter id="b4bum0gg0e" width="160.6%" height="181%" x="-30.3%" y="-40.5%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
        </filter>
        <filter id="lfopauskwg" width="132.3%" height="181%" x="-16.2%" y="-40.5%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
        </filter>
        <filter id="4m8o6hxzhh" width="192.5%" height="191.7%" x="-46.2%" y="-40.4%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius=".72" result="shadowSpreadOuter1"/>
            <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feMorphology in="SourceAlpha" radius="1" result="shadowInner"/>
            <feOffset dy="2" in="shadowInner" result="shadowInner"/>
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.673183718 0 0 0 0 0.560146019 0 0 0 0 0.298544488 0 0 0 1 0"/>
        </filter>
        <filter id="8de8km55pk" width="185.2%" height="207.4%" x="-42.3%" y="-47.3%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius=".72" result="shadowSpreadOuter1"/>
            <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feMorphology in="SourceAlpha" radius="1" result="shadowInner"/>
            <feOffset dy="2" in="shadowInner" result="shadowInner"/>
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.673183718 0 0 0 0 0.560146019 0 0 0 0 0.298544488 0 0 0 1 0"/>
        </filter>
        <filter id="is48uaz7oo" width="119%" height="141.6%" x="-9.2%" y="-20.8%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.16"/>
        </filter>
        <filter id="b3d2znsr0q" width="243.9%" height="283.4%" x="-72%" y="-91.7%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.16"/>
        </filter>
        <filter id="1hbkh1kitr" width="243.9%" height="283.4%" x="-72%" y="-91.7%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.16"/>
        </filter>
        <radialGradient id="yrt87cki4a" cx="51.821%" cy="47.185%" r="75.499%" fx="51.821%" fy="47.185%" gradientTransform="scale(.74906 1) rotate(35.273 .605 .745)">
            <stop offset="0%" stop-color="#BB9847" stop-opacity=".5"/>
            <stop offset="39.009%" stop-color="#C1A050" stop-opacity=".5"/>
            <stop offset="59.591%" stop-color="#CBAB58" stop-opacity=".5"/>
            <stop offset="100%" stop-color="#BB9847" stop-opacity=".5"/>
        </radialGradient>
        <radialGradient id="bdmemz3lmf" cx="51.821%" cy="47.185%" r="123.522%" fx="51.821%" fy="47.185%" gradientTransform="scale(.3995 1) rotate(20.668 .908 2.608)">
            <stop offset="0%" stop-color="#BB9847" stop-opacity=".5"/>
            <stop offset="39.009%" stop-color="#C1A050" stop-opacity=".5"/>
            <stop offset="59.591%" stop-color="#CBAB58" stop-opacity=".5"/>
            <stop offset="100%" stop-color="#BB9847" stop-opacity=".5"/>
        </radialGradient>
        <radialGradient id="g0e2ndkyyn" cx="51.821%" cy="47.185%" r="81.352%" fx="51.821%" fy="47.185%" gradientTransform="scale(.67224 1) rotate(32.406 .645 .907)">
            <stop offset="0%" stop-color="#BB9847" stop-opacity=".5"/>
            <stop offset="39.009%" stop-color="#C1A050" stop-opacity=".5"/>
            <stop offset="59.591%" stop-color="#CBAB58" stop-opacity=".5"/>
            <stop offset="100%" stop-color="#BB9847" stop-opacity=".5"/>
        </radialGradient>
        <radialGradient id="xebphqdo3p" cx="51.821%" cy="47.185%" r="73.229%" fx="51.821%" fy="47.185%" gradientTransform="scale(.78473 1) rotate(36.539 .59 .687)">
            <stop offset="0%" stop-color="#BB9847" stop-opacity=".5"/>
            <stop offset="39.009%" stop-color="#C1A050" stop-opacity=".5"/>
            <stop offset="59.591%" stop-color="#CBAB58" stop-opacity=".5"/>
            <stop offset="100%" stop-color="#BB9847" stop-opacity=".5"/>
        </radialGradient>
        <linearGradient id="jndm029oij" x1="28.667%" x2="71.386%" y1="35.722%" y2="77.996%">
            <stop offset="0%" stop-color="#A9924F"/>
            <stop offset="34.956%" stop-color="#D1C06F"/>
            <stop offset="60.494%" stop-color="#B19B55"/>
            <stop offset="100%" stop-color="#AC9551"/>
        </linearGradient>
        <linearGradient id="947pbsmjkm" x1="27.643%" x2="73.814%" y1="39.096%" y2="72.392%">
            <stop offset="0%" stop-color="#A9924F"/>
            <stop offset="34.956%" stop-color="#D1C06F"/>
            <stop offset="60.494%" stop-color="#B19B55"/>
            <stop offset="96.964%" stop-color="#AC9551"/>
        </linearGradient>
        <linearGradient id="nz5whxbgfs" x1="35.656%" x2="64.38%" y1="35.722%" y2="77.996%">
            <stop offset="0%" stop-color="#B39A53"/>
            <stop offset="34.956%" stop-color="#D1C06F"/>
            <stop offset="60.494%" stop-color="#B19B55"/>
            <stop offset="100%" stop-color="#C2A85B"/>
        </linearGradient>
        <path id="muhn2t20qi" d="M11.924 2.668h22.46c2.687 0 4.902 2.11 5.033 4.794l1.289 26.386c.136 2.78-2.008 5.144-4.788 5.28-.082.004-.164.006-.246.006H10.635c-2.783 0-5.04-2.256-5.04-5.04 0-.082.002-.164.006-.246l1.29-26.386c.13-2.684 2.345-4.794 5.033-4.794z"/>
        <path id="of7d6j6xzl" d="M12.027 2.668h22.435c2.784 0 5.04 2.257 5.04 5.04 0 .424-.053.846-.159 1.257l-5.42 21.05c-.573 2.226-2.58 3.783-4.88 3.783H18.049c-2.25 0-4.227-1.491-4.846-3.654L7.182 9.094c-.766-2.676.783-5.466 3.459-6.231.45-.13.917-.195 1.386-.195z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g transform="translate(-502 -133) translate(502.15 133)">
                    <ellipse cx="20.482" cy="2.668" fill="url(#yrt87cki4a)" filter="url(#j8jtekl0gb)" opacity=".265" rx="3.562" ry="2.668"/>
                    <ellipse cx="22.263" cy="12.452" fill="url(#yrt87cki4a)" filter="url(#1bsqk73src)" opacity=".462" rx="3.562" ry="2.668"/>
                    <ellipse cx="24.044" cy="22.235" fill="url(#yrt87cki4a)" filter="url(#gu0mfaortd)" opacity=".462" rx="3.562" ry="2.668"/>
                    <ellipse cx="31.168" cy="32.908" fill="url(#yrt87cki4a)" filter="url(#b4bum0gg0e)" opacity=".265" rx="3.562" ry="2.668"/>
                    <ellipse cx="22.708" cy="39.134" fill="url(#bdmemz3lmf)" filter="url(#lfopauskwg)" opacity=".265" rx="6.679" ry="2.668"/>
                    <g>
                        <use fill="#000" filter="url(#4m8o6hxzhh)" xlink:href="#muhn2t20qi"/>
                        <use stroke="url(#jndm029oij)" stroke-width="1.44" xlink:href="#muhn2t20qi"/>
                    </g>
                    <g>
                        <use fill="#000" filter="url(#8de8km55pk)" xlink:href="#of7d6j6xzl"/>
                        <use stroke="url(#947pbsmjkm)" stroke-width="1.44" xlink:href="#of7d6j6xzl"/>
                    </g>
                    <path fill="url(#g0e2ndkyyn)" fill-opacity=".3" d="M7.252 2.668h32.054c2.783 0 5.04 2.257 5.04 5.04 0 .544-.088 1.084-.261 1.6l-7.045 21.05c-.688 2.054-2.613 3.44-4.78 3.44H15.08c-2.106 0-3.99-1.31-4.724-3.284L2.528 9.465c-.97-2.609.358-5.51 2.967-6.48.562-.21 1.157-.317 1.757-.317z" filter="url(#is48uaz7oo)" opacity=".694"/>
                    <g fill="url(#xebphqdo3p)" opacity=".462" transform="translate(16.277 6.325)">
                        <ellipse cx="5.591" cy="3.829" filter="url(#b3d2znsr0q)" rx="4.502" ry="3.533"/>
                        <ellipse cx="8.163" cy="16.676" filter="url(#1hbkh1kitr)" rx="4.502" ry="3.533"/>
                    </g>
                </g>
                <g fill="url(#nz5whxbgfs)" fill-rule="nonzero" transform="translate(-502 -133) translate(502.15 133) translate(14.842 7.412)">
                    <path d="M13.005 0c.189 0 .342.154.342.339V2.05h2.395c.378 0 .685.306.685.683v4.447c0 1.318-1.074 2.39-2.396 2.39h-.684c-.002 1.887-1.533 3.417-3.422 3.417H8.898v3.418h1.37c1.134 0 2.052.92 2.052 2.051v2.05H4.107v-2.05c0-1.133.918-2.05 2.051-2.05H7.53v-3.419H6.502c-1.889 0-3.42-1.526-3.422-3.418h-.684C1.074 9.57 0 8.5 0 7.181V2.734c0-.377.306-.683.684-.683H3.08V.339c0-.187.153-.339.342-.339h9.583zM3.08 3.418H1.369v3.763c0 .525.401.96.915 1.016l.112.006h.684V3.418zm11.978 0h-1.711v4.785h.684c.566 0 1.027-.46 1.027-1.022V3.418z"/>
                </g>
            </g>
        </g>
    </g>
  `,
];
