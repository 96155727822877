import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

function SubHeaderNormal({ text }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ theme });

  return <div className={classes.headerRoot}>{text}</div>;
}

const useStyles = makeStyles(() => ({
  headerRoot: {
    textShadow: "0 0 6px rgba(0, 0, 0, 0.35)",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "16px",
    fontWeight: "300",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    marginBottom: "4px",
  },
}));

export default SubHeaderNormal;
