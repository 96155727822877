import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Box from "@material-ui/core/Box";
import { getUpdatedScore } from "./shared/functions";
import TrainingPopupContainerHeader from "../training/TrainingPopupContainerHeader";
import TrainingHeaderTitle from "../training/TrainingHeaderTitle";
import TrainingPopupContainerBody from "../training/TrainingPopupContainerBody";
import { setModalState } from "../../store/actions/ui";
import TimelineContainer from "./../training/leaderboardTimline/timeline/TimelineContainer";

function LeaderboardModalTraining({ closeClick }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dataState, setData] = useState([]);

  const leaderboard = useSelector((state) => state.leaderboardReducer);

  const openLeaderboard = () => {
    dispatch(setModalState(true, "leaderboard"));
    closeClick();
  };

  useEffect(() => {
    const leaderboard2 = getUpdatedScore(leaderboard);
    setData(leaderboard2);
  }, [leaderboard]);

  const headerSize = { width: "100%", height: "35px" };

  return (
    <div className={classes.leaderboardRoot}>
      <TrainingPopupContainerHeader closeClick={closeClick} size={headerSize}>
        <Box display="flex" justifyContent="start" alignItems="center">
          <TrainingHeaderTitle text="Leaderboard" />
          {/* <IconButton onClick={openLeaderboard}>
            <OpenInNewIcon className={classes.icon} fontSize="small" />
          </IconButton> */}
        </Box>
      </TrainingPopupContainerHeader>
      {/* <TrainingPopupContainerBody> */}
      <TimelineContainer leaderboard={dataState} />
      {/* </TrainingPopupContainerBody> */}
    </div>
  );
}

const useStyles = makeStyles({
  leaderboardRoot: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    border: "solid 1px rgba(255, 255, 255, 0.09)",
    borderRadius: "10px",
    WebkitBackdropFilter: "blur(5px)",
    backdropFilter: "blur(5px)",
    boxShadow: "0 0 2px 0 rgba(255, 255, 255, 0.35)",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  icon: {
    cursor: "pointer",
    color: "white",
  },
});

export default LeaderboardModalTraining;
