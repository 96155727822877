import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

function FeedbackInputBox({
  rows = 6,
  width,
  height,
  maxLength = 350,
  placeholder,
  onChange,
}) {
  const classes = useStyles({ width, height });

  return (
    <div className={classes.inputRoot}>
      <TextField
        id="feedback-multiline"
        multiline
        rows={rows}
        placeholder={placeholder}
        inputProps={{ maxLength: maxLength }}
        className={classes.inputBox}
        onChange={onChange}
      />
    </div>
  );
}

const useStyles = makeStyles({
  inputRoot: {
    width: (props) => props.width || "370px",
    height: (props) => props.height || "146px",
    objectFit: "contain",
    borderRadius: "19px",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
  },
  inputBox: {
    width: (props) => props.width || "370px",
    height: (props) => props.height || "146px",
    padding: "15px",
    color: "#1b202c",
    "& .MuiInputBase-input": {
      fontFamily: "NunitoSans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.3",
      letterSpacing: "normal",
      color: "#ffffff",
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
    "& .MuiInput-underline": {
      borderBottom: "0px solid red",
    },
    "& .MuiInput-underline::before": {
      borderBottom: "transparent",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "transparent",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: "transparent",
    },
    "& label.Mui-focused": {
      color: "rgba(27, 32, 44, 0.4)",
    },
    "& .MuiInputLabel-formControl": {
      top: "-7px",
      left: "25px",
    },
    "&  .MuiInputLabel-shrink ": {
      top: "5px",
      left: "20px",
    },
  },
});

export default FeedbackInputBox;
