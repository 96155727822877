export const graphQuestion = [
  "12 23",
  `
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="timeline-icons" transform="translate(-314.000000, -170.000000)">
      <g id="question-mark-" transform="translate(300.000000, 163.000000)">
          <g id="?" transform="translate(20.000000, 18.500000) rotate(1.000000) translate(-20.000000, -18.500000) translate(15.000000, 8.000000)">
              <path d="M4,20.002 C4,20.552 4.446,21 5,21 C5.55,21 6,20.552 6,20.002 C6,19.45 5.55,19 5,19 C4.446,19 4,19.45 4,20.002 Z" id="Fill-3" fill="#FFFFFF"></path>
              <path d="M0,5.09090909 C0,2.27781818 2.23714286,0 5,0 C7.76285714,0 10,2.27781818 10,5.09090909 C10,7.904 7.76285714,10.1818182 5,10.1818182 L5,16" id="Stroke-5" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          </g>
      </g>
  </g>
</g>`,
];
