import React, { useState, useRef, useCallback } from "react";

import { useDispatch } from "react-redux";
import { removeMissionPopup } from "./../../../store/actions/missions";
import { decrementPopupCount } from "../../../store/actions/ui";
import FloatingPopup from "../shared/FloatingPopup";
import useWindowDimensions from "../../../hooks/useWindowDiemensions";

function MissionWelcome({ mission }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [attachment, setAttachment] = useState({});
  const [currentSlide, setCurrentSlide] = useState(0);

  const missionSliderRef = useRef(null);
  const { height } = useWindowDimensions();
  const defaultPosition = { x: 35, y: height / -2.5 };
  const dispatch = useDispatch();

  const openAttachment = useCallback((name, type, value) => {
    setModalOpen(true);
    setAttachment({ name, type, value });
  }, []);

  const closeAttachment = () => {
    setModalOpen(false);
    setAttachment({});
  };

  const approveMission = useCallback(() => {
    dispatch(removeMissionPopup(mission?.id));
    dispatch(decrementPopupCount("missions", 1));
  }, [mission?.id]);

  const dotClick = (value) => {
    if (
      missionSliderRef?.current !== undefined &&
      missionSliderRef?.current !== null
    ) {
      missionSliderRef.current.slickGoTo(value);
    }
  };

  return (
    <FloatingPopup
      handle="mission"
      data={mission}
      approveClick={approveMission}
      modalOpen={modalOpen}
      attachment={attachment}
      closeAttachment={closeAttachment}
      openAttachment={openAttachment}
      dotClick={dotClick}
      currentSlide={currentSlide}
      setCurrentSlide={setCurrentSlide}
      sliderRef={missionSliderRef}
      defaultPosition={defaultPosition}
    />
  );
}

export default MissionWelcome;
