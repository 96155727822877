import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

function ShadowedText({ size = "25px", text, type = "white" }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const colors = {
    red: "#ff2d00",
    blue: "#16a3ed",
    green: "#16ed62",
    white: "#ffffff",
    grey: "#6f727a",
    orange: "#ff7b00",
  };

  const color = colors[type];
  const classes = useStyles({ size, color, theme });

  return <div className={classes.shadowText}>{text}</div>;
}

const useStyles = makeStyles({
  shadowText: {
    textShadow: (props) => `0 0 6px ${props.color}`,
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: (props) => props.size,
    color: (props) => props.color,
  },
});

export default memo(ShadowedText);
