import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import WelcomeApprovalButton from "../../buttons/PopupApprovalButton";
import GifSlider from "./GifSlider";
import PopupCard from "../shared/PopupCard";
import SubHeaderNormal from "./../shared/SubHeaderNormal";
import MainHeader from "./../shared/MainHeader";
import DescriptionText from "./../shared/DescriptionText";
import welcomeMessages from "./../../../constants/welcomeMessages";
import TeamTrainingHeader from "./../shared/TeamTrainingHeader";

function WelcomeContainer({ location, confirm }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [timer, setTimer] = useState(1);
  const classes = useStyles();
  const history = useHistory();

  const { scheduledTrainees } = useSelector((state) => state.trainingReducer);
  const { rejoinStatus } = useSelector((state) => state.trainingReducer);
  const descriptionMessage = welcomeMessages[currentSlide];
  const { theme } = useSelector((state) => state.uiReducer);


  // use this to restrict the time trainee can be inactive on the welcome page before sent to personal hall
  // useEffect(() => {
  //   setTimeout(() => {
  //     setTimer(0);
  //   }, 20000);
  // });

  const isTeamTraining = scheduledTrainees && scheduledTrainees.length > 1;

  useEffect(() => {
    setTimeout(() => {
      setTimer(0);
    }, 3600000);
  });


  useEffect(() => {
    if (timer === 0) {
      history.push("/personal-hall");
    }
  }, [timer]);

  const changeCurrentSlide = (next) => {
    setCurrentSlide(next);
  };

  const getStartSelfPacedStatus = async () => {
    confirm();
  };

  return (
    <div id="welcome" className={classes.welcomeRoot}>
      <PopupCard size={isTeamTraining ? ["551px", "570px"] : undefined}>
        {isTeamTraining && <TeamTrainingHeader />}
        <SubHeaderNormal text={`Welcome ${rejoinStatus ? "back" : "to"}`} />
        <MainHeader text={`${theme.COMPANY_DISPLAY_NAME} training`}/>
        <div className={classes.descriptionWrapper}>
          <DescriptionText text={descriptionMessage} />
        </div>
        <GifSlider changeCurrentSlide={changeCurrentSlide} />
        {location.pathname === "/training" ? (
          <WelcomeApprovalButton
            text={rejoinStatus ? "CONTINUE EXERCISE" : "JOIN EXERCISE!"}
            approveClick={getStartSelfPacedStatus}
          />
        ) : (
          <WelcomeApprovalButton
            text={rejoinStatus ? "CONTINUE EXERCISE" : "START EXERCISE!"}
            approveClick={getStartSelfPacedStatus}
          />
        )}
      </PopupCard>
    </div>
  );
}

const useStyles = makeStyles({
  welcomeRoot: {
    border: "none",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  descriptionWrapper: {
    height: "35px",
    paddingTop: "8px",
  },
});

export default WelcomeContainer;
