import { SET_MITRE_DB } from "../types/mitreDB.js";

const initialState = {};

const mitreDBReducer = (state = initialState, action) => {
  const { type, mitreDB } = action;

  switch (type) {
    case SET_MITRE_DB:
      return { ...state, mitreDB };
    default:
      return state;
  }
};
export default mitreDBReducer;
