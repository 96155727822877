export const leaderboardBadge = [
  "20 20",
  `
  <defs>
  <linearGradient id="wbr9lvv4ca" x1="50%" x2="50%" y1="19.351%" y2="100%">
      <stop offset="0%" stop-color="#FF852E"/>
      <stop offset="100%" stop-color="#EB7826"/>
  </linearGradient>
  <linearGradient id="izfym2tn9b" x1="50%" x2="50%" y1="19.351%" y2="100%">
      <stop offset="0%" stop-color="#FF852E"/>
      <stop offset="100%" stop-color="#EB7826"/>
  </linearGradient>
  <filter id="m9tlfxvcjd" width="157.1%" height="172.7%" x="-28.6%" y="-36.3%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation=".673"/>
  </filter>
  <filter id="060qaqcwge" width="157.1%" height="172.7%" x="-28.6%" y="-36.3%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation=".673"/>
  </filter>
  <radialGradient id="vwivxix8ac" cx="50%" cy="50%" r="50%" fx="50%" fy="50%" gradientTransform="matrix(0 1 -.78571 0 .893 0)">
      <stop offset="0%" stop-color="#FF8E3D"/>
      <stop offset="100%" stop-color="#FA7200"/>
  </radialGradient>
</defs>
<g fill="none" fill-rule="evenodd" opacity=".8">
  <g>
      <g>
          <g>
              <g stroke-width="1.346" transform="translate(-967 -304) translate(967 304) translate(.91 .91)">
                  <path fill="url(#wbr9lvv4ca)" stroke="url(#wbr9lvv4ca)" d="M18.142.15c-.054-.093-.155-.15-.263-.15h-5.455c-.107 0-.207.056-.261.147L9.09 5.283 6.02.147C5.964.056 5.865 0 5.758 0H.303C.194 0 .094.057.04.15c-.054.093-.053.207.002.3l4.776 7.985c-2.08 2.266-1.96 5.746.271 7.868 2.232 2.122 5.772 2.122 8.004 0 2.231-2.122 2.351-5.602.27-7.868L18.14.45c.055-.092.056-.206.002-.299zM.832.596h4.752L8.83 6.02l.001.003.29.483.03.052h-.026c-.08 0-.16.002-.24.005l-.09.003c-.114.005-.228.014-.346.026-.156.017-.31.041-.464.07-.052.011-.103.025-.155.036-.1.023-.201.045-.3.073-.061.017-.12.037-.18.056-.09.028-.178.056-.266.088-.061.023-.122.048-.183.073-.084.034-.167.07-.25.107-.06.028-.12.058-.18.088-.08.04-.16.083-.238.127l-.172.1c-.077.048-.154.098-.23.15-.053.036-.107.072-.16.11-.077.056-.15.115-.225.174-.048.04-.097.077-.143.117l-.044.035L.833.596zm13.41 11.625c0 2.723-2.191 4.958-4.958 5.06-2.767.1-5.12-1.968-5.327-4.684-.207-2.716 1.806-5.106 4.557-5.412.192-.02.384-.031.577-.031.046 0 .094.006.14.007.143.004.286.008.436.024.438.048.868.151 1.278.308.689.263 1.312.668 1.83 1.187h0c.943.944 1.47 2.216 1.467 3.54h0zm-1.319-4.225l-.043-.035c-.043-.038-.088-.073-.133-.108-.075-.062-.152-.122-.23-.18l-.15-.103c-.078-.054-.157-.105-.238-.155l-.155-.092c-.085-.048-.171-.094-.258-.138l-.15-.075c-.1-.047-.203-.09-.305-.13-.04-.017-.079-.035-.12-.05-.143-.054-.289-.103-.436-.146-.023-.006-.047-.01-.07-.017-.125-.035-.25-.065-.377-.091-.053-.011-.107-.019-.16-.028-.071-.013-.141-.027-.212-.037l-.443-.74L12.598.596h4.751l-4.426 7.4z"/>
                  <circle cx="9.091" cy="12.294" r="2.727" fill="url(#izfym2tn9b)" stroke="url(#izfym2tn9b)"/>
              </g>
              <g fill="url(#vwivxix8ac)" opacity=".265" transform="translate(-967 -304) translate(967 304) translate(.91 .91) translate(4.545)">
                  <ellipse cx="3.535" cy="2.879" filter="url(#m9tlfxvcjd)" rx="3.535" ry="2.778"/>
                  <ellipse cx="8.283" cy="15.707" filter="url(#060qaqcwge)" rx="3.535" ry="2.778"/>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
