import React, { memo, useMemo, useState, useEffect } from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";

function RoundAvatar({
  username,
  avatarClass,
  size,
  randomColor = false,
  image,
  color = "",
  avatarId,
}) {
  const [userAvatar, setUserAvatar] = useState("null");
  const { allTraineesAvatars } = useSelector(
    (state) => state.personalHallReducer
  );

  // console.log("wiwi 2", allTraineesAvatars);
  // console.log("wiwi 3", avatarId);

  useEffect(() => {
    if (allTraineesAvatars && avatarId > 0 && avatarId !== "100") {
      setUserAvatar(allTraineesAvatars[avatarId]);
    }
  }, [avatarId]);

  const myColor = useMemo(() => {
    if (color !== "") return { color };
    if (randomColor) return "";
    return { color: "#2054C3" };
  }, [color, randomColor]);

  return (
    <>
      <Avatar
        {...myColor}
        name={username}
        round={true}
        textSizeRatio={2}
        size={size}
        maxInitials={3}
        className={avatarClass}
        src={userAvatar}
      />
    </>
  );
}

export default memo(RoundAvatar);
