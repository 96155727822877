import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";

export default function ModalContainer(props) {
  const {
    open,
    setOpen,
    children,
    element,
    position,
    modalInvisible = false,
  } = props;
  const classes = useStyles({ position });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      container={() => document.getElementById(element)}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { position: "absolute" },
        invisible: modalInvisible,
      }}
    >
      <Fade in={open}>
        <>{children}</>
      </Fade>
    </Modal>
  );
}

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: (props) => props.position || "center",
  },
});
