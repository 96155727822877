import React, { useMemo, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion";
import CIcon from "@coreui/icons-react";
import RoundAvatar from "../avatar/Avatar";
import LeaderboardRowScore from "./../training/shared/AnimatedScore";
import LeaderboardRowRank from "./LeaderboardRowRank";
import LeaderboardRowName from "./LeaderboardRowName";
import { useSelector } from "react-redux";

const LeaderboardRow = ({
  data,
  isUser,
  background,
  backgroundSize = null,
  userImage,
  isClickable,
  leaderboardClick,
  setHoverLine,
}) => {
  const { id, name, avatarId, rank, score } = data;
  const { theme } = useSelector((state) => state.uiReducer);

  const classes = useStyles({
    background,
    backgroundSize,
    isUser,
    isClickable,
    theme,
  });

  const iconName = useMemo(() => {
    if (rank === 1) return "leaderboardTrophy";
    if (rank === 2) return "leaderboardSilverStar";
    if (rank === 3) return "leaderboardBronzeStar";
    return "leaderboardBadge";
  }, [rank]);

  const handleClick = () => {
    leaderboardClick(id);
  };

  const handleMouseEnter = () => {
    setHoverLine(id);
  };

  const handleMouseLeave = () => {
    setHoverLine(null);
  };

  return (
    <motion.div
      layoutId={name}
      initial={false}
      className={classes.leaderboard}
      onClick={isClickable ? handleClick : () => {}}
      // onMouseEnter={isClickable ? handleMouseEnter : () => {}}
      // onMouseLeave={isClickable ? handleMouseLeave : () => {}}
    >
      <CIcon name={iconName} width="20px" height="20px" />
      <LeaderboardRowRank rank={rank} isUser={isUser} />
      <RoundAvatar
        username={name}
        size={22}
        avatarId={avatarId}
        color="transparent"
      />
      <div className={classes.nameWrapper}>
        <LeaderboardRowName name={name} />
      </div>
      <div className={classes.scoreWrapper}>
        <LeaderboardRowScore score={score} />
      </div>
    </motion.div>
  );
};

const useStyles = makeStyles({
  leaderboard: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    fontFamily: (props) => props.theme.MAIN_FONT,
    height: "44px",
    marginBottom: (props) => (props.backgroundSize ? "6px" : "1px"),
    backgroundImage: (props) => `url(${props.background})`,
    backgroundSize: (props) =>
      props.backgroundSize || `${props.isUser ? "93%" : "92%"} 100%`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center right",
    cursor: (props) => (props.isClickable ? "pointer" : "auto"),
  },
  nameWrapper: {
    flexGrow: 1,
  },
  scoreWrapper: {
    marginRight: "10px",
  },
});

function progressPropsAreEqual(prevProps, nextProps) {
  // console.log("progressPropsAreEqual", prevProps, nextProps);
  return (
    prevProps?.data?.rank === nextProps?.data?.rank &&
    prevProps?.data?.score === nextProps?.data?.score &&
    prevProps?.data?.name === nextProps?.data?.name &&
    prevProps?.data?.picture === nextProps?.data?.picture
  );
}

export default memo(LeaderboardRow, progressPropsAreEqual);
