export const cancel = [
  "17 17",
  `
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="dropdown" transform="translate(-570.000000, -528.000000)" fill="#FFFFFF" fill-rule="nonzero" stroke="#FFFFFF">
      <g id="Group" transform="translate(571.000000, 529.000000)">
          <path d="M7.5,15 C11.63625,15 15,11.63625 15,7.5 C15,3.36375 11.63625,0 7.5,0 C3.36375,0 0,3.36375 0,7.5 C0,11.63625 3.36375,15 7.5,15 Z M7.5,1.125 C11.015625,1.125 13.875,3.984375 13.875,7.5 C13.875,11.015625 11.015625,13.875 7.5,13.875 C3.984375,13.875 1.125,11.015625 1.125,7.5 C1.125,3.984375 3.984375,1.125 7.5,1.125 Z" id="Shape"></path>
          <path d="M4.981875,10.018125 C5.0925,10.12875 5.235,10.183125 5.379375,10.183125 C5.52375,10.183125 5.668125,10.12875 5.776875,10.018125 L7.5,8.295 L9.223125,10.018125 C9.33375,10.12875 9.47625,10.183125 9.620625,10.183125 C9.765,10.183125 9.909375,10.12875 10.018125,10.018125 C10.2375,9.79875 10.2375,9.4425 10.018125,9.223125 L8.295,7.5 L10.018125,5.776875 C10.2375,5.5575 10.2375,5.20125 10.018125,4.981875 C9.79875,4.7625 9.4425,4.7625 9.223125,4.981875 L7.5,6.705 L5.776875,4.981875 C5.5575,4.7625 5.20125,4.7625 4.981875,4.981875 C4.7625,5.20125 4.7625,5.5575 4.981875,5.776875 L6.705,7.5 L4.981875,9.223125 C4.760625,9.4425 4.760625,9.79875 4.981875,10.018125 Z" id="Path"></path>
      </g>
  </g>
</g>
  `,
];
