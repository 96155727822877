import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AnimateSharedLayout } from "framer-motion";

import LeaderboardRow from "./LeaderboardRow";
import importImages from "./../../helpers/importImages";

function Leaderboard({
  backgroundSize,
  isClickable = false,
  leaderboardClick,
  setHoverLine,
}) {
  const { username, id } = useSelector((state) => state.userReducer);

  const leaderboard = useSelector((state) => state.leaderboardReducer);
  // console.log("leaderBoard!!!", leaderboard);

  const images = importImages(
    require.context(
      "../../assets/backgrounds/training/leaderboard",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );


  return (
    <AnimateSharedLayout>
      {leaderboard.map((dataItem, index) => {
        const isUser = username === dataItem?.name;
        const bgImage = `leaderboard-${
          isUser ? "current-user" : dataItem?.id
        }.svg`;
        const usrImage = `group-avatar-${dataItem?.id}.png`;
        return (
          <LeaderboardRow
            key={dataItem.id}
            data={{ ...dataItem, rank: index + 1 }}
            isUser={isUser}
            background={images?.[bgImage]}
            backgroundSize={backgroundSize}
            isClickable={isClickable}
            leaderboardClick={leaderboardClick}
            setHoverLine={setHoverLine}
          />
        );
      })}
    </AnimateSharedLayout>
  );
}

export default Leaderboard;
