import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import CIcon from "@coreui/icons-react";
import Box from "@material-ui/core/Box";
import leaderboardTrophyFrame from "../../../../assets/icons/training/leaderboard/ranks/leaderboard-trophy-frame.webp";
import leaderboardSilverStarFrame from "../../../../assets/icons/training/leaderboard/ranks/leaderboard-silver-star-frame.webp";
import leaderboardBronzeStarFrame from "../../../../assets/icons/training/leaderboard/ranks/leaderboard-bronze-star-frame.webp";
import leaderboardBadgeFrame from "../../../../assets/icons/training/leaderboard/ranks/leaderboard-badge-frame.webp";

function LeaderboardRank({ rank }) {
  const classes = useStyles();

  const icon = useMemo(() => {
    if (rank === 1) return leaderboardTrophyFrame;
    if (rank === 2) return leaderboardSilverStarFrame;
    if (rank === 3) return leaderboardBronzeStarFrame;
    return leaderboardBadgeFrame;
  }, []);

  return (
    <Box
      position="relative"
      className={classes.leaderboardRank}
      display="flex"
      flexDirection="column"
      justifyContent="end"
      alignItems="center"
    >
      <img src={icon} width="38px" height="32px" className={classes.icon} />
      <div>{rank}</div>
    </Box>
  );
}

const useStyles = makeStyles({
  leaderboardRank: {
    height: "100%",
    width: "82px",
    padding: "0 19px 10px 26px",
    fontSize: "30px",
    lineHeight: "1",
    color: "#ffffff",
  },
  icon: {
    position: "absolute",
    top: -5,
  },
});

export default LeaderboardRank;
