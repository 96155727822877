import { call, put, takeEvery } from "redux-saga/effects";
import { getOrganizationTokensSummary } from "../../services/Organizations";
import { setOrganizationTokensSummary } from "../actions/organizationTokensSummary";
import { FETCH_ORGANIZATION_SCENARIO_TOKENS_SUMMARY } from "../types/organizationTokensSummary";

function* fetchOrganizationTokensDataSummary(action) {
  try {
    const orgTokensSummary = yield call(
      getOrganizationTokensSummary,
      action.organizationID
    );

    if (
      orgTokensSummary &&
      Object.keys(orgTokensSummary).length > 0
    ) {
      yield put(setOrganizationTokensSummary(orgTokensSummary));
    } else {
      console.warn("No data received for organization tokens");
    }
  } catch (err) {
    console.warn("Failed to fetch organizationScenarioTokensData", err);
  }
}

export function* organizationTokensSummarySaga() {
  yield takeEvery(
    FETCH_ORGANIZATION_SCENARIO_TOKENS_SUMMARY,
    fetchOrganizationTokensDataSummary
  );
}
