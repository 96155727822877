import axios from "axios";

const myBaseURL =
  process.env.REACT_APP_ENVIRONMENT.trim() === "DEV"
    ? process.env.REACT_APP_BASE_URL_LOCAL_DEV
    : process.env.REACT_APP_BASE_URL_CLOUD;

const axiosConfig = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export function getUserOrganizationData(id) {
  return axios
    .get(`${myBaseURL}/Organizations/${id}`, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Failed to fetch User Information: ", err);
    });
}

export const getOrganizationPermissionsDisplay = async (
  organizationId,
  includeInvalid = true
) => {
  const axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    params: {
      includeInvalid,
    },
  };
  try {
    const res = await axios.get(
      `${myBaseURL}/Organizations/${organizationId}/ScenarioPermission/Display`,
      axiosConfig
    );
    return res?.data;
  } catch (err) {
    console.error("getOrganizationPermissions ERROR: ", err);
  }
};

export async function getAllOrganizationUsersScenarioPermissions() {

  return axios
    .get(`${myBaseURL}/Organizations/ScenarioPermission/Users`, axiosConfig)
    .then((res) => {
      const result = res.data.map((item) => {
        item.trainingType = "onDemand";
        return item;
      });
      return result;
    })
    .catch((err) => {
      console.log("Failed getAllOrganizationUsersScenarioPermissions: ", err);
    });
}

export async function getOrganizationTokensSummary(organizationId) {
  return axios
    .get(
      `${myBaseURL}/Organizations/${organizationId}/ScenarioTokensSummary`,
      axiosConfig
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Failed getOrganizationTokensSummary: ", err);
    });
}

export async function getOrganizationTokensObj(organizationId) {
  return axios
    .get(
      `${myBaseURL}/Organizations/${organizationId}/ScenarioTokens`,
      axiosConfig
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Failed getOrganizationTokensObj: ", err);
    });
}

export async function GetScheduledTrainings(organizationId) {
  return axios
    .get(
      `${myBaseURL}/Organizations/${organizationId}/ScheduledTrainings`,
      axiosConfig
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Failed GetScheduledTrainings: ", err);
    });
}

export async function GetPerformedTrainings(organizationId) {
  return axios
    .get(
      `${myBaseURL}/Organizations/${organizationId}/PerformedTrainings`,
      axiosConfig
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Failed GetPerformedTrainings: ", err);
    });
}

export async function GetTraineesPerformance(organizationId) {
  return axios
    .get(
      `${myBaseURL}/Organizations/${organizationId}/TraineesPerformance`,
      axiosConfig
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Failed GetTraineesPerformance: ", err);
    });
}

export async function GetPerformanceOverTime(organizationId) {
  return axios
    .get(
      `${myBaseURL}/Organizations/${organizationId}/PerformanceOverTime`,
      axiosConfig
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Failed GetPerformanceOverTime: ", err);
    });
}
