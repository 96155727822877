export const finishedIcon = [
  "56 56",
  `
  <g fill="none" fill-rule="evenodd" stroke-linecap="round">
  <g stroke="#4FD48A" stroke-width="6">
      <g>
          <g>
              <path d="M25 50c13.807 0 25-11.193 25-25 0-3.491-.716-6.816-2.008-9.834-.643-1.5-1.427-2.923-2.337-4.255-.936-1.37-2.005-2.642-3.188-3.797C37.96 2.712 31.797 0 25 0 11.193 0 0 11.193 0 25" transform="translate(-390 -214) translate(204 217) translate(189) rotate(-180 25 25)"/>
              <path d="M15 22.652L25.149 33 45 1" transform="translate(-390 -214) translate(204 217) translate(189)"/>
          </g>
      </g>
  </g>
</g> `,
];
