import { call, put, takeEvery } from "redux-saga/effects";

import { setUserAgent } from "../actions/userAgent";
import { getUserMachineDetailsApi } from "../../services/userApi";

function* getUserAgent() {
    try {
        const userMachineDetails = yield call(getUserMachineDetailsApi);
        console.log(userMachineDetails)
        const { city, country, ip, timezone } = userMachineDetails;
        if (city && country && ip ) {
            const userAgentDetails = { city, country, ip, timezone };
            localStorage.setItem("userAgent", JSON.stringify(userAgentDetails));
            put(setUserAgent(userAgentDetails));
        }
    } catch (e) {
        // yield put({ type: "FETCH_FAILED", message: e.message });
    }
}


export function* userSaga() {
    yield takeEvery("GET_USER_AGENT", getUserAgent);
}
