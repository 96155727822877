export const eventsCircle = [
  "34 34",
  `
  <g fill="none" fill-rule="evenodd" stroke-opacity=".282">
  <g stroke="#FFF" stroke-width=".75">
      <g>
          <g>
              <g>
                  <g transform="translate(-1119 -554) translate(1104 251) translate(7 48) translate(0 256) translate(9)">
                      <circle cx="15.907" cy="15.907" r="15.907"/>
                  </g>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
