import React, { useState, useEffect, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../../hooks/useWindowDiemensions";

import QuestionPopup from "./QuestionPopup";

function QuestionPopupContainer() {
  const { theme } = useSelector((state) => state.uiReducer);
  const [defaultPosition, setDefaultPosition] = useState({
    x: 0,
    y: 0,
  });

  const classes = useStyles({ theme });

  const { width, height } = useWindowDimensions();

  const questions = useSelector((state) => state.quizReducer);
  const { username } = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (width !== 0 && height !== 0) {
      setDefaultPosition({ x: 30, y: -600 });
    }
  }, [width, height]);

  if (!questions || questions.length === 0) return null;

  return (
    <div className={classes.popupContainer}>
      {questions &&
        questions.map((question, index) => {
          return (
            <QuestionPopup
              username={username}
              defaultPosition={defaultPosition}
              setDefaultPosition={setDefaultPosition}
              quiz={question}
              key={question.id || index}
            />
          );
        })}
    </div>
  );
}

const useStyles = makeStyles({
  popupContainer: {
    position: "fixed",
    fontFamily: (props) => props.theme.MAIN_FONT,
    letterSpacing: "0",
    lineHeight: "18px",
    textShadow: "0 0 6px 0 rgba(0,0,0,0.35)",
    color: "#FFFFFF",
    width: "100%",
    height: "100%",
    whiteSpace: "pre-line",
  },
});

export default QuestionPopupContainer;
