import React, { useState } from "react";
import Box from "@material-ui/core/Box";

import Leaderboard from "../../../leaderboard/Leaderboard";
import TimelineChart from "./TimelineChart";
import TimelineSummary from "./TimelineSummary";
import AxisLabel from "./AxisLabel";
import importImages from "./../../../../helpers/importImages";
import useEmail from "../../../../hooks/useEmail";

function TimelineContainer({ leaderboard }) {
  const [activeLine, setActiveLine] = useState("0");
  const [hoverLine, setHoverLine] = useState(null);
  const leaderboardClick = (line) => {
    console.log("line", line);
    setActiveLine(line);
  };
  const email = useEmail();



  return (
    <Box display="flex" height="100%" padding="15px 5px 40px ">
      {leaderboard.length > 0 ? (
        <>
          {" "}
          <AxisLabel text="POINTS" isVertical />
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            height="100%"
            // paddingRight="90px"
          >
            <TimelineSummary
              activeLine={activeLine}
            />
            <TimelineChart
              activeLine={activeLine}
              setActiveLine={setActiveLine}
              hoverLine={hoverLine}
              setHoverLine={setHoverLine}
              viewedTraineeId={email}

              // data={data}
            />
            <AxisLabel text="TIMELINE" />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width="260px"
            height="90%"
            paddingTop="40px"
            alignContent="center"
            justifyContent="center"
          >
            <Leaderboard
              backgroundSize="93%"
              leaderboardClick={leaderboardClick}
              isClickable={true}
              setHoverLine={setHoverLine}
              leaderboard={leaderboard}
            />
          </Box>{" "}
        </>
      ) : (
        <div
          style={{
            margin: "auto",
            fontFamily: (props) => props.theme.MAIN_FONT,
            color: "white",
            fontSize: "16px",
          }}
        >
          Waiting for trainees action, graph will be updated live...
        </div>
      )}
    </Box>
  );
}

export default TimelineContainer;
