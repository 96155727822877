import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import QuestionOptionIcon from "./QuestionOptionIcon";
import { useSelector } from "react-redux";

function QuestionOption({
  text,
  index,
  selected,
  status,
  toggleSelection,
  isSingleLine = false,
  isLocked = false,
  debrief = false,
}) {

  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({theme});

  const questionClassName = useMemo(() => {
    if (!isLocked) {
      return `${classes.questionRoot} ${selected ? classes.selected : ""}`;
    } else {
      return `${classes.questionRoot} ${classes[status]}`;
    }
  }, [isLocked, selected, status]);

  // console.log(debrief);
  // console.log(text);
  // console.log(selected);

  // Detrimine if user is correct or not,

  return (
    <Grid
      item
      onClick={() => toggleSelection(index)}
      xs={isSingleLine ? 12 : 6}
    >
      <div className={questionClassName}>
        {isLocked ? <QuestionOptionIcon status={status} /> : null}
        <div className={classes.questionText}>{text}</div>
      </div>
    </Grid>
  );
}

const useStyles = makeStyles({
  questionRoot: {
    fontFamily: (props) => props.theme.TRAINING_FONT,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "44px",
    height: "100%",
    width: "100%",
    padding: "10px 20px",
    borderRadius: "4px",
    backgroundColor: "rgba(255,255,255,0.08)",
    boxShadow:
      "0 5px 14px 0 rgba(0, 0, 0, 0.5), inset 1px -2px 4px 0 rgba(0, 0, 0, 0.36)",
    fontFamily: "NunitoSans",
    fontSize: "13px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    // textAlign: "center",
    color: "#ffffff",
    cursor: "pointer",
    position: "relative",

    "&:hover": {
      backgroundColor: `rgba(255,255,255,0.08)`,
      boxShadow:
        "inset -1px -1px 2px 0 rgba(237,22,77,0.36), 0 5px 14px 0 rgba(0,0,0,0.5)",
    },
  },
  selected: {
    border: (props) => `solid 0.5px ${props.theme.COLOR_PRIMARY}`,
  },
  questionText: {
    fontFamily: (props) => props.theme.TRAINING_FONT,
    width: "100%",
  },

  correct: {
    border: "solid 1px rgba(15, 174, 85, 0.5)",
  },
  unmarked: {
    border: "solid 1px rgba(15, 174, 85, 0.5)",
  },
  wrong: {
    border: "solid 1px #ff4747",
  },
});

export default QuestionOption;
