import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";

import TimelineAvatar from "./TimelineAvatar";
import TimelineRank from "./TimelineRank";
import TimelineScore from "./TimelineScore";
import LeaderboardAvatar from "../leaderboard/LeaderboardAvatar";

function TimelineSummary({ activeLine, userImage }) {
  const classes = useStyles();
  const [allHintsCount, setAllHintsCount] = useState(0);
  const [allMissionCount, setAllMissionCount] = useState(0);
  //LeaderBoard extand bug starts here
  const { allTraineesAvatars } = useSelector(
    (state) => state.personalHallReducer
  );
  const leaderboard = useSelector((state) => state.leaderboardReducer);
  const { missions, hints } = useSelector(
    (state) => state.missionsReducer
  );
  let userIndex = Object.values(leaderboard).findIndex(
    (el) => el.id === activeLine
  );
  userIndex < 0 ? (userIndex = 0) : (userIndex = userIndex);
  const activeUser = leaderboard[userIndex];
  const { id, name, picture, score, hintCount, missionCount } = activeUser;
  let userAvatar = activeUser.avatarId === 100 ? 0 : activeUser.avatarId;
  
  useEffect(() => {
    let maxMssionCount = 0;
    missions.forEach((mission) => {
      if (mission.type === "question" || mission.type === "task"){
        maxMssionCount++;
      }
    })
    setAllMissionCount(maxMssionCount);
  }, [missions]);

  useEffect(() => {
    let maxHintCount = 0;
    for (const missionHints of Object.values(hints)) {
      maxHintCount += missionHints.totalHints
    }
    setAllHintsCount(maxHintCount);
  }, [hints]);

  return (
    <div className={classes.summaryRoot}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="70px"
      >
        <TimelineAvatar
          size={3}
          imageSize={60}
          image={userAvatar ? allTraineesAvatars[userAvatar] : null}
          name={name}
        />
        <TimelineRank rank={userIndex + 1} />
        <TimelineScore score={score} />
      </Box>
      <Box
        display="flex"
        justifyContent="start"
        alignItems="start"
        height="30px"
        className={classes.summaryFooter}
      >
        <div className={classes.footerStats}>
          {missionCount}/{allMissionCount} Missions
        </div> 
        <div className={classes.footerStats}>0/0 Rings</div> {/* TODO: Add Feature. Max Rings */}
        <div className={classes.footerStats}>{hintCount}/{allHintsCount} Hints</div>
      </Box>
    </div>
  );
}
const useStyles = makeStyles({
  summaryRoot: {
    position: "relative",
    height: "110px",
    textShadow: "0 0 6px rgba(0, 0, 0, 0.35)",
    letterSpacing: "0",
    color: "#FFFFFF",
    padding: "0 30px 10px 80px",
  },
  summaryFooter: {
    fontSize: "13px",
    fontWeight: "600",
    lineHeight: "15px",
    background: `linear-gradient(
      to left, 
      rgba(255, 255, 255,0.2) 0%,
      rgba(255, 255, 255,0.6) 50%,
      rgba(255, 255, 255,0.2) 100%
    )
    left 
    bottom
    no-repeat`,
    backgroundSize: "100% 1px",
  },
  footerStats: {
    paddingRight: "20px",
    textShadow: "0 0 6px rgba(0, 0, 0, 0.35)",
    fontWeight: 600,
  },
});

export default TimelineSummary;
