export const profitReport = [
  "55 55",
  `
<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Cybering-UI---Scenarios" transform="translate(-26.000000, -380.000000)" fill="#FFFFFF" fill-rule="nonzero">
        <g id="Group-4" transform="translate(0.000000, 50.000000)">
            <g id="Group-5-Copy-4" transform="translate(0.000000, 308.000000)">
                <g id="profit-report" transform="translate(26.000000, 22.000000)">
                    <polygon id="Path" points="16.128 0 16.128 8.064 24.192 8.064"></polygon>
                    <path d="M16.128,10.08 C15.017184,10.08 14.112,9.174816 14.112,8.064 L14.112,0 L2.016,0 C0.905184,0 0,0.905184 0,2.016 L0,30.24 C0,31.352832 0.905184,32.256 2.016,32.256 L22.176,32.256 C23.288832,32.256 24.192,31.352832 24.192,30.24 L24.192,10.08 L16.128,10.08 Z M8.064,28.224 L4.032,28.224 L4.032,22.176 L8.064,22.176 L8.064,28.224 Z M14.112,28.224 L10.08,28.224 L10.08,18.144 L14.112,18.144 L14.112,28.224 Z M20.16,28.224 L16.128,28.224 L16.128,14.112 L20.16,14.112 L20.16,28.224 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
</g>
`,
];
