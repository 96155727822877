export const done = [
  "15 11",
  `
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="dropdown" transform="translate(-572.000000, -463.000000)" fill="#FFFFFF">
      <path d="M586.281952,466.908851 L579.571702,473.360634 C579.08808,473.825627 578.483553,474 577.879026,474 C577.274499,474 576.669972,473.767503 576.18635,473.360634 L572.680093,469.989432 C571.773302,469.117569 571.773302,467.664465 572.680093,466.792602 C573.586884,465.92074 575.098201,465.92074 576.004992,466.792602 L577.818573,468.536328 L582.896601,463.653897 C583.803391,462.782034 585.314709,462.782034 586.2215,463.653897 C587.249196,464.583884 587.249196,465.978864 586.281952,466.908851 Z" id="Path"></path>
  </g>
</g>
  `,
];
