export const timelineSettingsIcon = [
  "30 30",
  `
  <g fill="none" fill-opacity=".344" fill-rule="evenodd">
  <g fill="#FFF">
      <g>
          <path d="M16.558 30h-3.116c-.813 0-1.499-.582-1.631-1.383l-.523-3.174c-.355-.127-.704-.272-1.046-.435l-2.616 1.874c-.642.46-1.573.383-2.13-.173l-2.204-2.204c-.576-.576-.648-1.472-.174-2.132l1.874-2.614c-.163-.343-.308-.693-.435-1.048l-3.172-.522C.582 18.055 0 17.369 0 16.558v-3.115c0-.815.582-1.501 1.385-1.632l3.172-.523c.126-.354.272-.703.434-1.046L3.117 7.627c-.473-.661-.4-1.558.174-2.132l2.203-2.202c.557-.559 1.49-.636 2.132-.175l2.615 1.874c.342-.163.692-.308 1.046-.434l.523-3.175C11.942.582 12.628 0 13.441 0h3.116c.812 0 1.498.582 1.63 1.383l.523 3.175c.355.126.704.271 1.047.434l2.615-1.874c.643-.46 1.576-.384 2.133.175l2.202 2.202c.574.573.648 1.47.174 2.131l-1.875 2.617c.164.343.309.693.435 1.045l3.173.524c.803.13 1.386.815 1.386 1.63v3.115c0 .815-.584 1.5-1.386 1.63l-3.173.525c-.125.353-.27.703-.435 1.046l1.877 2.615c.474.661.4 1.557-.175 2.132l-2.203 2.202c-.312.313-.728.485-1.17.485-.347 0-.68-.107-.962-.309l-2.616-1.876c-.342.164-.691.31-1.045.435l-.524 3.174c-.132.802-.818 1.384-1.63 1.384zM15 21.562c-3.62 0-6.563-2.944-6.563-6.562 0-3.619 2.944-6.563 6.563-6.563 3.619 0 6.563 2.944 6.563 6.563 0 3.618-2.944 6.563-6.563 6.563zm0-10.312c-2.068 0-3.75 1.682-3.75 3.75 0 2.067 1.682 3.75 3.75 3.75s3.75-1.683 3.75-3.75c0-2.068-1.682-3.75-3.75-3.75z" transform="translate(-1224 -54) translate(1224 54)"/>
      </g>
  </g>
</g>
  `,
];
