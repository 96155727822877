import {
  SET_TRAINING_STATUS,
  SET_REJOIN_STATUS,
  SET_TRAINING_TIMER,
  SET_TIMER_WARNINGS,
  SET_TRAINING_TOKEN,
  SET_TRAINING_RANGER,
  SET_TRAINING_ID,
  SET_TRAINING_SCHEDULED_ID,
  SET_CONNECTED_TRAINEES,
  UPDATE_DISCONNECTED_TRAINEE,
  SET_SCHEDULED_TRAINEES,
  RESET_STATE,
} from "../types/training";

const initialState = {
  trainingStatus: "initial",
  trainingTimer: null,
  vncToken: "",
  rangerId: "",
  rangerName: "",
  avatarId: "",
  trainingId: "",
  scheduledID: "",
  timeLeft: 0,
  rejoinStatus: null,
  connectedTrainees: [],
  scheduledTrainees: [],
};

const trainingReducer = (state = initialState, action) => {
  const {
    status,
    vncToken,
    rangerId,
    rangerName,
    type,
    id,
    time,
    scheduledID,
    rejoinStatus,
    timeLeft,
    connectedTrainees,
    scheduledTrainees,
    traineeEmail,
  } = action;

  switch (type) {
    case SET_TRAINING_STATUS:
      return { ...state, trainingStatus: status };
    case SET_REJOIN_STATUS:
      return { ...state, rejoinStatus: rejoinStatus };
    case SET_TRAINING_TIMER:
      return { ...state, trainingTimer: time };
    case SET_TIMER_WARNINGS:
      return { ...state, timeLeft: timeLeft };
    case SET_TRAINING_ID:
      return { ...state, trainingId: id };
    case SET_TRAINING_TOKEN:
      return { ...state, vncToken };
    case SET_TRAINING_RANGER:
      return { ...state, rangerId, rangerName };
    case SET_TRAINING_SCHEDULED_ID:
      return { ...state, scheduledID };
    case SET_CONNECTED_TRAINEES:
      return { ...state, connectedTrainees };
    case SET_SCHEDULED_TRAINEES:
      return { ...state, scheduledTrainees };
    case UPDATE_DISCONNECTED_TRAINEE:
      const connectedTraineesAfterUpdate = state.connectedTrainees.filter(
        (trainee) => trainee !== traineeEmail
      );
      return {
        ...state,
        connectedTrainees: connectedTraineesAfterUpdate,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
export default trainingReducer;
