export const negativeEmoji = [
  "22 22",
  `
  <defs>
        <filter id="1ylpylghza">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.929412 0 0 0 0 0.086275 0 0 0 0 0.301961 0 0 0 1.000000 0"/>
        </filter>
        <filter id="x1uxv6ym9b" width="115%" height="115%" x="-7.5%" y="-7.5%" filterUnits="objectBoundingBox">
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5"/>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.933333333 0 0 0 0 0.188235294 0 0 0 0 0.258823529 0 0 0 1 0"/>
        </filter>
        <filter id="6cm4omwkgd" width="218.8%" height="218.8%" x="-59.4%" y="-59.4%" filterUnits="objectBoundingBox">
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5"/>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.933333333 0 0 0 0 0.188235294 0 0 0 0 0.258823529 0 0 0 1 0"/>
        </filter>
        <filter id="du2wofxinf" width="218.8%" height="218.8%" x="-59.4%" y="-59.4%" filterUnits="objectBoundingBox">
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5"/>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.933333333 0 0 0 0 0.188235294 0 0 0 0 0.258823529 0 0 0 1 0"/>
        </filter>
        <filter id="rkxt2buiyh" width="133.3%" height="215.9%" x="-16.7%" y="-57.9%" filterUnits="objectBoundingBox">
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5"/>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.933333333 0 0 0 0 0.188235294 0 0 0 0 0.258823529 0 0 0 1 0"/>
        </filter>
        <circle id="c5xt6s36ie" cx="13.946" cy="8.133" r="1.263"/>
        <circle id="xpsc754hvg" cx="6.054" cy="8.133" r="1.263"/>
        <path id="a8u3vhfsoc" d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18.889c-4.901 0-8.889-3.988-8.889-8.889S5.1 1.111 10 1.111 18.889 5.1 18.889 10 14.9 18.889 10 18.889z"/>
        <path id="e6brtkhd3i" d="M13.577 12.001c-2.006 1.788-5.148 1.788-7.154 0-.229-.204-.58-.184-.784.045-.204.23-.184.58.045.785 1.21 1.078 2.763 1.618 4.316 1.618s3.106-.54 4.316-1.618c.23-.204.25-.555.045-.785-.204-.229-.555-.249-.784-.045z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g filter="url(#1ylpylghza)" transform="translate(-498 -270) translate(409 153) translate(90 118)">
                    <g fill-rule="nonzero">
                        <g>
                            <use fill="#000" filter="url(#x1uxv6ym9b)" xlink:href="#a8u3vhfsoc"/>
                            <use fill="#FFF" fill-opacity=".952" xlink:href="#a8u3vhfsoc"/>
                        </g>
                        <g>
                            <use fill="#000" filter="url(#6cm4omwkgd)" xlink:href="#c5xt6s36ie"/>
                            <use fill="#FFF" fill-opacity=".952" xlink:href="#c5xt6s36ie"/>
                        </g>
                        <g>
                            <use fill="#000" filter="url(#du2wofxinf)" xlink:href="#xpsc754hvg"/>
                            <use fill="#FFF" fill-opacity=".952" xlink:href="#xpsc754hvg"/>
                        </g>
                        <g transform="matrix(1 0 0 -1 0 26.31)">
                            <use fill="#000" filter="url(#rkxt2buiyh)" xlink:href="#e6brtkhd3i"/>
                            <use fill="#FFF" fill-opacity=".952" xlink:href="#e6brtkhd3i"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  `,
];
