import React, { memo } from "react";
import { makeStyles } from "@material-ui/core";

function NotificationsCount({ count = 0 }) {
  const classes = useStyles();
  if (count === 0) return null;
  return (
    <div className={classes.notificationRoot}>{count > 99 ? "99+" : count}</div>
  );
}

const useStyles = makeStyles(() => ({
  notificationRoot: {
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "inset 0 -2px 1px 0 rgba(0, 0, 0, 0.27)",
    border: "solid 1px rgba(0, 0, 0, 0.42)",
    borderRadius: "50%",
    backgroundImage: "linear-gradient(to bottom, #ed164d, #bc0c39 100%)",
    fontSize: "8px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "right",
    color: "#ffffff",
  },
}));

export default memo(NotificationsCount);
