import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import ShadowedText from "../../shared/ShadowedText";
import LeaderboardAvatar from "../leaderboard/LeaderboardAvatar";

function TimelineAvatar({ name, image }) {
  const classes = useStyles();
  name = name.split("@")[0];

  return (
    <Box
      display="flex"
      justifyContent="start"
      alignItems="center"
      height="100%"
      width="calc(100% - 90px)"
    >
      <LeaderboardAvatar username={name} size={0} image={image} />
      <div className={classes.name}>
        <ShadowedText text={name} size="25px" />
      </div>
    </Box>
  );
}

const useStyles = makeStyles({
  avatarRoot: {
    height: "100%",
  },
  name: {
    paddingLeft: "8px",
  },
  missions: {
    fontSize: "13px",
    fontWeight: "600",
    lineHeight: "15px",
  },
});

export default TimelineAvatar;
