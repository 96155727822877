import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CIcon from "@coreui/icons-react";

import background from "../../../assets/backgrounds/training/popupBox.svg";

function HintContainer({ children, id }) {
  const classes = useStyles();
  return (
    <div className={classes.hintRoot}>
      <CIcon
        name="popupInfo"
        width="69px"
        height="69px"
        className={classes.hintIcon}
      />
      <div className={classes.hintBody}>{children}</div>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  hintRoot: {
    width: "438px",
    height: "248px",
    position: "relative",
    padding: "45px 10px 40px 10px",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    overflowWrap: "break-word",
  },
  hintBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "166px",
    width: "100%",
  },
  hintIcon: {
    position: "absolute",
    top: -25,
    left: 183,
  },
}));

export default HintContainer;
