import React, { useState, useMemo, useRef, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { setActiveMenu } from "../../store/actions/ui";
import TimerBox from "./TimerBox";

import TrainingMenuPlanet from "./TrainingMenuPlanet";
import TrainingRndContainer from "./TrainingRndContainer";
import { resetPopupCount } from "../../store/actions/ui";

function TrainingMenu({ scenario, userImage, OpenExitTrainingModal, networkListItem }) {
  const [hover, setHover] = useState(false);

  const stateRef = useRef(false);

  const { scnTimeout, components } = scenario;

  const { activeMenu: active } = useSelector((state) => state.uiReducer);

  const dispatch = useDispatch();

  const classes = useStyles();


  const [networkActive, setNetworkActive] = useState(false);
  const [infoActive, setMonitorActive] = useState(false);
  const [missionsActive, setMissionsActive] = useState(false);
  const [leaderboardActive, setLeaderboardActive] = useState(false);
  const [chatActive, setChatActive] = useState(false);

  const [activeWindow, setActiveWindow] = useState("");


  const selectIcon = useCallback((id) => {
    console.log(id);
    switch (id) {
      case "network":
        console.log(id);
        console.log(networkListItem);

        setNetworkActive(true);
        break;
      case "info":
        setMonitorActive(true);
        break;
      case "missions":
        setMissionsActive(true);
        break;
      case "leaderboard":
        setLeaderboardActive(true);
        break;
      case "chat":
        setChatActive(true);
        dispatch(resetPopupCount("chat"));
        break;
    }
  }, []);
  const isPopupActive = useMemo(() => {
    return active !== undefined && active !== "";
  }, [active]);

  const onTimerStop = useCallback(() => {
    console.log("timerstop");
  }, []);

  const handleMouseEnter = () => {
    setHover(true);
    if (stateRef.current) {
      stateRef.current = false;
    }
  };

  const closeMenu = (state) => {
    if (state) {
      setHover(false);
    }
  };

  const handleMouseLeave = () => {
    stateRef.current = true;
    setTimeout(() => {
      closeMenu(stateRef.current);
    }, 2000);
  };

  return (
    <div className={classes.menuRoot}>
      <div
        className={classes.planetWrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <TimerBox
          time={scnTimeout}
          start={false}
          onTimerStop={onTimerStop}
          OpenExitTrainingModal={OpenExitTrainingModal}
        />
        <TrainingMenuPlanet
          components={components}
          hover={hover}
          selectIcon={selectIcon}
          userImage={userImage}
        />
      </div>
      <div className={classes.popupWrapper}>
        {networkActive ? (
          <TrainingRndContainer
            selectIcon={(id) => setNetworkActive(false)}
            type={"network"}
            networkListItem={networkListItem}
            setActiveWindow={setActiveWindow}
            activeWindow={activeWindow}
          />
        ) : null}
        {infoActive ? (
          <TrainingRndContainer
            selectIcon={(id) => setMonitorActive(false)}
            type={"info"}
            setActiveWindow={setActiveWindow}
            activeWindow={activeWindow}
          />
        ) : null}
        {missionsActive ? (
          <TrainingRndContainer
            selectIcon={(id) => setMissionsActive(false)}
            type={"missions"}
            setActiveWindow={setActiveWindow}
            activeWindow={activeWindow}
          />
        ) : null}
        {leaderboardActive ? (
          <TrainingRndContainer
            selectIcon={(id) => setLeaderboardActive(false)}
            type={"leaderboard"}
            setActiveWindow={setActiveWindow}
            activeWindow={activeWindow}
          />
        ) : null}
        {chatActive ? (
          <TrainingRndContainer
            selectIcon={(id) => setChatActive(false)}
            type={"chat"}
            setActiveWindow={setActiveWindow}
            activeWindow={activeWindow}
          />
        ) : null}
      </div>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  menuRoot: {
    position: "fixed",
    right: 0,
    display: "flex",
    flexDirection: "column",
    width: "360px",
    height: "100vh",
    minHeight: "500px",
    justifyContent: "flex-end",
    alignItems: "center",
    pointerEvents: "none",
  },
  planetWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    width: "250px",
    height: "340px",
    padding: "50px 0 40px 20px",
    userSelect: "none",
  },
  popupWrapper: {
    flexGrow: 1,
    paddingBottom: "50px",
    marginRight: "-30px",
    pointerEvents: "auto",
  },
}));

export default TrainingMenu;
