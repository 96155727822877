import { SET_TRAINING_HISTORY } from "../types/trainingHistory";

const initialState = [
  // {
  //   date: "1624262171",
  //   scenarioName: "Celica",
  //   rings: [
  //     { name: "fastestRunBadge", description: "fastest Run" },
  //     { name: "leaderBronzeBadge", description: "bronze" },
  //     { name: "leaderGoldBadge", description: "leaderGoldBadge" },
  //     { name: "perfectRunBadge", description: "perfect Run" },
  //   ],
  //   position: 1,
  //   score: 35000,
  //   time: "01:43",
  //   mitreItems: 6,
  //   certifications: ["ceh", "cism"],
  // },
  // {
  //   date: "1624175771",
  //   scenarioName: "Mondeo",
  //   rings: ["fastestRunBadge", "leaderGoldBadge", "perfectRunBadge"],
  //   score: 32000,
  //   position: 2,
  //   time: "01:43",
  //   mitreItems: 6,
  //   certifications: ["ceh", "gsec"],
  // },
  // {
  //   date: "1621842971",
  //   scenarioName: "Boxter",
  //   rings: ["fastestRunBadge", "leaderBronzeBadge", "leaderGoldBadge"],
  //   position: 4,
  //   score: 72000,
  //   time: "01:43",
  //   mitreItems: 6,
  //   certifications: ["ceh"],
  // },
];

const trainingHistoryReducer = (state = initialState, action) => {
  const { type, trainingHistory } = action;

  switch (type) {
    case SET_TRAINING_HISTORY:
      return trainingHistory;
    default:
      return state;
  }
};
export default trainingHistoryReducer;
