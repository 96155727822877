export const leaderboardSilverStarFrame = [
  "38 34",
  `
  <defs>
        <linearGradient id="l73xdxr1hc" x1="28.721%" x2="72.667%" y1="35.722%" y2="79.32%">
            <stop offset="0%" stop-color="#505050"/>
            <stop offset="42.313%" stop-color="#F2F2F2"/>
            <stop offset="51.215%" stop-color="#C8C8C8"/>
            <stop offset="100%" stop-color="#4E4E4E"/>
        </linearGradient>
        <linearGradient id="8ssb35i03f" x1="27.643%" x2="73.814%" y1="39.068%" y2="72.448%">
            <stop offset="0%" stop-color="#505050"/>
            <stop offset="42.313%" stop-color="#F2F2F2"/>
            <stop offset="51.215%" stop-color="#C8C8C8"/>
            <stop offset="100%" stop-color="#4E4E4E"/>
        </linearGradient>
        <linearGradient id="y6qdb4sefg" x1="27.643%" x2="73.814%" y1="43.532%" y2="63.283%">
            <stop offset="0%" stop-color="#F2F2F2"/>
            <stop offset="100%" stop-color="#C8C8C8"/>
        </linearGradient>
        <linearGradient id="2gl67k5iji" x1="27.643%" x2="73.814%" y1="41.186%" y2="68.101%">
            <stop offset="0%" stop-color="#505050"/>
            <stop offset="42.313%" stop-color="#F2F2F2"/>
            <stop offset="51.215%" stop-color="#C8C8C8"/>
            <stop offset="100%" stop-color="#4E4E4E"/>
        </linearGradient>
        <linearGradient id="wqcqhjtp5l" x1="27.643%" x2="73.814%" y1="35.722%" y2="79.32%">
            <stop offset="0%" stop-color="#505050"/>
            <stop offset="42.313%" stop-color="#F2F2F2"/>
            <stop offset="51.215%" stop-color="#C8C8C8"/>
            <stop offset="100%" stop-color="#4E4E4E"/>
        </linearGradient>
        <filter id="jlfm221hya" width="214.4%" height="213.3%" x="-57.2%" y="-49.9%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius=".72" result="shadowSpreadOuter1"/>
            <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feMorphology in="SourceAlpha" radius="1" result="shadowInner"/>
            <feOffset dy="2" in="shadowInner" result="shadowInner"/>
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.388235294 0 0 0 0 0.388235294 0 0 0 0 0.388235294 0 0 0 1 0"/>
        </filter>
        <filter id="ano3hzpcld" width="205.3%" height="232.7%" x="-52.4%" y="-58.4%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius=".72" result="shadowSpreadOuter1"/>
            <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feMorphology in="SourceAlpha" radius="1" result="shadowInner"/>
            <feOffset dy="2" in="shadowInner" result="shadowInner"/>
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.388235294 0 0 0 0 0.388235294 0 0 0 0 0.388235294 0 0 0 1 0"/>
        </filter>
        <filter id="ghd3vqewjh" width="123.5%" height="151.4%" x="-11.4%" y="-25.7%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.16"/>
        </filter>
        <filter id="pimhjjzoej" width="278%" height="326.6%" x="-89%" y="-113.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.16"/>
        </filter>
        <filter id="3e7xst3l8k" width="278%" height="326.6%" x="-89%" y="-113.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.16"/>
        </filter>
        <path id="pzxo41708b" d="M10.56.16h16.32c2.688 0 4.903 2.11 5.034 4.794l.948 19.44c.136 2.78-2.008 5.144-4.788 5.28-.082.004-.164.006-.246.006H9.612c-2.784 0-5.04-2.256-5.04-5.04 0-.082.002-.164.006-.246l.948-19.44C5.657 2.27 7.872.16 10.56.16z"/>
        <path id="d69x5uxvie" d="M11.002.16H26.62c2.784 0 5.04 2.256 5.04 5.04 0 .423-.053.845-.159 1.255l-3.888 15.12c-.573 2.228-2.58 3.785-4.881 3.785h-7.41c-2.25 0-4.228-1.492-4.846-3.655l-4.32-15.12C5.39 3.908 6.94 1.119 9.617.354c.45-.129.916-.194 1.385-.194z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g>
                    <g transform="translate(-99 -240) translate(99 240) translate(0 2)">
                        <use fill="#000" filter="url(#jlfm221hya)" xlink:href="#pzxo41708b"/>
                        <use stroke="url(#l73xdxr1hc)" stroke-width="1.44" xlink:href="#pzxo41708b"/>
                    </g>
                    <g transform="translate(-99 -240) translate(99 240) translate(0 2)">
                        <use fill="#000" filter="url(#ano3hzpcld)" xlink:href="#d69x5uxvie"/>
                        <use stroke="url(#8ssb35i03f)" stroke-width="1.44" xlink:href="#d69x5uxvie"/>
                    </g>
                    <path fill="url(#y6qdb4sefg)" fill-opacity=".3" d="M7.248.16h23.193c2.784 0 5.04 2.256 5.04 5.04 0 .543-.088 1.083-.26 1.598l-5.054 15.12c-.688 2.056-2.613 3.442-4.78 3.442H12.864c-2.106 0-3.99-1.31-4.724-3.285L2.524 6.955c-.97-2.61.36-5.51 2.97-6.48C6.055.267 6.649.16 7.248.16z" filter="url(#ghd3vqewjh)" opacity=".294" transform="translate(-99 -240) translate(99 240) translate(0 2)"/>
                    <g transform="translate(-99 -240) translate(99 240) translate(0 2) translate(10.16 3.12)">
                        <ellipse cx="7.52" cy="3.1" fill="url(#2gl67k5iji)" fill-opacity=".5" filter="url(#pimhjjzoej)" opacity=".462" rx="3.64" ry="2.86"/>
                        <ellipse cx="9.6" cy="13.5" fill="url(#2gl67k5iji)" fill-opacity=".5" filter="url(#3e7xst3l8k)" opacity=".462" rx="3.64" ry="2.86"/>
                        <path stroke="url(#wqcqhjtp5l)" stroke-width="1.44" d="M8.065 12.776l-3.311 1.74c-.352.186-.787.05-.972-.301-.074-.14-.1-.301-.073-.457l.633-3.687c.04-.234-.038-.472-.207-.637l-2.68-2.611c-.284-.278-.29-.734-.012-1.019.11-.113.255-.187.412-.21l3.702-.537c.234-.035.437-.182.542-.394l1.655-3.355c.176-.356.608-.503.965-.327.142.07.257.185.327.327L10.7 4.663c.105.212.308.36.542.394l3.702.537c.394.058.666.423.61.817-.024.156-.097.301-.21.412l-2.68 2.61c-.17.166-.247.404-.207.638l.633 3.687c.067.392-.196.764-.588.831-.156.027-.317.002-.457-.072l-3.311-1.74c-.21-.111-.46-.111-.67 0z"/>
                        <path fill="url(#wqcqhjtp5l)" d="M8.065 10.576l-.84.442c-.321.168-.717.045-.886-.275-.067-.128-.09-.274-.066-.416l.16-.936c.041-.233-.036-.472-.206-.637l-.68-.663c-.26-.253-.265-.668-.012-.927.1-.103.232-.17.375-.191l.94-.137c.234-.034.437-.181.542-.393l.42-.852c.16-.325.553-.458.878-.298.13.064.234.169.298.298l.42.852c.105.212.308.36.542.393l.94.137c.358.052.606.385.554.743-.02.143-.088.274-.19.375l-.68.663c-.17.165-.248.404-.208.637l.16.936c.062.357-.178.696-.535.757-.142.024-.288.001-.415-.066l-.841-.442c-.21-.11-.46-.11-.67 0z"/>
                    </g>
                </g>
            </g>
        </g>
    </g>  `,
];
