import {
  SET_HINT,
  REMOVE_HINT,
  SET_MISSION_ID,
  OPEN_ASK_HINT,
  RESET_STATE,
} from "../types/hint";

const initialState = { hint: {}, missionId: "" };

const hintReducer = (state = initialState, action) => {
  const { hint, missionId, type } = action;

  switch (type) {
    case SET_HINT:
      return { ...state, hint };
    case SET_MISSION_ID:
      return { ...state, missionId };
    case OPEN_ASK_HINT:
      return { ...state, missionId, hint: {} };
    case REMOVE_HINT:
      return { hint: {}, missionId: "" }; // initialState as well like RESET_STATE
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
export default hintReducer;
