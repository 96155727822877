import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

function MissionWelcomeButton({
  approveClick,
  text = "Proceed",
  disabled = false,
  margin = "16px 0 0 51px",
  isBigger = false,
}) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ disabled, margin, theme });
  return (
    <div>
      {isBigger ? (
        <button
          type="button"
          className={classes.welcomeRootIsBigger}
          onClick={approveClick}
          disabled={disabled}
        >
          {text}
        </button>
      ) : (
        <button
          type="button"
          className={classes.welcomeRoot}
          onClick={approveClick}
          disabled={disabled}
        >
          {text}
        </button>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  welcomeRoot: {
    width: "100px",
    height: "27px",
    margin: (props) => props.margin,
    padding: "5px 16px 6px 18px",
    objectFit: "contain",
    borderRadius: "5px",
    border: "none",
    boxShadow:
      "0 0 3px 0 rgba(0, 0, 0, 0.31), inset 0 -2px 1px 0 rgba(0, 0, 0, 0.27)",
    backgroundImage: (props) =>
      `linear-gradient(to bottom, ${
        props.disabled ? "#636467" : props.theme.COLOR_PRIMARY
      }, ${props.disabled ? "#636467" : props.theme.COLOR_PRIMARY} 100%)`,
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "13px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#000",
    opacity: (props) => (props.disabled ? 0.6 : 1),
    // cursor: (props) => (props.disabled ? "auto" : "pointer"),
  },
  welcomeRootIsBigger: {
    width: "80%",
    height: "37px",
    margin: "16px 0px 0px 32px !important",
    padding: "5px 16px 6px 18px",
    objectFit: "contain",
    borderRadius: "5px",
    border: "none",
    boxShadow:
      "0 0 3px 0 rgba(0, 0, 0, 0.31), inset 0 -2px 1px 0 rgba(0, 0, 0, 0.27)",
    backgroundImage: (props) =>
      `linear-gradient(to bottom, #${props.disabled ? "636467" : "ed164d"}, #${
        props.disabled ? "636467" : "bc0c39"
      } 100%)`,
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "20px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
    opacity: (props) => (props.disabled ? 0.6 : 1),
    // cursor: (props) => (props.disabled ? "auto" : "pointer"),
  },
});

export default memo(MissionWelcomeButton);
