export const goldQuestionMark = [
  "34 34",
  `
  <defs>
  <filter id="zmauqgpqua" width="250%" height="250%" x="-75%" y="-75%" filterUnits="objectBoundingBox">
      <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.5"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 1 0 0 0 0 0.482352941 0 0 0 0 0 0 0 0 1 0"/>
  </filter>
  <filter id="j207qgr51c" width="390%" height="281.2%" x="-145%" y="-78.1%" filterUnits="objectBoundingBox">
      <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4.5"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.922818065 0 0 0 0 0.425234497 0 0 0 0 0 0 0 0 1 0"/>
  </filter>
  <linearGradient id="l2nm2euhle" x1="50%" x2="50%" y1="19.351%" y2="100%">
      <stop offset="0%" stop-color="#FF852E"/>
      <stop offset="100%" stop-color="#EB7826"/>
  </linearGradient>
  <circle id="m5o20tsm6b" cx="15.92" cy="15.92" r="7"/>
  <path id="irfcewye1d" d="M16.887 18.318c.36 0 .54-.175.54-.524v-.648c0-.398.223-.797.668-1.196l1.748-1.37C20.614 13.93 21 13.2 21 12.385v-.349c0-1.296-.368-2.292-1.105-2.99C19.158 8.349 18.112 8 16.758 8h-1.516c-1.354 0-2.4.349-3.137 1.047-.737.698-1.105 1.694-1.105 2.99v.574c0 .365.18.548.54.548h2.185c.36 0 .54-.183.54-.548v-.524c0-.531.115-.905.347-1.121.231-.216.613-.324 1.144-.324h.463c.565 0 .972.112 1.22.336.25.224.373.594.373 1.11v.149c0 .498-.274.955-.822 1.37l-1.44 1.147c-.445.349-.763.694-.951 1.034-.189.34-.283.785-.283 1.333v.673c0 .35.18.524.54.524h2.03zM16.964 24c.326 0 .488-.174.488-.523V20.41c0-.315-.162-.473-.488-.473h-2.288c-.343 0-.514.166-.514.498v3.016c0 .365.171.548.514.548h2.288z"/>
</defs>
<g fill="none" fill-rule="evenodd">
  <g>
      <g>
          <g>
              <g transform="translate(-1119 -351) translate(1104 251) translate(7 48) translate(9 53)">
                  <use fill="#000" fill-rule="nonzero" filter="url(#zmauqgpqua)" xlink:href="#m5o20tsm6b"/>
                  <g>
                      <use fill="#000" filter="url(#j207qgr51c)" xlink:href="#irfcewye1d"/>
                      <use fill="url(#l2nm2euhle)" xlink:href="#irfcewye1d"/>
                  </g>
                  <circle cx="15.907" cy="15.907" r="15.907" stroke="#FFF" stroke-opacity=".282" stroke-width=".75"/>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
