import {
  SET_SCENARIO_LIST,
  SET_SCENARIO_CATEGORY_LIST,
  UPDATE_SINGLE_SCENARIO,
  SCENARIO_LIST_FETCHING_STATE,
} from "../types/scenarioList";

const initialState = {
  scenarioList: [],
  scenarioCategoryList: [],
  isFetching: true,
};

const scenarioListReducer = (state = initialState, action) => {
  const { list, item, type, fetching } = action;
  switch (type) {
    case SET_SCENARIO_LIST:
      return { scenarioList: list, isFetching: false };
    case SET_SCENARIO_CATEGORY_LIST:
      return { scenarioCategoryList: list };
    case SCENARIO_LIST_FETCHING_STATE:
      return { ...state, isFetching: fetching };
    case UPDATE_SINGLE_SCENARIO:
      const tmpList = [...state?.scenarioList];
      if (tmpList?.Families !== undefined) {
        for (let family of tmpList.Families) {
          if (tmpList.scns_families[family?.id] !== undefined) {
            const myIndex = tmpList.scns_families[family.id].findIndex(
              (el) => el?.id === item?.id
            );
            tmpList.scns_families[family.id][myIndex] = item;
            if (myIndex !== -1) {
              break;
            }
          }
        }
      }
      return { scenarioList: tmpList, isFetching: true };
    default:
      return state;
  }
};
export default scenarioListReducer;
