import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import MissionWelcome from "./../welcome/MissionWelcome";

function MissionPopupContainer() {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ theme });

  const { popups } = useSelector((state) => state.missionsReducer);

  if (!popups || popups.length === 0) return null;
  return (
    <div className={classes.root}>
      {popups &&
        popups.map((popup, index) => {
          return (
            <MissionWelcome
              mission={popup}
              key={popup.id || `no-id-${index}`}
            />
          );
        })}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    fontFamily: (props) => props.theme.MAIN_FONT,
    letterSpacing: "0",
    lineHeight: "18px",
    textShadow: "0 0 6px 0 rgba(0,0,0,0.35)",
    backgroundColor: "rgba(0, 0, 0, 0.56)",
    color: "#FFFFFF",

    whiteSpace: "pre-line",
  },
});

export default MissionPopupContainer;
