import axios from "axios";
const FileDownload = require("js-file-download");

const myBaseURL =
  process.env.REACT_APP_ENVIRONMENT.trim() === "PROD"
    ? process.env.REACT_APP_BASE_URL_CLOUD
    : process.env.REACT_APP_BASE_URL_LOCAL_DEV;

function rdpFileDownloader(password, username, InstanceID) {
  const url = `${myBaseURL}/MotherShip/getStationRdp?pass=${password}&username=${username}&instance_id=${InstanceID}`;

  return axios({
    url,
    method: "GET",
    responseType: "blob", // important
  }).then((res) => {
    FileDownload(res.data, `rdp-connection-file.bat`);
  });
}

export default rdpFileDownloader;
