import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import EventIcon from "@material-ui/icons/Event";
import { TbCalendarMonth } from "react-icons/tb";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";

function PopupApprovalButton({
  approveClick,
  text = "Let’s Go!",
  disabled = false,
  children,
  size,
  type = "button",
  backgroundColor,
  icon = null,
  isTooltip,
  tooltipTitle,
  tooltipPlacement = "top",
  tooltipDisableHoverListener,
  backgroundImage,
}) {
  const { theme } = useSelector((state) => state.uiReducer);
  const buttonSize = {
    width: size?.width || "551px",
    height: size?.height || "35px",
    font: size?.font || "15px",
    borderRadius: size?.borderRadius || "5px",
    marginLeft: size?.marginLeft || null,
    marginBottom: size?.marginBottom || null,
    marginTop: size?.marginTop || null,
    fontFamily: size?.fontFamily || "Lato",
    margin: size?.margin || null,
  };

  const handleBlurOnClick = (e) => {
    e.target.blur();
    approveClick();
  };

  const classes = useStyles({
    disabled,
    size: buttonSize,
    backgroundColor,
    backgroundImage,
    theme,
  });

  if (text === "Quiz review") {
    return (
      <>
        <button
          type={type}
          className={classes.welcomeRoot}
          onClick={approveClick}
          disabled={disabled}
        >
          <HelpOutlineIcon
            style={{
              width: "15px",
              color: theme.BUTTON_ICON_COLOR,
              marginRight: "3px",
            }}
          />
          {text}
          {children}
        </button>
      </>
    );
  }

  if (text === "Calendar") {
    return (
      <>
        <button
          type={type}
          className={classes.welcomeRoot}
          onClick={approveClick}
          disabled={disabled}
        >
          <TbCalendarMonth
            size={18}
            style={{ color: theme.BUTTON_ICON_COLOR, marginRight: "3px" }}
          />
          {text}
          {children}
        </button>
      </>
    );
  }
  return isTooltip ? (
    <Tooltip
      title={tooltipTitle}
      placement={tooltipPlacement}
      arrow={true}
      open={!tooltipDisableHoverListener}
    >
      <button
        type={type}
        className={classes.welcomeRoot}
        onClick={(e) => handleBlurOnClick(e)}
        disabled={disabled}
      >
        <span className={classes.icon}>{icon}</span>
        {text}
        {children}
      </button>
    </Tooltip>
  ) : (
    <button
      type={type}
      className={classes.welcomeRoot}
      onClick={(e) => handleBlurOnClick(e)}
      disabled={disabled}
    >
      <span className={classes.icon}>{icon}</span>
      {text}
      {children}
    </button>
  );
}

const useStyles = makeStyles(() => ({
  welcomeRoot: {
    width: (props) => props.size.width,
    height: (props) => props.size.height,
    display: "flex",
    justifyContent: (props) =>
      props.justifyContent ? `${props.justifyContent}` : "center",
    alignItems: "center",
    borderRadius: (props) =>
      props.size.borderRadius
        ? props.size.borderRadius
        : props.theme.BOX_BORDER_RADIUS,
    border: (props) => props.theme.BUTTON_BORDER,
    backgroundColor: (props) => props.theme.COLOR_PRIMARY,
    fontSize: (props) => props.size.font,
    fontFamily: (props) => props.theme.TRAINING_FONT,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    textAlign: "center",
    color: (props) => props.theme.BUTTON_ICON_COLOR,
    cursor: (props) => (props.disabled ? "auto" : "pointer"),
    marginLeft: (props) => props.size.marginLeft,
    marginBottom: (props) => props.size.marginBottom,
    marginTop: (props) => props.size.marginTop,
    margin: (props) => props.size.margin,
    opacity: (props) => (props.disabled ? "0.4" : ""),
    // backgroundImage: (props) => props.backgroundImage,
  },
  icon: {
    marginRight: "5px",
  },
}));

export default memo(PopupApprovalButton);
