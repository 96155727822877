export const hourglass = [
  "20 23",
  `
  <defs>
  <filter x="-100.0%" y="-72.7%" width="300.0%" height="245.5%" filterUnits="objectBoundingBox" id="filter-1">
      <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
      <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
      <feColorMatrix values="0 0 0 0 0   0 0 0 0 0.329411765   0 0 0 0 0.764705882  0 0 0 1 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
      <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
  </filter>
</defs>
<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="Artboard" transform="translate(-102.000000, -32.000000)" fill-rule="nonzero">
      <g id="Group-8" transform="translate(98.000000, 32.000000)">
          <g id="sand-clock" transform="translate(10.000000, 6.000000)" filter="url(#filter-1)">
              <path d="M6.64128,0.998784 L7.653504,0.998784 L7.653504,0 L6.64128,0 L6.17088,0 L1.593216,0 L1.122816,0 L0.131328,0 L0.131328,0.998784 L0.718848,0.998784 L1.122816,0.998784 L1.122816,3.9456 L3.356544,5.433984 L1.022208,6.881664 L1.032576,6.89856 L1.0272,6.901632 L1.0272,9.858432 L0.718848,9.858432 L0.131328,9.858432 L0.131328,10.857216 L7.653504,10.857216 L7.653504,9.858432 L6.545664,9.858432 L6.545664,6.905472 L4.2816,5.43744 L6.645504,3.971712 L6.635136,3.954816 L6.64128,3.95136 L6.64128,0.998784 L6.64128,0.998784 Z M6.075264,7.246464 L6.075264,9.858432 L1.497984,9.858432 L1.497984,7.16352 L3.805056,5.73312 L6.075264,7.246464 Z M6.171264,3.689088 L3.827712,5.142528 L1.593216,3.69216 L1.593216,0.998784 L6.17088,0.998784 L6.17088,3.689088 L6.171264,3.689088 Z" id="Shape" fill-opacity="0.382911522" fill="#FFFFFF"></path>
              <polygon id="Path" fill="#166EE2" points="5.891328 3.457536 5.891328 2.350848 1.875456 2.350848 1.875456 3.452544 3.825024 4.740864"></polygon>
              <polygon id="Path" fill="#166EE2" points="2.86272 9.06432 1.757952 9.212928 1.757952 9.635712 5.828352 9.635712 5.828352 9.212928 4.822272 9.090432 3.793536 8.686464"></polygon>
          </g>
      </g>
  </g>
</g>
      `,
];
