import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

function OrangeTooltip({ title, children, placement, black = false }) {
  const classes = useStyles();

  return (
    <Tooltip
      title={title}
      arrow
      placement={placement || "top"}
      classes={{
        tooltip: black ? classes.blackTooltip : classes.tooltip,
        arrow: black ? classes.blackArrow : classes.arrow,
      }}
    >
      {children}
    </Tooltip>
  );
}

const useStyles = makeStyles({
  tooltip: {
    boxShadow: "0 2px 10px 0 rgba(237, 22, 77, 0.45)",
    backgroundImage: "linear-gradient(222.65deg, #FF852E 0%, #EBC426 100%)",
    fontFamily: "NunitoSans",
    fontSize: "12px",
    fontWeight: "bold",
  },
  blackTooltip: {
    color: "#fff",
    padding: "7px 11px 7px",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    fontFamily: "NunitoSans",
    fontSize: "13px",
    fontWeight: "bold",
    WebkitBackdropFilter: "blur(5px)",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.23",
    letterSpacing: "-0.46px",
    textAlign: "center",
    borderRadius: "11px",
    boxShadow: "0 0 2px 0 rgba(255, 255, 255, 0.47)",
  },
  arrow: {
    "&:before": {
      backgroundImage: "linear-gradient(222.65deg, #FF852E 0%, #EBC426 100%)",
    },
  },
  blackArrow: {
    "&:before": {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      WebkitBackdropFilter: "blur(5px)",
    },
  },
});

export default OrangeTooltip;
