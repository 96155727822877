import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import Confetti from "react-confetti";

import PopupCard from "../shared/PopupCard";
import MainHeader from "../shared/MainHeader";
import PopupApprovalButton from "../../buttons/PopupApprovalButton";
import { setModalState } from "../../../store/actions/ui";
import perfectRun from "../../../assets/icons/training/effects/perfect-run.png";

function EffectsContainer({}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const effect = useSelector((state) => state.effectsReducer);
  const { username } = useSelector((state) => state.userReducer);

  const approveEffect = useCallback(() => {
    dispatch(setModalState(false, ""));
  }, []);

  return (
    <div className={classes.effectsRoot}>
      <PopupCard padding="40px 10px 0 10px" size={["551px", "400px"]}>
        <MainHeader text={`congratulations, ${username}!`} />
        <div className={classes.effectsTitle}>you achieved:</div>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="relative"
          width="100%"
          height="280px"
        >
          <Confetti
            width={500}
            height={200}
            confettiSource={{ x: 200, y: 0, w: 250, h: 0 }}
            numberOfPieces={120}
          />
          <img src={perfectRun} width="120px" height="120px" />
          <MainHeader text={`Perfect Run`} />
        </Box>
        <PopupApprovalButton text="continue" approveClick={approveEffect} />
      </PopupCard>
    </div>
  );
}

const useStyles = makeStyles({
  effectsRoot: {
    border: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  effectsTitle: {
    color: "#FFFFFF",
    fontFamily: "NunitoSans",
    fontSize: "20px",
    letterSpacing: "0",
    lineHeight: "27px",
    margin: "25px 0 5px 0",
  },
});

export default EffectsContainer;
