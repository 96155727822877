import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CIcon from "@coreui/icons-react";
import { useSelector } from "react-redux";

function AttachmentButton({
  openAttachment,
  handlePopoverOpen,
  handlePopoverClose,
  name,
  open,
}) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ theme });

  return (
    <div
      className={classes.buttonRoot}
      onClick={openAttachment}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      aria-owns={open ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
    >
      <div className={classes.row}>
        <CIcon name="pinkAttachment" width="18" height="18" />
        <div className={classes.text}>{name}</div>
      </div>
    </div>
  );
}
const useStyles = makeStyles(() => ({
  buttonRoot: {
    display: "inline-block",
    paddingTop: "clamp(4px, 0.2vw, 8px)",
    paddingRight: "clamp(7px, 0.2vw, 10px)",
    paddingBottom: "clamp(4px, 0.2vw, 8px)",
    // paddingLeft: "clamp(5px, 0.2vw, 8px)",
    marginLeft: "20px",
    borderRadius: "5px",
    WebkitBackdropFilter: "blur(6px)",
    backdropFilter: "blur(6px)",
    boxShadow: "inset 0 -2px 1px 0 rgba(0, 0, 0, 0.27)",
    backgroundImage:
      "linear-gradient(to bottom, rgba(255, 255, 255, 0.21), rgba(255, 255, 255, 0.23) 95%)",
    cursor: "pointer",
    marginTop: "3px",
    maxWidth: "90%",
    textOverflow: "ellipsis",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    margin: "0 2px",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "clamp(10px, 0.6vw, 14px)",
    fontWeight: "500",
    color: "#000",
  },
}));

export default memo(AttachmentButton);
