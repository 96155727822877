import React, { memo } from "react";
import _ from "lodash";

function SlickDots({ dots = 1, dotClick, activeDot }) {
  if (dots === 1) return null;
  return (
    <ul
      style={{ display: "block", position: "relative" }}
      className="slick-dots mb-3"
    >
      {_.times(dots, (index) => {
        return (
          <li className={activeDot === index ? "slick-active" : ""} key={index}>
            <button onClick={() => dotClick(index)}>{index}</button>
          </li>
        );
      })}
    </ul>
  );
}

export default memo(SlickDots);
