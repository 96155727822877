export const avatarUser = [
  "15 18",
  `
  <defs>
  <filter id="xburb6nn5a">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"/>
  </filter>
</defs>
<g fill="none" fill-rule="evenodd">
  <g filter="url(#xburb6nn5a)" transform="translate(-845 -191)">
      <g>
          <path fill="#000" fill-rule="nonzero" d="M7.372 8.644c1.188 0 2.216-.426 3.056-1.267.84-.84 1.266-1.868 1.266-3.055 0-1.188-.426-2.216-1.266-3.056C9.588.426 8.559 0 7.372 0S5.157.426 4.316 1.266c-.84.84-1.266 1.868-1.266 3.056 0 1.187.426 2.215 1.266 3.056.84.84 1.869 1.266 3.056 1.266zM14.934 13.798c-.024-.35-.073-.731-.145-1.134-.073-.406-.167-.79-.279-1.14-.116-.362-.273-.72-.468-1.063-.203-.356-.44-.666-.707-.92-.279-.268-.62-.482-1.015-.639-.394-.155-.83-.234-1.296-.234-.183 0-.36.075-.703.298-.21.137-.456.296-.731.471-.235.15-.554.29-.947.418-.384.124-.774.187-1.158.187-.385 0-.774-.063-1.158-.187-.393-.127-.712-.268-.946-.417-.273-.174-.52-.333-.733-.472-.341-.223-.519-.298-.702-.298-.467 0-.902.079-1.296.234-.394.157-.736.371-1.015.638-.267.255-.504.565-.706.921-.195.343-.353.7-.469 1.063-.112.351-.206.734-.279 1.14-.072.402-.12.784-.145 1.134-.024.344-.036.7-.036 1.06 0 .937.298 1.695.885 2.254.58.552 1.347.832 2.28.832h8.64c.934 0 1.7-.28 2.28-.832.588-.559.886-1.317.886-2.254 0-.362-.013-.718-.037-1.06z" transform="translate(845 191)"/>
      </g>
  </g>
</g>
  `,
];
