export const hourglassRed = [
  "34 40",
  `
  <defs>
  <filter id="xpklufkaya" width="307.7%" height="242.1%" x="-103.8%" y="-71.1%" filterUnits="objectBoundingBox">
      <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.5"/>
      <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0.329411765 0 0 0 0 0.764705882 0 0 0 1 0"/>
      <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="SourceGraphic"/>
      </feMerge>
  </filter>
</defs>
<g fill="none" fill-rule="evenodd">
  <g fill-rule="nonzero">
      <g>
          <g filter="url(#xpklufkaya)" transform="translate(-79 -48) translate(73 48) translate(16.8 10.08)">
              <path fill="#FFF" fill-opacity=".383" d="M11.157 1.678h1.7V0H.222v1.678h1.665v4.95L5.64 9.13l-3.922 2.432.018.029-.01.005v4.967H.222v1.678h12.637v-1.678h-1.861v-4.96L7.193 9.134l3.971-2.463-.017-.028.01-.006v-4.96zm-.95 10.496v4.388h-7.69v-4.527l3.875-2.403 3.814 2.542zm.16-5.976L6.432 8.639 2.677 6.203V1.678h7.69v4.52z"/>
              <path fill="#FF2D00" d="M9.897 5.809L9.897 3.949 3.151 3.949 3.151 5.8 6.426 7.965zM4.809 15.228L2.953 15.478 2.953 16.188 9.792 16.188 9.792 15.478 8.101 15.272 6.373 14.593z"/>
          </g>
      </g>
  </g>
</g>
      `,
];
