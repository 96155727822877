import React, { useState, useEffect, useMemo, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Scrollbars from "react-custom-scrollbars";
import CIcon from "@coreui/icons-react";
import FeedbackInputBox from "../complete/FeedbackInputBox";
import QuestionOption from "../shared/QuestionOption";
import ScrollThumb from "../../base/scroll/ScrollThumb";

function QuestionPreview({ questionResults, quiz, status, isPartialFunc }) {
  const [selected, setSelected] = useState({});
  const [answer, setAnswer] = useState("");
  const [isSingleLine, setIsSingleLine] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [questionResult, setQuestionResult] = useState("");
  const [tries, setTries] = useState(1);
  const classes = useStyles();

  const { type, options } = quiz;
  const isPartial = isPartialFunc();
  const isDisabled = useMemo(() => {
    if (type === "options") {
      for (const value of Object.values(selected)) {
        if (value) return false;
      }
      return true;
    }
    return answer.length === 0;
  }, [type, answer, selected]);

  const answerStatus = useCallback(
    (index) => {
      if (isLocked && questionResults?.answers?.[0]?.answer?.answers?.[index]) {
        let res =
          questionResults.answers[questionResults.answers.length - 1].answer
            .answers[index];
        // if (res === "unmarked") {
        //   res = "";
        // }

        return res;
      }
      return "";
    },
    [isLocked, questionResults]
  );

  useEffect(() => {
    const tmpObj = {};
    if (options !== undefined) {
      options.forEach((option) => {
        if (option?.value.length > 25) setIsSingleLine(true);
        tmpObj[option?.index] = false;
      });
    }
    setSelected(tmpObj);
  }, [quiz?.options]);

  const fillAnswerBox = (e) => {
    const { value } = e.target;
    setAnswer(value);
  };

  const responseMessageQuestionResult = useMemo(() => {
    setIsLocked(true);
    const isOpenQuestion = options?.[0]?.type === "open";
    const lastAnswer = questionResults?.answers?.length > 0 ? questionResults.answers[questionResults.answers.length - 1] : null;
    const questionResult = lastAnswer ? lastAnswer.answer.question_result : questionResults?.questionResult;
    if (questionResult) {
      setQuestionResult(questionResult);
    }
    let correctAnswer;

    switch (questionResult) {
      case "partial":
        if (isOpenQuestion) {
          correctAnswer = options[0].value;
          return `Partially correct. The correct answer is: \t ${correctAnswer}`;
        } else {
          return `Partially correct. Not all correct answers selected.`;
        }
      case "wrong":
        if (isOpenQuestion) {
          correctAnswer = options[0]?.value;
          return `Wrong answer. The correct answer is: \t ${correctAnswer}`;
        } else {
          return "Wrong answer";
        }
      case "correct":
        return "Correct answer!";
      default:
        if (questionResults?.msg) {
          return questionResults.msg;
        }
        return "!";
    }
  }, [questionResults?.msg, options]);

  const responseMessage = useMemo(() => { // TODO: Delete in 2025! (for old debriefs of no questionResult)
    try {
      let questionType = options?.[0]?.type;

      if (questionType === "open") {
        let correctAnswer = options[0].value;
        if (isPartial) {
          return `Partially correct. The correct answer is: \t ${correctAnswer}`;
        } else if (!questionResults.IsCorrect) {
          return `Wrong answer. The correct answer is: \t ${correctAnswer}`;
        }
      }
      if (isPartial) {
        return `Partially correct. Not all correct answers selected.`;
      }
      if (questionResults.IsCorrect) {
        return "Correct answer!";
      }
      if (questionResults?.msg) {
        return questionResults.msg;
      }
      return "Wrong answer";
    } catch {}
  }, [questionResults?.msg, questionResults.IsCorrect, tries]);

  function getOpenAnswer() {
    let score = 0;
    let answer;
    questionResults.answers.forEach((answerElement) => {
      if (answerElement.answer.score_added >= score) {
        score = answerElement.answer.score_added;
        answer = answerElement.answer.answers[0];
      }
    });

    return answer;
  }

  const questionBody = () => {
    if (type === "open") {
      return (
        <FeedbackInputBox
          rows={2}
          width="270px"
          height="100px"
          placeholder="Your answer…"
          onChange={fillAnswerBox}
        />
      );
    }
    if (type === "options") {
      return (
        <Grid container spacing={2} className={classes.optionsWrapper}>
          {options !== undefined
            ? options.map((option) => {
                const { index } = option;
                const value =
                  option.type !== "open" ? option.value : getOpenAnswer();
                const isSelected = selected[index];
                const myStatus = answerStatus(index - 1);
                // console.log(value, myStatus);
                return (
                  <QuestionOption
                    text={value}
                    key={index}
                    index={index}
                    selected={isSelected}
                    isSingleLine={isSingleLine}
                    isLocked={isLocked}
                    status={myStatus}
                    debrief={status}
                  />
                );
              })
            : null}
        </Grid>
      );
    }
  };

  const iconMap = {
    partial: "correctPartlyIcon",
    correct: "correctIcon",
    wrong: "incorrectIcon"
  };

  return (
    <div>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        // justifyContent="space-between"
        alignItems="center"
      ></Box>
      <div className={isLocked ? classes.locked : ""}>
        <Scrollbars
          autoHeight
          autoHeightMax={400}
          autoHeightMin={145}
          renderThumbVertical={ScrollThumb}
          hideTracksWhenNotNeeded={true}
        >
          <div className={classes.bodyWrapper}>{questionBody()}</div>
        </Scrollbars>
      </div>

      {questionResult ? (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          width="100%"
          marginTop="7px"
        >
          <CIcon name={iconMap[questionResult]}
            width="21px"
            height="21px"
            className={classes.icon}
          />
          <div className={classes.responseMessage}>{responseMessageQuestionResult}</div>
        </Box>
      ) : questionResults.IsCorrect !== null ? ( // TODO: remove at 2025, enabls old user review response message 
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          width="100%"
          marginTop="7px"
        >
          <CIcon
            name={
              isPartial
                ? "correctPartlyIcon"
                : questionResults.IsCorrect
                ? "correctIcon"
                : "incorrectIcon"
            }
            width="21px"
            height="21px"
            className={classes.icon}
          />
          <div className={classes.responseMessage}>{responseMessage}</div>
        </Box>
      ) : null}
    </div>
  );
}

const useStyles = makeStyles({
  questionRoot: {
    position: "fixed",
    pointerEvents: "none",
  },
  title: {
    fontSize: "14px",
    fontWeight: "500",
  },
  description: {
    fontSize: "14px",
    fontWeight: "600",
    margin: "5px 0 10px 0",
  },
  optionsWrapper: {
    width: "100%",
    paddingTop: "8px",
    minWidth: "270px",
    minHeight: "120px",
    marginTop: 0,
    marginBottom: 0,
  },
  locked: {
    pointerEvents: "none",
  },
  bodyWrapper: {
    width: "100%",
    marginTop: "-10px",
  },
  icon: {
    marginRight: "8px",
  },
  responseMessage: {
    width: "calc(100% - 29px)",
    fontSize: "16px",
    overflowWrap: "break-word",
  },
});

export default QuestionPreview;
