import { SET_PERSONAL_HALL, SET_AVATAR_ID } from "../types/personalHall.js";

const initialState = {};
//   userDetails: {
//     id: 1,
//     name: "elad",
//     type: "trainee",
//     jobTitle: "CISO",
//     organizationName: "Organization name",
//     email: "email",
//     bio: "",
//     createDate: 1625381103,
//     rings: [{ name: "fastestRunBadge", description: "fastest run", count: 3 }],
//     certifications: [{ name: "cism", description: "certified information" }],
//     score: 15224555,
//     position_global: 1643,
//     position_organization: 200,
//     mitre_total: 55,
//     mitre_precentage: 24,
//   },
//   scheduledTraining: {
//     id: 1,
//     name: "Schenario name",
//     scnId: 2,
//     rangerId: 3,
//     date: 1625381103,
//     status: "attending",
//   },
//   suggestedTraining: [],
// };

const personalHallReducer = (state = initialState, action) => {
  const { personalHall, type, avatarId, scheduled } = action;

  switch (type) {
    case SET_PERSONAL_HALL:
      return { ...personalHall };
    case SET_AVATAR_ID:
      const tempState = state;
      tempState["userDetails"].avatarId = avatarId;
      return { ...tempState };
    default:
      return state;
  }
};
export default personalHallReducer;
