import { SET_SCENARIO_ITEM } from "../types/scenarioItem";

const initialState = {};

const scenarioItemReducer = (state = initialState, action) => {
  const { scenario, type } = action;
  switch (type) {
    case SET_SCENARIO_ITEM:
      return scenario;
    default:
      return state;
  }
};
export default scenarioItemReducer;
