import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import CIcon from "@coreui/icons-react";
import { FiPower, FiLogIn } from "react-icons/fi";
import { BsStop } from "react-icons/bs";

export default function VmMonitorHeader({
  classes,
  setOpen,
  selectedVm,
  handleStartVm,
  handleStopVm,
  handleLogInVm,
}) {
  const handleCloseClick = () => {
    setOpen(false);
  };

  return (
    <Grid
      container
      direction="row"
      justify="space-around"
      alignItems="center"
      className={classes.innerContainer}
    >
      <Grid item lg={7} md={6} sm={8} xs={11} className={classes.mainTitle}>
        <CIcon
          name="closeIcon"
          width="41"
          height="40"
          alt="close"
          className={classes.mainTitleIcon}
          onClick={handleCloseClick}
        />
        {selectedVm?.Name ? (
          <div className={classes.mainTitleText}>{selectedVm.Name}</div>
        ) : null}
      </Grid>
      <Grid item lg={5} md={6} sm={4} xs={1}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Hidden smDown>
            {/* <button className={classes.runButton} onClick={handleStartVm}>
              <div className={classes.buttonIcon}>
                <FiPower />
              </div>
              <div className={classes.buttonText}>Start</div>
            </button>
            <button className={classes.runButton} onClick={handleStopVm}>
              <div className={classes.buttonIcon}>
                <BsStop />
              </div>
              <div className={classes.buttonText}>Stop</div>
            </button> */}
            {selectedVm.InstanceID && (
              <button
                className={classes.runButton}
                onClick={() =>
                  handleLogInVm(selectedVm.Password, selectedVm.UserName)
                }
              >
                <div className={classes.buttonIcon}>
                  <FiLogIn />
                </div>
                <div className={classes.buttonText}>Log In</div>
              </button>
            )}
          </Hidden>
        </Box>
      </Grid>
    </Grid>
  );
}
