export const attachment = [
  "16 16",
  `
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.382963635">
  <g id="Cybring-training-flow-(4.1)" transform="translate(-1291.000000, -591.000000)" fill="#FFFFFF" fill-rule="nonzero">
      <g id="Group-9" transform="translate(1104.000000, 251.000000)">
          <g id="attach-interface-clip-symbol" transform="translate(187.000000, 340.000000)">
              <path d="M10.4086048,6.98652921 C10.4086048,6.98652921 11.219354,6.25044674 10.4525086,5.48360137 C9.68566323,4.71675601 8.90531959,5.48321649 8.90531959,5.48321649 L7.56824742,6.82039863 C6.22122337,8.16794502 6.05495533,10.1930997 7.40184192,11.5394914 C8.74941581,12.8870378 10.8217457,12.7678351 12.1682474,11.4203986 L14.4240825,9.16560825 C16.4961649,7.09352577 16.4961649,3.72162199 14.4240825,1.64956701 L14.3333883,1.55887285 C12.2602612,-0.514254296 8.88835739,-0.514254296 6.81624742,1.55887285 L1.55444674,6.82001375 C-0.518158076,8.89206873 -0.518158076,12.2639725 1.55444674,14.3371271 L1.64514089,14.4278213 C3.25861168,16.0409072 5.68478351,16.425512 7.73253608,15.4231203 C8.2822543,15.1540619 8.8796701,14.6444536 8.48533333,13.929677 C8.06812371,13.1734708 7.33520275,13.3381718 6.94768385,13.5148316 C5.79125773,13.9913127 4.08643299,13.8621031 3.14845361,12.924646 L3.05775945,12.8328797 C1.81404811,11.5902405 1.81404811,9.56690034 3.05775945,8.32321649 L8.31956014,3.06204811 C9.56327148,1.81833677 11.5865842,1.81833677 12.8292509,3.06204811 L12.9209897,3.15274227 C14.1636564,4.39645361 14.1636564,6.41872165 12.9209897,7.66243299 L10.6650172,9.91722337 C10.1469966,10.435244 9.42314777,10.5544742 8.90512715,10.0363162 C8.38710653,9.51815808 8.55351203,8.84175945 9.07153265,8.32360137 L10.4086048,6.98652921 Z" id="Path"></path>
          </g>
      </g>
  </g>
</g>
  `,
];
