import { take, put, call, fork, cancel, cancelled } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import io from "socket.io-client";
import { USER_LOGIN, USER_LOGOUT } from "../types/training";
import { messageReceived } from "../actions/chat";
import { sendEvent } from "../actions/socketEvent";
import { write, writeEvents } from "./write";
import { setQuestion } from "./../actions/quiz";
import {
  setModalState,
  incrementPopupCount,
  updateScore,
  updateRings,
} from "./../actions/ui";
import {
  updateMissions,
  updateQuestions,
  finishedMission,
  incrementCurrentHint,
  removeMissionPopup,
  setActiveMission,
  updateInformers,
} from "./../actions/missions";
import { setInformer } from "./../actions/informer";
import { setEffects } from "./../actions/effects";
import {
  setConnectedTrainees,
  setTrainingId,
  setTrainingStatus,
  setTrainingTimer,
  updateDisconnectedTrainee,
} from "./../actions/training";
import { setHint } from "./../actions/hint";
import highlightUrl from "./../../helpers/highlightUrl";
import { setLeaderboard } from "./../actions/leaderboard";
import { setTimeline } from "./../actions/timeline";
import { sendMessage } from "./../actions/chat";
import { addinfos, restoreInitialStore } from "../actions/info";
import generateUrlArr from "./../../helpers/generateUrlArr";
import { setQuestionResults } from "./../actions/quizResults";
import { setEvents } from "./../actions/events";
import logger from "../../helpers/logger";

var displayed = [];
var componentIdToIndexDict = {};
const env = process.env.REACT_APP_ENVIRONMENT.trim();
const URL_PROTOCOL = env === "DEV" ? "http" : "https";
const RTMS_PATH =
  env === "DEV"
    ? process.env.REACT_APP_RTMS_PATH_LOCAL_DEV
    : process.env.REACT_APP_RTMS_PATH_CLOUD;

const askScoreObj = (email, rangerName) => {
  const obj = {
    data: "",
    family: "ask_scoring",
    api_type: "ask_scoring",
    to: rangerName || "",
    from: email,
  };
  console.log(obj);
  return JSON.stringify(obj);
};

const getConnectedTrainees = (email, rangerName) => {
  const eventName = "update_connected_trainees";
  const eventData = {
    data: "",
    family: eventName,
    api_type: eventName,
    to: rangerName || "",
    from: email,
  };

  return eventData;
};

var myUser = { name: "", id: "" };

async function connect(user, rangerId, rangerName, userIp, avatarId) {
  logger("username, rangerId, userIp", userIp);
  logger("user avatarId", avatarId);

  const { username, email: userEmail } = user;

  logger("got socket connect request");
  logger({
    username,
    rangerName,
    userIp,
  });
  const response = await fetch('/config.json');
  const config = await response.json();
  console.log(response);
  console.log(config);

  myUser = user;
  const socket = io(`${URL_PROTOCOL}${`://`}${window.location.hostname}${env === "DEV" ? RTMS_PATH : ""}`,
    {
      path: env !== "DEV" ? RTMS_PATH : "",
      reconnection: true,
      query: {
        username,
        userEmail,
        rangerName,
        connectionType:"Trainee",
        userIp,
        avatarId,
      },
    }
  );

  return new Promise((resolve) => {
    socket.on("connect", () => {
      resolve(socket);
      logger("Socket connected");
    });
    socket.on("connect_error", (error) => {
      logger("Socket connect error", error);
    });
    socket.on("disconnect", (reason) => {
      resolve(socket);
      console.log("Socket disconnected due to:", reason);
    });
    socket.io.on("reconnection_attempt", (error) => {
      logger("Socket reconnection attempt", error);
    });
    socket.io.on("reconnect", () => {
      logger("Socket reconnected");
    });
  });
}

function* read(socket, user, rangerName) {
  const channel = yield call(subscribe, socket);
  try {
    yield put(sendMessage(askScoreObj(user?.email, rangerName)));
    const connectedTraineesEvent = getConnectedTrainees(user?.email, rangerName);
    yield put(sendEvent(connectedTraineesEvent.api_type, connectedTraineesEvent));
    while (true) {
      let action = yield take(channel);
      logger("action", action);
      yield put(action);
    }
  } finally {
    if (yield cancelled()) {
      socket.close();
    }
  }
}

export function ClearDisplayedArr() {
  displayed = [];
}

export function SetComponentIdToIndexDict(IdToIndexDict) {
  componentIdToIndexDict = IdToIndexDict;
}

function CheckComponentDuplication(componentId) {
  let isDuplicated = false;

  if (displayed.length > 0) {
    isDuplicated = displayed.includes(componentId);
    if (!isDuplicated) {
      isDuplicated =
        componentIdToIndexDict[componentId] <=
        componentIdToIndexDict[displayed[displayed.lastIndex]];
    }
  }

  return isDuplicated;
}

export function* subscribe(socket) {
  return new eventChannel((emit) => {

    const chat = (message) => {
      const parsedMessage = JSON.parse(message);
      if (parsedMessage?.data) {
        logger("chat event", parsedMessage);
        let parsedData;
        try {
          parsedData = JSON.parse(parsedMessage.data);
        } catch {
          parsedData = parsedMessage;
          parsedData.avatarId = parsedMessage?.data?.avatarId;
          parsedData.data = parsedMessage?.data?.message;
        }
        if (parsedData.data === "disconnected") {
          update_trainee_disconnection(parsedData.username);
          return;
        }
        if (parsedData.data === "connected") {
          return;
        }
        emit(messageReceived(parsedData));
        emit(incrementPopupCount("chat", 1));
      }
      return;
    };

    const quiz = (question) => {
      const parsedQuestion = JSON.parse(question);
      if (parsedQuestion?.data) {
        logger("quiz event", parsedQuestion);
        const parsedData = parsedQuestion.data;
        if (CheckComponentDuplication(parsedData.id)) {
          return;
        }
        displayed.push(parsedData.id);
        emit(setActiveMission(parsedData.id));
        emit(setQuestion({ ...parsedData }));
        emit(updateQuestions(parsedData));
        if (parsedData?.content) {
          const urlArr = generateUrlArr(parsedData.content);
          if (urlArr && urlArr.length > 0) {
            emit(addinfos(urlArr));
          }
        }
      }
      return;
    };

    const questionResponse = (questionResponse) => {
      const parsedQuestionResponse = JSON.parse(questionResponse);
      if (parsedQuestionResponse?.data) {
        const parsedData = parsedQuestionResponse.data;
        logger("questionResponse event", parsedData);
        if (parsedData?.id) {
          emit(setQuestionResults(parsedData.id, parsedData));
        }
      }
      return;
    };

    const task = (mission) => {
      const parsedMission = JSON.parse(mission);
      if (parsedMission?.data) {
        logger("task event", parsedMission);
        const parsedData = parsedMission.data;
        if (CheckComponentDuplication(parsedData.taskId)) {

          return;
        }
        else {
          displayed.push(parsedData.taskId);
          emit(setActiveMission(parsedData.taskId));
          emit(updateMissions(parsedData));
          emit(incrementPopupCount("missions", 1));
          if (parsedData?.content) {
            const urlArr = generateUrlArr(parsedData.content);
            if (urlArr && urlArr.length > 0) {
              emit(addinfos(urlArr));
            }
          }
        }
      }
      return;
    };

    const taskFinished = (mission) => {
      const parsedMission = JSON.parse(mission);
      if (parsedMission?.data?.missionId !== undefined) {
        logger("taskFinished event", parsedMission);
        emit(finishedMission(parsedMission.data.missionId));
        emit(removeMissionPopup(parsedMission.data.missionId));
        emit(incrementPopupCount("missions", 1));
      }
      return;
    };

    const informer = (info) => {
      const parsedInfo = JSON.parse(info);
      if (parsedInfo?.data) {
        logger("informer event", parsedInfo);
        const parsedData = parsedInfo.data;
        if (parsedData) {
          if (CheckComponentDuplication(parsedData.id)) {
            return;
          }
          displayed.push(parsedData.id);
          emit(setActiveMission(parsedData.id));
          emit(finishedMission(parsedData.id)); // CYB-1804
          const { textArr, urlArr } = highlightUrl(parsedData?.text);
          const { topic, data, content } = JSON.parse(parsedData?.text);
          const informerObj = {
            data: data,
            topic: topic,
            id: parsedData.id,
            content: content,
          };

          if (urlArr && urlArr.length > 0) {
            emit(addinfos(urlArr));
          }
          emit(updateInformers(informerObj));
          emit(
            setInformer({
              ...parsedData,
              id: parsedData.id,
              data: textArr,
              topic,
            })
          );
          emit(incrementPopupCount("info", 1));
        }
      }
      return;
    };

    const effect = (effect) => {
      const parsedEffect = JSON.parse(effect);
      if (parsedEffect?.data) {
        logger("hint event", parsedEffect);
        logger("parsedEffect ?.data", parsedEffect?.data);
        emit(setModalState(true, "effects"));
        emit(setEffects({ ...parsedEffect.data }));
      }
      return;
    };

    const hint = (hint) => {
      const parsedHint = JSON.parse(hint);
      if (parsedHint?.data) {
        logger("hint event", parsedHint);
        const parsedData = parsedHint.data;
        const { urlArr, textArr } = highlightUrl(parsedData?.text);
        const highlightedHint = {
          ...parsedData,
          text: textArr,
        };
        if (urlArr && urlArr.length > 0) {
          emit(addinfos(urlArr));
        }
        emit(incrementCurrentHint(parsedData?.missionId, highlightedHint));
        emit(setHint(highlightedHint));
      }
      return;
    };

    const score = (newScore) => {
      const parsedScore = JSON.parse(newScore);
      if (parsedScore?.data) {
        logger("score event", parsedScore);
        const parsedData = parsedScore.data;
        if (parsedData?.leaderBoard) {
          const filteredUser = parsedData.leaderBoard.filter(
            (el) => el.name === myUser?.email // CYB-1187: el.name references to the user email
          );
          if (filteredUser?.[0]?.score) {
            emit(updateScore(filteredUser[0].score));
          }
          emit(setLeaderboard(parsedData.leaderBoard));
        }
        if (parsedData?.timeLine) {
          const { graph, dots } = parsedData.timeLine;
          if (graph) {
            emit(setTimeline(graph, dots || {}));
          }
        }
      }
      return;
    };

    const initialScoring = (scoring) => {
      const parsedScore = JSON.parse(scoring);
      if (parsedScore?.data) {
        logger("initialScoring event", parsedScore);
        const parsedData = JSON.parse(parsedScore.data);
        const connected_trainees_list = parsedData.connected_trainees;
        if (connected_trainees_list) {
          emit(setConnectedTrainees(connected_trainees_list));
        }
        if (parsedData?.leaderBoard) {
          const filteredUser = parsedData.leaderBoard.filter(
            (el) => el.name === myUser?.username
          );
          if (filteredUser?.[0]?.score) {
            emit(updateScore(filteredUser[0].score));
          }
          emit(setLeaderboard(parsedData.leaderBoard));
        }
        if (parsedData?.timeLine) {
          const { graph, dots } = parsedData.timeLine;
          if (graph) {
            emit(setTimeline(graph, dots || {}));
            const myDots = parsedData?.timeLine?.dots?.[myUser?.id] || {};
            const myDotsArr = Object.entries(myDots).map(([name, value]) => ({
              id: name,
              topic: value?.message || "",
              icon: value?.icon,
              username: myUser?.username,
              time: name,
            }));
            emit(setEvents(myDotsArr));
          }
        }
      }
      return;
    };

    const scnStopped = (data) => {
      logger("scnStopped event");
      const parsedData = JSON.parse(data);
      if (parsedData?.data) {
        if (parsedData?.data?.score) {
          emit(updateScore(parsedData.data.score));
        }
        if (parsedData?.data?.badges) {
          emit(updateRings(parsedData.data.badges));
        }
      }
      emit(setTrainingId(parsedData.trainingID));
      emit(setTrainingTimer(1));
      emit(setTrainingStatus("finished"));
      emit(setModalState(true, "complete"));
      localStorage.removeItem("infos");
      return;
    };

    const userExit = () => {
      logger("userExit event");
      emit(setTrainingStatus("user-exit"));
      return;
    };

    const scnStarted = (data) => {
      try {
        logger("scnStarted event");
        localStorage.removeItem("infos");
        const parsedScenarioTimeData = JSON.parse(data);
        const { scenarioTime } = parsedScenarioTimeData.data;
        emit(setTrainingTimer(scenarioTime));
        emit(setTrainingStatus("started"));
      } catch (err) {
        console.warn(err);
      }
      return;
    };

    const updatedScenarioData = (data) => {
      logger("updatedScenarioData event", data);
      try {
        const parsedScenarioData = JSON.parse(data);
        const { isRunning, scenarioTime } =
          parsedScenarioData.data;
        if (isRunning) {
          emit(setTrainingStatus("started"));
        } else {
          logger("is scenario running? ", isRunning);
        }
        emit(setTrainingTimer(scenarioTime));
      } catch (err) {
        console.warn(err);
      }
    };

    const update_connected_trainees = (data) => {
      logger("update_connected_trainees event");
      emit(setConnectedTrainees(JSON.parse(data)));
    };
    const update_trainee_disconnection = (traineeEmail) => {
      emit(updateDisconnectedTrainee(traineeEmail));
    };

    socket.on("chat", chat);
    socket.on("CHAT_BROADCAST", chat);
    socket.on("question", quiz);
    socket.on("questionResponse", questionResponse);
    socket.on("task", task);
    socket.on("task_ended", taskFinished);
    socket.on("informer", informer);
    socket.on("effect", effect);
    socket.on("hint", hint);
    socket.on("score_updated", score);
    socket.on("scoring_received", initialScoring);
    socket.on("updatedScenarioData", updatedScenarioData);
    socket.on("scn_started", scnStarted);
    socket.on("scn_stopped", scnStopped);
    socket.on("exit", userExit);
    socket.on("update_connected_trainees", update_connected_trainees);
    socket.onAny((event, ...args) => {
      logger(`got ${event}, ${args}`);
    });
    return () => { };
  });
}

export function* ReadWrite() {
  while (true) {
    const { user, rangerId, rangerName, userIp, avatarId } = yield take(
      USER_LOGIN
    );

    const socket = yield call(
      connect,
      user,
      rangerId,
      rangerName,
      userIp,
      avatarId
    );
    const readTask = yield fork(read, socket, user, rangerName);
    const writeTask = yield fork(write, socket);
    const writeEventTask = yield fork(writeEvents, socket);
    yield take(USER_LOGOUT);
    yield cancel(readTask);
    yield cancel(writeTask); // sendMessage logic
    yield cancel(writeEventTask);
  }
}
