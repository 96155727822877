import axios from "axios";

const myBaseURL =
  process.env.REACT_APP_ENVIRONMENT.trim() === "PROD"
    ? process.env.REACT_APP_BASE_URL_CLOUD
    : process.env.REACT_APP_BASE_URL_LOCAL_DEV;

export function getRequiredHosts(ScnID) {
  const obj = {
    ScnID,
    token: "!!!emptyToken",
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("GetRequiredHosts request", postData);

  return axios
    .post(`${myBaseURL}/Content/GetRequiredHosts`, postData, axiosConfig)
    .then((res) => {
      console.log("GetRequiredHosts response", res);
      return res;
    })
    .catch((err) => {
      console.log("GetRequiredHosts ERROR: ", err);
    });
}

export function removeRequiredHost(ScnID, InstanceID) {
  const obj = {
    ScnID,
    InstanceID,
    token: "!!!emptyToken",
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("RemoveRequiredHost request", postData);

  return axios
    .post(`${myBaseURL}/Content/RemoveRequiredHost`, postData, axiosConfig)
    .then((res) => {
      console.log("RemoveRequiredHost response", res);
      return res;
    })
    .catch((err) => {
      console.log("RemoveRequiredHost ERROR: ", err);
    });
}

export function addRequiredHost(ScnID, InstanceID) {
  const obj = {
    ScnID,
    InstanceID,
    token: "!!!emptyToken",
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("AddRequiredHost request", postData);

  return axios
    .post(`${myBaseURL}/Content/AddRequiredHost`, postData, axiosConfig)
    .then((res) => {
      console.log("AddRequiredHost response", res);
      return res;
    })
    .catch((err) => {
      console.log("AddRequiredHost ERROR: ", err);
    });
}

export function editRequiredHost(ScnID, InstanceID) {
  const obj = {
    ScnID,
    InstanceID,
    token: "!!!emptyToken",
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("EditRequiredHost request", postData);

  return axios
    .post(`${myBaseURL}/Content/EditRequiredHost`, postData, axiosConfig)
    .then((res) => {
      console.log("EditRequiredHost response", res);
      return res;
    })
    .catch((err) => {
      console.log("EditRequiredHost ERROR: ", err);
    });
}

export function GetSnapshotsOfInstance(instanceID, token) {
  const obj = {
    instanceID: instanceID + "",
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("GetSnapshotsOfInstance request", postData);

  return axios
    .post(`${myBaseURL}/Network/GetSnapshotsOfInstance`, postData, axiosConfig)
    .then((res) => {
      console.log("GetSnapshotsOfInstance response", res);
      return res;
    })
    .catch((err) => {
      console.log("GetSnapshotsOfInstance ERROR: ", err);
    });
}

export function GetWorkstationsVms(token) {
  const obj = {
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("GetWorkstationsVms request", postData);

  return axios
    .post(`${myBaseURL}/Network/GetWorkstationsVms`, postData, axiosConfig)
    .then((res) => {
      console.log("GetWorkstationsVms response", res);
      return res;
    })
    .catch((err) => {
      console.log("GetWorkstationsVms ERROR: ", err);
    });
}

export function getAllTrainingHistory(token) {
  const obj = {
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("getAllTrainingHistory request", postData);

  return axios
    .post(`${myBaseURL}/Network/getAllTrainingHistory`, postData, axiosConfig)
    .then((res) => {
      console.log("getAllTrainingHistory response", res);
      return res;
    })
    .catch((err) => {
      console.log("getAllTrainingHistory ERROR: ", err);
    });
}

export function getAllExistingVms(token) {
  const obj = {
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("GetAllExistingVms request", postData);

  return axios
    .post(`${myBaseURL}/Network/GetAllExistingVms`, postData, axiosConfig)
    .then((res) => {
      console.log("GetAllExistingVms response", res);
      return res;
    })
    .catch((err) => {
      console.log("GetAllExistingVms ERROR: ", err);
    });
}

export function startVm(token, instance_id) {
  const obj = {
    instance_id: instance_id + "",
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("StartVm request", postData);

  return axios
    .post(`${myBaseURL}/Network/StartVm`, postData, axiosConfig)
    .then((res) => {
      console.log("StartVm response", res);
      return res;
    })
    .catch((err) => {
      console.log("StartVm ERROR: ", err);
    });
}

export function stopVm(token, instance_id) {
  const obj = {
    instance_id: instance_id + "",
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("StopVm request", postData);

  return axios
    .post(`${myBaseURL}/Network/StopVm`, postData, axiosConfig)
    .then((res) => {
      console.log("StopVm response", res);
      return res;
    })
    .catch((err) => {
      console.log("StopVm ERROR: ", err);
    });
}

export function loginToVm(token, instance_id) {
  const obj = {
    instance_id: instance_id + "",
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("LoginToVm request", postData);

  return axios
    .post(`${myBaseURL}/Network/LoginToVm`, postData, axiosConfig)
    .then((res) => {
      console.log("LoginToVm response", res);
      return res;
    })
    .catch((err) => {
      console.log("LoginToVm ERROR: ", err);
    });
}

export function addNewExistingVm(token, values) {
  const obj = {
    ...values,
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("AddNewExistingVm request", postData);

  return axios
    .post(`${myBaseURL}/Network/AddNewExistingVm`, postData, axiosConfig)
    .then((res) => {
      console.log("AddNewExistingVm response", res);
      return res;
    })
    .catch((err) => {
      console.log("AddNewExistingVm ERROR: ", err);
    });
}

export function updateExistingVm(token, values) {
  const obj = {
    ...values,
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("UpdateExistingVm request", postData);

  return axios
    .post(`${myBaseURL}/Network/UpdateExistingVm`, postData, axiosConfig)
    .then((res) => {
      console.log("UpdateExistingVm response", res);
      return res;
    })
    .catch((err) => {
      console.log("UpdateExistingVm ERROR: ", err);
    });
}

export function generateTemplate(token, values) {
  const obj = {
    ...values,
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("GenerateTemplate request", postData);

  return axios
    .post(`${myBaseURL}/Network/GenerateTemplate`, postData, axiosConfig)
    .then((res) => {
      console.log("GenerateTemplate response", res);
      return res;
    })
    .catch((err) => {
      console.log("GenerateTemplate ERROR: ", err);
    });
}

export function generateSnapshot(token, values) {
  const obj = {
    ...values,
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("GenerateSnapshot request", postData);

  return axios
    .post(`${myBaseURL}/Network/GenerateSnapshot`, postData, axiosConfig)
    .then((res) => {
      console.log("GenerateSnapshot response", res);
      return res;
    })
    .catch((err) => {
      console.log("GenerateSnapshot ERROR: ", err);
    });
}

export function buildTemplate(token, id) {
  const obj = {
    templateId: id + "",
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("BuildVmFromTemplate request", postData);

  return axios
    .post(`${myBaseURL}/Network/BuildVmFromTemplate`, postData, axiosConfig)
    .then((res) => {
      console.log("BuildVmFromTemplate response", res);
      return res;
    })
    .catch((err) => {
      console.log("BuildVmFromTemplate ERROR: ", err);
    });
}

export function revertToSnapshot(token, id) {
  const obj = {
    templateId: id + "",
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("revertToSnapshot request", postData);

  return axios
    .post(`${myBaseURL}/Network/revertToSnapshot`, postData, axiosConfig)
    .then((res) => {
      console.log("revertToSnapshot response", res);
      return res;
    })
    .catch((err) => {
      console.log("revertToSnapshot ERROR: ", err);
    });
}

export function loadSnapshotsClickApi(instanceId) {
  const obj = {
    instanceId,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("loadSnapshotsClick request");

  return axios
    .post(`${myBaseURL}/Network/loadSnapshotsClick`, postData, axiosConfig)
    .then((res) => {
      console.log("loadSnapshotsClick response", res);
      return res;
    })
    .catch((err) => {
      console.log("loadSnapshotsClick ERROR: ", err);
    });
}

export function setDefaultTemplate(token, id) {
  const obj = {
    templateId: id + "",
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("setDefaultTemplate request", postData);

  return axios
    .post(`${myBaseURL}/Network/setDefaultTemplate`, postData, axiosConfig)
    .then((res) => {
      console.log("setDefaultTemplate response", res);
      return res;
    })
    .catch((err) => {
      console.log("setDefaultTemplate ERROR: ", err);
    });
}

export function deleteTemplate(token, id) {
  const obj = {
    templateId: id + "",
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("DeleteTemplate request", postData);

  return axios
    .post(`${myBaseURL}/Network/DeleteTemplate`, postData, axiosConfig)
    .then((res) => {
      console.log("DeleteTemplate response", res);
      return res;
    })
    .catch((err) => {
      console.log("DeleteTemplate ERROR: ", err);
    });
}

export function getAllTemplates(token) {
  const obj = {
    token: token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("GetAllTemplates request", postData);

  return axios
    .post(`${myBaseURL}/Network/GetAllTemplates`, postData, axiosConfig)
    .then((res) => {
      console.log("GetAllTemplates response", res);
      return res;
    })
    .catch((err) => {
      console.log("GetAllTemplates ERROR: ", err);
    });
}
export function getAllTemplatesOfScenario(token, ScenarioID) {
  const obj = {
    ScenarioID: ScenarioID + "",
    token: token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("GetAllTemplatesOfScenario request", postData);

  return axios
    .post(
      `${myBaseURL}/Network/GetAllTemplatesOfScenario`,
      postData,
      axiosConfig
    )
    .then((res) => {
      console.log("GetAllTemplatesOfScenario response", res);
      return res;
    })
    .catch((err) => {
      console.log("GetAllTemplatesOfScenario ERROR: ", err);
    });
}
export function getRangerStatus(rangerId, token) {
  const obj = {
    RangeID: `${rangerId}`,
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("GetRangerStatus request", postData);

  return axios
    .post(`${myBaseURL}/Rangers/GetRangerStatus`, postData, axiosConfig)
    .then((res) => {
      console.log("GetRangerStatus response", res);
      return res;
    })
    .catch((err) => {
      console.log("GetRangerStatus ERROR: ", err);
    });
}
export function GetRangerStatusJson(rangerId, token) {
  const obj = {
    RangeID: `${rangerId}`,
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(`${myBaseURL}/Rangers/GetRangerStatusJson`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("GetRangerStatusJson ERROR: ", err);
    });
}

export function StopScenario(connectedRangers, token) {
  const obj = {
    connectedRangers: JSON.stringify(connectedRangers),
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(`${myBaseURL}/Training/StopScenario`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("StopScenario ERROR: ", err);
    });
}

export function disconnectUserCall(user) {
  const obj = {
    user,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(`${myBaseURL}/Training/disconnectUser`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("disconnectUser ERROR: ", err);
    });
}

export function ReconnectToRMS(token) {
  const obj = {
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(`${myBaseURL}/Training/ReconnectToRMS`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("ReconnectToRMS ERROR: ", err);
    });
}

export function updateTemplatesOfScenario(ScenarioID, templateDict, token) {
  const obj = {
    ScenarioID: ScenarioID + "",
    TemplateDict: JSON.stringify(templateDict),
    token: token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("UpdateTemplatesOfScenario request", postData);

  return axios
    .post(
      `${myBaseURL}/Network/UpdateTemplatesOfScenario`,
      postData,
      axiosConfig
    )
    .then((res) => {
      console.log("UpdateTemplatesOfScenario response", res);
      return res;
    })
    .catch((err) => {
      console.log("GetAllTemplatesOfScenario ERROR: ", err);
    });
}

export function updateTemplate(token, templateId, templateDetails) {
  const obj = {
    templateId: templateId + "",
    ...templateDetails,
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  console.log("UpdateTemplate request", postData);

  return axios
    .post(`${myBaseURL}/Network/UpdateTemplate`, postData, axiosConfig)
    .then((res) => {
      console.log("UpdateTemplate response", res);
      return res;
    })
    .catch((err) => {
      console.log("UpdateTemplate ERROR: ", err);
    });
}

export function GetVmPowerState(vms) {
  const obj = {
    vms: JSON.stringify(vms),
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(`${myBaseURL}/Network/GetVmPowerStateAndStart`, postData, axiosConfig)
    .then((res) => {
      console.log("GetVmPowerState response", res);
      return res;
    })
    .catch((err) => {
      console.log("GetVmPowerState ERROR: ", err);
    });
}

export function deleteVmFromDB(instanceid) {
  const obj = {
    instanceid,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(`${myBaseURL}/Network/deleteVmFromDB`, postData, axiosConfig)
    .then((res) => {
      console.log("deleteVmFromDB response", res);
      return res;
    })
    .catch((err) => {
      console.log("deleteVmFromDB ERROR: ", err);
    });
}

export function RevertAllVms() {
  const obj = {
    vms: "",
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(`${myBaseURL}/Network/RevertAllVms`, postData, axiosConfig)
    .then((res) => {
      console.log("RevertAllVms response", res);
      return res;
    })
    .catch((err) => {
      console.log("RevertAllVms ERROR: ", err);
    });
}
export function fetchVmsFromServer() {
  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(`${myBaseURL}/Network/ImportVmsFromServer`, axiosConfig)
    .then((res) => {
      console.log("ImportVmsFromServer response", res);
      return res;
    })
    .catch((err) => {
      console.log("ImportVmsFromServer ERROR: ", err);
    });
}
