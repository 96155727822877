import React from "react";
import { makeStyles } from "@material-ui/core/styles";

function PopupCard({
  children,
  isLarge = false,
  padding = "36px 0 0",
  size = ["551px", "447px"],
}) {
  const width = size[0] || "80%";
  const height = size[1] || "80%";
  const classes = useStyles({ padding, isLarge, width, height });

  return <div className={classes.card}>{children}</div>;
}

const useStyles = makeStyles({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: (props) => (props.isLarge ? "100%" : "551px"),
    minWidth: "551px",
    // minHeight: "447px",
    width: (props) => props.width,
    height: (props) => props.height,
    margin: "0 0 17px",
    padding: (props) => props.padding,
    borderRadius: "10px",
    WebkitBackdropFilter: "blur(5px)",
    backdropFilter: "blur(5px)",
    boxShadow: "0 0 2px 0 rgba(255, 255, 255, 0.47)",
    backgroundColor: "rgba(0, 0, 0, 0.95)",
  },
});

export default PopupCard;
