import {
  SET_MISSIONS,
  UPDATE_MISSION,
  UPDATE_QUESTION,
  SET_ACTIVE_MISSION,
  INCREMENT_CURRENT_HINT,
  FINISHED_MISSION,
  REMOVE_MISSION_POPUP,
  UPDATE_INFORMER,
  RESET_STATE,
} from "../types/missions";
import {
  missionUpdater,
  questionUpdater,
  markMissionFinished,
  informerUpdater,
} from "../../helpers/missionsBuilder";

const initialState = {
  missions: [],
  progress: {},
  popups: [],
  hints: {},
  active: "",
  TotalMissions: "",
  Completed: "",
};

const missionsReducer = (state = initialState, { type, ...payload }) => {
  const {
    missions,
    mission,
    question,
    informer,
    active,
    progress,
    hints,
    missionId,
    hint,
    TotalMissions = "",
    Completed = "",
  } = payload;
  switch (type) {
    case SET_MISSIONS:
      return { ...state, missions, progress, hints, TotalMissions, Completed };
    case UPDATE_MISSION:
      const newState = missionUpdater(
        mission,
        state.missions,
        state.progress,
        state.popups,
        state.hints
      );
      return newState;
    case UPDATE_QUESTION:
      const newStateQuestion = questionUpdater(
        question,
        state.missions,
        state.progress,
        state.popups,
        state.hints
      );
      return newStateQuestion;
    case SET_ACTIVE_MISSION:
      return { ...state, active };
    case FINISHED_MISSION:
      const updatedState = markMissionFinished(missionId, state);
      return { ...updatedState };
    case REMOVE_MISSION_POPUP:
      const filteredPopups = state.popups.filter((el) => el.id !== missionId);
      return { ...state, popups: filteredPopups };
    case UPDATE_INFORMER:
      const newStateInformer = informerUpdater(
        informer,
        state.missions,
        state.progress,
        state.popups,
        state.hints
      );
      return newStateInformer;
    case INCREMENT_CURRENT_HINT:
      if (
        state?.hints?.[missionId] &&
        hint?.hintId !== undefined &&
        hint.hintId !== "-1"
      ) {
        const myCurrentHint = state.hints?.[missionId]?.currentHint || 0;
        const { hintId } = hint;
        const myHints = state["hints"];
        const hintIndex = myHints[missionId]?.hints.findIndex(
          (hint) => hint.HintID === Number(hintId)
        );
        if (hintIndex !== -1) {
          myHints[missionId]["hints"][hintIndex].hint = hint?.text || [];
          const newHints = {
            ...myHints,
            [missionId]: {
              ...myHints[missionId],
              currentHint: myCurrentHint + 1,
            },
          };

          const newProgress = { ...state?.progress };
          if (state?.progress?.[missionId]?.currentScore) {
            const myCurrentScore = state.progress[missionId].currentScore;
            newProgress[missionId]["currentScore"] =
              myCurrentScore - hint.price;
          }
          return { ...state, hints: newHints, progress: newProgress };
        } else {
          return state;
        }
      } else {
        return state;
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default missionsReducer;
