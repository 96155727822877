import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

function MainHeader({ text }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ theme });

  return <div className={classes.headerRoot}>{text}</div>;
}

const useStyles = makeStyles(() => ({
  headerRoot: {
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: (props) => props.theme.MAIN_HEADER_FONT_SIZE,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.2",
    letterSpacing: "normal",
    color: "#ffffff",
  },
}));

export default MainHeader;
