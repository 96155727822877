import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Collapse, Tooltip } from "@material-ui/core";
import { Resizable } from "re-resizable";
import Draggable from "react-draggable";
import {
  getPopupDimensionsFromLocalStorage,
  savePopupDimensionsToLocalStorage,
} from "./popupDimentions";

import missionWelcomeBg from "../../../assets/backgrounds/training/missionStartPopup.svg";
import missionWelcomeBgOpacity from "../../../assets/backgrounds/training/missionStartPopupOpacity.svg";

function MerlinContainer({
  children,
  isDraggable,
  isPopup = true,
  isOpacity = false,
  margin = "0 0 50px 40px",
  avatarId,
  handlePopupExpendMinimizeClick,
  expended,
}) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ theme, isPopup, margin, avatarId, isDraggable });
  const { informer_width } = getPopupDimensionsFromLocalStorage();
  const [width, setWidth] = useState(informer_width || 315);
  const [height, setHeight] = useState(350);

  const { allTrainerAvatars } = useSelector(
    (state) => state.personalHallReducer
  );
  const trainerAvatar = allTrainerAvatars[avatarId] || null;
  const companyLogo = theme.TRAINER_LOGO;
  const trainerIconWidth =
    theme.COMPANY_NAME === "CYBRING"
      ? "95px"
      : theme.COMPANY_NAME === "ISC2"
      ? "65px"
      : theme.COMPANY_NAME === "TAME"
      ? "100px"
      : "65px";
  const trainerIconHeight =
    theme.COMPANY_NAME === "CYBRING"
      ? ""
      : theme.COMPANY_NAME === "ISC2"
      ? "65px"
      : "";

  const handleSetWidth = (width) => {
    setWidth(width);
    savePopupDimensionsToLocalStorage(width, height);
  };

  return (
    <Draggable
      handle=".drag-handle"
      // bounds="parent"
      disabled={!isDraggable}
    >
      <div className={classes.merlinContainer}>
        <img
          src={trainerAvatar || companyLogo}
          width={trainerIconWidth}
          height={trainerIconHeight}
          className={`${classes.img} drag-handle`}
        />
        {!expended && (
          <Tooltip placement="top" title={"Maximize window"}>
            <div
              className={classes.expendIcon}
              onClick={handlePopupExpendMinimizeClick}
            ></div>
          </Tooltip>
        )}
        <Collapse orientation="horizontal" in={expended}>
          <Resizable
            size={{ width, height }}
            onResizeStop={(e, direction, ref, d) => {
              handleSetWidth(width + d.width);
            }}
            minWidth={315}
            maxWidth={750}
            minHeight={350}
            maxHeight={350}
          >
            <div className={classes.card}>
              <img
                src={isOpacity ? missionWelcomeBgOpacity : missionWelcomeBg}
                width="100%"
                height="100%"
                className={classes.bgImage}
              />
              <div className={classes.cardInner}>{children}</div>
              <div className={`${classes.dragHandle} drag-handle top`}></div>
              {/* <div className={`${classes.dragHandle} drag-handle right`}></div> */}
              <div className={`${classes.dragHandle} drag-handle bottom`}></div>
              <div className={`${classes.dragHandle} drag-handle left`}></div>
            </div>
          </Resizable>
        </Collapse>
      </div>
    </Draggable>
  );
}

const useStyles = makeStyles({
  merlinContainer: {
    display: "flex",
    height: "35px",
    pointerEvents: "auto",
    position: "relative",
  },
  card: {
    position: "relative",
    marginLeft: "5px",
  },
  cardInner: {
    padding: "20px 20px 20px 25px",
    width: "100%",
    height: "100%",
    // pointerEvents: "none",
  },
  img: {
    borderRadius: (props) => props.theme.COMPANY_LOGO_BORDERS_RADIUS,
    cursor: (props) => (props.isDraggable ? "move" : "default"),
    userDrag: "none",
    WebkitUserSelect: "none"
  },
  bgImage: {
    position: "absolute",
    zIndex: (props) => (props.isPopup ? -1 : 0),
  },
  expendIcon: {
    cursor: "pointer",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    boxShadow: "0px 0px 3px 3px rgba(227, 75, 59,0.4)",
    backgroundColor: (props) => props.theme.COLOR_PRIMARY,
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  dragHandle: {
    position: "absolute",
    cursor: "move",
    zIndex: 1000,
    "&.top": {
      top: 0,
      left: 0,
      right: 0,
      height: "10px",
    },
    "&.right": {
      top: 0,
      right: 0,
      bottom: 0,
      width: "10px",
    },
    "&.bottom": {
      bottom: 0,
      left: 0,
      right: 0,
      height: "10px",
    },
    "&.left": {
      top: 0,
      left: 0,
      bottom: 0,
      width: "10px",
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
});

export default MerlinContainer;