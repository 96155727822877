export const settingsIcon = [
  "55 55",
  `
  <g fill="none" fill-rule="evenodd">
  <g fill="#FFF" fill-rule="nonzero">
      <g>
          <g>
              <g>
                  <path d="M24.192 2.304H13.035c.501 1.05.789 2.218.789 3.456s-.288 2.406-.79 3.456h11.158c.926 0 1.796-.358 2.447-1.01.65-.65 1.009-1.52 1.009-2.446 0-1.905-1.55-3.456-3.456-3.456zM11.52 5.76c0 3.181-2.579 5.76-5.76 5.76C2.58 11.52 0 8.94 0 5.76 0 2.58 2.579 0 5.76 0c3.181 0 5.76 2.579 5.76 5.76zM13.824 19.584c0-1.238.288-2.407.79-3.456H3.455C1.551 16.128 0 17.678 0 19.584c0 .926.358 1.796 1.01 2.447.65.65 1.52 1.01 2.446 1.01h11.157c-.501-1.05-.789-2.219-.789-3.457zM27.648 19.584c0 3.181-2.579 5.76-5.76 5.76-3.181 0-5.76-2.579-5.76-5.76 0-3.181 2.579-5.76 5.76-5.76 3.181 0 5.76 2.579 5.76 5.76z" transform="translate(-25 -307) translate(0 50) translate(0 231) translate(25 26)"/>
              </g>
          </g>
      </g>
  </g>
</g>

  `,
];
