import React from "react";
import { getBezierPath, getMarkerEnd } from "react-flow-renderer";
import { useStoreState } from "react-flow-renderer";

import calculateEdgePosition from "./../../../helpers/calculateEdgePosition";

export default function CustomEdge({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = { color: "white" },
  data,
  arrowHeadType,
  markerEndId,
  ...props
}) {
  const nodes = useStoreState((store) => store.nodes);
  const sourceNodes = nodes.filter((el) => el.id === source);
  const targetNodes = nodes.filter((el) => el.id === target);
  const calculatedSourcePosition = calculateEdgePosition(
    sourceNodes[0],
    targetNodes[0]
  );
  const calculatedTargetPosition = calculateEdgePosition(
    targetNodes[0],
    sourceNodes[0]
  );
  const {
    edgePosition: mySourcePostion,
    positionDeviation: mySourceDevation,
  } = calculatedSourcePosition;
  const {
    edgePosition: myTargetPosition,
    positionDeviation: myTargetDevation,
  } = calculatedTargetPosition;

  const edgePath = getBezierPath({
    sourceX: mySourceDevation.x,
    sourceY: mySourceDevation.y,
    sourcePosition: mySourcePostion,
    targetX: myTargetDevation.x,
    targetY: myTargetDevation.y,
    targetPosition: myTargetPosition,
  });

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  return (
    <path
      id={id}
      style={style}
      className="react-flow__edge-path"
      d={edgePath}
      markerEnd={markerEnd}
    />
  );
}
