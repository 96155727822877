import React from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

function TrainingTime({ time }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ theme });

  return <div className={classes.timeRoot}>{time}</div>;
}

const useStyles = makeStyles(() => ({
  timeRoot: {
    color: "rgba(255,255,255,0.5)",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "10px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "12px",
  },
}));

export default TrainingTime;
