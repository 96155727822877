export const graphListener = [
  "15 21",
  `
  <defs>
  <filter id="filter-1">
      <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"></feColorMatrix>
  </filter>
</defs>
<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="timeline-icons" transform="translate(-364.000000, -71.000000)">
      <g id="ear" transform="translate(351.000000, 63.000000)" filter="url(#filter-1)">
          <g transform="translate(13.000000, 8.000000)">
              <path d="M7.08,0 C3.1922,0 0.02804,3.16348 0.02804,7.05196 C0.02804,7.4288 0.33388,7.73464 0.71072,7.73464 C1.08756,7.73464 1.3934,7.4288 1.3934,7.05196 C1.3934,3.91648 3.94452,1.36536 7.08,1.36536 C10.21548,1.36536 12.7666,3.91648 12.7666,7.05196 C12.7666,9.102 11.96856,9.8816 10.85992,10.96364 C9.65296,12.1426 8.15108,13.60896 8.15108,16.77312 C8.15108,18.06472 7.10044,19.11468 5.80884,19.11468 C4.51792,19.11468 3.46728,18.06472 3.46728,16.77312 C3.46728,16.3956 3.16144,16.09044 2.7846,16.09044 C2.40776,16.09044 2.10192,16.3956 2.10192,16.77312 C2.10192,18.81704 3.76488,20.48 5.8088,20.48 C7.85272,20.48 9.51636,18.81704 9.51636,16.77312 C9.51636,14.18444 10.6844,13.04372 11.81764,11.93644 C12.95564,10.82572 14.13188,9.67816 14.13188,7.05196 C14.13188,3.16348 10.96848,0 7.08,0 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
              <path d="M7.40972,10.39224 C7.20696,9.59828 6.70724,8.932 6.00412,8.5156 C5.43272,8.17564 4.7808,8.03364 4.11996,8.10192 C3.74516,8.14084 3.4728,8.4774 3.5124,8.85216 C3.55132,9.22696 3.88036,9.49524 4.26264,9.45972 C4.62856,9.42216 4.98968,9.50068 5.30712,9.68976 C5.6976,9.92052 5.97408,10.28984 6.08672,10.73016 C6.20004,11.17116 6.13384,11.62924 5.90172,12.01972 C5.6696,12.41156 5.2996,12.68804 4.85928,12.80068 C4.41896,12.91264 3.96156,12.84712 3.5704,12.615 C3.24612,12.42316 2.82696,12.52968 2.63516,12.85392 C2.44332,13.1782 2.54984,13.59736 2.87408,13.78984 C3.354,14.07452 3.88988,14.2206 4.43396,14.2206 C4.6886,14.2206 4.9446,14.18852 5.19648,14.12364 C5.99044,13.92088 6.6574,13.42116 7.07516,12.71736 C7.49436,12.0122 7.61248,11.18684 7.40972,10.39224 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
              <path d="M7.43704,3.18396 C5.24092,3.18396 3.45436,4.9712 3.45436,7.16732 C3.45436,7.54416 3.7602,7.85 4.13704,7.85 C4.51388,7.85 4.81972,7.54416 4.81972,7.16732 C4.81972,5.72348 5.99392,4.54928 7.43708,4.54928 C8.87956,4.54928 10.05376,5.72348 10.05376,7.16732 C10.05376,7.54416 10.35892,7.85 10.73644,7.85 C11.11396,7.85 11.41912,7.54416 11.41912,7.16732 C11.41904,4.97116 9.63248,3.18396 7.43704,3.18396 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
              <path d="M4.8074,6.89424 C4.79444,6.5174 4.48244,6.2232 4.1022,6.2348 C3.72536,6.24776 3.42976,6.56316 3.44276,6.94 L3.50352,8.75588 C3.5158,9.1252 3.81892,9.416 4.18552,9.416 C4.19304,9.416 4.20052,9.416 4.20872,9.41532 C4.58556,9.40236 4.88116,9.08696 4.86816,8.71012 L4.8074,6.89424 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
          </g>
      </g>
  </g>
</g>`,
];
