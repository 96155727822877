export const SET_TRAINING_STATUS = "SET_TRAINING_STATUS";
export const SET_REJOIN_STATUS = "SET_REJOIN_STATUS";
export const SET_TRAINING_TIMER = "SET_TRAINING_TIMER";
export const SET_TIMER_WARNINGS = "SET_TIMER_WARNINGS";
export const SET_TRAINING_TOKEN = "SET_TRAINING_TOKEN";
export const SET_TRAINING_RANGER = "SET_TRAINING_RANGER";
export const SET_TRAINING_ID = "SET_TRAINING_ID";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const SET_TRAINING_SCHEDULED_ID = "SET_TRAINING_SCHEDULED_ID";
export const SET_CONNECTED_TRAINEES = "SET_CONNECTED_TRAINEES";
export const SET_SCHEDULED_TRAINEES = "SET_SCHEDULED_TRAINEES";
export const UPDATE_DISCONNECTED_TRAINEE = "UPDATE_DISCONNECTED_TRAINEE";
export const RESET_STATE = "RESET_STATE";
