const logger = (title, data, color = "blue") => {
  if (process.env.REACT_APP_ENVIRONMENT.trim() === "PROD")
    return console.log("%c" + "CyberMind", "color:" + color + ";font-weight:bold;");
  if (typeof data !== "string") {
    console.log(title, data);
  } else {
    return console.log(
      "%c" + `${title + " : " + data}`,
      "color:" + color + ";font-weight:bold;"
    );
  }
};

export default logger;
