import { SET_EFFECTS } from "./../types/effects";

const initialState = {
  effect: "",
  value: 0,
};

const effectsReducer = (state = initialState, action) => {
  const { effect, type } = action;

  switch (type) {
    case SET_EFFECTS:
      return effect;
    default:
      return state;
  }
};

export default effectsReducer;
