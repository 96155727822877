export const fastestRunBadge = [
  "30 30",
  `
  <defs>
        <linearGradient id="20ii9ig8zb" x1="50%" x2="50%" y1="19.351%" y2="100%">
            <stop offset="0%" stop-color="#FF852E"/>
            <stop offset="100%" stop-color="#EB7826"/>
        </linearGradient>
        <linearGradient id="10fjbsobxe" x1="50%" x2="50%" y1="19.351%" y2="100%">
            <stop offset="0%" stop-color="#FF852E"/>
            <stop offset="100%" stop-color="#EB7826"/>
        </linearGradient>
        <linearGradient id="o2ownjtpvi" x1="50%" x2="50%" y1="19.351%" y2="100%">
            <stop offset="0%" stop-color="#FF852E"/>
            <stop offset="100%" stop-color="#EB7826"/>
        </linearGradient>
        <linearGradient id="06e94pxuqj" x1="50%" x2="50%" y1="19.351%" y2="100%">
            <stop offset="0%" stop-color="#FF852E"/>
            <stop offset="100%" stop-color="#EB7826"/>
        </linearGradient>
        <linearGradient id="7mgfwimt7k" x1="50%" x2="50%" y1="19.351%" y2="100%">
            <stop offset="0%" stop-color="#FF852E"/>
            <stop offset="100%" stop-color="#EB7826"/>
        </linearGradient>
        <linearGradient id="xu8saq9bul" x1="50%" x2="50%" y1="19.351%" y2="100%">
            <stop offset="0%" stop-color="#FF852E"/>
            <stop offset="100%" stop-color="#EB7826"/>
        </linearGradient>
        <linearGradient id="qia1zr75ym" x1="50%" x2="50%" y1="19.351%" y2="100%">
            <stop offset="0%" stop-color="#FF852E"/>
            <stop offset="100%" stop-color="#EB7826"/>
        </linearGradient>
        <path id="0tbtwpd0ka" d="M4.554 2.397L5.397 4.793 18.046 9.746 16.697 19.493 2.193 20.292 1.687 21.57 0 23.168 9.107 27.801 24.286 22.529 23.106 11.824 17.985 2.748 8.095 0z"/>
        <path id="r6pu34c2xd" d="M26.218 17.285L29.591 17.285 27.905 0.872 0 0 0.92 5.229 1.533 6.246 2.453 7.117z"/>
        <path id="12e91zhw1g" d="M2.76 0L0 1.017l2.593 7.105c4.124 2.868 7.365 4.506 9.722 4.912 2.357.406 5.381.406 9.074 0l.076-3.157-.307-1.743L8.74 5.664 2.76 0z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g transform="translate(-419 -141) translate(419 141)">
                <g>
                    <g transform="translate(7.13 3.684)">
                        <mask id="lj68enkxhc" fill="#fff">
                            <use xlink:href="#0tbtwpd0ka"/>
                        </mask>
                        <ellipse cx="9.192" cy="13.661" stroke="url(#20ii9ig8zb)" stroke-width="1.65" mask="url(#lj68enkxhc)" rx="12.227" ry="11.584"/>
                    </g>
                    <g transform="translate(4.906)">
                        <mask id="nlm76nwsrf" fill="#fff">
                            <use xlink:href="#r6pu34c2xd"/>
                        </mask>
                        <ellipse cx="11.729" cy="17.212" stroke="url(#10fjbsobxe)" stroke-width="1.5" mask="url(#nlm76nwsrf)" rx="15.409" ry="14.598"/>
                    </g>
                    <g transform="translate(0 21.352)">
                        <mask id="9cugydw6wh" fill="#fff">
                            <use xlink:href="#12e91zhw1g"/>
                        </mask>
                        <ellipse cx="16.635" cy="-4.14" stroke="url(#10fjbsobxe)" stroke-width="1.5" mask="url(#9cugydw6wh)" rx="15.409" ry="14.598"/>
                    </g>
                </g>
                <g>
                    <path fill="url(#o2ownjtpvi)" stroke="url(#o2ownjtpvi)" stroke-linejoin="round" stroke-width="1.35" d="M12.594 0H16.029V2.169H12.594z" transform="translate(1.944 8.596)"/>
                    <path fill="url(#06e94pxuqj)" fill-rule="nonzero" stroke="#000" stroke-width=".675" d="M14.438 15.75c4.1 0 7.437-3.16 7.437-7.046 0-3.885-3.336-7.046-7.437-7.046C10.336 1.658 7 4.818 7 8.704c0 3.885 3.336 7.046 7.438 7.046h0z" transform="translate(1.944 8.596)"/>
                    <path fill-rule="nonzero" stroke="url(#06e94pxuqj)" stroke-width="2" d="M14.438 2.658c1.78 0 3.398.683 4.568 1.792 1.15 1.09 1.869 2.593 1.869 4.254 0 1.661-.718 3.164-1.869 4.254-1.17 1.109-2.787 1.792-4.568 1.792-1.782 0-3.399-.683-4.57-1.792C8.719 11.868 8 10.365 8 8.704c0-1.661.718-3.164 1.869-4.254 1.17-1.109 2.787-1.792 4.569-1.792z" transform="translate(1.944 8.596)"/>
                    <path fill="#000" fill-rule="nonzero" d="M14.188 10.746c.217 0 .405-.102.504-.253l1.99-1.701c.226-.193.226-.505 0-.698-.225-.192-.59-.192-.815 0l-1.102.941V6.634c0-.273-.258-.494-.577-.494-.319 0-.577.221-.577.494v3.618c0 .273.259.494.577.494z" transform="translate(1.944 8.596)"/>
                    <path fill="url(#7mgfwimt7k)" fill-rule="nonzero" stroke="url(#7mgfwimt7k)" stroke-width=".5" d="M4.978 8.446c0-.34-.151-.614-.337-.614H.337c-.186 0-.337.274-.337.614 0 .339.151.614.337.614h4.304c.186 0 .337-.275.337-.614z" transform="translate(1.944 8.596)"/>
                    <path fill="url(#xu8saq9bul)" fill-rule="nonzero" stroke="url(#xu8saq9bul)" stroke-width=".5" d="M5.585 4.489H3.74c-.123 0-.222.275-.222.614 0 .339.1.614.222.614h1.845c.123 0 .222-.275.222-.614 0-.34-.1-.614-.222-.614z" transform="translate(1.944 8.596)"/>
                    <path fill="url(#qia1zr75ym)" fill-rule="nonzero" stroke="url(#qia1zr75ym)" stroke-width=".5" d="M5.469 11.232H2.662c-.187 0-.339.275-.339.614 0 .34.152.614.339.614h2.807c.187 0 .338-.275.338-.614 0-.339-.151-.614-.338-.614z" transform="translate(1.944 8.596)"/>
                </g>
                <circle cx="20.417" cy="13.816" r="1" fill="#000"/>
                <circle cx="22.361" cy="17.5" r="1" fill="#000"/>
                <circle cx="20.417" cy="21.184" r="1" fill="#000"/>
                <circle cx="16.528" cy="23.026" r="1" fill="#000"/>
                <circle cx="12.639" cy="21.798" r="1" fill="#000"/>
                <circle cx="10.694" cy="17.5" r="1" fill="#000"/>
                <circle cx="12.639" cy="13.816" r="1" fill="#000"/>
                <circle cx="16.528" cy="11.974" r="1" fill="#000"/>
            </g>
        </g>
    </g>
  `,
];
