import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CIcon from "@coreui/icons-react";
import RoundAvatar from "../../../../views/avatar/Avatar";

function LeaderboardAvatar({ size, image, imageSize = 60, username }) {
  const classes = useStyles();

  return (
    <Box
      position="relative"
      className={classes.leaderboardAvatar}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {image ? (
        <img
          src={image}
          width={`${imageSize}px`}
          height={`${imageSize}px`}
          className={classes.avatarImg}
        />
      ) : (
        <RoundAvatar
          username={username}
          avatarClass={classes.avatarImg}
          size={30}
        />
      )}
      {size && size > 0 ? (
        <div className={classes.size}>
          {size}
          <CIcon name="avatarUser" width="15px" height="18px" />
        </div>
      ) : null}
    </Box>
  );
}

const useStyles = makeStyles({
  leaderboardAvatar: {
    height: "100%",
    width: "55px",
    marginRight: "10px",
    fontSize: "25px",
    fontWeight: "bold",
    lineHeight: "1",
    color: "#ffffff",
  },
  size: {
    position: "absolute",
    bottom: 5,
    right: 5,
  },
  avatarImg: {
    border: "solid 0.5px rgba(255, 255, 255, 0.28)",
    borderRadius: "50%",
  },
});

export default memo(LeaderboardAvatar);
