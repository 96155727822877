import React, { useState, useEffect, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import InformerPopup from "./InformerPopup";
import useWindowDimensions from "../../../hooks/useWindowDiemensions";

function InformerPopupContainer() {
  const [defaultPosition, setDefaultPosition] = useState({
    x: 0,
    y: 0,
  });

  const { width, height } = useWindowDimensions();
  const { theme } = useSelector((state) => state.uiReducer);

  const classes = useStyles({ theme });

  const informers = useSelector((state) => state.informerReducer);
  const { username } = useSelector((state) => state.userReducer);
  const questions = useSelector((state) => state.quizReducer);

  useEffect(() => {
    if (width !== 0 && height !== 0) {
      /// height = 780 ySize = -222 -> Min For Black Screen (better looks)
      let ySize = height / -3.5; /// height = 710 ySize = -202 -> MIN window for operation
      if (ySize > -222) {
        ySize += ySize / 2;
      }
      setDefaultPosition({ x: 35, y: ySize });
    }
  }, [height]);

  if (!informers || informers.length === 0) return null;

  return (
    <div className={classes.root}>
      {informers &&
        informers.map((informer, index) => {
          return (
            <InformerPopup
              username={username}
              informer={informer}
              key={informer.id || index}
              defaultPosition={defaultPosition}
              setDefaultPosition={setDefaultPosition}
            />
          );
        })}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    position: "fixed",
    fontFamily: (props) => props.theme.MAIN_FONT,
    letterSpacing: "0",
    lineHeight: "18px",
    textShadow: "0 0 6px 0 rgba(0,0,0,0.35)",
    color: "#FFFFFF",

    whiteSpace: "pre-line",
  },
});

export default InformerPopupContainer;
