import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ConnectedTraineesSlider from "./ConnectedTraineesSlider";
import { useSelector } from "react-redux";

function TeamTrainingHeader({ isChat = false }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ theme });

  return (
    <div className={classes.Root}>
      {!isChat && (
        <div className={classes.title}>
          {"This is a Team training with the following team members:"}
        </div>
      )}
      <ConnectedTraineesSlider isChat={isChat} />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  Root: {
    marginBottom: "1rem",
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
  },
  title: {
    textShadow: "0 0 6px rgba(0, 0, 0, 0.35)",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "16px",
    fontWeight: "300",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
  },
}));

export default TeamTrainingHeader;
