import React, { useMemo, memo } from "react";
import { makeStyles } from "@material-ui/core";
import MissionFooterHeaderText from "./MissionFooterHeaderText";
import ShadowedText from "../shared/ShadowedText";

function MissionFooterRings({ currentRings, totalRings, status }) {
  const classes = useStyles({ status });

  const ringsDisplay = useMemo(() => {
    // if (status === "finished") {
    //   return (
    //     <div className={classes.footerText}>
    //       <span>
    //         <ShadowedText type="green" text={currentRings} size="15px" />
    //       </span>
    //       <span className={classes.finishedLower}>/{totalRings}</span>
    //     </div>
    //   );
    // }
    const type = status === "active" ? "blue" : "grey";
    return (
      <ShadowedText
        type="green"
        type={type}
        text={`${currentRings}/${totalRings}`}
        size="15px"
      />
    );
  }, [currentRings, totalRings, status]);

  return (
    <div>
      <MissionFooterHeaderText status={status} text="Rings" />
      {ringsDisplay}
    </div>
  );
}

const useStyles = makeStyles({
  footerText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "15px",
    minWidth: "50px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  finishedLower: {
    fontSize: "10px",
    opacity: 0.77,
    color: "#ffffff",
  },
});

export default memo(MissionFooterRings);
