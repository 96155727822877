import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ShadowedText from "../shared/ShadowedText";

function HintCost({ cost }) {
  const classes = useStyles();

  return (
    <div className={classes.hintRoot}>
      This hint will cost you
      <div className={classes.costWrapper}>
        <ShadowedText type="red" text={cost} />
      </div>
      points
    </div>
  );
}

const useStyles = makeStyles({
  hintRoot: {
    textShadow: "0 0 6px rgba(0, 0, 0, 0.35)",
    display: "flex",
    alignItems: "center",
    fontFamily: "NunitoSans",
    fontSize: "18px",
    fontWeight: "300",
    lineHeight: "1.67",
    color: "#ffffff",
  },
  costWrapper: {
    margin: "0 6px",
  },
});

export default memo(HintCost);
