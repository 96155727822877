import React from "react";
import { makeStyles } from "@material-ui/core";

import TrainingPopupContainer from "./../TrainingPopupContainer";
import TrainingPopupContainerHeader from "../TrainingPopupContainerHeader";
import TrainingPopupContainerBody from "../TrainingPopupContainerBody";
import TrainingHeaderTitle from "../TrainingHeaderTitle";
// import Mission from "./Mission";
import { useSelector } from "react-redux";
import Event from "./Event";

function EventsContainer({ closeClick }) {
  const classes = useStyles();

  const events = useSelector((state) => state.eventsReducer);

  const headerSize = { width: "100%", height: "35px" };

  return (
    <TrainingPopupContainer overflow="hidden">
      <TrainingPopupContainerHeader size={headerSize} closeClick={closeClick}>
        <TrainingHeaderTitle text="scenario events" />
      </TrainingPopupContainerHeader>
      <TrainingPopupContainerBody>
        <div className={classes.innerBody}>
          {events.map((event) => {
            const { topic, username, icon, time, id } = event;
            return (
              <Event
                key={id}
                topic={topic}
                username={username}
                icon={icon}
                time={time}
              />
            );
          })}
        </div>
      </TrainingPopupContainerBody>
    </TrainingPopupContainer>
  );
}

const useStyles = makeStyles(() => ({
  innerBody: {
    backgroundColor: "rgba(0,0,0,0.46)",
    minHeight: "100%",
    width: "100%",
    borderRadius: "4px",
    padding: "5px 0 5px 3px",
    WebkitBackdropFilter: "blur(5px)",
    backdropFilter: "blur(5px)",
  },
}));

export default EventsContainer;
