import { SET_TIMELINE, UPDATE_TIMELINE, RESET_STATE } from "../types/timeline";

const initialState = {
  graph: [
    {
      name: "12:00",
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
    },
    {
      name: "12:30",
      1: 6000,
      2: 5000,
      3: 2500,
      4: 3000,
      5: 3000,
      6: 6500,
      7: 4500,
      8: 1500,
      9: 7000,
      10: 6000,
    },
    {
      name: "13:00",
      1: 6000,
      2: 4500,
      3: 2000,
      4: 3000,
      5: 3500,
      6: 6000,
      7: 6000,
      8: 5000,
      9: 2500,
      10: 6100,
    },
    {
      name: "13:30",
      1: 6000,
      2: 7000,
      3: 2000,
      4: 6000,
      5: 3500,
      6: 7000,
      7: 4500,
      8: 2000,
      9: 5000,
      10: 4500,
    },
    {
      name: "14:00",
      1: 6000,
      2: 7000,
      3: 4000,
      4: 6500,
      5: 4500,
      6: 5000,
      7: 5500,
      8: 5000,
      9: 7500,
      10: 2500,
    },
    {
      name: "14:30",
      1: 7000,
      2: 6000,
      3: 4500,
      4: 5500,
      5: 4500,
      6: 7500,
      7: 6500,
      8: 5000,
      9: 7900,
      10: 3000,
    },
    {
      name: "15:00",
      1: 7000,
      2: 4000,
      3: 3500,
      4: 5000,
      5: 4500,
      6: 7000,
      7: 7500,
      8: 6000,
      9: 6500,
      10: 7000,
    },
  ],
  dots: {
    1: {
      "12:30": { icon: "orangeHint", message: "orangeHint" },
      "13:00": { icon: "orangeShield", message: "orangeShield" },
      "14:00": { icon: "orangeHat", message: "orange hat" },
      "14:30": { icon: "greenShield", message: "greenShield" },
      "15:00": { icon: "pinkShield", message: "pinkShield" },
    },
  },
};

const emptyState = {
  graph: [],
  dots: {},
};

const timelineReducer = (state = initialState, action) => {
  const { graph, dots, timelineUpdate, type } = action;

  switch (type) {
    case SET_TIMELINE:
      return { graph, dots: dots || {} };
    case UPDATE_TIMELINE:
      if (
        !timelineUpdate?.timestamp ||
        !timelineUpdate?.scoreBoard ||
        !timelineUpdate?.user
      )
        return state;
      const index = state.graph.findIndex(
        (el) => el.timestamp === timelineUpdate.timestamp
      );
      const tmpGraph = [...state.graph];
      if (index !== -1) {
        tmpGraph[index] = timelineUpdate.scoreBoard;
      } else {
        tmpGraph.push(timelineUpdate.scoreBoard);
      }
      const tmpDots = { ...state.dots };
      if (tmpDots[timelineUpdate.user]) {
      } else {
        tmpDots[timelineUpdate.user] = {};
      }
      tmpDots[timelineUpdate.user][timelineUpdate.timestamp] = {
        icon: timelineUpdate?.icon,
        message: timelineUpdate?.message,
      };
      return { dots: tmpDots, graph: tmpGraph };
    case RESET_STATE:
      return emptyState;
    default:
      return state;
  }
};
export default timelineReducer;
