import {
  SET_INFO,
  ADD_INFOS,
  REMOVE_INFO,
  SET_INITIAL,
  RESET_STATE,
} from "../types/info";

export const setInfo = (info) => ({
  type: SET_INFO,
  info,
});

export const addinfos = (infos) => ({
  type: ADD_INFOS,
  infos,
});
export const restoreInitialStore = (initial) => ({
  type: SET_INITIAL,
  initial,
});

export const removeInfo = (id) => {
  return { type: REMOVE_INFO, id };
};

export const resetInfosState = () => ({
  type: RESET_STATE,
});
