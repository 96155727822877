import { call, put, takeLatest } from "redux-saga/effects";
import { getStaticMitreData } from "../../services/Mitre";
import { setMitreDB } from "../actions/mitreDB";

function* fetchMitreDB(action) {
  try {
    const response = yield call(getStaticMitreData);
    const mitreDB = response?.data;

    if (Object.keys(mitreDB)?.length > 0) {
      yield put(setMitreDB(mitreDB));
    }
  } catch (err) {
    console.warn("Failed to fetch mitreDB", err);
  }
}

export function* mitreDBSaga() {
  yield takeLatest("LOAD_MITRE_DB", fetchMitreDB);
}
