import highlightUrl from "./highlightUrl";

export const missionBuilder = (steps) => {
  const missions = [];
  const progress = {};
  const myHints = {};
  let myUrlArr = [];

  if (steps && steps.length > 0) {
    steps.forEach((step, index) => {
      const { score, topic, id, isLocked, Rings, content, hints, type } = step;
      // currently added to informers isLocked manually to hide feature informers as default @ CYB-1387
      const status = isLocked ? "locked" : "active";
      const mission = { id, topic, status, type };
      const RichText = content.map((el) => {
        const innerArr = [];

        el.forEach((innerEl) => {
          let value = innerEl?.value || "";
          if (innerEl?.type === "text") {
            const { urlArr, textArr } = highlightUrl(innerEl.value);
            value = textArr;
            myUrlArr = [...myUrlArr, ...urlArr];
          }
          if (innerEl?.type === "link") {
            const { display, subtype, value } = innerEl;
            const { urlArr } = highlightUrl(innerEl.value, {
              display,
              subtype,
              value,
            });
            myUrlArr = [...myUrlArr, ...urlArr];
          }
          innerArr.push({ ...innerEl, value });
        });

        return innerArr;
      });

      progress[id] = {
        totalScore: score,
        currentScore: score,
        totalRings: Rings,
        currentRings: 0,
        status,
        priority: index,
      };
      myHints[id] = {
        currentHint: 0,
        totalHints: hints.length || 0,
        hints: [...hints],
      };

      mission["RichText"] = RichText || [];

      missions.push(mission);
    });
  }

  return {
    missions,
    progress,
    hints: myHints,
    urlArr: myUrlArr,
  };
};

export const missionUpdater = (task, missions, progress, popups, hints) => {
  const myMissions = [...missions];
  const myProgress = { ...progress };
  const myPopups = [...popups];
  let myHints = { ...hints };
  const { topic, taskId, family, content } = task;
  let newStatus = family === "task_finished" ? "finished" : "active";

  const mission = { id: taskId, topic, status: newStatus, type: family };
  let active = taskId;
  const RichText = content.map((el) => {
    const innerArr = [];
    el.forEach((innerEl) => {
      let value = innerEl?.value || "";
      if (innerEl?.type === "text") {
        const { textArr } = highlightUrl(innerEl.value);
        value = textArr;
      }
      innerArr.push({ ...innerEl, value });
    });
    return innerArr;
  });

  mission["RichText"] = RichText || [];

  const index = missions.findIndex((el) => el.id === taskId);
  if (index !== -1) {
    if (family === "task_finished") {
      active = "";
    }
    if (myProgress?.[taskId]) {
      myProgress[taskId]["status"] = newStatus;
    }
    myMissions[index] = mission;
  }
  const newState = Object.assign(
    {},
    {
      missions: myMissions,
      progress: myProgress,
      popups: [...myPopups, { ...mission }],
      hints: { ...myHints },
      active,
    }
  );

  return newState;
};

export const questionUpdater = (
  question,
  missions,
  progress,
  popups,
  hints
) => {
  const myMissions = [...missions];
  const myProgress = { ...progress };
  let myHints = { ...hints };
  const { questions: topic, id, family } = question;
  let newStatus = "active";
  const mission = { id, topic, status: newStatus, type: "question" };
  let active = id;
  mission["RichText"] = [];

  const index = missions.findIndex((el) => el.id === id);
  if (index !== -1) {
    if (family === "task_finished") {
      active = "";
    }
    if (myProgress?.[id]) {
      myProgress[id]["status"] = newStatus;
    }
    myMissions[index] = mission;
  }
  const newState = Object.assign(
    {},
    {
      missions: myMissions,
      progress: myProgress,
      popups,
      hints: { ...myHints },
      active,
    }
  );
  return newState;
};

export const informerUpdater = (
  informer,
  missions,
  progress,
  popups,
  hints
) => {
  const myMissions = [...missions];
  const myProgress = { ...progress };
  let myHints = { ...hints };

  const { topic, id, content } = informer;
  let newStatus = "active";
  const mission = { id, topic, status: newStatus, type: "informer" };
  let activeId = id;
  mission["RichText"] = content;

  const index = missions.findIndex((el) => el.id === id);
  if (index !== -1) {
    if (myProgress?.[id]) {
      myProgress[id]["status"] = newStatus;
    }
    myMissions[index] = mission;
  }

  const newState = Object.assign(
    {},
    {
      missions: myMissions,
      progress: myProgress,
      popups,
      hints: { ...myHints },
      active: activeId,
    }
  );

  return newState;
};

export const markMissionFinished = (missionId, state) => {
  const { missions, progress, popups, hints, active } = state;
  const myMissions = [...missions];
  const myProgress = { ...progress };

  const index = missions.findIndex((el) => el.id === missionId);

  if (myMissions?.[index]?.["status"]) {
    myMissions[index]["status"] = "finished";
  }
  if (myProgress?.[missionId]?.["status"]) {
    myProgress[missionId]["status"] = "finished";
  }

  const newState = Object.assign(
    {},
    {
      missions: myMissions,
      progress: myProgress,
      popups,
      hints: hints,
      active,
    }
  );

  return newState;
};

export const debriefBuilder = (debriefData) => {
  const { Missions: steps, TotalMissions, Completed } = debriefData || {};
  const missions = [];
  const progress = {};
  const myHints = {};
  let myUrlArr = [];

  if (steps && steps.length > 0) {
    steps.forEach((step, index) => {
      if (step?.Step) {
        const { topic, id, Rings, content, hints, type, MitreTechniques} = step.Step;
        const totalScore = step.Step.score;
        const { time_seconds, trials, used_hints } = step;
        let { score } = step;
        if (step.trials > 1) {
          if (step.userReviewDict.answers.length > 1) {
            step.userReviewDict.answers.forEach((answer) => {
              score =
                answer.answer.score_added > score
                  ? answer.answer.score_added
                  : score;
            });
          }
        }
        const status = "debrief";
        const mission = { id, topic, status, type, MitreTechniques };
        const userReviewDict = step?.userReviewDict;
        const details = step?.details;

        // const options = step?.userReviewDict;

        const RichText = content.map((el) => {
          const innerArr = [];
          el.forEach((innerEl) => {
            let value = innerEl?.value || "";

            if (innerEl?.type === "text") {
              const { urlArr, textArr } = highlightUrl(innerEl.value);
              value = textArr;
              myUrlArr = [...myUrlArr, ...urlArr];
            }
            if (innerEl?.type === "link") {
              const { display, subtype, value } = innerEl;
              const { urlArr } = highlightUrl(innerEl.value, {
                display,
                subtype,
                value,
              });
              myUrlArr = [...myUrlArr, ...urlArr];
            }
            innerArr.push({ ...innerEl, value });
          });
          return innerArr;
        });

        progress[id] = {
          totalScore: totalScore,
          currentScore: score,
          totalRings: Rings,
          currentRings: Rings,
          status,
          priority: index,
          time_seconds,
          trials,
        };

        const myHintsArr = hints.map((el) => {
          return {
            HintID: el?.HintID || 0,
            hint: [{ text: el?.hint || "", type: "text" }],
            price: el?.price || "",
            openNewTab: el?.openNewTab
          };
        });

        myHints[id] = {
          currentHint: used_hints,
          totalHints: hints.length || 0,
          hints: [...myHintsArr],
        };

        mission["RichText"] = RichText || [];
        mission["userReviewDict"] = userReviewDict || [];
        mission["details"] = details || [];

        missions.push(mission);
      }
    });
  }

  return {
    missions,
    progress,
    hints: myHints,
    urlArr: myUrlArr,
    TotalMissions,
    Completed,
  };
};
