import React, { useRef } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import FlowChartNetworkNode from "./FlowChartNetworkNode";
import {
  Handle,
  getOutgoers,
  getIncomers,
  useStoreState,
} from "react-flow-renderer";

// import calculateHandlePosition from "./../../../helpers/calculateHandlePosition";

function CustomNetworkFlowChart({ data, ...props }) {
  const { label, display, content, cmdId, device } = data;
  const { id, selected } = props;
  const { Legs } = device;
  const nodeElement = useRef();

  const getLeftPosition = (idx) => {
    const actualWidth = nodeElement?.current?.offsetWidth;
    const LegsCount = Legs.length;
    return (actualWidth / LegsCount) * idx + actualWidth / LegsCount / 2;
  };

  // const { nodes, edges } = useStoreState((store) => store);
  // const elements = [...nodes, ...edges];
  // const incomers = getIncomers(props, elements);
  // const outgoers = getOutgoers(props, elements);
  // const myNodes = elements.filter((el) => el.id === id);

  const returnHandles = () => {
    switch (device?.Type) {
      case "FIREWALL":
        return returnFirewallHandles();
      case "SUBLAN":
        return returnSublanHandles();
      default:
        return returnDefaultHandles(content);
    }
  };

  const returnDefaultHandles = (content) => {
    return (
      <>
        <Tooltip title={"Input"} placement={"left"}>
          <div style={{ pointerEvents: "none" }}>
            <Handle
              type={"target"}
              position={"left"}
              id={`${"target"}-${id}-`}
              key={`${"target"}-${id}-`}
              style={{
                background: "#fff",
                border: "2px solid #1D50BD",
                borderRadius: "6px",
                width: "15px",
                height: "15px",
                left: "-15px",
              }}
            />
          </div>
        </Tooltip>
        {content !== "finish" ? (
          <Tooltip title={"Output"} placement={"right"}>
            <div style={{ pointerEvents: "none" }}>
              <Handle
                type={"source"}
                position={"right"}
                id={`${"source"}-${id}-`}
                key={`${"source"}-${id}-`}
                style={{
                  background: "#fff",
                  border: "2px solid #991A40",
                  borderRadius: "6px",
                  width: "15px",
                  height: "15px",
                  paddingLeft: "10px",
                  right: "-15px",
                }}
              />
            </div>
          </Tooltip>
        ) : null}
      </>
    );
  };

  const returnFirewallHandles = () => {
    return (
      <>
        <Tooltip title={"Input"} placement={"left"}>
          <div style={{ pointerEvents: "none" }}>
            <Handle
              type={"target"}
              position={"left"}
              id={`${"target"}-${id}-`}
              key={`${"target"}-${id}-`}
              style={{
                background: "#fff",
                border: "2px solid #1D50BD",
                borderRadius: "6px",
                width: "15px",
                height: "15px",
                left: "-15px",
              }}
            />
          </div>
        </Tooltip>
        <Tooltip title={"Output"} placement={"right"}>
          <div style={{ pointerEvents: "none" }}>
            <Handle
              type={"source"}
              position={"right"}
              id={`${"source"}-${id}-`}
              key={`${"source"}-${id}-`}
              style={{
                background: "#fff",
                border: "2px solid #991A40",
                borderRadius: "6px",
                width: "15px",
                height: "15px",
                paddingLeft: "10px",
                right: "-15px",
              }}
            />
          </div>
        </Tooltip>
        {Legs &&
          Legs.length > 0 &&
          Legs.map((leg, idx) => {
            return (
              <Tooltip
                title={`IP Range: ${leg.IpRange}`}
                placement={"bottom-start"}
              >
                <div style={{ pointerEvents: "none" }}>
                  <Handle
                    type={"source"}
                    position={"bottom"}
                    id={`${leg.name}`}
                    key={`${leg.name}`}
                    style={{
                      // background: "gray",
                      // border: "1px solid white",
                      // borderRadius: "6px",
                      width: "7px",
                      height: "7px",
                      top: "55px",
                      left: `${!idx ? 0 : idx * 7}px`,
                    }}
                  />
                </div>
              </Tooltip>
            );
          })}
      </>
    );
  };
  const returnSublanHandles = () => {
    return (
      <>
        <Tooltip title={"Input"} placement={"left"}>
          <div style={{ pointerEvents: "none" }}>
            <Handle
              type={"target"}
              position={"left"}
              id={`${"target"}-${id}-`}
              key={`${"target"}-${id}-`}
              style={{
                background: "#fff",
                border: "2px solid #1D50BD",
                borderRadius: "6px",
                width: "15px",
                height: "15px",
                left: "-15px",
              }}
            />
          </div>
        </Tooltip>
        <Tooltip title={"Output"} placement={"right"}>
          <div style={{ pointerEvents: "none" }}>
            <Handle
              type={"source"}
              position={"right"}
              id={`${"source"}-${id}-`}
              key={`${"source"}-${id}-`}
              style={{
                background: "#fff",
                border: "2px solid #991A40",
                borderRadius: "6px",
                width: "15px",
                height: "15px",
                paddingLeft: "10px",
                right: "-15px",
              }}
            />
          </div>
        </Tooltip>
        {Legs &&
          Legs.length > 0 &&
          Legs.map((leg, idx) => {
            return (
              <Tooltip
                title={`IP Range: ${leg.IpRange}`}
                placement={"bottom-start"}
              >
                <div style={{ pointerEvents: "none" }}>
                  <Handle
                    type={"source"}
                    position={"bottom"}
                    id={`${leg.name}`}
                    key={`${leg.name}`}
                    style={{
                      width: "7px",
                      height: "7px",
                      top: "25px",
                      left: `${getLeftPosition(idx)}px`,
                    }}
                  />
                </div>
              </Tooltip>
            );
          })}
      </>
    );
  };

  return (
    <div ref={nodeElement}>
      {returnHandles()}
      <FlowChartNetworkNode
        data={data}
        label={label}
        content={content}
        display={display}
        selected={selected}
        powerState={device.PowerState}
        cmdId={cmdId}
      />
    </div>
  );
}

export default CustomNetworkFlowChart;
