import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import TrainingPopupContainer from "./../TrainingPopupContainer";
import TrainingPopupContainerHeader from "./../TrainingPopupContainerHeader";
import TrainingPopupContainerBody from "./../TrainingPopupContainerBody";
import TrainingHeaderTitle from "./../TrainingHeaderTitle";
import ChatMessage from "./ChatMessage";
import ChatMessageBox from "./ChatMessageBox";
import { sendMessage } from "./../../../store/actions/chat";
import TeamTrainingHeader from "../shared/TeamTrainingHeader";

function ChatContainer({ closeClick }) {
  const classes = useStyles();
  const scrollRef = useRef(null);
  const dispatch = useDispatch();

  const headerSize = { width: "100%", height: "35px" };

  const { messages } = useSelector((state) => state.chatReducer);
  const { email: user } = useSelector((state) => state.userReducer);
  const { rangerName } = useSelector((state) => state.trainingReducer);
  const { userDetails } = useSelector((state) => state.personalHallReducer);
  const { scheduledTrainees } = useSelector((state) => state.trainingReducer);
  const isTeamTraining = scheduledTrainees && scheduledTrainees.length > 1;

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollToBottom();
    }
  }, [messages]);

  const sendChatMessage = (data) => {
    const obj = {
      username: user,
      data: { message: data, avatarId: userDetails?.avatarId || null },
      api_type: "chat",
      family: "chat",
      to: rangerName || "",
      from: user,
    };
    const stringObj = JSON.stringify(obj);
    dispatch(sendMessage(stringObj));
  };

  return (
    <TrainingPopupContainer overflow="hidden">
      <TrainingPopupContainerHeader size={headerSize} closeClick={closeClick}>
        <TrainingHeaderTitle text="chatroom" />
      </TrainingPopupContainerHeader>
      {isTeamTraining && <TeamTrainingHeader isChat={true} />}
      <TrainingPopupContainerBody scrollRef={scrollRef}>
        <div className={classes.innerBody}>
          {messages &&
            messages.map((message, index) => {
              const { color, username, data, time, avatarId } = message;
              const displayName = username.split("@")[0];
              const isLeft = username === user;
              return (
                <ChatMessage
                  nameColor={color}
                  name={username}
                  text={data}
                  time={time}
                  isLeft={isLeft}
                  key={index}
                  displayName={displayName}
                  avatarId={avatarId}
                />
              );
            })}
        </div>
      </TrainingPopupContainerBody>
      <ChatMessageBox sendMessage={sendChatMessage} />
    </TrainingPopupContainer>
  );
}

const useStyles = makeStyles(() => ({
  innerBody: {
    padding: "5px 0 5px 3px",
    WebkitBackdropFilter: "blur(5px)",
    backdropFilter: "blur(5px)",
  },
}));

export default ChatContainer;
