import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import PopupCard from "../shared/PopupCard";
import NetworkViewerAnimated from "../../base/flowChart/NetworkViewerAnimated";
import { ReactFlowProvider } from "react-flow-renderer";
import Grid from "@material-ui/core/Grid";
import NetworkViewHeader from "../shared/NetworkViewHeader";
import TrainingPopupContainer from "../TrainingPopupContainer";
import TrainingPopupContainerHeader from "../TrainingPopupContainerHeader";
import TrainingHeaderTitle from "../TrainingHeaderTitle";
import TrainingPopupContainerBody from "../TrainingPopupContainerBody";

function NetworkViewContainer({ networkFlowChart, closeClick }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const headerSize = { width: "100%", height: "35px" };

  //   const closeClick = () => {
  // console.log("closeClick");
  //   };

  return (
    <TrainingPopupContainer overflow="hidden">
      <TrainingPopupContainerHeader size={headerSize} closeClick={closeClick}>
        <TrainingHeaderTitle text="Network Diagram" />
      </TrainingPopupContainerHeader>
      <TrainingPopupContainerBody>
        <PopupCard padding="0" size={["70vw", "70vh"]} isLarge={true}>
          <Grid container className={classes.previewBody}>
            <Grid item sm={12} className={classes.flowGraph}>
              {networkFlowChart ? (
                <ReactFlowProvider>
                  <NetworkViewerAnimated chartData={networkFlowChart} />
                </ReactFlowProvider>
              ) : (
                "Loading..."
              )}
            </Grid>
          </Grid>
        </PopupCard>
      </TrainingPopupContainerBody>
    </TrainingPopupContainer>
  );
}

const useStyles = makeStyles({
  leaderboardRoot: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    border: "solid 1px rgba(255, 255, 255, 0.09)",
    borderRadius: "10px",
    WebkitBackdropFilter: "blur(5px)",
    backdropFilter: "blur(5px)",
    boxShadow: "0 0 2px 0 rgba(255, 255, 255, 0.35)",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  icon: {
    cursor: "pointer",
    color: "white",
  },
  networkChart: {
    width: "100%",
  },
  previewBody: {
    height: "100%",
    padding: "5px",
  },
  flowGraph: {
    height: "100%",
    padding: "10px",
  },
});

export default NetworkViewContainer;
