import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import HintContainer from "./HintContainer";
import HintHeader from "./HintHeader";
import ConfirmButton from "../buttons/ConfirmButton";
import CancelButton from "../buttons/CancelButton";
import HintCost from "./HintCost";

function TakeHintPopup({ getHint, cancelHint, hintCost, currentHint, id }) {
  const classes = useStyles();
  const size = { width: "108px", height: "35px", font: "15px" };

  const confirmHint = () => {
    getHint();
  };

  const myNumberWithEndText = () => {
    const nextHintNumber = currentHint + 1;
    const lastDigit = nextHintNumber % 10;
    const lastTwoDigits = nextHintNumber % 100;

    if (lastDigit === 1 && lastTwoDigits !== 11) {
      return nextHintNumber + "st";
    }
    if (lastDigit === 2 && lastTwoDigits !== 12) {
      return nextHintNumber + "nd";
    }
    if (lastDigit === 3 && lastTwoDigits !== 13) {
      return nextHintNumber + "rd";
    }
    return nextHintNumber + "th";
  };
  const myHintCost =
    hintCost === undefined || hintCost === null ? "-" : hintCost;

  return (
    <HintContainer id={id}>
      <HintHeader
        text={`Would you like to take the ${myNumberWithEndText()} hint for this mission`}
      />
      <HintCost cost={myHintCost} />
      <div className={classes.footer}>
        <CancelButton cancel={cancelHint} />
        <ConfirmButton
          text="get hint"
          confirm={confirmHint}
          size={size}
          type="active"
        />
      </div>
    </HintContainer>
  );
}

const useStyles = makeStyles(() => ({
  footer: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default TakeHintPopup;
