import { take } from "redux-saga/effects";
import logger from "../../helpers/logger";
import { SEND_MESSAGE } from "../types/chat";
import { SEND_EVENT } from "../types/socketEvent";

export function* write(socket) {
  try {
    while (true) {
      const { message } = yield take(SEND_MESSAGE);
      logger("MessageFromClient", message);
      socket.emit("MessageFromClient", message);
    }
  } finally {
    console.log("closed write");
  }
}

export function* writeEvents(socket) {
  try {
    while (true) {
      const { eventName, eventData } = yield take(SEND_EVENT);
      logger(eventData);
      socket.emit(eventName ,eventData);
    }
  } finally {
    console.log("closed writeEvents");
  }
}