export const greenTarget = [
  "34 34",
  `
  <defs>
  <filter id="cj6svwh83a">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.125490 0 0 0 0 0.764706 0 0 0 0 0.505882 0 0 0 1.000000 0"/>
  </filter>
  <filter id="siwa5g8lyb" width="220%" height="220%" x="-60%" y="-60%" filterUnits="objectBoundingBox">
      <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3"/>
      <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.125490196 0 0 0 0 0.764705882 0 0 0 0 0.505882353 0 0 0 1 0"/>
      <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="SourceGraphic"/>
      </feMerge>
  </filter>
</defs>
<g fill="none" fill-rule="evenodd">
  <g>
      <g>
          <g>
              <g>
                  <g transform="translate(-1117 -303) translate(1104 251) translate(7 48) translate(0 7) translate(9)">
                      <circle cx="15.907" cy="15.907" r="15.907" stroke="#FFF" stroke-opacity=".282" stroke-width=".75"/>
                      <g filter="url(#cj6svwh83a)">
                          <g filter="url(#siwa5g8lyb)" transform="translate(6 6)">
                              <g fill="#000" fill-rule="nonzero">
                                  <path d="M13.086 4.444v1.771L9.774 9.527c-.127.125-.178.308-.133.48.046.172.18.306.352.352.172.045.355-.006.48-.133l3.312-3.312h1.77c.132 0 .257-.052.35-.145l2.963-2.963c.14-.141.183-.354.107-.538-.077-.185-.257-.305-.456-.305h-1.482V1.481c0-.2-.12-.38-.305-.456-.184-.076-.397-.034-.538.107l-2.963 2.963c-.093.093-.145.218-.145.35z"/>
                                  <path d="M18.838 5.232L17.36 6.71c1.526 3.484.357 7.56-2.784 9.705-3.14 2.144-7.363 1.75-10.052-.94-2.689-2.688-3.083-6.91-.939-10.051 2.145-3.14 6.221-4.31 9.705-2.784l1.478-1.478C10.448-1.141 5.095.065 2.181 3.999c-2.914 3.933-2.509 9.406.952 12.867 3.461 3.462 8.935 3.867 12.868.953 3.933-2.913 5.14-8.268 2.837-12.587z"/>
                                  <path d="M12.099 4.444c-.001-.39.154-.766.43-1.042-3.057-1.143-6.497 0-8.262 2.745-1.765 2.745-1.378 6.349.93 8.656 2.307 2.308 5.911 2.695 8.656.93 2.744-1.765 3.888-5.205 2.745-8.262-.276.276-.652.431-1.042.43h-.911c.956 2.181.225 4.734-1.742 6.078-1.966 1.343-4.61 1.096-6.294-.588-1.684-1.684-1.931-4.328-.588-6.294C7.365 5.13 9.918 4.399 12.1 5.355v-.91z"/>
                                  <path d="M11.666 6.239C9.926 5.501 7.907 6.1 6.85 7.668c-1.056 1.567-.854 3.663.483 5 1.336 1.336 3.432 1.538 5 .482 1.567-1.057 2.166-3.076 1.428-4.816l-2.59 2.59c-.374.376-.92.523-1.433.387-.512-.137-.912-.537-1.049-1.05-.136-.512.011-1.058.387-1.432l2.59-2.59z"/>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
