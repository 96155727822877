import React, { useMemo } from "react";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import importImages from "./../../../../helpers/importImages";
import LeaderboardRow from "./LeaderboardRow";

function LeaderboardContainer() {
  // const avatars = useSelector((state) => state.avatarsReducer);
  // const allTraineesAvatars = avatars.A;

  const avatarsFromLocalStorage = localStorage.getItem("allTraineesAvatars");
  const allTraineesAvatars = JSON.parse(avatarsFromLocalStorage);

  const { username } = useSelector((state) => state.userReducer);
  const { userDetails } = useSelector((state) => state.personalHallReducer);
  const leaderboard = useSelector((state) => state.leaderboardReducer);
  const avatarId = userDetails?.avatarId ? userDetails.avatarId : 0;
  const backgrounds = importImages(
    require.context(
      "../../../../assets/backgrounds/training/leaderboard-timeline/leaderboard",
      false,
      /\.(png)$/
    )
  );

  const userImages = allTraineesAvatars;
  const len = leaderboard?.length;

  const gridSize = useMemo(() => {
    if (!len) return 0.25;
    const flooredLen = len > 5 ? Math.ceil(len / 2) : len;
    return (1 / flooredLen) * 100;
  }, [leaderboard]);

  return (
    <Box
      display="flex"
      alignContent="center"
      alignItems="center"
      flexDirection="column"
      flexWrap="wrap"
      width="100%"
      height="100%"
      padding="35px 40px"
    >
      {leaderboard.map((dataItem, index) => {
        const isUser = username === dataItem?.name;
        const bgImage = `leaderboard-lg-${isUser ? "3" : dataItem?.id}.png`;
        const isRight = len > 5 ? index > Math.floor(len / 2) : false;
        const userAvatarId = dataItem.avatarId;
        return (
          <LeaderboardRow
            key={dataItem.id}
            id={dataItem.id}
            data={{ ...dataItem, rank: index + 1 }}
            isUser={isUser}
            background={backgrounds?.[bgImage]}
            userImage={userImages?.[userAvatarId]}
            gridSize={gridSize}
            isRight={isRight}
          />
        );
      })}
    </Box>
  );
}

export default LeaderboardContainer;
