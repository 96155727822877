export const graphFlagStart = [
  "44 35",
  `
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
  <g id="timeline-icons" transform="translate(-377.000000, -276.000000)" stroke="#FFFFFF" stroke-width="2">
	  <g id="Group-27" transform="translate(372.000000, 263.000000)">
		  <g id="Group-26" transform="translate(5.171951, 14.437500)">
			  <g id="start-copy" transform="translate(29.552742, 15.991858) rotate(-345.000000) translate(-29.552742, -15.991858) translate(21.052742, 2.491858)">
				  <line x1="0.574185796" y1="26.6326045" x2="0.574185796" y2="0" id="Path-11"></line>
				  <polygon id="Path-12" points="0.574185796 0 16.6513881 0 10.9653126 6.14691584 15.0787428 10.5937279 0.574185796 10.5937279"></polygon>
			  </g>
			  <g id="start-copy-2" transform="translate(12.603356, 15.991858) scale(-1, 1) rotate(-345.000000) translate(-12.603356, -15.991858) translate(4.103356, 2.491858)">
				  <line x1="0.574185796" y1="26.6326045" x2="0.574185796" y2="0" id="Path-11"></line>
				  <polygon id="Path-12" points="0.574185796 0 16.6513881 0 10.9653126 6.14691584 15.0787428 10.5937279 0.574185796 10.5937279"></polygon>
			  </g>
		  </g>
	  </g>
  </g>
</g>  `,
];
