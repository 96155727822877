import axios from "axios";

const myBaseURL =
  process.env.REACT_APP_ENVIRONMENT.trim() === "DEV"
    ? process.env.REACT_APP_BASE_URL_LOCAL_DEV
    : process.env.REACT_APP_BASE_URL_CLOUD;

const axiosConfig = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export function initialLoadScenarios(scnId, token) {
  const obj = {
    itemid: scnId,
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("initialLoadScenarios request", postData);

  return axios
    .post(
      `${myBaseURL}/Content/LoadScenarioList_IncludeFirstObject`,
      postData,
      axiosConfig
    )
    .then((res) => {
      // console.log("initialLoadScenarios response", res);
      return res;
    })
    .catch((err) => {
      // console.log("initialLoadScenarios ERROR: ", err);
    });
}

export function LoadActiveScenarioData_ByRangerName(rangerName) {
  const obj = {
    rangerName,
  };
  const postData = JSON.stringify(JSON.stringify(obj));

  return axios
    .post(
      `${myBaseURL}/Content/LoadActiveScenarioData_ByRangerName`,
      postData,
      axiosConfig
    )
    .then((res) => {
      console.log("LoadActiveScenarioData_ByRangerName response", res);
      return res;
    })
    .catch((err) => {
      console.log("LoadActiveScenarioData_ByRangerName ERROR: ", err);
    });
}

export function loadScenariosApi(token) {
  const obj = {
    type: "scn",
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("loadScenariosApi request", postData);

  return axios
    .post(`${myBaseURL}/Content/LoadList`, postData, axiosConfig)
    .then((res) => {
      // console.log("loadScenariosApi response", res);
      return res;
    })
    .catch((err) => {
      // console.log("loadScenariosApi ERROR: ", err);
    });
}

export function loadFamiliesApi() {
  const obj = {
    type: "family",
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  return axios
    .post(`${myBaseURL}/Content/LoadList`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("loadFamiliesApi ERROR: ", err);
    });
}

export function loadScenarioApi(s_itemId, token) {
  const obj = {
    s_itemId: s_itemId + "",
    token: token + "",
  };

  const postData = JSON.stringify(JSON.stringify(obj));
  
  return axios
    .post(`${myBaseURL}/Content/LoadScenarioData`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("LoadScenarioData ERROR: ", err);
    });
}

export function setIsAttending(scheduledID, token, IsAttending, userId) {
  const obj = {
    ScheduledID: scheduledID + "",
    token,
    IsAttending: IsAttending.toString(),
    userId: userId + "",
  };

  const postData = JSON.stringify(JSON.stringify(obj));


  return axios
    .post(`${myBaseURL}/Training/setIsAttending`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("setIsAttending ERROR: ", err);
    });
}

export function GetTrainingCountdown(scheduledID, token) {
  const obj = {
    ScheduledID: scheduledID + "",
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("GetTrainingCountdown request", postData);

  return axios
    .post(`${myBaseURL}/Training/GetTrainingCountdown`, postData, axiosConfig)
    .then((res) => {
      // console.log("GetTrainingCountdown response", res);
      return res;
    })
    .catch((err) => {
      // console.log("GetTrainingCountdown ERROR: ", err);
    });
}
export function GetAllScenarios(token) {
  const obj = {
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  // console.log("GetAllScenarios request", postData);

  return axios
    .post(`${myBaseURL}/DataBase/GetAllScenarios`, postData, axiosConfig)
    .then((res) => {
      // console.log("GetAllScenarios response", res);
      return res;
    })
    .catch((err) => {
      // console.log("GetAllScenarios ERROR: ", err);
    });
}
export function GetTrainingTimeline(token, TrainingID) {
  const obj = {
    TrainingID: TrainingID + "",
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  return axios
    .post(`${myBaseURL}/Rangers/GetTrainingTimeline`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("GetTrainingTimeline ERROR: ", err);
    });
}
export function GetScheduleTrainingAttendees(scheduledID) {
  return axios
    .get(`${myBaseURL}/ScheduleTraining/${scheduledID}/Attendees`, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.warn("GetScheduleTrainingAttendees ERROR: ", err);
    });
}
