export const perfectRunBadge = [
  "30 30",
  `
  <defs>
  <linearGradient id="827npyykza" x1="50%" x2="50%" y1="19.351%" y2="100%">
      <stop offset="0%" stop-color="#FF852E"/>
      <stop offset="100%" stop-color="#EB7826"/>
  </linearGradient>
  <linearGradient id="zpreqc5jeb" x1="50%" x2="50%" y1="19.351%" y2="100%">
      <stop offset="0%" stop-color="#FF852E"/>
      <stop offset="100%" stop-color="#EB7826"/>
  </linearGradient>
  <linearGradient id="tc0qbit6nf" x1="50%" x2="50%" y1="19.351%" y2="100%">
      <stop offset="0%" stop-color="#FF852E"/>
      <stop offset="100%" stop-color="#EB7826"/>
  </linearGradient>
  <linearGradient id="dnvz4k9j8i" x1="50%" x2="50%" y1="19.351%" y2="100%">
      <stop offset="0%" stop-color="#FF852E"/>
      <stop offset="100%" stop-color="#EB7826"/>
  </linearGradient>
  <linearGradient id="o937bhx5tm" x1="50%" x2="50%" y1="19.351%" y2="100%">
      <stop offset="0%" stop-color="#FF852E"/>
      <stop offset="100%" stop-color="#EB7826"/>
  </linearGradient>
  <linearGradient id="8fnebzq19n" x1="50%" x2="50%" y1="19.351%" y2="100%">
      <stop offset="0%" stop-color="#FF852E"/>
      <stop offset="100%" stop-color="#EB7826"/>
  </linearGradient>
  <path id="4jtn23d8pe" d="M4.554 2.397L5.397 4.793 18.046 9.746 16.697 19.493 2.193 20.292 1.687 21.57 0 23.168 9.107 27.801 24.286 22.529 23.106 11.824 17.985 2.748 8.095 0z"/>
  <path id="y11epficnh" d="M26.218 17.285L29.591 17.285 27.905 0.872 0 0 0.92 5.229 1.533 6.246 2.453 7.117z"/>
  <path id="vcccgp2vtk" d="M2.76 0L0 1.017l2.593 7.105c4.124 2.868 7.365 4.506 9.722 4.912 2.357.406 5.381.406 9.074 0l.076-3.157-.307-1.743L8.74 5.664 2.76 0z"/>
  <filter id="mm5h5bi4rc">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.921569 0 0 0 0 0.470588 0 0 0 0 0.149020 0 0 0 1.000000 0"/>
  </filter>
  <filter id="gnc4wguu8d" width="1476.7%" height="1558.8%" x="-683.3%" y="-721.2%" filterUnits="objectBoundingBox">
      <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6"/>
      <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.922818065 0 0 0 0 0.425234497 0 0 0 0 0 0 0 0 1 0"/>
      <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="SourceGraphic"/>
      </feMerge>
  </filter>
</defs>
<g fill="none" fill-rule="evenodd">
  <g>
      <g>
          <g>
              <g>
                  <path fill="url(#827npyykza)" fill-rule="nonzero" stroke="url(#827npyykza)" stroke-width=".2" d="M14.135 12.134l-1.898-.129-1.254-1.98-.627-.99V8.76c0-.298-.255-.54-.57-.54-.07 0-.14.013-.205.037l-1.804.659.136-.433c.108-.365-.066-.75-.419-.928l-1.415-.72c-.103-.053-.231-.02-.291.075l-2.076 3.15-.044-.025c-.105-.057-.239-.022-.299.077l-.004.006-.65 1.16c-.056.1-.017.223.088.278l7.903 4.15c.033.017.07.026.107.026h3.266c.726 0 1.314-.557 1.315-1.245v-1.073c-.003-.672-.55-1.23-1.259-1.28z" transform="translate(-378 -141) translate(378 141) translate(7.778 8.596)"/>
                  <path fill="url(#zpreqc5jeb)" stroke="url(#zpreqc5jeb)" stroke-width=".971" d="M1.605 5.041L.77 5.48c-.054.028-.12.008-.147-.046-.011-.02-.015-.045-.011-.069l.16-.927c.001-.01-.002-.022-.01-.03l-.675-.656c-.044-.042-.044-.11-.003-.154.017-.017.04-.028.063-.032l.933-.135c.01-.002.02-.009.025-.019l.418-.844c.027-.053.092-.075.146-.049.021.01.038.028.049.05l.417.843c.005.01.015.017.026.019l.933.135c.06.009.1.064.092.123-.003.024-.015.046-.032.063l-.675.656c-.008.008-.011.02-.01.03l.16.927c.01.06-.03.116-.089.126-.024.004-.048 0-.069-.01l-.835-.439c-.01-.005-.022-.005-.031 0zM12.623 5.041l-.835.438c-.053.028-.119.008-.147-.046-.01-.02-.015-.045-.01-.069l.159-.927c.002-.01-.002-.022-.01-.03l-.675-.656c-.043-.042-.044-.11-.002-.154.017-.017.039-.028.062-.032l.933-.135c.011-.002.021-.009.026-.019l.417-.844c.027-.053.092-.075.146-.049.021.01.039.028.05.05l.417.843c.005.01.014.017.025.019l.933.135c.06.009.101.064.092.123-.003.024-.014.046-.031.063l-.675.656c-.008.008-.012.02-.01.03l.16.927c.01.06-.03.116-.09.126-.023.004-.047 0-.068-.01l-.835-.439c-.01-.005-.022-.005-.032 0zM6.79 2.585l-.835.438c-.053.028-.12.007-.147-.046-.011-.02-.015-.045-.01-.069l.158-.927c.002-.01-.001-.022-.01-.03l-.674-.656c-.043-.042-.044-.111-.002-.154.016-.017.038-.028.062-.032l.933-.135c.011-.002.02-.009.026-.019l.417-.844c.027-.054.092-.076.146-.049.021.01.038.028.05.05l.417.843c.004.01.014.017.025.019l.933.135c.06.009.1.064.092.123-.003.024-.014.046-.032.063l-.675.656c-.008.008-.011.02-.01.03l.16.927c.01.06-.03.116-.089.126-.023.004-.048 0-.069-.01l-.835-.439c-.01-.005-.021-.005-.031 0z" transform="translate(-378 -141) translate(378 141) translate(7.778 8.596)"/>
              </g>
          </g>
          <g>
              <g transform="translate(-378 -141) translate(378 141) translate(7.13 3.684)">
                  <mask id="1c4zyl1neg" fill="#fff">
                      <use xlink:href="#4jtn23d8pe"/>
                  </mask>
                  <ellipse cx="9.192" cy="13.661" stroke="url(#tc0qbit6nf)" stroke-width="1.65" mask="url(#1c4zyl1neg)" rx="12.227" ry="11.584"/>
              </g>
              <g transform="translate(-378 -141) translate(378 141) translate(4.906)">
                  <mask id="32m8sp98bj" fill="#fff">
                      <use xlink:href="#y11epficnh"/>
                  </mask>
                  <ellipse cx="11.729" cy="17.212" stroke="url(#dnvz4k9j8i)" stroke-width="1.5" mask="url(#32m8sp98bj)" rx="15.409" ry="14.598"/>
              </g>
              <g transform="translate(-378 -141) translate(378 141) translate(0 21.352)">
                  <mask id="y1oa1pvi5l" fill="#fff">
                      <use xlink:href="#vcccgp2vtk"/>
                  </mask>
                  <ellipse cx="16.635" cy="-4.14" stroke="url(#dnvz4k9j8i)" stroke-width="1.5" mask="url(#y1oa1pvi5l)" rx="15.409" ry="14.598"/>
              </g>
          </g>
          <path fill="url(#o937bhx5tm)" fill-rule="nonzero" stroke="url(#o937bhx5tm)" stroke-width=".5" d="M8.867 17.997c0-.339-.151-.614-.338-.614H4.226c-.186 0-.337.275-.337.614 0 .34.15.614.337.614H8.53c.187 0 .338-.274.338-.614z" transform="translate(-378 -141) translate(378 141)"/>
          <path fill="url(#8fnebzq19n)" fill-rule="nonzero" stroke="url(#8fnebzq19n)" stroke-width=".5" d="M9.474 20.726H7.629c-.123 0-.222.275-.222.614 0 .34.1.614.222.614h1.845c.123 0 .222-.275.222-.614 0-.339-.1-.614-.222-.614z" transform="translate(-378 -141) translate(378 141)"/>
      </g>
  </g>
</g>
`,
];
