import {
  SET_LEADERBOARD,
  UPDATE_USER_SCORE,
  RESET_STATE,
} from "../types/leaderboard";

export const setLeaderboard = (leaderboard) => {
  return { type: SET_LEADERBOARD, leaderboard };
};

export const updateUserScore = (user, newScore) => {
  return { type: UPDATE_USER_SCORE, user, newScore };
};

export const resetLeaderboardState = () => ({
  type: RESET_STATE,
});
