import React, { useState, useEffect, useMemo, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Draggable from "react-draggable";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Scrollbars from "react-custom-scrollbars";
import CIcon from "@coreui/icons-react";
import MerlinContainer from "./MerlinContainer";
import MissionWelcomeButton from "../../buttons/MissionWelcomeButton";
import FeedbackInputBox from "../complete/FeedbackInputBox";
import QuestionOption from "../shared/QuestionOption";
import { sendMessage } from "../../../store/actions/chat";
import { removeQuestion } from "../../../store/actions/quiz";
import TimerBox from "./../TimerBox";
import { openAskHintPopup } from "./../../../store/actions/hint";
import ScrollThumb from "../../base/scroll/ScrollThumb";
import ShadowedText from "../shared/ShadowedText";
import MissionFooterHeaderText from "../missions/MissionFooterHeaderText";
import { finishedMission } from "../../../store/actions/missions";
import ConfirmButton from "../buttons/ConfirmButton";
import { FaMinus } from "react-icons/fa";

function QuestionPopup({
  username,
  quiz,
  defaultPosition,
  setDefaultPosition,
}) {
  const [selected, setSelected] = useState({});
  const [initialSelected, setInitialSelected] = useState({});
  const [answer, setAnswer] = useState("");
  const [isSingleLine, setIsSingleLine] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const [tries, setTries] = useState(1);
  const [expended, setExpended] = useState(true);
  
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({theme});

  const dispatch = useDispatch();

  const {
    id,
    type,
    title,
    questions,
    options,
    timeout,
    multiple,
    immediateResponse,
    trials,
  } = quiz;

  const questionResults = useSelector(
    (state) => state.quizResultsReducer?.[id]
  );
  const { AvatarID } = useSelector((state) => state.scenarioItemReducer);

  const { hints: hintsArr } = useSelector((state) => state.missionsReducer);

  const { rangerName } = useSelector((state) => state.trainingReducer);

  const { email } = useSelector((state) => state.userReducer);

  const hints = hintsArr[id];

  const currentHint = hints?.currentHint || 0;
  const totalHints = hints?.totalHints || 0;
  const price = hints?.hints?.[currentHint]?.price;
  const myHintCost = price === undefined || price === null ? "" : price;

  const hintDisabled = isLocked || !totalHints;

  const handlePopupExpendMinimizeClick = () => {
    setExpended((prev) => !prev);
  };

  const isDisabled = useMemo(() => {
    if (type === "options") {
      for (const [key, value] of Object.entries(selected)) {
        if (value) return false;
      }
      return true;
    }
    return answer.length === 0;
  }, [type, answer, selected]);

  const answerStatus = useCallback(
    (index) => {
      if (isLocked && questionResults?.answersArray?.[index]) {
        return questionResults.answersArray[index];
      }
      return "";
    },
    [isLocked, questionResults]
  );

  useEffect(() => {
    const tmpObj = {};
    options.forEach((option) => {
      if (option?.value.length > 25) setIsSingleLine(true);
      tmpObj[option?.index] = false;
    });
    setSelected(tmpObj);
    setInitialSelected(tmpObj);
  }, [quiz?.options]);

  useEffect(() => {
    if (trials) {
      setTries(trials);
    }
  }, []);

  useEffect(() => {
    if (questionResults?.trials_left !== undefined) {
      setTries(questionResults.trials_left);
      if (questionResults.trials_left <= 0 || questionResults?.isCorrect) {
        setIsLocked(true);
      }
    }
    if (questionResults?.isCorrect !== undefined) {
      setIsCorrect(questionResults.isCorrect);
    }
  }, [questionResults?.trials_left, questionResults?.isCorrect]);

  const toggleSelection = (id) => {
    const state = selected[id];
    if (!multiple) {
      setSelected({ ...initialSelected, [id]: !state });
    } else {
      setSelected({ ...selected, [id]: !state });
    }
  };

  const fillAnswerBox = (e) => {
    const { value } = e.target;
    setAnswer(value);
  };

  const onTimerStop = useCallback(() => {
    // console.log("timerstop");
  }, []);

  const onPopupStop = (event, data) => {
    const { x, y } = data;
    if (x && y) {
      setDefaultPosition({ x, y });
    }
  };

  const approveSubmit = () => {
    // console.log("approveSubmit", isLocked, tries);
    if (isLocked || tries <= 0) {
      sendContinue();
    } else {
      sendAnswers();
    }
  };

  const closeQuestion = () => {
    dispatch(finishedMission(id));
    dispatch(removeQuestion(id));
  };

  const sendAnswers = () => {
    const answers = [];
    if (type === "options") {
      for (const [key, value] of Object.entries(selected)) {
        if (value) {
          answers.push({ type: "text", value: true, index: Number(key) });
        } else {
          answers.push(null);
        }
      }
    } else {
      answers.push({ type: "open", value: answer, index: 1 });
    }

    const obj = {
      username,
      email,
      data: { answers: [...answers], id },
      family: "question_res",
      api_type: "question_res",
      to: rangerName || "",
      from: email,
    };

    const stringObj = JSON.stringify(obj);

    dispatch(sendMessage(stringObj));
    if (!immediateResponse) {
      closeQuestion();
    }
  };

  const sendContinue = () => {
    const obj = {
      username,
      email,
      data: { id },
      family: "continue_question",
      api_type: "continue_question",
      to: rangerName || "",
      from: email,
    };

    const stringObj = JSON.stringify(obj);

    dispatch(sendMessage(stringObj));
    closeQuestion();
  };

  const onClickHint = () => {
    dispatch(openAskHintPopup(id));
  };

  const responseMessage = useMemo(() => {
    if (questionResults?.msg) {
      return questionResults.msg;
    }
    if (isCorrect === true) {
      return "Great Job";
    }
    if (isCorrect === false && tries > 0) {
      return `Wrong, ${tries} more trials to go`;
    }
    return "";
  }, [questionResults?.msg, isCorrect, tries]);

  const questionBody = () => {
    if (type === "open") {
      return (
        <FeedbackInputBox
          rows={2}
          width="265px"
          height="100px"
          placeholder="Your answer…"
          onChange={fillAnswerBox}
        />
      );
    }
    if (type === "options") {
      return (
        <Grid container spacing={2} className={classes.optionsWrapper}>
          {options.map((option) => {
            const { value, index } = option;
            const isSelected = selected[index];
            const myStatus = answerStatus(index - 1);
            return (
              <QuestionOption
                text={value}
                key={index}
                index={index}
                selected={isSelected}
                toggleSelection={toggleSelection}
                isSingleLine={isSingleLine}
                isLocked={isLocked}
                status={myStatus}
              />
            );
          })}
        </Grid>
      );
    }
  };

  return (
    <Draggable
      handle="#draggable-handle-question"
      bounds="#vnc-card"
      defaultPosition={defaultPosition}
      onStop={onPopupStop}
    >
      <div id="question" className={classes.questionRoot}>
        <MerlinContainer
          avatarId={AvatarID}
          isDraggable={true}
          handle="question"
          setExpended={setExpended}
          expended={expended}
          handlePopupExpendMinimizeClick={handlePopupExpendMinimizeClick}
        >
          <Box
            display="flex"
            width="100%"
            justifyContent={timeout !== -1 ? "space-between" : "flex-start"}
            // justifyContent="space-between"
            alignItems="center"
          >
            {timeout !== undefined && timeout !== -1 ? (
              <TimerBox
                onTimerStop={onTimerStop}
                time={timeout * -10}
                margin="13px 0 0"
                isTimeline={true}
                hourglassSize={["28px", "40px"]}
              />
            ) : null}
          </Box>

          <div className={classes.title}>{title || " "}</div>
          <div className={classes.description}>
            <div>
              <FaMinus
                className={classes.expendIcon}
                onClick={handlePopupExpendMinimizeClick}
              />
            </div>
            {questions}
          </div>
          <div className={isLocked ? classes.locked : ""}>
            <Scrollbars
              autoHeight
              autoHeightMax={400}
              autoHeightMin={145}
              renderThumbVertical={ScrollThumb}
              hideTracksWhenNotNeeded={true}
            >
              <div className={classes.bodyWrapper}>{questionBody()}</div>
            </Scrollbars>
          </div>
          <Box
            display="flex"
            width="100%"
            height="50px"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <div>
              {/* <ConfirmButtonMotion
                  text="get hint"
                  disabled={
                    isLocked ||
                    !totalHints ||
                    currentHint === undefined ||
                    totalHints === currentHint
                  }
                  confirm={onClickHint}
                  id={`popup-${id}`}
                  size={{ width: "90px", height: "26px", font: "13px" }}
                  /> */}
              {hintDisabled ? null : (
                <>
                  <div className={classes.flexBox}>
                    <div>
                      <MissionFooterHeaderText
                        status="active"
                        text={`${currentHint}/${totalHints} hints`}
                        isHint={true}
                      />
                      <ConfirmButton
                        text="get hint"
                        type={"active"}
                        confirm={onClickHint}
                        disabled={
                          isLocked ||
                          !totalHints ||
                          totalHints === 0 ||
                          currentHint === totalHints
                        }
                        size={{ width: "90px", height: "26px", font: "13px" }}
                      />
                    </div>
                    <span className={classes.hintCost}>
                      {myHintCost && !hintDisabled ? (
                        <ShadowedText
                          type="red"
                          text={`-${myHintCost}`}
                          size="15px"
                        />
                      ) : null}
                    </span>
                  </div>
                </>
              )}
            </div>

            <MissionWelcomeButton
              text={isLocked ? "Continue" : "Submit"}
              approveClick={approveSubmit}
              disabled={isDisabled}
            />
          </Box>
          {isCorrect !== null ? (
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              width="100%"
              marginTop="7px"
              marginLeft="7px"
            >
              <CIcon
                name={isCorrect ? "correctIcon" : "incorrectIcon"}
                width="21px"
                height="21px"
                className={classes.icon}
              />
              <div className={classes.responseMessage}>{responseMessage}</div>
            </Box>
          ) : null}
        </MerlinContainer>
      </div>
    </Draggable>
  );
}

const useStyles = makeStyles({
  questionRoot: {
    fontFamily: (props) => props.theme.TRAINING_FONT,
    position: "fixed",
    pointerEvents: "none",
  },
  title: {
    fontFamily: (props) => props.theme.TRAINING_FONT,
    fontSize: "18px",
    fontWeight: "500",
  },
  description: {
    fontFamily: (props) => props.theme.TRAINING_FONT,
    display: "flex",
    fontSize: "18px",
    fontWeight: "600",
    margin: "5px 0 10px 0",
    marginLeft: "10px",
  },
  optionsWrapper: {
    width: "100%",
    paddingTop: "8px",
    // minWidth: "270px",
    minHeight: "120px",
    marginTop: 0,
    marginBottom: 0,
    margin: 0,
  },
  locked: {
    pointerEvents: "none",
  },
  bodyWrapper: {
    width: "100%",
    marginTop: "-10px",
  },
  icon: {
    marginRight: "8px",
  },
  responseMessage: {
    width: "calc(100% - 29px)",
    fontSize: "14px",
    fontWeight: "600",
    overflowWrap: "break-word",
  },
  expendIcon: {
    color: "#BC314B",
    cursor: "pointer",
    marginRight: "10px",
  },
  hintCost: {
    marginLeft: "5px",
    marginBottom: "2px",
    alignSelf: "flex-end",
  },
  flexBox: {
    marginLeft: "5px",
    display: "flex",
  },
});

export default QuestionPopup;
