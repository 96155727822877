export const orangeHat = [
  "38 38",
  `
     <defs>
        <linearGradient id="myx6a07zec" x1="81.152%" x2="0%" y1="32.128%" y2="73.447%">
            <stop offset="0%" stop-color="#FF852E"/>
            <stop offset="100%" stop-color="#EBC426"/>
        </linearGradient>
        <filter id="37dw0vba1a" width="158.6%" height="185.6%" x="-29.3%" y="-42.8%" filterUnits="objectBoundingBox">
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2.5"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.929411765 0 0 0 0 0.752941176 0 0 0 0 0.152941176 0 0 0 1 0"/>
        </filter>
        <path id="0ezrsix7mb" d="M13.539.748l12.1 4.734c.251.09.424.321.439.588.014.267-.133.517-.374.633h-.038l-1.647.871v7.318c0 .372-.3.673-.672.673-.371 0-.672-.301-.672-.673V8.265l-1.345.672v5.68c-.058.89-.57 1.688-1.354 2.112-2.065 1.094-4.388 1.607-6.721 1.487-2.344.089-4.667-.461-6.722-1.591-.785-.424-1.296-1.222-1.354-2.111v-5.68L.843 6.702c-.24-.123-.38-.378-.357-.646.022-.267.205-.495.46-.575l12.1-4.734c.16-.057.333-.057.493 0zm6.097 9.07l-2.897 1.354-2.093 1.041-1.088.54c-.19.1-.417.1-.606 0l-1.09-.54-2.092-1.041-3.225-1.354v1.637c0 .975 2.544 3.77 6.71 3.77 4.165 0 6.381-2.824 6.381-3.77V9.818z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g transform="translate(-767 -279) translate(768 280)">
                <circle cx="18" cy="18" r="18" fill="#000" fill-opacity=".78" stroke="#4B4B4B"/>
                <g fill-rule="nonzero">
                    <g transform="translate(4.91 7.91) translate(0 .273)">
                        <use fill="#000" filter="url(#37dw0vba1a)" xlink:href="#0ezrsix7mb"/>
                        <use fill="url(#myx6a07zec)" xlink:href="#0ezrsix7mb"/>
                    </g>
                </g>
            </g>
        </g>
    </g>  `,
];
