import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";

import chat from "../../../assets/animations/chat.gif";
import hoverAvatar from "../../../assets/animations/hoverAvatar.gif";
import taskHint from "../../../assets/animations/taskHint.gif";
import tasks from "../../../assets/animations/tasks.gif";

function GifSlider({ changeCurrentSlide }) {
  const classes = useStyles();

  const sliderSettings = {
    dots: true,
    speed: 500,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const sliderList = [hoverAvatar, tasks, taskHint, chat];

  const handleSlideChange = useCallback((current, next) => {
    changeCurrentSlide(next);
  }, []);

  return (
    <div className={classes.sliderWrapper}>
      <Slider {...sliderSettings} beforeChange={handleSlideChange}>
        {sliderList.length === 0 ? (
          <div>empty</div>
        ) : (
          sliderList.map((slide, index) => {
            return (
              <img
                src={slide}
                className={classes.slide}
                width="370px"
                height="235px"
                key={index}
              />
            );
          })
        )}
      </Slider>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  sliderWrapper: {
    width: "390px",
    height: "235px",
    margin: "20px 0 30px 0",
    border: "transparent",
  },
  slide: {
    width: "370px!important",
    height: "235px!important",
    borderRadius: "19px",
    border: "transparent",
  },
}));

export default GifSlider;
