import {
  SET_ORGANIZATION_TOKENS_SUMMARY,
  UPDATE_ORGANIZATION_SCENARIO_TOKENS_DATA,
} from "../types/organizationTokensSummary.js";

const initialState = {
  organizationScenarioTokensData: {
    organizationID: -1,
    isUnlimited: false,
    organizationTokens: -1,
    startAt: -1,
    expiredAt: -1,
  },
  userAllocatedScenarioTokens: [],
};

const organizationTokensSummaryReducer = (state = initialState, action) => {
  const {
    type,
    orgTokensSummary: summary,
    organizationTokensData,
  } = action;

  switch (type) {
    case SET_ORGANIZATION_TOKENS_SUMMARY:
      return {
        ...state,
        organizationScenarioTokensData: {
          ...state.organizationScenarioTokensData,
          ...summary.organizationScenarioTokensData,
        },
        userAllocatedScenarioTokens:
          summary.userAllocatedScenarioTokens ||
          state.userAllocatedScenarioTokens,
      };
    case UPDATE_ORGANIZATION_SCENARIO_TOKENS_DATA:
      return {
        ...state.organizationScenarioTokensData,
        ...organizationTokensData,
      };
    default:
      return state;
  }
};

export default organizationTokensSummaryReducer;
