import {
  SET_HINT,
  REMOVE_HINT,
  SET_MISSION_ID,
  OPEN_ASK_HINT,
  RESET_STATE,
} from "../types/hint";

export const setHint = (hint) => ({
  type: SET_HINT,
  hint,
});

export const setMissionId = (missionId) => ({
  type: SET_MISSION_ID,
  missionId,
});

export const openAskHintPopup = (missionId) => ({
  type: SET_MISSION_ID,
  missionId,
});

export const removeHint = (hint) => ({
  type: REMOVE_HINT,
  hint,
});

export const resetHintsState = () => ({
  type: RESET_STATE,
});
