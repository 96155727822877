export const leaderboardTrophy = [
  "20 20",
  `
 <defs>
        <filter id="ystz4qpeea">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.733333 0 0 0 0 0.596078 0 0 0 0 0.278431 0 0 0 1.000000 0"/>
        </filter>
        <filter id="7r8maqalzb">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.733333 0 0 0 0 0.596078 0 0 0 0 0.282353 0 0 0 1.000000 0"/>
        </filter>
        <filter id="xlcdr8fzyd" width="276.8%" height="325%" x="-88.4%" y="-112.5%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.083"/>
        </filter>
        <filter id="g7xz2uk09f" width="276.8%" height="325%" x="-88.4%" y="-112.5%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.083"/>
        </filter>
        <filter id="la6kqs11fg" width="341.4%" height="293.1%" x="-120.7%" y="-96.5%" filterUnits="objectBoundingBox">
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.701960784 0 0 0 0 0.603921569 0 0 0 0 0.329411765 0 0 0 0.2 0"/>
        </filter>
        <radialGradient id="s0ahnjlmxc" cx="50%" cy="50%" r="49.353%" fx="50%" fy="50%" gradientTransform="matrix(0 1 -.78571 0 .893 0)">
            <stop offset="0%" stop-color="#C7A755"/>
            <stop offset="100%" stop-color="#C5A452"/>
        </radialGradient>
        <radialGradient id="2nmsmw8wue" cx="50%" cy="50%" r="50%" fx="50%" fy="50%" gradientTransform="matrix(0 1 -.78571 0 .893 0)">
            <stop offset="0%" stop-color="#CAAA58"/>
            <stop offset="100%" stop-color="#C4A352"/>
        </radialGradient>
        <linearGradient id="iaz42ylrni" x1="35.692%" x2="64.344%" y1="35.722%" y2="77.996%">
            <stop offset="0%" stop-color="#B39A53"/>
            <stop offset="34.956%" stop-color="#D1C06F"/>
            <stop offset="60.494%" stop-color="#B19B55"/>
            <stop offset="100%" stop-color="#C2A85B"/>
        </linearGradient>
        <path id="5iv6fv88gh" d="M11.808 0c.171 0 .31.14.31.308v1.556h2.175c.344 0 .622.279.622.622v4.042c0 1.198-.975 2.172-2.175 2.172h-.622c-.001 1.717-1.391 3.108-3.107 3.108h-.932v3.107h1.245c1.028 0 1.862.837 1.862 1.864v1.864H3.73V16.78c0-1.03.833-1.864 1.862-1.864h1.245v-3.107h-.932c-1.715 0-3.106-1.388-3.107-3.108h-.622C.975 8.7 0 7.726 0 6.528V2.486c0-.343.278-.622.621-.622h2.176V.308c0-.17.139-.308.31-.308h8.701zM2.797 3.107H1.243v3.421c0 .475.36.87.824.923l.108.006h.622v-4.35zm10.875 0h-1.554v4.35h.622c.514 0 .932-.418.932-.929v-3.42z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g filter="url(#ystz4qpeea)" opacity=".862" transform="translate(-967 -157) translate(967 157)">
                    <g filter="url(#7r8maqalzb)" transform="translate(5.455 .91)">
                        <ellipse cx="3.535" cy="2.879" fill="url(#s0ahnjlmxc)" filter="url(#xlcdr8fzyd)" rx="3.535" ry="2.778"/>
                        <ellipse cx="5.556" cy="12.98" fill="url(#2nmsmw8wue)" filter="url(#g7xz2uk09f)" rx="3.535" ry="2.778"/>
                    </g>
                </g>
                <g fill-rule="nonzero">
                    <g transform="translate(-967 -157) translate(967 157) translate(2.727 .91)">
                        <use fill="#000" filter="url(#la6kqs11fg)" xlink:href="#5iv6fv88gh"/>
                        <use fill="url(#iaz42ylrni)" xlink:href="#5iv6fv88gh"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
  `,
];
