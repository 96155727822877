import * as React from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@material-ui/core/Tooltip";

const StyledBadge = styled(Badge)(({ connected, isChat }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: connected ? "#44b700" : "gray",
    color: connected ? "#44b700" : "gray",
    boxShadow: isChat ? "" : `0 0 0 2px ${connected ? "#57ea00" : "gray"}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      //   border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function TraineeAvatarWithConnectivity({ trainee, isChat }) {
  console.log("isChat", isChat);
  return (
    <StyledBadge
      overlap="circular"
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      variant="dot"
      connected={trainee.connected}
      isChat={isChat}
    >
      <Tooltip title={trainee.email}>
        <Avatar
          sx={{ width: isChat ? 35 : 45, height: isChat ? 35 : 45 }}
          src={trainee.avatarUrl}
        />
      </Tooltip>
    </StyledBadge>
  );
}
