import React, { useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { motion, useAnimation } from "framer-motion";
import { useSelector } from "react-redux";

function ConfirmButtonMotion({ text, confirm, size, disabled = false, id }) {
  const { missionId } = useSelector((state) => state.hintReducer);

  const buttonSize = useMemo(() => {
    return {
      width: size?.width || "53px",
      height: size?.height || "20px",
      font: size?.font || "10px",
    };
  }, [size]);

  const classes = useStyles({ size: buttonSize });

  const controls = useAnimation();

  useEffect(() => {
    if (missionId === id) {
      controls.start((i) => ({
        opacity: 1,
      }));
    } else {
      controls.stop();
    }
  }, [missionId, id, controls]);

  return (
    <motion.button
      layoutId={`get-hint-${id}`}
      className={classes.buttonRoot}
      onClick={confirm}
      disabled={disabled}
      initial={false}
      animate={controls}
      exit={false}
    >
      {text}
    </motion.button>
  );
}

const useStyles = makeStyles({
  buttonRoot: {
    width: (props) => props.size.width,
    height: (props) => props.size.height,
    textAlign: "center",
    fontFamily: (props) => props.theme.MAIN_FONT,
    textTransform: "uppercase",
    fontWeight: 600,
    padding: "4px 0 5px 0",
    borderRadius: "5px",
    WebkitBackdropFilter: "blur(6px)",
    backdropFilter: "blur(6px)",
    border: "none",
    boxShadow: "inset 0 -2px 1px 0 rgba(0,0,0,0.27)",
    background: "linear-gradient(180deg, #FF6800 0%, #b45204 100%)",
    fontSize: (props) => props.size.font,
    color: "#ffffff",
  },
});

export default ConfirmButtonMotion;
