import React, { useState, useEffect } from "react";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
  const [config, setConfig] = useState(null);

  useEffect(() => { // Needed for the correct directory config.json when using ecosystem deployment
      fetch("./config.json")
        .then((response) => response.json())
        .then((data) => setConfig(data))
        .catch((error) => {
          console.error('Error fetching config.json:', error);
        });
  }, []);

  if (!config)
  {
    return null
  }

  const domain = config?.AUTH0_DOMAIN;
  const clientId = config?.AUTH0_CLIENT_ID;
  const audience = config?.AUTH0_AUDIENCE;
  const redirectURL = window.location.origin;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectURL}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;