import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CIcon from "@coreui/icons-react";

function QuestionOptionIcon({ status }) {
  const classes = useStyles();

  if (status === "correct") {
    return (
      <CIcon
        name="correctIcon"
        className={classes.cornerIcon}
        height={21}
        width={21}
      />
    );
  }
  if (status === "wrong") {
    return (
      <CIcon
        name="incorrectIcon"
        className={classes.cornerIcon}
        height={21}
        width={21}
      />
    );
  }
  return null;
}

const useStyles = makeStyles({
  cornerIcon: {
    position: "absolute",
    top: -5,
    right: -5,
  },
});

export default memo(QuestionOptionIcon);
