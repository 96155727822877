export const correctPartlyIcon = [
    "21 21",
    `
    <g fill="none" fill-rule="evenodd">
    <g>
        <g>
            <g transform="translate(-609 -619) translate(102 549) translate(507 70)">
                <circle cx="10.5" cy="10.5" r="10.5" fill="#ef9c07"/>
                <g stroke="#FFF" stroke-width="1.6">
                    <path d="M2.796 0.707L2.796 10.307 6.999 10.355" transform="translate(6 3) scale(-1 1) rotate(-45 0 17.354)"/>
                </g>
            </g>
        </g>
    </g>
  </g>
    `,
  ];
  