export const globalIcon = [
  "55 55",
  `
  <g fill="none" fill-rule="evenodd">
        <g fill="#FFF" fill-rule="nonzero">
            <g>
                <g>
                    <g>
                        <path d="M29.102 8.033l-.149-.257C26.26 3.132 21.238 0 15.493 0 9.75 0 4.728 3.132 2.035 7.776l-.148.257C.847 9.906.186 12.015 0 14.256v2.592c.186 2.241.847 4.35 1.886 6.222l.148.258c2.693 4.644 7.716 7.776 13.46 7.776 5.744 0 10.766-3.132 13.46-7.776l.148-.258c1.039-1.873 1.7-3.98 1.885-6.222v-2.592c-.185-2.241-.846-4.35-1.885-6.223zm-5.859 6.223c-.051-1.361-.182-2.663-.385-3.888h4.506c.531 1.212.887 2.517 1.024 3.888h-5.145zm-7.75 14.256c-1.464 0-3.03-1.997-4.053-5.184h8.108c-1.024 3.187-2.59 5.184-4.054 5.184zm-4.71-7.776c-.229-1.2-.383-2.506-.443-3.888h10.308c-.06 1.382-.214 2.687-.443 3.888h-9.423zm-.443-6.48c.06-1.382.214-2.687.442-3.888h9.423c.229 1.2.383 2.506.443 3.888H10.34zm5.154-11.664c1.465 0 3.03 1.997 4.053 5.184H11.44c1.024-3.187 2.589-5.184 4.054-5.184zm10.343 5.184h-3.545c-.438-1.581-1.008-2.974-1.687-4.13 2.086.9 3.881 2.338 5.232 4.13zm-15.454-4.13c-.68 1.156-1.25 2.549-1.688 4.13H5.15c1.352-1.792 3.146-3.23 5.233-4.13zm-6.76 6.722H8.13c-.203 1.225-.335 2.527-.386 3.888H2.6c.137-1.37.493-2.676 1.024-3.888zm4.121 6.48c.051 1.361.182 2.663.386 3.888H3.623c-.53-1.212-.886-2.517-1.023-3.888h5.144zm-2.594 6.48h3.545c.438 1.581 1.009 2.974 1.687 4.13-2.086-.9-3.88-2.338-5.232-4.13zm15.454 4.13c.68-1.156 1.25-2.549 1.687-4.13h3.545c-1.35 1.792-3.145 3.23-5.232 4.13zm6.76-6.722h-4.506c.203-1.225.335-2.527.386-3.888h5.145c-.137 1.37-.493 2.676-1.025 3.888z" transform="translate(-23 -227) translate(0 50) translate(0 154) translate(23 23)"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
  `,
];
