export const closeIcon = [
  "40 41",
  `
  <defs>
  <rect id="ho78w7r1ya" width="40" height="40" x="0" y="0" rx="20"/>
</defs>
<g fill="none" fill-rule="evenodd">
  <g>
      <g>
          <g>
              <g transform="translate(-117 -90) translate(97 70) translate(20 20.5)">
                  <use fill="#FFF" fill-opacity=".05" xlink:href="#ho78w7r1ya"/>
              </g>
              <path stroke="#FFF" stroke-width="2" d="M12 12.5L27.5 28m0-15.5L12 28" transform="translate(-117 -90) translate(97 70) translate(20 20.5)"/>
          </g>
      </g>
  </g>
</g>
  `,
];
