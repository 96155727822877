import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

function HintHeader({ text, fontSize = "25px", children }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ fontSize, theme });

  if (text === "") {
    return <div className={classes.headerRoot}>{children}</div>;
  }

  return <div className={classes.headerRoot}>{text}</div>;
}

const useStyles = makeStyles(() => ({
  headerRoot: {
    textShadow: "0 0 6px rgba(0, 0, 0, 0.35)",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: (props) => props.fontSize,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.2",
    letterSpacing: "normal",
    color: "#ffffff",
    textAlign: "center",
    padding: "0 45px",
    width: "100%",
    height: "120px",
    overflow: "auto !important",
  },
}));

export default HintHeader;
