import { call, put, takeLatest } from "redux-saga/effects";
import { loadScenarioApi } from "../../services/scenarios";
import { setScenarioItem } from "./../actions/scenarioItem";
import {SetComponentIdToIndexDict} from "./read.js";

function* scenarioItem(action) {
  try {
    const scnResult = yield call(loadScenarioApi, action.itemId, action.token);
    if (scnResult?.data) {
      const parsedArr = JSON.parse(scnResult.data);
      const scn = parsedArr?.data;
      const parsedScn = JSON.parse(scn);
      if (parsedScn?.steps_all?.length > 0)
      {
        let componentIdToIndexDict = {};
        //  create componentIdToIndexDict for handling UI components duplication
        for (let index = 0; index < parsedScn.steps_all.length; index++) 
        {
          // parsedScn.steps_all[index].isLocked = true; // Not needed anymore
          componentIdToIndexDict[parsedScn.steps_all[index]?.id] = index;
        }
        SetComponentIdToIndexDict(componentIdToIndexDict);
      }
      yield put(setScenarioItem(parsedScn));
    }
  } catch (e) { console.warn(e); }  
}

export function* scenarioSaga() {
  yield takeLatest("LOAD_SCENARIO", scenarioItem);
}
