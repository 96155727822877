import axios from "axios";

const myBaseURL =
  process.env.REACT_APP_ENVIRONMENT.trim() === "DEV"
    ? process.env.REACT_APP_BASE_URL_LOCAL_DEV
    : process.env.REACT_APP_BASE_URL_CLOUD;

export function AddNewUser(userData) {
  const userDataObj = {
    email: String(userData.email),
    name: String(userData.name),
    middle_name: String(userData.middle_name),
    last_name: String(userData.last_name),
    user_type_id: Number(1),
    avatar_id: Number(0),
  };

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(`${myBaseURL}/Users`, userDataObj, axiosConfig)
    .then((res) => {
      console.log("AddNewUser response", res);
      return res;
    })
    .catch((err) => {
      console.log("AddNewUser ERROR: ", err);
    });
}

export function getUserInformation(id) {
  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .get(`${myBaseURL}/Users/${id}`, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Failed to fetch User Information: ", err);
    });
}


export function updateUser(userData) {
  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .put(`${myBaseURL}/Users`, userData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Failed to fetch User Information: ", err);
    });
}
export function deactivateUser(userId) {
  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .patch(`${myBaseURL}/Users/${userId}/deactivate`, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Failed to fetch User Information: ", err);
    });
}
export const getUserPermissionsDisplay = async (userId, includeInvalid) => {
  const axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    params: {
      includeInvalid,
    },
  };

  try {
    const res = await axios.get(
      `${myBaseURL}/Users/${userId}/ScenarioPermission/Display`,
      axiosConfig
    );
    return res?.data;
  } catch (err) {
    console.error("getOrganizationPermissions ERROR: ", err);
  }
};

export function getActiveUserPermissionType() {
  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`${myBaseURL}/Users/PermissionType`, axiosConfig)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log("Failed to fetch User Information: ", err);
    });
}
