import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";

function Score({ score = "", size = "28px", lineHeight = "36px" }) {
  const classes = useStyles({ size });

  return <div className={classes.score}>{score.toLocaleString()}</div>;
}

const useStyles = makeStyles(() => ({
  score: {
    fontSize: (props) => props.size,
    fontWeight: "600",
    lineHeight: (props) => props.lineHeight,
    textShadow: "0 0 6px #ee3042",
    marginRight: "8px",
  },
}));

export default memo(Score);
