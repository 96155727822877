import { SET_EVENTS } from "../types/events";

const initialState = [
  // {
  //   id: 1,
  //   topic: "Username 5 has solved a mission",
  //   icon: "greenTarget",
  //   username: "",
  //   time: "12:21",
  // },
  // {
  //   id: 2,
  //   topic: "Username 2 has taken a hint.",
  //   icon: "goldQuestionMark",
  //   username: "By Username1",
  //   time: "12:21",
  // },
  // {
  //   id: 3,
  //   topic: "group_name_2 solved a mission",
  //   icon: "redTarget",
  //   username: "",
  //   time: "12:21",
  // },
  // {
  //   id: 4,
  //   topic: "our team has reached 1st place",
  //   icon: "goldTrophy",
  //   username: "",
  //   time: "12:21",
  // },
  // {
  //   id: 5,
  //   topic: "group_name_2 has reached 1st place",
  //   icon: "redTrophy",
  //   username: "",
  //   time: "12:21",
  // },
  // {
  //   id: 6,
  //   topic: "group_name_3 has completed the training",
  //   icon: "redHat",
  //   username: "",
  //   time: "12:21",
  // },
  // {
  //   id: 7,
  //   topic: "Username 5 from group 3 has taken a hint",
  //   icon: "redQuestionMark",
  //   username: "",
  //   time: "12:21",
  // },
  // {
  //   id: 8,
  //   topic: "Event attack!",
  //   icon: "pinkMission",
  //   username: "By Username1",
  //   time: "12:21",
  // },
];

const eventsReducer = (state = initialState, action) => {
  const { events, type } = action;

  switch (type) {
    case SET_EVENTS:
      return events;
    default:
      return state;
  }
};

export default eventsReducer;
