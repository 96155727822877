import React, { useMemo, memo } from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import IconButton from "@material-ui/core/IconButton";

function MissionHeader({
  text,
  status,
  bodyOpen = true,
  children,
  setBodyOpen,
}) {
  const classes = useStyles();

  const toggleAccordion = () => {
    setBodyOpen((state) => !state);
  };
  
  if (status === "debrief") {
    if (text.includes("###")) {
      const dynamicIpPattern = /\@\[.*?\]\([#@]{3}(\d+)[#@]{3}\)/g;

      text = text.replace(dynamicIpPattern, " <Dynamic IP>");
    }
  }
  const headerArrow = useMemo(() => {
    if (status === "active" || status === "finished" || status === "debrief") {
      return (
        <IconButton onClick={toggleAccordion} className={classes.icon}>
          {bodyOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      );
    }
    return null;
  }, [status, bodyOpen]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <div className={classes.missionName}>{text}</div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {children}
        {headerArrow}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  missionName: {
    textShadow: "0 0 6px rgba(0, 0, 0, 0.35)",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.15",
    letterSpacing: "normal",
    color: "#ffffff",
    margin: "10px 2px 10px 10px",
  },
  icon: {
    color: "white",
    padding: "4px",
  },
}));

export default memo(MissionHeader);
