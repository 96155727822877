import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";

import PopupCard from "../shared/PopupCard";
import MainHeader from "../shared/MainHeader";
import PopupApprovalButton from "../../buttons/PopupApprovalButton";
import completeBg from "../../../assets/backgrounds/training/trainingComplete.svg";
import defaultProfilePic from "../../../assets/avatar.png";
import { setModalState } from "../../../store/actions/ui";
import ShadowedText from "../shared/ShadowedText";

function CompleteContainer() {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ theme });

  const dispatch = useDispatch();

  const { username } = useSelector((state) => state.userReducer);
  const { score, badges } = useSelector((state) => state.uiReducer);
  const { allTraineesAvatars, userDetails } = useSelector(
    (state) => state.personalHallReducer
  );

  const approveTrainingEnd = useCallback(() => {
    dispatch(setModalState(true, "feedback"));
  }, []);

  const profilePic =
    allTraineesAvatars && userDetails?.avatarId !== 100
      ? allTraineesAvatars[userDetails?.avatarId]
      : defaultProfilePic;

  return (
    <div className={classes.completeRoot}>
      <PopupCard padding="0">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="center"
          className={classes.completeHeader}
        >
          <div className={classes.avatar}>
            <img
              className="completeAvatar"
              alt={"profile"}
              src={profilePic}
              width="128px"
              height="128px"
            />
          </div>
          <MainHeader text="Training completed" />
          <div className={classes.username}>Great job, {username}!</div>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={`${classes.completeHeader}, ${classes.summary}`}
        >
          <div>
            <div className={classes.summryUpper}>Score</div>
            <ShadowedText type="red" text={score || 0} size="30px" />
          </div>
          <div>
            <div className={classes.summryUpper}>Rings</div>
            <ShadowedText
              type="blue"
              text={`${badges?.length || "0"}`}
              size="30px"
            />
          </div>
        </Box>
        <div className="text-center"></div>
        <PopupApprovalButton
          text="Leave feedback"
          approveClick={approveTrainingEnd}
        />
      </PopupCard>
    </div>
  );
}

const useStyles = makeStyles({
  completeRoot: {
    border: "none",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  completeHeader: {
    backgroundImage: `url(${completeBg})`,
    backgroundPosition: "0 -10px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "245px",
    height: "245px",
  },
  username: {
    textShadow: "0 0 6px rgba(0, 0, 0, 0.35)",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "20px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
    margin: "3px 0",
  },
  summary: {
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    width: "150px",
    marginBottom: "10px",
  },
  summryUpper: {
    fontSize: "20px",
    opacity: "0.4",
    color: "#ffffff",
  },
});

export default CompleteContainer;
