export const pinkShield = [
  "38 38",
  `
  <defs>
        <filter id="y0owu0blga" color-interpolation-filters="auto">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.929412 0 0 0 0 0.086275 0 0 0 0 0.301961 0 0 0 0.850000 0"/>
        </filter>
        <filter id="miivgy2ovb" color-interpolation-filters="auto">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.092827 0 0 0 0 1.000000 0 0 0 0 0.413502 0 0 0 0.850000 0"/>
        </filter>
        <filter id="gadexzp3ac" color-interpolation-filters="auto">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.929412 0 0 0 0 0.086275 0 0 0 0 0.301961 0 0 0 1.000000 0"/>
        </filter>
        <filter id="ks77c16u9e" width="250%" height="228.6%" x="-75%" y="-64.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="3"/>
        </filter>
        <filter id="hk18cegvkg" width="211.4%" height="248.6%" x="-55.7%" y="-74.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
        </filter>
        <filter id="pjc86t687i" width="211.4%" height="248.6%" x="-55.7%" y="-74.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
        </filter>
        <filter id="4jy2d8yh4k" width="211.4%" height="248.6%" x="-55.7%" y="-74.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
        </filter>
        <filter id="1hgttfs2zm" width="325%" height="300%" x="-112.5%" y="-100%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
        </filter>
        <filter id="iohhg60x4o" width="250%" height="185.7%" x="-75%" y="-42.9%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
        </filter>
        <filter id="e34c3ppx1p" width="211.4%" height="248.6%" x="-55.7%" y="-74.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
        </filter>
        <linearGradient id="pwfk1oonyd" x1="0%" x2="100%" y1="52.719%" y2="52.719%">
            <stop offset="0%" stop-color="#00FF68"/>
            <stop offset="46.443%" stop-color="#48FF92" stop-opacity=".64"/>
            <stop offset="100%" stop-color="#39CA74"/>
        </linearGradient>
        <linearGradient id="ivc8cggz6f" x1="0%" x2="100%" y1="52.719%" y2="52.719%">
            <stop offset="0%" stop-color="#00FF68" stop-opacity="0"/>
            <stop offset="46.443%" stop-color="#4AFF94"/>
            <stop offset="100%" stop-color="#39CA74" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="3szj7psgzh" x1="0%" x2="100%" y1="52.719%" y2="52.719%">
            <stop offset="0%" stop-color="#00FF68" stop-opacity=".46"/>
            <stop offset="46.443%" stop-color="#4AFF94"/>
            <stop offset="100%" stop-color="#39CA74" stop-opacity=".58"/>
        </linearGradient>
        <linearGradient id="16ktd99anj" x1="0%" x2="100%" y1="52.719%" y2="52.719%">
            <stop offset="0%" stop-color="#00FF68"/>
            <stop offset="46.443%" stop-color="#48FF92" stop-opacity=".9"/>
            <stop offset="100%" stop-color="#39CA74"/>
        </linearGradient>
        <linearGradient id="h87r1tv5wl" x1="0%" x2="100%" y1="52.719%" y2="52.719%">
            <stop offset="0%" stop-color="#00FF68" stop-opacity="0"/>
            <stop offset="46.443%" stop-color="#4AFF94"/>
            <stop offset="100%" stop-color="#39CA74" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="s24tvv6qcn" x1="0%" x2="100%" y1="52.719%" y2="52.719%">
            <stop offset="0%" stop-color="#00FF68" stop-opacity="0"/>
            <stop offset="46.443%" stop-color="#4AFF94" stop-opacity=".72"/>
            <stop offset="100%" stop-color="#39CA74" stop-opacity="0"/>
        </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g transform="translate(-339 -430) translate(339.849 431)">
                <circle cx="17.093" cy="17.093" r="17.093" fill="#000" fill-opacity=".6" stroke="#FFF" stroke-opacity=".282" stroke-width=".817"/>
                <g filter="url(#y0owu0blga)">
                    <g>
                        <g filter="url(#miivgy2ovb)" transform="translate(7.53 6)">
                            <g>
                                <path fill="#16ED62" d="M8.504 1.499c-1.99 1.736-4.471 2.458-7.051 2.52-.241.007-.39.074-.402.31-.86 6.145.308 14.57 7.195 16.624.196.064.264.054.26-.17-.004-.496.259-1.201-.301-1.424-5.48-1.871-6.435-8.424-5.991-13.474.019-.234.115-.354.355-.386 2.033-.245 4.048-.8 5.759-1.971.112-.078.178-.155.177-.296-.003-.55-.001-1.101-.001-1.733m6.359 6.334c0-.42-.005-.838.002-1.257.003-.182-.045-.334-.233-.365-1.028-.26-1.535-.178-2.637-.56-1.027-.356-2.001-.815-2.907-1.413-.155-.102-.256-.112-.417-.003-.836.692-4.633 1.882-5.18 1.93-.556.08-.606.116-.6.688-.041 3.103.222 6.406 2.004 9.05.77 1.162 1.906 1.853 3.113 2.518.446.246 1.236.288 1.878-.074 1.366-.77 2.45-1.731 3.288-3.061.715-1.136 1.376-3.912 1.397-4.015.244-1.134.31-2.282.292-3.438m1.918-2.261c-.02-.625-.043-.853-.07-1.28-.012-.186-.094-.264-.276-.258-.651.023-1.291-.078-1.935-.154-1.953-.347-3.725-1.07-5.253-2.378v.88c0 1.094 0 1.094.957 1.64 1.532.825 3.195 1.252 4.906 1.472.399.053.5.267.492.593.223 3.996-.187 8.589-3.225 11.54-.807.768-1.765 1.327-2.808 1.724-.408.112-.314.457-.32.782-.002.93-.002.93.86.606 5.959-2.176 7.309-9.496 6.672-15.167M13.83 19.41c-.54.247-4.21 2.395-4.954 2.353-1.39-.078-4.268-1.894-4.883-2.352l-.031-.017.032.016c.376.678 1.13 1.182 1.778 1.625.634.454 2.333 1.389 3.104 1.33 1.69-.123 4.677-2.465 4.955-2.955M12.5 21.567c-.822.49-1.686.885-2.58 1.236-.378.151-.746.207-1.108.196-1.123-.036-2.175-.72-3.191-1.202-4.2-2.579-5.448-7.64-5.62-12.207-.005-1.027-.007-2.055.06-3.08.043-.966.25-1.928.238-2.873.155-.376.449-.453.832-.445 2.399.039 4.838-.648 6.7-2.214.348-.293.452-.44.646-.685.599-.808.953.267 1.503.697 2.01 1.565 4.546 2.208 7.018 2.272.215.001.354.088.385.31.17 1.169.349 2.336.366 3.52.153 5.215-.59 11.264-5.25 14.475" transform="translate(.622)"/>
                            </g>
                        </g>
                        <g filter="url(#gadexzp3ac)" transform="translate(7.53 6)">
                            <ellipse cx="9.67" cy="10.596" fill="url(#pwfk1oonyd)" filter="url(#ks77c16u9e)" rx="6" ry="7"/>
                            <ellipse cx="9.987" cy="2.019" fill="url(#ivc8cggz6f)" filter="url(#hk18cegvkg)" opacity=".752" rx="2.692" ry="2.019"/>
                            <ellipse cx="5.987" cy="6.019" fill="url(#3szj7psgzh)" filter="url(#pjc86t687i)" rx="2.692" ry="2.019"/>
                            <ellipse cx="9.987" cy="11.019" fill="url(#16ktd99anj)" fill-opacity=".6" filter="url(#4jy2d8yh4k)" rx="2.692" ry="2.019"/>
                            <ellipse cx="1.333" cy="3.923" fill="url(#h87r1tv5wl)" filter="url(#1hgttfs2zm)" opacity=".752" rx="1.333" ry="1.5"/>
                            <ellipse cx="16.841" cy="16.286" fill="url(#s24tvv6qcn)" filter="url(#iohhg60x4o)" opacity=".63" rx="2" ry="3.5" transform="rotate(17 16.841 16.286)"/>
                            <ellipse cx="9.064" cy="18.904" fill="url(#ivc8cggz6f)" filter="url(#e34c3ppx1p)" rx="2.692" ry="2.019"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>`,
];
