import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { LineChart, Line, YAxis, XAxis, ResponsiveContainer } from "recharts";

import TimelineIcons from "./TimelineIcons";
import leaderboardColors from "./../../../../constants/leaderboardColors";

function TimelineChart({
  activeLine,
  setActiveLine,
  hoverLine,
  setHoverLine,
  isStatic = false,
  data = [],
  height,
  viewedTraineeId,
}) {
  const classes = useStyles({ isStatic });

  const leaderboard = useSelector((state) => state.leaderboardReducer);
  const { graph, dots } = useSelector((state) => state.timelineReducer);
  const { username, id: userId } = useSelector((state) => state.userReducer);
  const graphData = GetGraphData();

  function GetGraphData() {
    let graphDataArray = [];

    if (
      dots !== undefined &&
      Object.keys(dots) > 0 &&
      graph !== undefined &&
      Object.keys(graph) > 0
    ) {
      let currentScore = 0;
      let trainedUser = Object.keys(dots)[0];
      let timeDotsArray = Object.keys(dots[trainedUser]);
      let timeGraphArray = graph.map(({ name }) => name);
      let timeDiffArray = timeDotsArray.filter(
        (time) => !timeGraphArray.includes(time)
      );
      for (let i = 0; i < timeGraphArray.length; i++)
      {
        currentScore = graph[i][trainedUser];
        let graphTimeInSeceonds = convertTimeStringToSeconds(timeGraphArray[i]);
        for (let j = 0; j < timeDiffArray.length; j++) {
          let timeDiffInSeconds = convertTimeStringToSeconds(timeDiffArray[j]);
          if (timeDiffInSeconds < graphTimeInSeceonds) {
            graphDataArray.push({
              [trainedUser]: currentScore,
              name: timeDiffArray[j],
            });
            timeDiffArray.splice(j, 1);
          } else if (timeDiffInSeconds === graphTimeInSeceonds) {
            timeDiffArray.splice(j, 1);
          }
        }
        graphDataArray.push(graph[i]);
      }
      if (timeDiffArray.length !== 0) {
        for (let i = 0; i < timeDiffArray.length; i++) {
          graphDataArray.push({
            [trainedUser]: currentScore,
            name: timeDiffArray[i],
          });
        }
      }
    } else {
      graphDataArray = graph;
    }

    return graphDataArray;
  }

  function convertTimeStringToSeconds(timeString) {
    let timeSplitArray = timeString.split(":");
    let timeInSeconds =
      +timeSplitArray[0] * 60 * 60 +
      +timeSplitArray[1] * 60 +
      +timeSplitArray[2];
    return timeInSeconds;
  }

  // const leaderboardData = data ? data.graph : leaderboard;

  // console.log("leaderboardData", leaderboard);
  // console.log("graph", graph);
  // console.log("activeLine", activeLine);

  const lineWidth = useCallback(
    (dataKey) => {
      return activeLine === dataKey || hoverLine === dataKey ? 3.5 : 1;
    },
    [activeLine, hoverLine]
  );

  const chartMouseEnter = (id) => {
    if (!isStatic) {
      setHoverLine(id);
    }
  };

  const chartMouseLeave = (id) => {
    if (!isStatic) {
      setHoverLine(null);
    }
  };

  const chartMouseClick = (id) => {
    if (!isStatic) {
      // console.log("id", id);
      setActiveLine(id);
    }
  };

  return (
    <ResponsiveContainer
      minHeight="180px"
      width="100%"
      height={height ? height : "100%"}
      padding
    >
      <LineChart
        width={"100%"}
        height={"100%"}
        data={graphData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        className={classes.lineChart}
      >
        <YAxis
          axisLine={false}
          tickFormatter={(value) => (value !== 0 ? value : "")}
          padding={{ top: 10, bottom: 10 }}
        />
        <XAxis
          axisLine={false}
          dataKey="name"
          padding={{ top: 10, bottom: 10 }}
        />
        {leaderboard?.map((user, index) => {
          const { id } = user;
          const colorIndex = id === userId ? 10 : index;
          const stroke = leaderboardColors[colorIndex];
          if (id === activeLine) return null;
          return (
            <Line
              key={id}
              brushBottom={10}
              type="monotone"
              dataKey={id}
              fillOpacity={0.2}
              stroke={stroke}
              strokeWidth={lineWidth(id)}
              onMouseEnter={() => chartMouseEnter(id)}
              onMouseLeave={() => chartMouseLeave(id)}
              onClick={() => chartMouseClick(id)}
              handleMouseLeave={() => setHoverLine(null)}
              dot={
                activeLine === id ? (
                  <TimelineIcons dots={dots} dataKey={id} />
                ) : null
              }
              style={{
                position: "relative",
                zindex: 1,
                cursor: isStatic ? "" : "pointer",
              }}
            />
          );
        })}
        <Line
          key={activeLine}
          brushBottom={10}
          type="monotone"
          dataKey={activeLine}
          stroke={leaderboardColors[activeLine === userId ? 10 : activeLine]}
          strokeWidth={lineWidth(activeLine)}
          onMouseEnter={() => chartMouseEnter(activeLine)}
          onMouseLeave={() => chartMouseLeave(activeLine)}
          onClick={() => chartMouseClick(activeLine)}
          handleMouseLeave={() => setHoverLine(null)}
          dot={<TimelineIcons dots={dots} />}
          style={{
            position: "relative",
            zindex: 1000,
            cursor: isStatic ? "" : "pointer",
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

const useStyles = makeStyles({
  leaderboardBody: {
    flex: 1,
  },
  lineChart: {
    "& .recharts-cartesian-axis-tick-line": {
      display: "none",
    },
    "& .recharts-cartesian-axis": {
      fontFamily: "NunitoSans",
      fontSize: "13px",
      fontWeight: "300",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "-0.46px",
      color: "#ffffff",
    },
  },
  axisTick: {
    fontFamily: "NunitoSans",
    fontSize: "13px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2.25",
    letterSpacing: "-0.46px",
    color: "#ffffff",
  },
  text: {
    fontFamily: "NunitoSans",
    fontSize: "20px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2.25",
    letterSpacing: "-0.46px",
    color: "#ffffff",
  },
});

export default TimelineChart;
