export const redTrophy = [
  "34 34",
  `
  <defs>
  <filter id="o76q02sdza">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 0.174921 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
  </filter>
  <filter id="cg1rbb8skb" width="250%" height="250%" x="-75%" y="-75%" filterUnits="objectBoundingBox">
      <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.5"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.7 0"/>
  </filter>
  <circle id="qg4nyw2ghc" cx="7.92" cy="7" r="7"/>
</defs>
<g fill="none" fill-rule="evenodd">
  <g>
      <g>
          <g>
              <g transform="translate(-1119 -507) translate(1104 251) translate(7 48) translate(9 209)">
                  <g>
                      <g filter="url(#o76q02sdza)" transform="translate(8 10)">
                          <use fill="#000" fill-rule="nonzero" filter="url(#cg1rbb8skb)" transform="translate(0 .92)" xlink:href="#qg4nyw2ghc"/>
                      </g>
                  </g>
                  <circle cx="15.907" cy="15.907" r="15.907" stroke="#FFF" stroke-opacity=".282" stroke-width=".75"/>
                  <path fill="#EB1616" fill-rule="nonzero" d="M19.515 8c.152 0 .276.125.276.274V9.66h1.937c.305 0 .553.248.553.554v3.6c0 1.066-.868 1.933-1.937 1.933h-.553c-.001 1.529-1.239 2.767-2.767 2.767h-.83v2.767h1.108c.916 0 1.659.745 1.659 1.66v1.66h-6.64v-1.66c0-.916.742-1.66 1.658-1.66h1.108v-2.767h-.83c-1.527 0-2.765-1.235-2.767-2.767h-.553C9.868 15.747 9 14.88 9 13.813v-3.6c0-.305.248-.553.553-.553h1.937V8.274c0-.151.124-.274.276-.274h7.749zm-8.025 2.767h-1.383v3.046c0 .42.317.77.726.821l.104.007h.553v-3.874zm9.684 0h-1.383v3.874h.553c.458 0 .83-.373.83-.828v-3.046z"/>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
