import React, { useMemo, memo, useState } from "react";
import { Rnd } from "react-rnd";

import ChatContainer from "./chat/ChatContainer";
import MissionsContainer from "./missions/MissionsContainer";
import EventsContainer from "./events/EventsContainer";
import LeaderboardModalTraining from "../leaderboard/LeaderboardModalTraining";
import InfoContainer from "./info/InfoContainer";
import { setModalState } from "../../store/actions/ui";
import { useDispatch } from "react-redux";
import NetworkViewContainer from "./network/NetworkContainer";

function TrainingRndContainer({
  selectIcon,
  type,
  selectedRanger,
  scenarioListItem,
  networkListItem,
  runTraining,
  stopTraining,
  continueTraining,
  userDetails,
  rangerStatus,
  setActiveWindow,
  activeWindow,
}) {
  const [windowZindex, setWindowZindex] = useState(0);
  const SetSize = (type) => {
    switch (type) {
      case "chat":
        return {
          x: -450,
          y: -300,
          width: 250,
          height: 500,
        };
      case "missions":
        return {
          x: -250,
          y: -100,
          width: 250,
          height: 430,
        };
      case "leaderboard":
        return {
          x: -1300,
          y: -300,
          width: 1000,
          height: 600,
        };
      case "monitor":
        return {
          x: -1350,
          y: -300,
          width: 900,
          height: 800,
        };
      case "network":
        return {
          x: -900,
          y: -300,
          width: 850,
          height: 430,
        };
      case "info":
        return {
          x: -450,
          y: -300,
          width: 250,
          height: 500,
        };
    }
  };
  const dispatch = useDispatch();

  const handleConfirmClick = () => {
    dispatch(setModalState(false, ""));
  };

  const returnPopup = useMemo(() => {
    if (type === "chat") {
      return <ChatContainer closeClick={() => selectIcon("chat")} />;
    }
    if (type === "missions") {
      return <MissionsContainer closeClick={() => selectIcon("missions")} />;
    }
    if (type === "leaderboard") {
      return (
        <LeaderboardModalTraining
          closeClick={() => selectIcon("leaderboard")}
        />
      );
    }
    if (type === "events") {
      return <EventsContainer closeClick={() => selectIcon("")} />;
    }
    if (type === "info") {
      return <InfoContainer closeClick={() => selectIcon("")} />;
    }
    if (type === "network") {
      return (
        <NetworkViewContainer
          networkFlowChart={networkListItem}
          closeClick={() => selectIcon("network")}
        />
      );
    }
  }, [type]);

  return (
    <Rnd
      style={{ zIndex: type === activeWindow ? 1 : 0 }}
      onClick={() => setActiveWindow(type)}
      default={SetSize(type)}
      minHeight={250}
      maxHeight={500}
      bounds="#vnc-card"
      dragHandleClassName="rnd-handle"
      enableResizing={{
        top: false,
        bottom: false,
        left: false,
        right: false,
      }}
    >
      {returnPopup}
    </Rnd>
  );
}

export default memo(TrainingRndContainer);
