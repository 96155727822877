import React, { useState, useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CCard, CCardBody } from "@coreui/react";
import { ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Prompt } from "react-router-dom";
import {
  setModalState,
  resetTrainingUiState,
} from "../../../../store/actions/ui";
import TrainingMenu from "../../../training/TrainingMenu";
import ModalContainer from "../../../base/modals/ModalContainer";
import WelcomeContainer from "./../../../training/welcome/WelcomeContainer";
import FeedbackContainer from "./../../../training/complete/FeedbackContainer";
import CompleteContainer from "../../../training/complete/CompleteContainer";
import { missionBuilder } from "../../../../helpers/missionsBuilder";
import {
  setMissions,
  resetMissionsState,
} from "../../../../store/actions/missions";
import {
  userLogout,
  resetTrainingState,
  setRejoinStatus,
  setTrainingRanger,
  setVncTrainingToken,
  userLogin,
} from "./../../../../store/actions/training";
import { setScenarioItem } from "../../../../store/actions/scenarioItem";

import { resetHintsState } from "../../../../store/actions/hint";
import { resetInformersState } from "../../../../store/actions/informer";
import { resetLeaderboardState } from "../../../../store/actions/leaderboard";
import { resetQuestionsState } from "../../../../store/actions/quiz";
import { resetQuestionResultsState } from "../../../../store/actions/quizResults";
import { resetTimelineState } from "../../../../store/actions/timeline";
import { sendMessage, resetChatState } from "../../../../store/actions/chat";
import {
  restoreInitialStore,
  resetInfosState,
} from "../../../../store/actions/info";
import QuestionPopupContainer from "./../../../training/popupMessages/QuestionPopupContainer";
import InformerPopupContainer from "./../../../training/popupMessages/InformerPopupContainer";
import HintPopupContainer from "./../../../training/missions/HintPopupContainer";
import MissionPopupContainer from "./../../../training/popupMessages/MissionPopupContainer";
import LeaderboardTimelineContainer from "./../../../training/leaderboardTimline/LeaderboardTimelineContainer";
import VncIframe from "./VncIframe";
import EffectsContainer from "./../../../training/effects/EffectsContainer";
import {
  GetScheduledTrainingStatus,
  GetSelfPacedTrainingStatus,
} from "../../../../services/vnc";
import { GetDefaultRanger } from "../../../../services/training";

import createSocketUri from "../../../../helpers/createSocketUri";
import useUserId from "../../../../hooks/useUserId";
import AlertDialog from "./../../../base/modals/popups/AlertDialog";
import { BiExit } from "react-icons/bi";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import { ClearDisplayedArr } from "../../../../store/sagas/read.js";
import { StartScenarioByTrainee } from "../../../../services/training";
import Lottie from "react-lottie";
import cyberingloader from "../../../../assets/animations/cyberingloader.json";
import { loadNetworkItem } from "../../../../services/NetworkService";
import { LoadActiveScenarioData_ByRangerName } from "../../../../services/scenarios";
import NetworkViewContainer from "../../../leaderboard/NetworkViewContainer";
import { setTrainingStatus } from "./../../../../store/actions/training";

function VncCard({ location }) {
  const dispatch = useDispatch();

  const history = useHistory();
  const userId = useUserId();

  const [networkFlowChart, setNetworkFlowchart] = useState({});
  const [pointerUp, setPointerUp] = useState(false);
  const [isWarningPromptOpen, setIsWarningPromptOpen] = useState(false);
  const [warningTimeLeft, setWarningTimeLeft] = useState(0);
  const [trainingIsReady, setTrainingIsReady] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("false");

  const { modalOpen, modalType } = useSelector((state) => state.uiReducer);
  const { ip: userIp } = useSelector((state) => state.userAgentReducer);
  const scenarioListItem = useSelector((state) => state.scenarioItemReducer);
  const { theme } = useSelector((state) => state.uiReducer);
  const { username, token, id, email, avatarId } = useSelector(
    (state) => state.userReducer
  );
  const {
    trainingStatus,
    rangerName,
    rejoinStatus,
    timeLeft,
    trainingId,
    vncToken,
  } = useSelector((state) => state.trainingReducer);

  const { missions } = useSelector(
    (state) => state.missionsReducer
  );

  const isFinished =
    trainingStatus === "finished" || trainingStatus === "feedback-finished";

  // This line should stay here after the state //
  const classes = useStyles({ pointerUp, theme });

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: cyberingloader,
  };

  useEffect(() => {
    if (scenarioListItem !== undefined && scenarioListItem?.steps_all) {
      if (!missions?.length > 0) {
        const parsedMissions = missionBuilder(scenarioListItem.steps_all);
        dispatch(setMissions(parsedMissions));
      }
    }
  }, [scenarioListItem]);

  useEffect(() => {
    const fetchNetwork = async () => {
      if (scenarioListItem.NetID !== -1) {
        try {
          const netResult = await loadNetworkItem(scenarioListItem.NetID);
          if (netResult?.data) {
            const parsedArr = JSON.parse(netResult.data);
            const network = parsedArr?.data;
            const parsedNetwork = JSON.parse(network);
            const { devices, connections } = JSON.parse(
              parsedNetwork.DiagramJson
            );
            parsedNetwork.devices = devices;
            parsedNetwork.connections = connections;

            setNetworkFlowchart(parsedNetwork);
          }
        } catch (e) {
          console.log("Failed to fetch network");
        }
      } else {
        console.log(
          "scenarioListItem NetID is -1, no static network for the scenario on LoadNetworkData"
        );
      }
    };
    if (scenarioListItem.NetID !== undefined) {
      fetchNetwork();
    }
  }, [scenarioListItem]);

  useEffect(() => {
    if (trainingStatus === "feedback-finished") {
      dispatch(setTrainingStatus("initial"));
      resetStateOnTrainingFinished();
    }
    if (trainingStatus === "user-exit") {
      dispatch(setTrainingStatus("initial"));
      history.push("/dashboard");
    }
  }, [trainingStatus]);

  useEffect(() => {
    if (timeLeft !== 0) {
      setIsWarningPromptOpen(true);
      setWarningTimeLeft(timeLeft);
    }
  }, [timeLeft]);

  useEffect(() => {
    return () => {
      ClearDisplayedArr();
    };
  }, []);

  useEffect(() => {
    if (username) {
      handleUserReJoin();
    }
  }, [username]);

  const handleUserReJoin = async () => {
    try {
      const [scheduledJoinStatus, selfPacedJoinStatus, defaultRangerAvailible] =
        await Promise.all([
          GetScheduledTrainingStatus(),
          GetSelfPacedTrainingStatus(),
          GetDefaultRanger(),
        ]);
      const [scheduledStatus, selfPacedStatus, defaultRanger] = [
        scheduledJoinStatus?.data,
        selfPacedJoinStatus?.data,
        defaultRangerAvailible?.data,
      ];
      console.log(
        "scheduledStatus, selfPacedStatus, defaultRanger",
        scheduledStatus,
        selfPacedStatus,
        defaultRanger
      );
      const joinStatusData =
        scheduledStatus?.statusCode > 0
          ? scheduledStatus
          : selfPacedStatus?.statusCode > 0
          ? selfPacedStatus
          : defaultRanger;
      const {
        statusCode,
        rangerName: statusRangerName,
        rangerID: statusRangerID,
        vnc: statusVnc,
        scenarioID: statusScenarioID,
      } = joinStatusData;

      switch (statusCode) {
        case 2: // Status Rejoin
          dispatch(setRejoinStatus(true));
          connectToTraining(
            statusRangerName,
            statusRangerID,
            statusVnc,
            statusScenarioID
          );
          break;
        case 1: // Status Available
          connectToTraining(
            statusRangerName,
            statusRangerID,
            statusVnc,
            statusScenarioID
          );
          break;
        default:
          history.push("/personal-hall");
          break;
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const handleConfirmClick = () => {
    dispatch(setModalState(false, ""));
  };

  useEffect(() => {
    if (rejoinStatus !== null) {
      if (!rejoinStatus) {
        localStorage.removeItem("infos");
        return;
      }
      try {
        const dataFromLS = localStorage.getItem("infos");
        if (dataFromLS) {
          dispatch(restoreInitialStore(JSON.parse(dataFromLS)));
        }
      } catch (e) {
        console.warn("Error", e);
      }
    }
  }, [rejoinStatus]);

  const connectToTraining = async (
    statusRangerName,
    statusRangerID,
    statusVnc,
    statusScenarioID
  ) => {
    console.log(
      "connectToTraining",
      statusRangerName,
      statusRangerID,
      statusVnc,
      statusScenarioID
    );
    if (statusRangerName) {
      try {
        const updatedScenarioRes = await LoadActiveScenarioData_ByRangerName(
          statusRangerName
        );
        if (updatedScenarioRes?.data) {
          const updateScenarioData = JSON.parse(updatedScenarioRes.data);
          dispatch(setScenarioItem(updateScenarioData));
          const parsedMissions = missionBuilder(updateScenarioData.steps_all);
          dispatch(setMissions(parsedMissions));
        }
      } catch (e) {
        console.log("Failed LoadActiveScenarioData_ByRangerName", e);
      }
    } else {
      console.log("statusRangerName is empty, no ranger for the scenario");
    }

    dispatch(setTrainingRanger(statusRangerName, statusRangerID));
    dispatch(
      userLogin(
        { username, id, email },
        statusRangerID,
        statusRangerName,
        userIp || "",
        avatarId || 0
      )
    );
    const socketUri = createSocketUri(statusVnc);

    dispatch(setVncTrainingToken(socketUri));
    if (!rejoinStatus) {
      await StartScenarioByTrainee(token);
    }
    setTrainingIsReady(true);
  };

  const handleCancelExitTraining = () => {
    dispatch(setModalState(false, ""));
  };

  const handleConfirmExitTraining = () => {
    emit_trainee_exit_training();
    resetTrainingData();
    history.push("/personal-hall");
  };

  const resetStateOnTrainingFinished = () => {
    resetTrainingData();
    history.push(`/training-results?tid=${trainingId}`);
  };

  const resetTrainingData = () => {
    dispatch(userLogout());
    dispatch(resetChatState());
    dispatch(resetHintsState());
    dispatch(resetInfosState());
    dispatch(resetTimelineState());
    dispatch(resetTrainingState());
    dispatch(resetMissionsState());
    dispatch(resetQuestionsState());
    dispatch(resetInformersState());
    dispatch(setRejoinStatus(false));
    dispatch(resetTrainingUiState());
    dispatch(resetLeaderboardState());
    dispatch(resetQuestionResultsState());
    dispatch({ type: "LOAD_PERSONAL_HALL", userId });
  };

  const emit_trainee_exit_training = () => {
    const eventData = {
      username: email,
      data: { username: email },
      api_type: "trainee_exit_training",
      family: "trainee_exit_training",
      to: rangerName || "",
      from: email,
    };
    const event = JSON.stringify(eventData);

    dispatch(sendMessage(event));
  };

  const modalChildren = useMemo(() => {
    if (modalType === "welcome") {
      return (
        <WelcomeContainer
          isRejoin={rejoinStatus}
          confirm={handleConfirmClick}
          location={location}
        />
      );
    }
    if (modalType === "complete") {
      return <CompleteContainer />;
    }
    if (modalType === "feedback") {
      return <FeedbackContainer />;
    }
    if (modalType === "leaderboard") {
      return <LeaderboardTimelineContainer closeClick={handleConfirmClick} />;
    }
    if (modalType === "network") {
      return (
        <NetworkViewContainer
          networkFlowChart={networkFlowChart}
          closeClick={handleConfirmClick}
        />
      );
    }
    if (modalType === "effects") {
      return <EffectsContainer />;
    }
    if (modalType === "exit") {
      return (
        <AlertDialog
          open={modalType === "exit"}
          title="Exit current training?"
          message="You will not be able to return to this training."
          handleCancel={handleCancelExitTraining}
          handleConfirm={handleConfirmExitTraining}
          icon={<BiExit />}
          ConfirmButtonText="Exit Training"
          cancelButtonText="Stay"
        />
      );
    }
    return <div />;
  }, [modalType, rejoinStatus]);

  useEffect(() => {
    return () => {
      // If user clicked on "Back" and then approved the propt - this will trigger a disconnection
      //  this is necessary because a regular back click dosn't trigger a disconnection and that breaks the reconnection flow.
      if (history.action === "POP") {
        history.go(0);
      }
    };
  }, [history]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 116) {
        event.preventDefault(); // Prevent browser refresh
        setIsSnackbarOpen(true);
        setSnackbarMessage(
          "If you want to refresh the browser please do it manually"
        );
      }
      if ((event.ctrlKey || event.metaKey) && event.key === "r") {
        event.preventDefault(); // Prevent browser refresh
        setIsSnackbarOpen(true);
        setSnackbarMessage(
          "If you want to refresh the browser please do it manually"
        );
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  //** Missions popups box settings **//
  const pointer = document.querySelector("div");
  pointer.onpointerup = () => {
    setPointerUp(false);
  };
  const overHandler = () => {
    setPointerUp(true);
  };
  //** Missions popups box settings End **//

  const OpenExitTrainingModal = () => {
    dispatch(setModalState(true, "exit"));
  };

  const handleCloseWarningPrompt = () => {
    setIsWarningPromptOpen(false);
  };

  if (trainingIsReady) {
    return (
      <>
        <CCard className={classes.vncCardRoot}>
          <TrainingMenu
            scenarioListItem={
              scenarioListItem?.item ? scenarioListItem.item : {}
            }
            scenario={scenarioListItem?.item ? scenarioListItem.item : {}}
            networkListItem={networkFlowChart ? networkFlowChart : {}}
            OpenExitTrainingModal={OpenExitTrainingModal}
            // userImage={userImage}
            // handleExitTraining={handleExitTraining}
            // selectedRanger={selectedRanger}
            // runTraining={runTraining}
            // stopTraining={stopTraining}
            // continueTraining={continueTraining}
            // userDetails={userDetails}
            // rangerStatus={rangerStatus}
          />
          <CCardBody className={classes.cardBody}>
            <VncIframe
              url={vncToken}
              isFinished={isFinished}
              classes={classes}
            />
          </CCardBody>
        </CCard>

        <Snackbar
          open={isWarningPromptOpen}
          autoHideDuration={15000}
          ClickAwayListenerProps={{ disableReactTree: false }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseWarningPrompt}
        >
          <Alert
            onClose={handleCloseWarningPrompt}
            severity="warning"
            sx={{ width: "100%" }}
          >
            You got {warningTimeLeft} minutes left to complete this training!
          </Alert>
        </Snackbar>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={5000}
          ClickAwayListenerProps={{ disableReactTree: false }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={setIsSnackbarOpen}
        >
          <Alert severity="error">{snackbarMessage}</Alert>
        </Snackbar>

        <ModalContainer open={modalOpen} position="center">
          {modalChildren}
        </ModalContainer>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {isFinished ? null : (
          <div
            className={classes.resizableDiv}
            onPointerOver={() => overHandler()}
          >
            <MissionPopupContainer />
            <QuestionPopupContainer />
            <InformerPopupContainer />
            <HintPopupContainer />
          </div>
        )}
        <Prompt
          when={!isFinished && !modalType === "exit"}
          message="This action will get you out of this training."
        />
      </>
    );
  } else {
    return (
      <CCard className={classes.vncCardRoot}>
        <div className={classes.empty2}>
          <Lottie height={35} width={35} options={lottieOptions} speed={2} />
        </div>
      </CCard>
    );
  }
}

const useStyles = makeStyles({
  // resizableDiv: {
  //   pointerEvents: "none",
  //   position: "relative",
  //   padding: "10px" /* Adjust the padding value to your preference */,
  //   "&::after": {
  //     content: '""',
  //     position: "absolute",
  //     top: "-10px" /* Negative value to extend the hit area */,
  //     left: "-10px" /* Negative value to extend the hit area */,
  //     right: "-10px" /* Negative value to extend the hit area */,
  //     bottom: "-10px" /* Negative value to extend the hit area */,
  //   },
  // },
  vncCardRoot: {
    width: "100%",
    height: "100%",
    maxWidth: "100vw",
    WebkitBackdropFilter: "blur(1px)",
    backdropFilter: "blur(1px)",
    boxShadow: "0 0 33px 0 rgba(0, 0, 0, 0.21)",
    boxShadow: "0 0 33px 0 rgba(0, 0, 0, 0.21)",
    backgroundColor: "rgba(9, 12, 18)",
    pointerEvents: (props) => (props.pointerUp ? "none" : "auto"),
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainTitleIcon: {
    cursor: "pointer",
  },
  mainTitle: {
    color: "#FFFFFF",
    backgroundColor: "inherit",
    fontSize: "25px",
    letterSpacing: "-0.38px",
    lineHeight: "29px",
    display: "flex",
    alignItems: "center",
  },
  mainTitleText: {
    marginLeft: "12px",
  },
  dot: {
    marginLeft: "8px",
  },
  cardBody: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerIcons: {
    "& .MuiIconButton-root": {
      color: "white",
      padding: "8px 5px 0 5px",
    },
  },
  empty1: {
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "16px",
    letterSpacing: "-0.25px",
    color: "#818394",
    textTransform: "uppercase",
    marginTop: "45vh",
    marginLeft: "43vw",
  },
  empty2: {
    marginTop: "50vh",
  },
  copyPasteBox: {
    position: "fixed",
    bottom: "15px",
    right: "20px",
    zIndex: 10,
  },

  buttonStack: {
    position: "relative",
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "flex-end",
    paddingTop: "110px",
    marginTop: "-110px",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: -10,
      zIndex: -1,
    },
    "& > *:not(:last-child)": {
      position: "absolute",
      right: "0",
      opacity: 0,
      transform: "translateY(10px)",
      transition: "opacity 0.3s ease, transform 0.3s ease",
      pointerEvents: "none",
    },
    "& > *:nth-child(2)": {
      bottom: "calc(100% - 95px)",
    },
    "& > *:nth-child(1)": {
      bottom: "calc(100% - 50px)",
    },
    "&:hover > *:not(:last-child)": {
      opacity: 1,
      transform: "translateY(0)",
      pointerEvents: "auto",
    },
  },

  toolsButton: {
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    border: "none",
    backgroundColor: (props) => props.theme.COLOR_PRIMARY,
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },

  pasteButton: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    border: "none",
    backgroundColor: "#ff8151cf",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
      backgroundColor: "#ff8151",
    },
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  },

  copyButton: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    border: "none",
    backgroundColor: "#4BCE97",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
      backgroundColor: "#3da881",
    },
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  },
});

export default VncCard;
