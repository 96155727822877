const calculateEdgePosition = (firstNode, secondNode, type) => {
  // measurements
  const firstWidth = firstNode.__rf.width;
  const firstHeight = firstNode.__rf.height;
  const secondWidth = secondNode.__rf.width;
  const secondHeight = secondNode.__rf.height;

  // position
  const firstX = firstNode.__rf.position.x;
  const firstY = firstNode.__rf.position.y;
  const secondX = secondNode.__rf.position.x;
  const secondY = secondNode.__rf.position.y;

  let edgePosition = "top";
  let positionDeviation = 50;

  // console.log(firstNode, secondNode);
  if (secondX - (firstX + firstWidth) > 0) {
    edgePosition = "right";
    if (firstY > secondY + secondHeight) {
      positionDeviation = { x: firstX + firstWidth, y: firstY }; //from top
    } else if (secondY > firstY + firstHeight) {
      positionDeviation = { x: firstX + firstWidth, y: firstY + firstHeight }; //from top
    } else {
      const centerSecond = secondY + secondHeight / 2;
      const topY = firstY - secondHeight / 2;
      const bottomY = firstY + firstHeight + secondHeight / 2;
      const rangeY = bottomY - topY;
      positionDeviation = {
        x: firstX + firstWidth,
        y: (1 - (bottomY - centerSecond) / rangeY) * firstHeight + firstY,
      };
    }
    return { edgePosition, positionDeviation };
  } else if (firstX > secondX + secondWidth) {
    edgePosition = "left";
    if (firstY > secondY + secondHeight) {
      positionDeviation = { x: firstX, y: firstY }; //from top
    } else if (secondY > firstY + firstHeight) {
      positionDeviation = { x: firstX, y: firstY + firstHeight }; //from top
    } else {
      const centerSecond = secondY + secondHeight / 2;
      const topY = firstY - secondHeight / 2;
      const bottomY = firstY + firstHeight + secondHeight / 2;
      const rangeY = bottomY - topY;
      positionDeviation = {
        x: firstX,
        y: (1 - (bottomY - centerSecond) / rangeY) * firstHeight + firstY,
      };
    }
    return { edgePosition, positionDeviation };
  } else {
    if (firstY > secondY - secondHeight) {
      edgePosition = "top";
    } else {
      edgePosition = "bottom";
    }
    const myY = edgePosition === "top" ? firstY : firstY + firstHeight;

    if (firstX > secondX + secondWidth) {
      positionDeviation = { x: firstX, y: myY }; //from left
    } else if (secondX > firstX + firstWidth) {
      positionDeviation = { x: firstX + firstWidth, y: myY }; //from left
    } else {
      const centerSecond = secondX + secondWidth / 2;
      const right = firstX + firstWidth + secondWidth / 2;
      const left = firstX - secondWidth / 2;
      const rangeX = right - left;
      positionDeviation = ((centerSecond - left) / rangeX) * 100;

      positionDeviation = {
        x: ((centerSecond - left) / rangeX) * firstWidth + firstX,
        y: myY,
      };
    }

    return { edgePosition, positionDeviation };
  }
};

export default calculateEdgePosition;
