import {
  ADD_USER,
  REMOVE_USER,
  MESSAGE_SENT,
  MESSAGE_RECEIVED,
  RESET_STATE,
} from "../types/chat";

const initialState = {
  users: [],
  messages: [],
};

const chatReducer = (state = initialState, { type, ...payload }) => {
  const { message, user } = payload;
  switch (type) {
    case ADD_USER:
      return { ...state, users: [...state.users, user] };
    case REMOVE_USER:
      const filteredUsers = state.users.filter((usr) => usr.id !== user.id);
      return { ...state, users: [...filteredUsers] };
    case MESSAGE_SENT:
      return { ...state, messages: [...state.messages, message] };
    case MESSAGE_RECEIVED:
      return { ...state, messages: [...state.messages, message] };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default chatReducer;
