import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Planet } from "react-planet";
import { useSelector } from "react-redux";

import TrainingAvatar from "./TrainingAvatar";
import TrainingMenuIcon from "./TrainingMenuIcon";

function TrainingMenuPlanet({ hover, selectIcon, userImage }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState(0);

useEffect(() => {
    let isComponentMounted = true; // Protection from memory leak on setting unmounted component
  
    fetch("./config.json")
      .then((response) => response.json())
      .then((data) => {
        if (isComponentMounted ) {
          setConfig(data);
        }
      })
      .catch((error) => {
        console.error('Error fetching config:', error);
      });
  
    return () => {
      isComponentMounted  = false;
    };
  }, []);

  const classes = useStyles();

  const { activeMenu: active } = useSelector((state) => state.uiReducer);

  const CYBRING_ICONS_CLOUD = [
    { name: "missions", icon: "menuMission", tooltip: "Missions" },
    // { name: "events", icon: "menuList", tooltip: "Scenario Events" },
    {},
    {},
    {},
    {},
    {},
    {},
    { name: "info", icon: "menuInfo", tooltip: "Info" },
    { name: "chat", icon: "menuChat", tooltip: "Chatroom" },
    { name: "leaderboard", icon: "menuLeaderboard", tooltip: "Leaderboard" },
    // { name: "timeline", icon: "menuTimeline" },
  ];
  const CYBRING_ICONS_LOCAL = [
    { name: "missions", icon: "menuMission", tooltip: "Missions" },
    { name: "network", icon: "networkMenu", tooltip: "Scenario Network" },
    {},
    {},
    {},
    {},
    {},
    {},
    { name: "info", icon: "menuInfo", tooltip: "Info" },
    { name: "chat", icon: "menuChat", tooltip: "Chatroom" },
    { name: "leaderboard", icon: "menuLeaderboard", tooltip: "Leaderboard" },
  ];
  const ISC2_ICONS = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    // { name: "network", icon: "networkMenu", tooltip: "Scenario Network" },
    { name: "missions", icon: "menuMission", tooltip: "Missions" },
    { name: "info", icon: "menuInfo", tooltip: "Info" },
  ];

  const isOpen = open || hover;
  const icons =
    theme.COMPANY_NAME === "CYBRING" && config.ENV_TYPE === "Local"
      ? CYBRING_ICONS_LOCAL
      : theme.COMPANY_NAME === "CYBRING" && config.ENV_TYPE === "Cloud"
      ? CYBRING_ICONS_CLOUD
      : theme.COMPANY_NAME === "ISC2"
      ? ISC2_ICONS
      : CYBRING_ICONS_LOCAL;

  const toggleMenu = () => {
    setOpen((state) => !state);
  };

  return (
    <Planet
      centerContent={<TrainingAvatar userImage={userImage} />}
      hideOrbit
      open={isOpen}
      rotation={80}
      onClick={toggleMenu}
      mass={4}
      friction={50}
      tension={500}
    >
      {icons.map((icon, index) => {
        if (icon?.name === undefined)
          return <div key={index} className={classes.emptyIcon} />;
        return (
          <TrainingMenuIcon
            name={icon.name}
            tooltip={icon.tooltip}
            icon={icon.icon}
            active={active}
            selectIcon={selectIcon}
            key={icon.name}
          />
        );
      })}
    </Planet>
  );
}

const useStyles = makeStyles(() => ({
  emptyIcon: {
    background: "transparent",
  },
}));

export default TrainingMenuPlanet;
