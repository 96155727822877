import React, { useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";

import LeaderboardBadges from "./LeaderboardBadges";

function LeaderboardBadgesContainer({ badges = [], id }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const isOpen = Boolean(anchorEl);

  const classes = useStyles();

  const len = badges.length;
  const myBadges = useMemo(() => {
    if (len < 6) {
      const emptyArr = new Array(6 - len).fill("");
      return [...badges, ...emptyArr];
    }
    if (len > 6) {
      return [...badges.slice(0, 5), "plus-badge"];
    }

    return badges;
  }, [badges]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.badgesContainerWrapper}>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: 100,
        }}
        className={classes.popover}
      >
        <div className={classes.leaderboardBadgesContainerPopup}>
          <LeaderboardBadges badges={badges} setOpen={handleClick} />
        </div>
      </Popover>
      <div className={classes.leaderboardBadgesContainer}>
        <LeaderboardBadges badges={myBadges} setOpen={handleClick} />
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  badgesContainerWrapper: {
    position: "relative",
    width: "120px",
  },
  leaderboardBadgesContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    padding: "3px 0",
  },
  leaderboardBadgesContainerPopup: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "rgba(0,0,0,1)",
    zIndex: 3,
    width: "185px",
    minHeight: "125px",
    maxHeight: "140px",
    padding: "10px",
    border: "solid 1px rgba(255, 255, 255, 0.09)",
    borderRadius: "10px",
  },
  icon: {
    color: "white",
  },
  popover: {
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },
  },
});

export default LeaderboardBadgesContainer;
