import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import PopupCard from "../shared/PopupCard";
import LeaderboardTimelineHeader from "./leaderboard/LeaderboardTimelineHeader";
import TimelineContainer from "./timeline/TimelineContainer";
import LeaderboardContainer from "./leaderboard/LeaderboardContainer";
import { useDispatch, useSelector } from "react-redux";

//omri added to try and fix missing images
// import group1 from "../../../assets/icons/training/leaderboard/users/group-avatar-1.webp";

function LeaderboardTimelineContainer({ closeClick }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const [activeTab, setActiveTab] = useState(0);
  const classes = useStyles({ theme });
  const leaderboard = useSelector((state) => state.leaderboardReducer);

  return (
    <div className={classes.containerRoot}>
      <PopupCard padding="0" size={["100%", "100%"]} isLarge={true}>
        <LeaderboardTimelineHeader
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          closeClick={closeClick}
        />
        <div className={classes.leaderboardBody}>
          {activeTab === 0 ? (
            <LeaderboardContainer />
          ) : (
            <TimelineContainer data={leaderboard} />
          )}
        </div>
      </PopupCard>
    </div>
  );
}

const useStyles = makeStyles({
  containerRoot: {
    fontFamily: (props) => props.theme.MAIN_FONT,
    // maxWidth: "1280px",
    // maxHeight: "640px",
    // minHeight: "750px",
    // minWidth: "900px",
    width: "100%",
    height: "100%",
  },
  leaderboardBody: {
    width: "100%",
    overflow: "auto",
    flex: 1,
  },
});

export default LeaderboardTimelineContainer;
