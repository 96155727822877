export const leaderGoldBadge = [
  "30 30",
  `
  <defs>
  <linearGradient id="zeemz2ld5h" x1="27.643%" x2="72.412%" y1="44.754%" y2="60.285%">
      <stop offset="0%" stop-color="#A9924F"/>
      <stop offset="34.956%" stop-color="#D1C06F"/>
      <stop offset="60.494%" stop-color="#B19B55"/>
      <stop offset="100%" stop-color="#AC9551"/>
  </linearGradient>
  <linearGradient id="kq1fcg65jj" x1="27.643%" x2="72.412%" y1="37.185%" y2="75.127%">
      <stop offset="0%" stop-color="#A9924F"/>
      <stop offset="34.956%" stop-color="#D1C06F"/>
      <stop offset="60.494%" stop-color="#B19B55"/>
      <stop offset="100%" stop-color="#AC9551"/>
  </linearGradient>
  <linearGradient id="dr94c0yy5m" x1="27.643%" x2="73.814%" y1="37.185%" y2="76.315%">
      <stop offset="0%" stop-color="#A9924F"/>
      <stop offset="34.956%" stop-color="#D1C06F"/>
      <stop offset="60.494%" stop-color="#B19B55"/>
      <stop offset="96.964%" stop-color="#AC9551"/>
  </linearGradient>
  <filter id="c171jo13da" width="238.9%" height="246.6%" x="-69.4%" y="-73.3%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation="6.3"/>
  </filter>
  <filter id="0kzh6nnq0b" width="253.6%" height="262.1%" x="-76.8%" y="-81%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation="1"/>
      <feOffset dy="-2" in="shadowBlurInner1" result="shadowOffsetInner1"/>
      <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1"/>
      <feColorMatrix in="shadowInnerInner1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.267313497 0"/>
  </filter>
  <filter id="g3j1i0391e" width="341.6%" height="497.6%" x="-121.2%" y="-199%" filterUnits="objectBoundingBox">
      <feOffset dy="5" in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8.5"/>
      <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.784313725 0 0 0 0 0.71372549 0 0 0 0 0.407843137 0 0 0 1 0"/>
      <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="SourceGraphic"/>
      </feMerge>
  </filter>
  <path id="clze7iq4ji" d="M4.554 2.397L5.397 4.793 18.046 9.746 16.697 19.493 2.193 20.292 1.687 21.57 0 23.168 9.107 27.801 24.286 22.529 23.106 11.824 17.985 2.748 8.095 0z"/>
  <path id="lqu030ckzl" d="M26.218 17.285L29.591 17.285 27.905 0.872 0 0 0.92 5.229 1.533 6.246 2.453 7.117z"/>
  <path id="s1qkevn2so" d="M2.76 0L0 1.017l2.593 7.105c4.124 2.868 7.365 4.506 9.722 4.912 2.357.406 5.381.406 9.074 0l.076-3.157-.307-1.743L8.74 5.664 2.76 0z"/>
  <ellipse id="x30yax1n2c" cx="13.611" cy="12.895" rx="13.611" ry="12.895"/>
  <ellipse id="gsoklm8l0d" cx="13.611" cy="12.895" rx="12.833" ry="12.158"/>
  <radialGradient id="nsfox6m6xg" cx="50%" cy="31.751%" r="82.491%" fx="50%" fy="31.751%" gradientTransform="matrix(.60612 0 0 1 .197 0)">
      <stop offset="0%" stop-color="#C2A85B"/>
      <stop offset="39.506%" stop-color="#B19B55"/>
      <stop offset="65.044%" stop-color="#D1C06F"/>
      <stop offset="100%" stop-color="#B39A53"/>
  </radialGradient>
</defs>
<g fill="none" fill-rule="evenodd">
  <g>
      <g>
          <g transform="translate(-460 -141) translate(460 141) translate(2.593 4.298)">
              <g fill="#000" filter="url(#c171jo13da)">
                  <use filter="url(#0kzh6nnq0b)" xlink:href="#x30yax1n2c"/>
              </g>
              <mask id="vwu7gzl59f" fill="#fff">
                  <use xlink:href="#gsoklm8l0d"/>
              </mask>
              <g fill="url(#nsfox6m6xg)" fill-rule="nonzero" stroke="url(#zeemz2ld5h)" stroke-width=".6" filter="url(#g3j1i0391e)" mask="url(#vwu7gzl59f)">
                  <path d="M36.067 17.187l-12.912-6.19c-.109-.156-.292-.248-.488-.248-.197 0-.38.092-.49.248l-.569.81L18.98 8.07V4.618h1.908l.001 1.528c.023.453.423.808.912.808h3.768c.211 0 .401-.103.508-.277.106-.173.107-.381.002-.555l-.883-1.463c-.005-.008-.005-.019 0-.027l.883-1.463c.105-.174.104-.382-.002-.555-.107-.174-.297-.277-.508-.277h-2.89v-.904c0-.468-.401-.849-.895-.849h-2.804V.557c0-.307-.263-.557-.587-.557h-.617c-.324 0-.587.25-.587.556V8.07l-2.63 3.736-.57-.81c-.108-.155-.291-.247-.488-.247s-.38.092-.489.248L.1 17.924c-.12.171-.131.39-.029.572.102.18.3.293.518.293l12.568 3.132v-.529.53l13.872-2.027c.217 0 8.966-1.955 9.069-2.136.102-.182.09-.4-.03-.572z" transform="translate(-4.472 5.158)"/>
              </g>
          </g>
          <g>
              <g transform="translate(-460 -141) translate(460 141) rotate(31 5.807 28.338)">
                  <mask id="y0ts7lfbrk" fill="#fff">
                      <use xlink:href="#clze7iq4ji"/>
                  </mask>
                  <ellipse cx="9.192" cy="13.661" stroke="url(#kq1fcg65jj)" stroke-width="1.65" mask="url(#y0ts7lfbrk)" rx="12.227" ry="11.584"/>
              </g>
              <g transform="translate(-460 -141) translate(460 141) translate(4.906)">
                  <mask id="weqkoddzyn" fill="#fff">
                      <use xlink:href="#lqu030ckzl"/>
                  </mask>
                  <ellipse cx="11.729" cy="17.212" stroke="url(#dr94c0yy5m)" stroke-width="1.5" mask="url(#weqkoddzyn)" rx="15.409" ry="14.598"/>
              </g>
              <g transform="translate(-460 -141) translate(460 141) translate(0 21.352)">
                  <mask id="ijv6ysudvp" fill="#fff">
                      <use xlink:href="#s1qkevn2so"/>
                  </mask>
                  <ellipse cx="16.635" cy="-4.14" stroke="url(#dr94c0yy5m)" stroke-width="1.5" mask="url(#ijv6ysudvp)" rx="15.409" ry="14.598"/>
              </g>
          </g>
      </g>
  </g>
</g>
`,
];
