import React, { useMemo, memo } from "react";
import { makeStyles } from "@material-ui/core";
import ShadowedText from "../shared/ShadowedText";

function MissionFooterHeaderText({ text, isHint = false, status }) {
  const fontSize = "clamp(14px, 0.55vw, 16px)";

  const classes = useStyles({ isHint, fontSize });

  const headerBody = useMemo(() => {
    if (isHint) {
      const type = status === "active" ? "orange" : "grey";
      return <ShadowedText type={type} size={fontSize} text={text} />;
    }
    return <> {text} </>;
  }, [isHint, status, text]);

  return <div className={classes.headerText}>{headerBody}</div>;
}

const useStyles = makeStyles({
  headerText: {
    opacity: (props) => (props.isHint ? "0.8" : "0.4"),
    marginBottom: "3px",
    minWidth: "40px",
    width: "100%",
    fontSize: (props) => props.fontSize,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    textAlign: "center",
  },
});

export default memo(MissionFooterHeaderText);
