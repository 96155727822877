import {
  SET_MISSIONS,
  UPDATE_MISSION,
  UPDATE_QUESTION,
  SET_ACTIVE_MISSION,
  INCREMENT_CURRENT_HINT,
  FINISHED_MISSION,
  REMOVE_MISSION_POPUP,
  UPDATE_INFORMER,
  RESET_STATE,
} from "../types/missions";

export const setMissions = (payload) => ({
  type: SET_MISSIONS,
  ...payload,
});

export const updateMissions = (mission) => ({
  type: UPDATE_MISSION,
  mission,
});

export const updateQuestions = (question) => ({
  type: UPDATE_QUESTION,
  question,
});

export const setActiveMission = (active) => ({
  type: SET_ACTIVE_MISSION,
  active,
});

export const incrementCurrentHint = (missionId, hint) => ({
  type: INCREMENT_CURRENT_HINT,
  missionId,
  hint,
});

export const finishedMission = (missionId) => ({
  type: FINISHED_MISSION,
  missionId,
});

export const removeMissionPopup = (missionId) => ({
  type: REMOVE_MISSION_POPUP,
  missionId,
});

export const updateInformers = (informer) => ({
  type: UPDATE_INFORMER,
  informer,
});

export const resetMissionsState = () => ({
  type: RESET_STATE,
});
