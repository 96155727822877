export const backButton = [
  "52 52",
  `
<g fill="none" fill-rule="evenodd">
<g>
    <g transform="translate(-149 -175) translate(150 176)">
        <circle cx="25" cy="25" r="25" stroke="#141824"/>
        <path fill="#1B202C" fill-rule="nonzero" d="M25.467 33c.37 0 .729-.153.979-.404l.815-.829c.26-.251.413-.611.413-.982s-.152-.731-.413-.982l-3.185-3.208h7.652c.783 0 1.272-.655 1.272-1.397v-1.396c0-.742-.49-1.397-1.272-1.397h-7.652l3.185-3.197c.26-.262.413-.622.413-.993s-.152-.731-.413-.993l-.815-.807c-.261-.262-.609-.415-.979-.415s-.728.153-.989.415l-7.076 7.092c-.25.262-.402.622-.402.993s.152.731.402.982l7.076 7.114c.261.251.62.404.99.404z"/>
    </g>
</g>
</g>
`,
];
