import React from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";

function MissionHint({ missionHint, openAttachment }) {
  const classes = useStyles();

  const { hint, HintID } = missionHint;

  const handleAttachmentClick = (el) => {
    openAttachment(el?.text, "url", el?.text);
  };

  return (
    <Box width="100%">
      <div className={classes.hintTitle}>{`hint ${HintID}:`}</div>
      <div className={classes.hintDescription}>
        {hint.map((el, index) => {
          if (el?.type === "text") {
            return <div key={index}>{el?.text}</div>;
          }
          let myValue = "";
          if (el?.text && typeof el.text === "string") {
            myValue =
              el.text.length > 30 ? el.text.substring(0, 30) + "..." : el.text;
          }
          return (
            <div
              className={classes.missionUrl}
              key={index}
              onClick={() => handleAttachmentClick(el)}
            >
              {myValue}
            </div>
          );
        })}
      </div>
    </Box>
  );
}

const useStyles = makeStyles({
  hintTitle: {
    fontSize: "clamp(13px, 0.7vw, 20px)",
    fontWeight: 600,
    color: "#ff7b00",
  },
  hintDescription: {
    flex: 1,
    fontSize: "clamp(14px, 0.7vw, 22px)",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "rgba(255, 255, 255, 0.6)",
  },
  missionUrl: {
    cursor: "pointer",
    color: "#FF2166",
  },
});

export default MissionHint;
