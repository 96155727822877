import React, { useEffect, useState } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import interceptor from "./interceptor";
import "react-calendar/dist/Calendar.css";
import "./scss/style.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { setUser } from "./store/actions/user";
import { loginWithAuth0Token, loginWithToken } from "./services/userApi";
import { setUserAgent } from "./store/actions/userAgent";
import Training from "./views/scenarios/Training";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0ProviderWithHistory from "./views/pages/auth/Auth0-provider-with-history";
import { getUserInformation } from "./services/Users";
import { getUserOrganizationData } from "./services/Organizations";
import { setTheme } from "./store/actions/ui";
import { themes } from "./constants/theme/themes";

const logoutUrl = window.location.origin;

export const history = createBrowserHistory();

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
// Pages
const LoginWithAuth0 = React.lazy(() =>
  import("./views/pages/login/LoginWithAuth0")
);

const DEV_Login = React.lazy(() => import("./views/pages/login/Login"));

const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

function App() {
  const [config, setConfig] = useState(null);
  const { theme } = useSelector((state) => state.uiReducer);

  const dispatch = useDispatch();
  const logout = useAuth0();

  const userData = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    fetch("./config.json")
      .then((response) => response.json())
      .then((data) => setConfig(data))
      .catch((error) => {
        console.error("Error fetching config.json:", error);
      });
  }, []);

  useEffect(() => {
    const root = document.documentElement;

    if (root) {
      document.title = theme.COMPANY_DISPLAY_NAME;
      root.style.setProperty("--MAIN_FONT", theme.MAIN_FONT);
      root.style.setProperty("--TRAINING_FONT", theme.TRAINING_FONT);
      root.style.setProperty("--COLOR_PRIMARY", theme.COLOR_PRIMARY);
      root.style.setProperty("--COLOR_BACKGROUND", theme.COLOR_BACKGROUND);
      root.style.setProperty(
        "--COLOR_BACKGROUND_SECONDARY",
        theme.COLOR_BACKGROUND_SECONDARY
      );
    }
  }, [theme]);

  useEffect(() => {
    if (config !== null) {
      dispatch(setTheme(themes[config?.THEME]));
      const resetToken = () => {
        if (config?.AUTH_METHOD === "Auth0") {
          logout({ federated: "true", returnTo: logoutUrl });
        }
        dispatch(setUser({}));
        localStorage.removeItem("token");
        localStorage.clear();
      };

      const handleUserAutoLogin = async (parsedToken) => {
        const { token, email } = parsedToken;

        try {
          const result =
            config?.AUTH_METHOD === "Auth0"
              ? await loginWithAuth0Token(email, token)
              : await loginWithToken(email, token);
          const parsedResults = JSON.parse(result?.data);
          const parsedData = JSON.parse(parsedResults?.data);

          if (parsedData) {
            const userId = parsedData?.id;
            const myUsername = parsedData?.name || "";
            const EULA_Confirmation = parsedData?.EULA_Confirmation || 0;
            dispatch(
              setUser({
                ...parsedData,
                username: myUsername,
                token,
                type: userData.type,
                EULA_Confirmation,
              })
            );

            try {
              const user = await getUserInformation(userId);
              const organization = await getUserOrganizationData(
                user?.data?.organization_id
              );
              const organizationInfo = {
                id: organization?.data.id,
                name: organization?.data.name,
                logo: organization?.data.logo,
              };

              localStorage.setItem(
                "organization_info",
                JSON.stringify(organizationInfo)
              );
            } catch (error) {
              dispatch({ type: "LOAD_PERSONAL_HALL", userId });
            }
            dispatch({ type: "LOAD_PERSONAL_HALL", userId });
            dispatch({ type: "LOAD_MITRE_DB" });
          } else {
            resetToken();
          }
        } catch (error) {
          resetToken();
        }
      };

      const myToken = localStorage.getItem("token");
      const myUserAgent = localStorage.getItem("userAgent");

      if (myToken === null) {
        
        dispatch(setUser({}));
      } else {
        const parsedToken = JSON.parse(myToken);
        handleUserAutoLogin(parsedToken);
      }
      if (myUserAgent === null) {
        dispatch({ type: "GET_USER_AGENT" });
      } else {
        const parsedUserAgent = JSON.parse(myUserAgent);
        dispatch(setUserAgent(parsedUserAgent));
      }
    }
  }, [config]);

  const content = (
    <>
      <HashRouter history={history}>
        <React.Suspense fallback={loading}>
          <Switch>
            {config?.AUTH_METHOD === "Auth0" ? (
              <Route
                exact
                path="/login"
                name="Login Page"
                render={() => <LoginWithAuth0 />}
              />
            ) : (
              <Route
                exact
                path="/login"
                name="Login Page"
                render={() => <DEV_Login />}
              />
            )}

            <Route
              exact
              path="/training"
              name="Training"
              render={() => <Training />}
            />
            <Route
              exact
              path="/self-training"
              name="Training"
              render={() => <Training />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={() => <Page404 />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={() => <Page500 />}
            />
            <Route path="/" name="Home" component={TheLayout} />
          </Switch>
        </React.Suspense>
      </HashRouter>
      <ToastContainer
        limit={1}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );

  if (!config) {
    return null;
  }

  return (
    <>
      {config?.AUTH_METHOD === "Auth0" ? (
        <Auth0ProviderWithHistory config={config}>
          {content}
        </Auth0ProviderWithHistory>
      ) : (
        content
      )}
    </>
  );
}

export default App;