import React from "react";
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export const FormikTextInputDisabled = ({ label, style, ...props }) => {
  const [field, meta] = useField(props);
  const isError = meta.touched && meta.error !== undefined;
  const error = isError ? meta.error : "";
  return (
    <TextField
      {...field}
      className={style}
      error={isError}
      label={label}
      helperText={error}
      type="input"
      disabled
    />
  );
};


export const FormikTextInput = ({ value, label, style, ...props }) => {
  const [field, meta] = useField(props);
  const isError = meta.touched && meta.error !== undefined;
  const error = isError ? meta.error : "";
  if (value) {
    return (
      <TextField
        {...field}
        className={style}
        error={isError}
        label={label}
        helperText={error}
        value={value}
      />
    );
  }
  return (
    <TextField
      {...field}
      className={style}
      error={isError}
      label={label}
      helperText={error}
    />
  );
};

export const PasswordInput = ({
  classes,
  handleClickShowPassword,
  handleMouseDownPassword,
  showPassword,
  handleBlur,
  touched,
  errors,
  name,
  ...props
}) => {
  const [field] = useField(props);
  const isError = touched[name] !== undefined && errors[name] !== undefined;
  const errorText = isError ? errors[name] : "";
  const value = field.value[name];
  const label = name === "passwordTwo" ? "Repeat Password" : "Password";
  return (
    <FormControl className={classes.inputBox}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Input
        id={name}
        type={showPassword ? "text" : "password"}
        {...field}
        value={value}
        error={isError}
        onBlur={handleBlur}
        endAdornment={
          <InputAdornment position="start">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              className={classes.passwordIcon}
            >
              {showPassword ? (
                <Visibility fontSize="small" />
              ) : (
                <VisibilityOff fontSize="small" />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText error={isError}>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FormikTextArea = ({
  label,
  style,
  variant,
  size,
  inputProps = {},
  minRows,
  ...props
}) => {
  const [field, meta] = useField(props);
  const isError = meta.touched && meta.error !== undefined;
  const error = isError ? meta.error : "";
  return (
    <TextField
      {...field}
      className={style}
      label={label}
      error={isError}
      helperText={error}
      variant={variant}
      rows={minRows || 0}
      size={size || "medium"}
      inputProps={inputProps}
      multiline
    />
  );
};

export const MyDateField = ({ field, form }) => {
  const { touched, errors } = form;
  const { name, value, onBlur } = field;
  const myError = () => {
    if (touched[name] && errors[name]) {
      console.log("myError is true");
      return <div>Expiration date must be later than start date</div>;
    }
    return null;
  };
  return (
    <>
      <DatePicker
        selected={(value && new Date(value)) || null}
        name={name}
        onChange={(value) => {
          form.setFieldValue(name, value);
        }}
        onBlur={onBlur}
        showTimeSelect
      />
      {myError()}
    </>
  );
};

// export const SelectField = ({ options, field, form }) => {
//   const handleSelectChange = (option) => {
//     form.setFieldValue(field.name, option.value);
//   };

//   const { setFieldTouched } = form;
//   return (
//     <Select
//       options={options}
//       name={field.name}
//       value={
//         options ? options.find((option) => option.value === field.value) : ""
//       }
//       onChange={(option) => handleSelectChange(option)}
//       onBlur={() => setFieldTouched(field.name)}
//     />
//   );
// };
export const SelectField = ({ options, field, form, label }) => {
  const handleSelectChange = (option) => {
    form.setFieldValue(field.name, option.value);
  };

  const { setFieldTouched } = form;
  return (
    <Select
      style={{ minWidth: "50px" }}
      options={options}
      name={field.name}
      placeholder={label}
      value={
        options ? options.find((option) => option.value === field.value) : ""
      }
      onChange={(option) => handleSelectChange(option)}
      onBlur={() => setFieldTouched(field.name)}
    />
  );
};
