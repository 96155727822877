import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

function HintCounter({ hint = 1, total = 5 }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ theme });

  return <div className={classes.counterRoot}>{`Hint ${hint}/${total}`}</div>;
}

const useStyles = makeStyles(() => ({
  counterRoot: {
    opacity: "0.64",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "15px",
    fontWeight: "600",
    textAlign: "center",
    color: "#ffffff",
  },
}));

export default HintCounter;
