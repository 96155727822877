import { SET_USER } from "../types/user";

const initialState = {
  id: "",
  email: "",
  username: "",
  authorization: "",
  type: "",
};

const userReducer = (state = initialState, action) => {
  const { user } = action;
  switch (action.type) {
    case SET_USER:
      return user;
    default:
      return state;
  }
};

export default userReducer;
