import React, { memo } from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import CIcon from "@coreui/icons-react";
import { useSelector } from "react-redux";

import NotificationsCount from "./NotificationsCount";
import StyledTooltip from "./shared/StyledTooltip";

function TrainingMenuIcon({ active, selectIcon, name, icon, tooltip }) {
  const classes = useStyles();

  const count = useSelector((state) => state.uiReducer[name]) || 0;

  const placement = ["info", "chat"].includes(name) ? "bottom" : "left";

  // const title = name.toUpperCase();

  return (
    <div className={classes.iconWrapper}>
      <div className={classes.notificaionCount}>
        {name === "chat" && <NotificationsCount count={count} />}
      </div>
      <StyledTooltip
        title={tooltip}
        placement={placement}
        enterDelay={800}
        enterNextDelay={200}
      >
        <div>
          <CIcon
            onClick={() => selectIcon(name)}
            className={classes.icon}
            name={`${icon}${active === name ? "Active" : ""}`}
            width="35px"
            height="35px"
          />
        </div>
      </StyledTooltip>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  iconWrapper: {
    position: "relative",
    zIndex: "0!important",
    pointerEvents: "auto",
  },

  notificaionCount: {
    position: "absolute",
    top: "-6px",
    right: "-7px",
  },
  icon: {
    cursor: "pointer",
  },
  tooltip: {
    fontSize: "10px",
  },
}));

function iconPropsAreEqual(prevIcon, nextIcon) {
  return (
    prevIcon.active === nextIcon.active &&
    prevIcon.name === nextIcon.name &&
    prevIcon.icon === nextIcon.icon
  );
}

export default memo(TrainingMenuIcon, iconPropsAreEqual);
