import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@mui/material";

function LeaderboardName({ name }) {
  const classes = useStyles();
  let displayName = name.split("@")[0].slice(0, 22);

  return <div className={classes.name}>{displayName.split("@")[0]}</div>;
}

const useStyles = makeStyles({
  name: {
    textShadow: "0 0 6px rgba(0, 0, 0, 0.35)",
    fontSize: "13px",
    fontWeight: "600",
    lineHeight: "1.15",
    marginLeft: "8px",
  },
});

export default LeaderboardName;
