import {
  SET_LEADERBOARD,
  UPDATE_USER_SCORE,
  RESET_STATE,
} from "../types/leaderboard";

// const initialState = [
//   {
//     id: "1",
//     name: "Jane",
//     picture: "",
//     score: 51000,
//     hints: 2,
//     missions: 10,
//     badges: [
//       "fastestRunBadge",
//       "leaderBronzeBadge",
//       "leaderGoldBadge",
//       "leaderSilverBadge",
//       "perfectRunBadge",
//       "perfectRunBadge",
//       "perfectRunBadge",
//     ],
//   },
//   {
//     id: "2",
//     name: "Victor",
//     picture: "",
//     score: 46200,
//     hints: 3,
//     missions: 9,
//     badges: [
//       "fastestRunBadge",
//       "leaderBronzeBadge",
//       "leaderGoldBadge",
//       "perfectRunBadge",
//     ],
//   },
//   {
//     id: "3",
//     name: "elad",
//     picture: "",
//     score: 38800,
//     hints: 4,
//     missions: 9,
//     badges: ["fastestRunBadge"],
//   },
//   {
//     id: "4",
//     name: "Quinn",
//     picture: "",
//     score: 33400,
//     hints: 4,
//     missions: 8,
//     badges: ["fastestRunBadge"],
//   },
//   {
//     id: "5",
//     name: "Adam",
//     picture: "",
//     score: 31600,
//     hints: 5,
//     missions: 7,
//     badges: [
//       "fastestRunBadge",
//       "leaderBronzeBadge",
//       "leaderGoldBadge",
//       "perfectRunBadge",
//     ],
//   },
//   {
//     id: "6",
//     name: "Charley",
//     picture: "",
//     score: 30800,
//     hints: 6,
//     missions: 7,
//     badges: ["fastestRunBadge"],
//   },
//   {
//     id: "7",
//     name: "LeonaA",
//     picture: "",
//     score: 22300,
//     hints: 4,
//     missions: 5,
//     badges: ["perfectRunBadge"],
//   },
//   {
//     id: "8",
//     name: "Sunny",
//     picture: "",
//     score: 17800,
//     hints: 4,
//     missions: 3,
//     badges: ["perfectRunBadge", "perfectRunBadge"],
//   },
//   {
//     id: "9",
//     name: "CyberKing",
//     picture: "",
//     score: 14300,
//     hints: 4,
//     missions: 3,
//     badges: ["perfectRunBadge", "leaderGoldBadge"],
//   },
//   {
//     id: "10",
//     name: "Omri",
//     picture: "",
//     score: 12800,
//     hints: 4,
//     missions: 3,
//     badges: ["perfectRunBadge"],
//   },
// ];

// const initialState = [
//   {
//     id: "1",
//     name: "elad",
//     avatarId: 100,
//     score: 1000,
//     hints: 0,
//     missions: 0,
//     badges: [],
//   },
//   {
//     id: "2",
//     name: "dani",
//     avatarId: 100,
//     score: 1000,
//     hints: 0,
//     missions: 0,
//     badges: [],
//   },
// ];
const initialState = [];

const leaderboardReducer = (state = initialState, action) => {
  const { leaderboard, user, newScore, type } = action;

  switch (type) {
    case SET_LEADERBOARD:
      return leaderboard;
    case UPDATE_USER_SCORE:
      const index = state.findIndex((el) => el.id === user);
      if (index !== -1) {
        return { ...state, [index]: { ...state[index], score: newScore } };
      } else {
        return state;
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
export default leaderboardReducer;
