import { SET_QUESTION, REMOVE_QUESTION, RESET_STATE } from "../types/quiz";

export const setQuestion = (question) => {
  let questionType = "open";
  if (question?.options?.length > 1) {
    questionType = "options";
  }
  return { type: SET_QUESTION, question: { ...question, type: questionType } };
};

export const removeQuestion = (id) => {
  return { type: REMOVE_QUESTION, id };
};

export const resetQuestionsState = () => ({
  type: RESET_STATE,
});
