import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import CIcon from "@coreui/icons-react";
import TextField from "@material-ui/core/TextField";

function ChatMessageBox({ sendMessage }) {
  const [message, setMessage] = useState("");

  const handleSend = () => {
    if (message) {
      sendMessage(message);
      setMessage("");
    }
  };

  const handleChatChange = (e) => {
    const { value } = e.target;
    setMessage(value);
  };

  const handleKeyDown = (e) => {
    const { keyCode } = e;
    if (keyCode === 13) {
      e.preventDefault();
      handleSend();
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.boxRoot}>
      <TextField
        id="chatbox-multiline"
        multiline
        rows={6}
        rowsMax={2}
        inputProps={{
          maxLength: 300,
          className: classes.input,
          "aria-label": "chat-box",
        }}
        InputProps={{ disableUnderline: true }}
        placeholder="Your message..."
        onChange={handleChatChange}
        onKeyDown={handleKeyDown}
        value={message}
      />

      <CIcon
        name="attachment"
        width="16px"
        height="16px"
        className={classes.icon}
      />

      <CIcon
        name="sendMessage"
        width="25px"
        height="25px"
        className={classes.icon}
        onClick={handleSend}
      />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  boxRoot: {
    height: "51px",
    width: "250px",
    backgroundColor: "rgba(0,0,0,0)",
    boxShadow: "inset 0 -1px 12px 0 rgba(0,0,0,0.27)",
    padding: "7px 7px 11px 10px",
    borderTop: "solid 1px rgba(255, 255, 255, 0.09)",
    borderRadius: "0 0 10px 10px",
    WebkitBackdropFilter: "blur(5px)",
    backdropFilter: "blur(5px)",
  },
  input: {
    fontFamily: "NunitoSans",
    fontSize: "13px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    width: "168px",
  },
  icon: {
    margin: "8px 7px 0 5px",
    cursor: "pointer",
  },
}));

export default ChatMessageBox;
