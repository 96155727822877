import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";

function Info({ value, openAttachment }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ theme });

  const handleAttachmentClick = () => {
    openAttachment(value, "url", value);
  };

  let myValue = "";
  if (value && typeof value === "string") {
    myValue = value.length > 30 ? value.substring(0, 30) + "..." : value;
  }

  return (
    <Box
      display="flex"
      width="100%"
      // borderBottom="1px solid rgba(255,255,255,0.05)"
      padding="7px 8px"
      alignItems="center"
      className={classes.details}
      onClick={handleAttachmentClick}
    >
      {myValue}
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  details: {
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "14px",
    fontWeight: "500",
    cursor: "pointer",
    color: "#FF2166",
  },
}));

export default Info;
