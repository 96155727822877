import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import CIcon from "@coreui/icons-react";
import Grid from "@material-ui/core/Grid";

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 10,
    label: "1",
  },
  {
    value: 20,
    label: "2",
  },
  {
    value: 30,
    label: "3",
  },
  {
    value: 40,
    label: "4",
  },
  {
    value: 50,
    label: "5",
  },
  {
    value: 60,
    label: "6",
  },
  {
    value: 70,
    label: "7",
  },
  {
    value: 80,
    label: "8",
  },
  {
    value: 90,
    label: "9",
  },
  {
    value: 100,
    label: "10",
  },
];

const IOSSlider = withStyles({
  root: {
    width: "310px",
    height: "9px",
    margin: "6px 10px",
    padding: "0 66px 0 0",
    borderRadius: "19px",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
  },
  thumb: {
    height: 13,
    width: 13,
    marginTop: "-2px",
    borderRadius: "50%",
    border: "solid 1px #20c381",
    backgroundColor: "rgba(255, 255, 255, 0.95)",
    "&:focus, &:hover": {
      boxShadow: "0 0 6px 0 #20c381",
    },
  },
  active: {},
  track: {
    height: 9,
    boxShadow: "0 0 6px 0 #20c381",
    backgroundColor: "rgba(255, 255, 255, 0.95)",
    borderRadius: "19px",
  },
  rail: {
    height: 9,
    opacity: 0.5,
    backgroundColor: "transparent",
  },
  mark: {
    backgroundColor: "transparent",
  },
  markLabel: {
    fontFamily: "Rajdhani",
    fontSize: "11px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
    opacity: 0.4,
  },
})(Slider);

export default function FeedbackSlider({ handleSliderChange }) {
  return (
    <Grid container spacing={2} justify="center">
      <Grid item>
        <CIcon name="negativeEmoji" width="22px" height="22px" />
      </Grid>
      <Grid item>
        <IOSSlider
          aria-label="feedback slider"
          defaultValue={0}
          step={10}
          marks={marks}
          onChange={handleSliderChange}
        />
      </Grid>
      <Grid item>
        <CIcon name="positiveEmoji" width="22px" height="22px" />
      </Grid>
    </Grid>
  );
}
