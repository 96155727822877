export const nounQuiz = [
  "55 55",
  `
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Cybering-UI---Scenarios" transform="translate(-24.000000, -148.000000)" fill="#FFFFFF" fill-rule="nonzero">
          <g id="Group-4" transform="translate(0.000000, 50.000000)">
              <g id="Group-5-Copy" transform="translate(0.000000, 77.000000)">
                  <g id="Group" transform="translate(24.000000, 21.000000)">
                      <path d="M27.792,27.972 C28.224,27.756 28.548,27.324 28.548,26.784 L28.548,9.504 C28.548,8.496 27.72,7.668 26.712,7.668 L21.024,7.668 C19.692,3.348 15.624,0.18 10.872,0.18 C5.004,0.18 0.252,4.932 0.252,10.8 C0.252,15.66 3.528,19.764 7.992,21.024 L7.992,29.952 L7.992,29.952 C7.992,29.952 7.992,29.952 7.992,29.952 C7.992,32.4 9.972,34.38 12.42,34.38 L27.216,34.38 C27.828,34.38 28.332,33.984 28.476,33.372 C28.62,32.796 28.332,32.184 27.792,31.896 C27.036,31.5 26.568,30.78 26.568,29.916 C26.64,29.088 27.072,28.368 27.792,27.972 Z M2.412,10.8 C2.412,6.12 6.228,2.34 10.872,2.34 C15.552,2.34 19.332,6.156 19.332,10.8 C19.332,15.444 15.552,19.26 10.872,19.26 C6.192,19.26 2.412,15.48 2.412,10.8 Z M24.372,31.788 L12.42,31.788 C11.412,31.788 10.584,30.96 10.584,29.952 C10.584,28.944 11.412,28.116 12.42,28.116 L24.372,28.116 C24.156,28.692 24.012,29.304 24.012,29.952 C24.012,30.6 24.156,31.212 24.372,31.788 Z" id="Shape"></path>
                      <path d="M12.42,7.776 C12.636,8.208 12.6,8.712 12.348,9.072 C12.06,9.468 11.808,9.648 11.484,9.864 C10.728,10.404 10.08,10.98 9.936,12.564 C9.936,12.744 9.972,12.924 10.116,13.068 C10.224,13.212 10.404,13.284 10.584,13.284 L11.52,13.284 C11.844,13.284 12.132,13.032 12.168,12.672 C12.204,12.024 12.348,11.952 12.78,11.664 C13.14,11.412 13.644,11.052 14.148,10.404 C14.904,9.36 15.012,7.956 14.436,6.804 C13.788,5.544 12.492,4.788 10.98,4.788 C8.424,4.788 7.272,6.624 7.056,8.208 C7.02,8.388 7.092,8.568 7.2,8.712 C7.344,8.856 7.524,8.928 7.704,8.928 L8.604,8.928 C8.928,8.928 9.18,8.712 9.252,8.388 C9.36,7.74 9.756,6.984 10.98,6.984 C11.88,6.948 12.276,7.452 12.42,7.776 Z" id="Path"></path>
                      <circle id="Oval" cx="11.052" cy="15.516" r="1.332"></circle>
                  </g>
              </g>
          </g>
      </g>
  </g>
`,
];
