import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

function AxisLabel({ text, isVertical = false }) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="center"
      className={classes.axisLabel}
      paddingLeft={isVertical ? "0" : "50px"}
      paddingBottom={isVertical ? "50px" : "0"}
    >
      <div className={isVertical ? classes.verticalWrapper : ""}>
        <div className={isVertical ? classes.verticalLabel : ""}>{text}</div>
      </div>
    </Box>
  );
}

const useStyles = makeStyles({
  axisLabel: {
    fontFamily: "NunitoSans",
    fontSize: "13px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2.25",
    letterSpacing: "0.24px",
    color: "#ffffff",
  },
  verticalLabel: {
    WebkitTransform: "rotate(-90deg) translate(-50%, -40%)",
    transform: "rotate(-90deg) translate(-50%, -40%)",
    position: "absolute",
    top: "40%",
    left: "50%",
  },
  verticalWrapper: {
    position: "relative",
    paddingRight: "15px",
  },
});

export default AxisLabel;
