const urlRegex =
  /(\b(https?|http?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
const myRegex = new RegExp(urlRegex);

const exactArr = ["https", "http", "ftp", "file"];

const highlightUrl = (text = "", linkData) => {
  const urlArr = [];
  const textArr = text.split(myRegex).map((el) => {
    if (!exactArr.includes(el)) {
      let type = "text";
      if (linkData !== undefined) {
        if (urlArr.findIndex(object => {
          return object.value === linkData.value}) === -1)
        {
          urlArr.push({ component: "link", ...linkData });
        }
      } else if (myRegex.test(el)) {
        type = "url";
        urlArr.push({ component: "text", value: el });
      }
      return { type, text: el };
    }
  });
  return { urlArr, textArr };
};

export default highlightUrl;
