const networkFlowBuilder = (devices, connections) => {
  const elements = [];
  const usedConnections = {};

  const getSourceIpByConnection = (connection) => {
    const device = devices.find(
      (device) =>
        device.DeviceID === connection.d1 && device.Type === "FIREWALL"
    );
    if (device) {
      if (device.Legs) {
        const ip = device.Legs.find(
          (leg) => leg.name === connection.d1_ExitHandle
        );
        if (ip) {
          return ip.IpRange;
        }
      }
    }
  };
  const getSourceWWWByConnection = (connection) => {
    const device = devices.find(
      (device) => device.DeviceID === connection.d1 && device.Name === "WWW"
    );

    if (device) {
      return true;
    }
    return false;
  };

  for (let device of devices) {
    const { DeviceID, Description, PlaceX, PlaceY, Name, Type } = device;

    const singleComp = {
      id: `${DeviceID}`,
      type: "special",
      data: { label: Name, Name, device },
      position: { x: PlaceX, y: PlaceY },
    };
    elements.push(singleComp);
  }

  for (let connection of connections) {
    console.log("connection", connection);
    const { d1, d1_ExitHandle, d2 } = connection;
    const sourceIp = getSourceIpByConnection(connection);
    const isWWW = getSourceWWWByConnection(connection);

    const name = `${d1}_${d1_ExitHandle}-${d2}`;
    if (usedConnections[name] === undefined) {
      usedConnections[name] = name;
      const singleConn = {
        id: `${d1}_${d1_ExitHandle}_${d2}`,
        source: String(d1),
        target: String(d2),

        type: isWWW ? "round" : "straight",
        animated: isWWW ? true : false,
        label: sourceIp || null,
        labelBgBorderRadius: 8,
        arrowHeadType: "arrowclosed",
        sourceHandle: d1,
        sourceHandle: d1_ExitHandle,
        targetHandle: `target-${d2}-${d1}`,
      };
      elements.push(singleConn);
    }
  }

  return elements;
};

export default networkFlowBuilder;
