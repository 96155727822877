import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { setActiveMenu, setModalState } from "../../store/actions/ui";
import PopupCard from "../training/shared/PopupCard";
import NetworkViewHeader from "../training/shared/NetworkViewHeader";
import NetworkViewerAnimated from "../base/flowChart/NetworkViewerAnimated";
import { ReactFlowProvider } from "react-flow-renderer";
import Grid from "@material-ui/core/Grid";

function NetworkViewContainer({ networkFlowChart }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const closeClick = () => {
    dispatch(setModalState(false, ""));
    dispatch(setActiveMenu(""));
  };

  return (
    <div>
      <PopupCard padding="0" size={["70vw", "70vh"]} isLarge={true}>
        <NetworkViewHeader closeClick={closeClick} />
        <Grid container className={classes.previewBody}>
          <Grid item sm={12} className={classes.flowGraph}>
            {networkFlowChart ? (
              <ReactFlowProvider>
                <NetworkViewerAnimated chartData={networkFlowChart} />
              </ReactFlowProvider>
            ) : (
              "Loading..."
            )}
          </Grid>
        </Grid>
      </PopupCard>
    </div>
  );
}

const useStyles = makeStyles({
  leaderboardRoot: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    border: "solid 1px rgba(255, 255, 255, 0.09)",
    borderRadius: "10px",
    WebkitBackdropFilter: "blur(5px)",
    backdropFilter: "blur(5px)",
    boxShadow: "0 0 2px 0 rgba(255, 255, 255, 0.35)",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  icon: {
    cursor: "pointer",
    color: "white",
  },
  networkChart: {
    width: "100%",
  },
  previewBody: {
    height: "100%",
    padding: "5px",
  },
  flowGraph: {
    height: "100%",
    padding: "10px",
  },
});

export default NetworkViewContainer;
