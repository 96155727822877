export const leaderboardBadgeFrame = [
  "38 34",
  `
  <defs>
        <filter id="85tav2rkqb" width="170.1%" height="193.5%" x="-35.1%" y="-46.7%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".673"/>
        </filter>
        <filter id="3g56xi84ld" width="175%" height="200%" x="-37.5%" y="-50%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
        </filter>
        <filter id="dwgmciswre" width="175%" height="200%" x="-37.5%" y="-50%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
        </filter>
        <filter id="zhyn4znanf" width="175%" height="200%" x="-37.5%" y="-50%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
        </filter>
        <filter id="ehikogk52h" width="137.4%" height="193.5%" x="-18.7%" y="-46.7%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".673"/>
        </filter>
        <filter id="12pmth0cli" width="200.1%" height="199.4%" x="-50.1%" y="-46.3%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius=".673" result="shadowSpreadOuter1"/>
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feMorphology in="SourceAlpha" radius="1" result="shadowInner"/>
            <feOffset dy="1" in="shadowInner" result="shadowInner"/>
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4.5"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.922818065 0 0 0 0 0.425234497 0 0 0 0 0 0 0 0 1 0"/>
        </filter>
        <filter id="mubixvrc5k" width="191.1%" height="216.5%" x="-45.3%" y="-54.3%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius=".673" result="shadowSpreadOuter1"/>
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feMorphology in="SourceAlpha" radius="1" result="shadowInner"/>
            <feOffset dy="1" in="shadowInner" result="shadowInner"/>
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4.5"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.922818065 0 0 0 0 0.425234497 0 0 0 0 0 0 0 0 1 0"/>
        </filter>
        <filter id="y1gkj93j8n" width="121.2%" height="148.1%" x="-10.3%" y="-24%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.019"/>
        </filter>
        <filter id="mfrcwbnm5o">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.929412 0 0 0 0 0.086275 0 0 0 0 0.301961 0 0 0 1.000000 0"/>
        </filter>
        <filter id="2j9vqhhmjq" width="155.5%" height="170.6%" x="-27.7%" y="-35.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".673"/>
        </filter>
        <filter id="qtq4rsqfyr" width="155.5%" height="170.6%" x="-27.7%" y="-35.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".673"/>
        </filter>
        <filter id="wf9eh60qus" width="273.4%" height="277.5%" x="-86.8%" y="-82.7%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius=".673" result="shadowSpreadOuter1"/>
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4.5"/>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.922818065 0 0 0 0 0.425234497 0 0 0 0 0 0 0 0 1 0"/>
        </filter>
        <filter id="s8wwfva2pv" width="767%" height="767%" x="-333.5%" y="-310.8%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius=".673" result="shadowSpreadOuter1"/>
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4.5"/>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.922818065 0 0 0 0 0.425234497 0 0 0 0 0 0 0 0 1 0"/>
        </filter>
        <radialGradient id="umd0eyr47a" cx="50%" cy="50%" r="50%" fx="50%" fy="50%" gradientTransform="matrix(0 1 -.75 0 .875 0)">
            <stop offset="0%" stop-color="#FF8E3D"/>
            <stop offset="100%" stop-color="#FA7200"/>
        </radialGradient>
        <radialGradient id="fy0zl4io0c" cx="50%" cy="50%" r="50%" fx="50%" fy="50%" gradientTransform="matrix(0 1 -.75 0 .875 0)">
            <stop offset="0%" stop-color="#ED154C" stop-opacity=".11"/>
            <stop offset="100%" stop-color="#ED164D" stop-opacity=".63"/>
        </radialGradient>
        <radialGradient id="ac9xc1233g" cx="50%" cy="50%" r="50%" fx="50%" fy="50%" gradientTransform="matrix(0 1 -.4 0 .7 0)">
            <stop offset="0%" stop-color="#FF8E3D"/>
            <stop offset="100%" stop-color="#FA7200"/>
        </radialGradient>
        <radialGradient id="p0qbpfw7im" cx="50%" cy="56.285%" r="43.468%" fx="50%" fy="56.285%" gradientTransform="matrix(0 1 -.67308 0 .879 .063)">
            <stop offset="0%" stop-color="#EB7928"/>
            <stop offset="100%" stop-color="#EB7928" stop-opacity=".28"/>
        </radialGradient>
        <radialGradient id="3vilfe2clp" cx="50%" cy="50%" r="50%" fx="50%" fy="50%" gradientTransform="matrix(0 1 -.78571 0 .893 0)">
            <stop offset="0%" stop-color="#FF8E3D"/>
            <stop offset="100%" stop-color="#FA7200"/>
        </radialGradient>
        <path id="1q6tzmnwfj" d="M10.56 2.16h16.32c2.688 0 4.903 2.11 5.034 4.794l.948 19.44c.136 2.78-2.008 5.144-4.788 5.28-.082.004-.164.006-.246.006H9.612c-2.784 0-5.04-2.256-5.04-5.04 0-.082.002-.164.006-.246l.948-19.44C5.657 4.27 7.872 2.16 10.56 2.16z"/>
        <path id="h9mq80en3l" d="M11.002 2.16H26.62c2.784 0 5.04 2.256 5.04 5.04 0 .423-.053.845-.159 1.255l-3.888 15.12c-.573 2.228-2.58 3.785-4.881 3.785h-7.41c-2.25 0-4.228-1.492-4.846-3.655l-4.32-15.12c-.765-2.677.785-5.466 3.461-6.231.45-.129.916-.194 1.385-.194z"/>
        <path id="qeuane6let" d="M16.763.139C16.713.053 16.62 0 16.52 0h-5.04c-.1 0-.191.052-.241.136L8.4 4.882 5.561.136C5.511.052 5.42 0 5.32 0H.28C.18 0 .087.053.037.139c-.05.085-.05.19.002.276l4.413 7.38c-1.922 2.093-1.811 5.308.25 7.269 2.063 1.96 5.334 1.96 7.396 0 2.062-1.96 2.173-5.176.25-7.27L16.761.415c.051-.085.052-.19.002-.276zM.77.55h4.39l2.998 5.012v.002l.268.447.029.047H8.43c-.073 0-.147.003-.221.005l-.084.003c-.104.005-.21.013-.319.024-.144.016-.287.038-.429.066-.048.009-.095.022-.143.032-.093.021-.186.042-.278.067-.056.016-.11.035-.165.052-.083.026-.165.052-.246.082-.057.02-.113.044-.17.067-.077.031-.154.064-.23.1-.056.025-.11.052-.166.08-.074.038-.148.077-.22.118-.054.03-.107.06-.16.092-.071.044-.142.09-.212.138-.05.034-.1.067-.148.102-.07.052-.14.106-.208.161-.044.036-.09.07-.132.108l-.04.032L.769.551zm12.39 10.74c-.001 2.517-2.025 4.582-4.582 4.676-2.556.094-4.73-1.818-4.922-4.327-.191-2.51 1.669-4.719 4.21-5.001.178-.02.356-.029.534-.03.042 0 .087.007.13.008.132.003.263.007.402.022.405.044.802.14 1.181.285.637.243 1.213.616 1.69 1.096.873.873 1.36 2.048 1.357 3.272zm-1.219-3.903l-.04-.032c-.04-.035-.081-.067-.122-.1-.07-.057-.141-.113-.214-.165-.045-.033-.091-.065-.137-.096-.073-.05-.146-.097-.221-.143-.047-.03-.095-.058-.143-.085-.078-.045-.158-.087-.239-.128l-.139-.07c-.092-.043-.186-.082-.28-.12l-.11-.046c-.134-.05-.268-.095-.405-.134-.021-.007-.043-.01-.064-.017-.115-.031-.231-.06-.349-.084-.049-.01-.098-.017-.148-.026-.065-.01-.13-.025-.196-.034l-.409-.683L11.64.55h4.39l-4.089 6.837z"/>
        <linearGradient id="idqv9nu7tu" x1="50%" x2="50%" y1="19.351%" y2="100%">
            <stop offset="0%" stop-color="#FF852E"/>
            <stop offset="100%" stop-color="#EB7826"/>
        </linearGradient>
        <linearGradient id="nxcfxaf7rx" x1="50%" x2="50%" y1="19.351%" y2="100%">
            <stop offset="0%" stop-color="#FF852E"/>
            <stop offset="100%" stop-color="#EB7826"/>
        </linearGradient>
        <circle id="83zfw4mhxw" cx="8.4" cy="11.36" r="2.2"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g transform="translate(-741 -126) translate(741 126)">
                    <ellipse cx="16.56" cy="2.16" fill="url(#umd0eyr47a)" filter="url(#85tav2rkqb)" opacity=".265" rx="2.88" ry="2.16"/>
                    <ellipse cx="18" cy="10.08" fill="url(#fy0zl4io0c)" filter="url(#3g56xi84ld)" opacity=".462" rx="2.88" ry="2.16"/>
                    <ellipse cx="19.44" cy="18" fill="url(#fy0zl4io0c)" filter="url(#dwgmciswre)" opacity=".462" rx="2.88" ry="2.16"/>
                    <ellipse cx="25.2" cy="26.64" fill="url(#fy0zl4io0c)" filter="url(#zhyn4znanf)" opacity=".265" rx="2.88" ry="2.16"/>
                    <ellipse cx="18.36" cy="31.68" fill="url(#ac9xc1233g)" filter="url(#ehikogk52h)" opacity=".265" rx="5.4" ry="2.16"/>
                    <g>
                        <use fill="#000" filter="url(#12pmth0cli)" xlink:href="#1q6tzmnwfj"/>
                        <use stroke="#EB7928" stroke-width="1.346" xlink:href="#1q6tzmnwfj"/>
                    </g>
                    <g>
                        <use fill="#000" filter="url(#mubixvrc5k)" xlink:href="#h9mq80en3l"/>
                        <use stroke="#EB7928" stroke-width="1.346" xlink:href="#h9mq80en3l"/>
                    </g>
                    <path fill="url(#p0qbpfw7im)" d="M7.248 2.16h23.193c2.784 0 5.04 2.256 5.04 5.04 0 .543-.088 1.083-.26 1.598l-5.054 15.12c-.688 2.056-2.613 3.442-4.78 3.442H12.864c-2.106 0-3.99-1.31-4.724-3.285L2.524 8.955c-.97-2.61.36-5.51 2.97-6.48.561-.208 1.155-.315 1.754-.315z" filter="url(#y1gkj93j8n)" opacity=".7"/>
                    <g filter="url(#mfrcwbnm5o)" opacity=".265">
                        <g fill="url(#3vilfe2clp)" transform="translate(13.36 5.12)">
                            <ellipse cx="4.32" cy="3.1" filter="url(#2j9vqhhmjq)" rx="3.64" ry="2.86"/>
                            <ellipse cx="6.4" cy="13.5" filter="url(#qtq4rsqfyr)" rx="3.64" ry="2.86"/>
                        </g>
                    </g>
                </g>
                <g>
                    <g transform="translate(-741 -126) translate(741 126) translate(10.4 5.6)">
                        <use fill="#000" filter="url(#wf9eh60qus)" xlink:href="#qeuane6let"/>
                        <use fill="url(#idqv9nu7tu)" stroke="url(#idqv9nu7tu)" stroke-width="1.346" xlink:href="#qeuane6let"/>
                    </g>
                    <g transform="translate(-741 -126) translate(741 126) translate(10.4 5.6)">
                        <use fill="#000" filter="url(#s8wwfva2pv)" xlink:href="#83zfw4mhxw"/>
                        <use fill="url(#nxcfxaf7rx)" stroke="url(#nxcfxaf7rx)" stroke-width="1.346" xlink:href="#83zfw4mhxw"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
  `,
];
