import React, { useCallback, useEffect } from "react";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core";
import AttachmentButton from "../../buttons/AttachmentButton";
import FileView from "./FileViewer";
import { useSelector } from "react-redux";

function MissionBody({
  missionBody = [],
  openAttachment,
  missionId,
  children,
  isPopup = false,
  sliderRef,
  setCurrentSlide,
  isDebrief = false,
  isFloatingPopup = false,
}) {
  const { fontSize } = useSelector((state) => state.uiReducer);
  const { theme } = useSelector((state) => state.uiReducer);
  const scenarioListItem = useSelector((state) => state.scenarioItemReducer);
  const missionFontSize =
    fontSize === 1 ? "18px" : fontSize === 2 ? "22px" : "24px";
  const classes = useStyles({ isPopup, isDebrief, missionFontSize, theme });

  const settings = {
    dots: !isPopup,
    infinite: false,
    arrows: false,
  };

  useEffect(() => {
    // Check if informer has one page with one media file ONLY, to automatically expand the media window.
    if (isFloatingPopup) {
      if (
        missionBody &&
        missionBody.length === 1 &&
        missionBody[0].length === 1
      ) {
        const { type, subtype, display, value, openNewTab } = missionBody[0][0];
        if (!openNewTab) {
          if (type === "link" || type === "view") {
            openAttachment(display, subtype, value);
          }
        }
      }
    }
  }, [scenarioListItem]);

  const handleSlideChange = useCallback(
    (current, next) => {
      if (isPopup) {
        setCurrentSlide(next);
      }
    },
    [isPopup]
  );

  const openAttachmentInNewTab = (value) => {
    window.open(value, "_blank", "noreferrer");
  };
  return (
    <>
      <div className={classes.root}>
        <Slider
          {...settings}
          ref={isPopup ? sliderRef : null}
          beforeChange={handleSlideChange}
        >
          {missionBody?.map((missionArr, idx) => {
            return (
              <div key={`mission-body-${idx}`} className={classes.missionSlide}>
                {missionArr?.map((mission, index) => {
                  const { type, subtype, display, value, openNewTab } = mission;
                  if (openNewTab) {
                    return (
                      <div key={`${index}`}>
                        <AttachmentButton
                          openAttachment={() => openAttachmentInNewTab(value)}
                          name={display}
                        />
                      </div>
                    );
                  }
                  try {
                    let isValueArray = false;
                    switch (type) {
                      case "text":
                        isValueArray = Array.isArray(value);
                        const textArray = isValueArray
                          ? value
                          : [{ type: "text", text: value }];
                        return (
                          <Slider>
                            <div
                              className={classes.missionDescription}
                              key={`text-${missionId || "no-id"}-${index}`}
                            >
                              {textArray?.map((el, index) => {
                                if (el?.type === "text") {
                                  if (isDebrief) {
                                    if (el?.text.includes("###")) {
                                      const dynamicIpPattern =
                                        /\@\[.*?\]\([#@]{3}(\d+)[#@]{3}\)/g;
                                      el.text = el.text.replace(
                                        dynamicIpPattern,
                                        " <Dynamic IP>"
                                      );
                                    }
                                  }
                                  const textValue = el?.text
                                    ? el?.text
                                    : isValueArray
                                    ? ""
                                    : value;
                                  return (
                                    <div className={classes.text} key={index}>
                                      {textValue}
                                    </div>
                                  );
                                }

                                let myValue = "";
                                if (el?.text && typeof el.text === "string") {
                                  myValue =
                                    el.text.length > 30
                                      ? el.text.substring(0, 30) + "..."
                                      : el.text;
                                }

                                return (
                                  <div
                                    className={classes.missionUrl}
                                    key={`${index}, index`}
                                    onClick={() =>
                                      openAttachment(el?.text, "url", el?.text)
                                    }
                                  >
                                    {myValue}
                                  </div>
                                );
                              })}
                            </div>
                          </Slider>
                        );
                      case "view":
                        if (subtype === "url") {
                          return (
                            <div
                              key={`view-${
                                missionId || "no-id"
                              }-${index}, idChanger`}
                            >
                              <AttachmentButton
                                openAttachment={() =>
                                  openAttachment(display, subtype, value)
                                }
                                name={display}
                              />
                            </div>
                          );
                        } else if (subtype === "pdf") {
                          return (
                            <div
                              key={`view-${
                                missionId || "no-id"
                              }-${index}, idChanger2`}
                              onClick={() =>
                                openAttachment(display, subtype, value)
                              }
                            >
                              {isPopup ? (
                                <FileView
                                  isPreview={true}
                                  type={subtype}
                                  value={value}
                                  name={display}
                                />
                              ) : (
                                <AttachmentButton
                                  openAttachment={() =>
                                    openAttachment(display, subtype, value)
                                  }
                                  name={display}
                                />
                              )}
                            </div>
                          );
                        }
                        return (
                          <div key={`view-${missionId || "no-id"}-${index}`}>
                            <img
                              height={isPopup ? "auto" : "80px"}
                              width="auto"
                              src={value}
                              className={classes.viewImage}
                              onClick={() =>
                                openAttachment(display, subtype, value)
                              }
                            />
                          </div>
                        );
                      case "link":
                        return (
                          <div key={`link-${missionId || "no-id"}-${index}`}>
                            <AttachmentButton
                              openAttachment={() =>
                                openAttachment(display, subtype, value)
                              }
                              name={display}
                            />
                          </div>
                        );
                      default:
                        return <div key={`emptyu-${index}`} />;
                    }
                  } catch (e) {
                    console.error(e);
                  }
                })}
              </div>
            );
          })}
        </Slider>
        {children}
      </div>
    </>
  );
}

const useStyles = makeStyles({
  root: {
    paddingRight: "15px",
    width: "100%",
    height: "100%",
    marginBottom: "15px",
    "& .slick-dots": {
      left: "-15px",
      bottom: (props) => (props.isPopup ? "-15px" : "-24px"),
    },
  },
  missionDescription: {
    fontFamily: (props) => props.theme.TRAINING_FONT,
    fontSize: (props) => props.isDebrief ? "16px" : props.missionFontSize,
    fontWeight: "500",
    width: "90% !important",
    overflow: "visible !important",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "rgba(255, 255, 255, 0.7)",
    marginBottom: "5px",
    // padding: "10px",
    // whiteSpace: "pre-line",
    marginRight: "6px",
    // maxHeight: "120px",
    // overflow: "scroll",
  },
  text: {
    // fontSize:"16px",
    padding: "5px",
    color: "white",
    whiteSpace: "break-spaces",
    userSelect: "text",
  },
  missionSlide: {
    width: "180px",
    minHeight: "60px",
    marginLeft: "-30px",
    display: "block",
    // border: "1px solid green"
    // overflow: "visible !important",
    // marginLeft: "0 !important",
  },
  viewImage: {
    maxWidth: "100%",
    cursor: "pointer",
    marginTop: "5px",
    borderRadius: "9px",
    marginLeft: "20px",
    // boxShadow: "3px 2px 29px 0 #181C27",
    padding: (props) => (props.isPopup ? "10px 15px 10px 0" : "5px"),
    // margin: (props) => (props.isPopup ? "10px" : "0"),
  },
  missionUrl: {
    cursor: "pointer",
    color: "#FF2166",
  },
});

export default MissionBody;
