import FileDownload from "js-file-download";
import axios from "axios";

export function downloadFile(url, name) {
  axios
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      FileDownload(res.data, name);
    });
}
