import {
  SET_SCENARIO_LIST,
  SET_SCENARIO_CATEGORY_LIST,
  UPDATE_SINGLE_SCENARIO,
  SCENARIO_LIST_FETCHING_STATE,
} from "../types/scenarioList";

export const setScenarioList = (list) => ({
  type: SET_SCENARIO_LIST,
  list,
});
export const setScenarioCategoryList = (list) => ({
  type: SET_SCENARIO_CATEGORY_LIST,
  list,
});

export const updateSingleScenario = (item) => ({
  type: UPDATE_SINGLE_SCENARIO,
  item,
});
export const scenarioListFetchingState = (fetching) => ({
  type: SCENARIO_LIST_FETCHING_STATE,
  fetching,
});
