import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledTooltip = withStyles(() => ({
  tooltip: {
    fontFamily: "Rajdhani",
    fontSize: 10,
    textTransform: "uppercase",
    borderRadius: "9.5px",
  },
}))(Tooltip);

export default StyledTooltip;
