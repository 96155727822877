export const leaderboardBronzeStarFrame = [
  "38 34",
  `
  <defs>
  <filter id="0id5dq8tyb" width="175%" height="200%" x="-37.5%" y="-50%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
  </filter>
  <filter id="6k0d81r46c" width="175%" height="200%" x="-37.5%" y="-50%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
  </filter>
  <filter id="vf2qijr2od" width="175%" height="200%" x="-37.5%" y="-50%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
  </filter>
  <filter id="dcqrnilthe" width="175%" height="200%" x="-37.5%" y="-50%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
  </filter>
  <filter id="o61gnq7r2g" width="140%" height="200%" x="-20%" y="-50%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
  </filter>
  <filter id="mhy2va0u9h" width="214.4%" height="213.3%" x="-57.2%" y="-49.9%" filterUnits="objectBoundingBox">
      <feMorphology in="SourceAlpha" operator="dilate" radius=".72" result="shadowSpreadOuter1"/>
      <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
      <feMorphology in="SourceAlpha" radius="1" result="shadowInner"/>
      <feOffset dy="2" in="shadowInner" result="shadowInner"/>
      <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.68627451 0 0 0 0 0.447058824 0 0 0 0 0.196078431 0 0 0 1 0"/>
  </filter>
  <filter id="ge3fj5gghk" width="205.3%" height="232.7%" x="-52.4%" y="-58.4%" filterUnits="objectBoundingBox">
      <feMorphology in="SourceAlpha" operator="dilate" radius=".72" result="shadowSpreadOuter1"/>
      <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
      <feMorphology in="SourceAlpha" radius="1" result="shadowInner"/>
      <feOffset dy="2" in="shadowInner" result="shadowInner"/>
      <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.68627451 0 0 0 0 0.447058824 0 0 0 0 0.196078431 0 0 0 1 0"/>
  </filter>
  <filter id="gw1bjed6bo" width="123.5%" height="151.4%" x="-11.4%" y="-25.7%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation="2.16"/>
  </filter>
  <filter id="wgw6jm6urq" width="278%" height="326.6%" x="-89%" y="-113.3%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation="2.16"/>
  </filter>
  <filter id="bbdozzuo9r" width="278%" height="326.6%" x="-89%" y="-113.3%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation="2.16"/>
  </filter>
  <linearGradient id="dkp0s53ppj" x1="24.329%" x2="72.667%" y1="14.521%" y2="79.32%">
      <stop offset="0%" stop-color="#AA7034"/>
      <stop offset="34.157%" stop-color="#B6742F"/>
      <stop offset="61.214%" stop-color="#FCBE68"/>
      <stop offset="100%" stop-color="#AF7A3B"/>
  </linearGradient>
  <linearGradient id="lt6audsw0m" x1="23.029%" x2="73.814%" y1="22.836%" y2="72.448%">
      <stop offset="0%" stop-color="#AA7034"/>
      <stop offset="34.157%" stop-color="#B6742F"/>
      <stop offset="61.214%" stop-color="#FCBE68"/>
      <stop offset="100%" stop-color="#AF7A3B"/>
  </linearGradient>
  <linearGradient id="5fe87ka2ln" x1="23.029%" x2="73.814%" y1="33.927%" y2="63.283%">
      <stop offset="0%" stop-color="#AA7034" stop-opacity=".5"/>
      <stop offset="34.157%" stop-color="#B6742F" stop-opacity=".5"/>
      <stop offset="61.761%" stop-color="#FCBE68" stop-opacity=".5"/>
      <stop offset="100%" stop-color="#AF7A3B" stop-opacity=".5"/>
  </linearGradient>
  <linearGradient id="f0wmfhbjfs" x1="23.029%" x2="73.814%" y1="14.521%" y2="79.32%">
      <stop offset="0%" stop-color="#AA7034"/>
      <stop offset="34.157%" stop-color="#B6742F"/>
      <stop offset="61.214%" stop-color="#FCBE68"/>
      <stop offset="100%" stop-color="#AF7A3B"/>
  </linearGradient>
  <radialGradient id="3izrprblha" cx="51.821%" cy="47.185%" r="75.436%" fx="51.821%" fy="47.185%" gradientTransform="matrix(.61205 .57795 -.43346 .81607 .406 -.213)">
      <stop offset="0%" stop-color="#BB9847" stop-opacity=".5"/>
      <stop offset="39.009%" stop-color="#C1A050" stop-opacity=".5"/>
      <stop offset="59.591%" stop-color="#CBAB58" stop-opacity=".5"/>
      <stop offset="100%" stop-color="#BB9847" stop-opacity=".5"/>
  </radialGradient>
  <radialGradient id="k8055l9axf" cx="51.821%" cy="47.185%" r="123.386%" fx="51.821%" fy="47.185%" gradientTransform="scale(.4 1) rotate(20.692 .907 2.6)">
      <stop offset="0%" stop-color="#BB9847" stop-opacity=".5"/>
      <stop offset="39.009%" stop-color="#C1A050" stop-opacity=".5"/>
      <stop offset="59.591%" stop-color="#CBAB58" stop-opacity=".5"/>
      <stop offset="100%" stop-color="#BB9847" stop-opacity=".5"/>
  </radialGradient>
  <radialGradient id="69dh4i69dp" cx="51.821%" cy="47.185%" r="73.17%" fx="51.821%" fy="47.185%" gradientTransform="matrix(.631 .59585 -.46816 .8031 .412 -.216)">
      <stop offset="0%" stop-color="#BB9847" stop-opacity=".5"/>
      <stop offset="39.009%" stop-color="#C1A050" stop-opacity=".5"/>
      <stop offset="59.591%" stop-color="#CBAB58" stop-opacity=".5"/>
      <stop offset="100%" stop-color="#BB9847" stop-opacity=".5"/>
  </radialGradient>
  <path id="sb9g2zbyai" d="M10.56 2.16h16.32c2.688 0 4.903 2.11 5.034 4.794l.948 19.44c.136 2.78-2.008 5.144-4.788 5.28-.082.004-.164.006-.246.006H9.612c-2.784 0-5.04-2.256-5.04-5.04 0-.082.002-.164.006-.246l.948-19.44C5.657 4.27 7.872 2.16 10.56 2.16z"/>
  <path id="a26ivj4byl" d="M11.002 2.16H26.62c2.784 0 5.04 2.256 5.04 5.04 0 .423-.053.845-.159 1.255l-3.888 15.12c-.573 2.228-2.58 3.785-4.881 3.785h-7.41c-2.25 0-4.228-1.492-4.846-3.655l-4.32-15.12c-.765-2.677.785-5.466 3.461-6.231.45-.129.916-.194 1.385-.194z"/>
</defs>
<g fill="none" fill-rule="evenodd">
  <g>
      <g transform="translate(-99 -354) translate(99 354)">
          <ellipse cx="16.56" cy="2.16" fill="url(#3izrprblha)" filter="url(#0id5dq8tyb)" opacity=".265" rx="2.88" ry="2.16"/>
          <ellipse cx="18" cy="10.08" fill="url(#3izrprblha)" filter="url(#6k0d81r46c)" opacity=".462" rx="2.88" ry="2.16"/>
          <ellipse cx="19.44" cy="18" fill="url(#3izrprblha)" filter="url(#vf2qijr2od)" opacity=".462" rx="2.88" ry="2.16"/>
          <ellipse cx="25.2" cy="26.64" fill="url(#3izrprblha)" filter="url(#dcqrnilthe)" opacity=".265" rx="2.88" ry="2.16"/>
          <ellipse cx="18.36" cy="31.68" fill="url(#k8055l9axf)" filter="url(#o61gnq7r2g)" opacity=".265" rx="5.4" ry="2.16"/>
          <g>
              <use fill="#000" filter="url(#mhy2va0u9h)" xlink:href="#sb9g2zbyai"/>
              <use stroke="url(#dkp0s53ppj)" stroke-width="1.44" xlink:href="#sb9g2zbyai"/>
          </g>
          <g>
              <use fill="#000" filter="url(#ge3fj5gghk)" xlink:href="#a26ivj4byl"/>
              <use stroke="url(#lt6audsw0m)" stroke-width="1.44" xlink:href="#a26ivj4byl"/>
          </g>
          <path fill="url(#5fe87ka2ln)" fill-opacity=".3" d="M7.248 2.16h23.193c2.784 0 5.04 2.256 5.04 5.04 0 .543-.088 1.083-.26 1.598l-5.054 15.12c-.688 2.056-2.613 3.442-4.78 3.442H12.864c-2.106 0-3.99-1.31-4.724-3.285L2.524 8.955c-.97-2.61.36-5.51 2.97-6.48.561-.208 1.155-.315 1.754-.315z" filter="url(#gw1bjed6bo)" opacity=".694"/>
          <g transform="translate(10.16 5.12)">
              <ellipse cx="7.52" cy="3.1" fill="url(#69dh4i69dp)" filter="url(#wgw6jm6urq)" opacity=".462" rx="3.64" ry="2.86"/>
              <ellipse cx="9.6" cy="13.5" fill="url(#69dh4i69dp)" filter="url(#bbdozzuo9r)" opacity=".462" rx="3.64" ry="2.86"/>
              <path stroke="url(#f0wmfhbjfs)" stroke-width="1.44" d="M8.065 12.776l-3.311 1.74c-.352.186-.787.05-.972-.301-.074-.14-.1-.301-.073-.457l.633-3.687c.04-.234-.038-.472-.207-.637l-2.68-2.611c-.284-.278-.29-.734-.012-1.019.11-.113.255-.187.412-.21l3.702-.537c.234-.035.437-.182.542-.394l1.655-3.355c.176-.356.608-.503.965-.327.142.07.257.185.327.327L10.7 4.663c.105.212.308.36.542.394l3.702.537c.394.058.666.423.61.817-.024.156-.097.301-.21.412l-2.68 2.61c-.17.166-.247.404-.207.638l.633 3.687c.067.392-.196.764-.588.831-.156.027-.317.002-.457-.072l-3.311-1.74c-.21-.111-.46-.111-.67 0z"/>
          </g>
      </g>
  </g>
</g>
`,
];
