export const graphLoop = [
  "19 19",
  `
  <defs>
  <filter id="filter-1">
      <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"></feColorMatrix>
  </filter>
</defs>
<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="timeline-icons" transform="translate(-311.000000, -72.000000)">
      <g id="reload" transform="translate(300.000000, 63.000000)" filter="url(#filter-1)">
          <g transform="translate(11.000000, 9.000000)">
              <path d="M1.65448828,13.2666609 C1.28229963,13.4210039 0.856005469,13.2438797 0.701947247,12.8722606 C0.236213095,11.7485754 0,10.5572584 0,9.3312 C0,4.21381935 4.14262794,0.0753204987 9.2583,0.0753204987 C11.436757,0.0753204987 13.6157836,0.836641406 15.3747809,2.38533925 L15.3747809,0.729 C15.3747809,0.326341406 15.7012647,0 16.1037809,0 C16.5064394,0 16.8327809,0.326341406 16.8327809,0.729 L16.8327809,4.20641544 C16.8547078,4.62274278 16.5178301,4.97514025 16.1037809,4.97514025 L12.5856439,4.97514025 C12.1829853,4.97514025 11.8566439,4.64879881 11.8566439,4.24614025 C11.8566439,3.84348162 12.1829853,3.51714025 12.5856439,3.51714025 L14.4549879,3.51714025 C13.0717389,2.28139981 11.2619109,1.5333205 9.2583,1.5333205 C4.94666369,1.5333205 1.458,5.02155703 1.458,9.3312 C1.458,10.3646144 1.65676641,11.3682709 2.04888869,12.3139775 C2.20294688,12.6860238 2.02653456,13.1124603 1.65448828,13.2666609 Z M17.8146528,5.79013943 C17.6604522,5.41809315 17.2340156,5.24168088 16.8621117,5.39573906 C16.4900654,5.54993966 16.3136531,5.97651856 16.4677113,6.34842247 C16.8598336,7.29412909 17.0586,8.29778557 17.0586,9.3312 C17.0586,13.640843 13.5699363,17.1290795 9.2583,17.1290795 C7.25468906,17.1290795 5.44486112,16.3810002 4.06161213,15.1452597 L5.93095606,15.1452597 C6.33361466,15.1452597 6.65995606,14.818776 6.65995606,14.4162597 C6.65995606,14.0136012 6.33361466,13.6872597 5.93095606,13.6872597 L2.41281912,13.6872597 C1.99962422,13.6872597 1.66174981,14.0363824 1.68381912,14.4559846 L1.68381912,17.9334 C1.68381912,18.3360586 2.01016057,18.6624 2.41281912,18.6624 C2.81533534,18.6624 3.14181912,18.3360586 3.14181912,17.9334 L3.14181912,16.2770608 C4.90081641,17.8257586 7.07984297,18.5870795 9.2583,18.5870795 C14.3739721,18.5870795 18.5166,14.4485807 18.5166,9.3312 C18.5166,8.10514159 18.2803869,6.91382463 17.8146528,5.79013943 L17.8146528,5.79013943 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
          </g>
      </g>
  </g>
</g> `,
];
