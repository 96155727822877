import React, { useEffect, useRef } from "react";

const sortArrayOfObjects = (arr, key) => {
  return arr.sort((a, b) => {
      return a[key] - b[key];
  });
};

export const getUpdatedScore = (data) => {
  return sortArrayOfObjects(data, "score").reverse();
};

export const usePrevious = value => {
  const prevChildrenRef = useRef();
  useEffect(() => {
    prevChildrenRef.current = value;
  }, [value]);
  return prevChildrenRef.current;
};

export const calculateBoundingBoxes = children => {
  const boundingBoxes = {};
  React.Children.forEach(children, child => {
    const domNode = child.ref.current;
    const nodeBoundingBox = domNode.getBoundingClientRect();
    boundingBoxes[child.key] = nodeBoundingBox;
  });
  return boundingBoxes;
};