import React from "react";
import AnimatedNumber from "react-animated-numbers";
import { useSelector } from "react-redux";

function AnimatedScore({ score, size = 16 }) {
  const { theme } = useSelector((state) => state.uiReducer);
  return (
    <AnimatedNumber
      fontStyle={{
        textShadow: "0 0 6px #ee3042",
        fontFamily: theme.MAIN_FONT,
        fontSize: size,
        fontWeight: "600",
        color: "#ffffff",
      }}
      animateToNumber={score || 0}
      includeComma
      config={{ tension: 89, friction: 40 }}
      // onStart={() => console.log("onStart")}
      // onFinish={() => console.log("onFinish")}
      animationType={"calm"}
    />
  );
}

export default AnimatedScore;
