import { SET_QUESTION_RESULTS, RESET_STATE } from "../types/quizResults";

const initialState = {};

const quizResultsReducer = (state = initialState, action) => {
  const { question, id, type } = action;

  switch (type) {
    case SET_QUESTION_RESULTS:
      return { ...state, [id]: { ...question } };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
export default quizResultsReducer;
