import React from "react";

const ScrollThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.34)",
    cursor: "pointer",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

export default ScrollThumb;
