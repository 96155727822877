import React, { useState, useEffect, memo } from "react";

const cx = 80;
const cy = 80;
const radius = 76;
const circumference = 2 * Math.PI * radius;
const adjustedCircumference = 0.97 * circumference; // gaps between segments
const strokeWidth = 6;
const angleOffset = -84;

function MissionProgress({ segments, total }) {
  const [calculatedRings, SetCalculatedRings] = useState([]);

  useEffect(() => {
    let acc = 0;
    let ringsArr = [];
    segments.forEach((segment) => {
      const { value } = segment;
      const ratio = 1 / total;
      const offset = circumference - ratio * circumference;
      const myAngleOffset = acc * 360 + angleOffset;
      ringsArr.push({
        ...segment,
        offset,
        transform: `rotate(${myAngleOffset}, ${cx}, ${cy})`,
      });
      acc += ratio;
    });

    SetCalculatedRings(ringsArr);
  }, [segments]);

  return (
    <svg
      viewBox="0 0 160 160"
      xmlns="http://www.w3.org/2000/svg"
      css={{
        width: "100%",
        height: "100%",
        opacity: 1,
      }}
    >
      <defs>
        <filter id="stripes">
          <feDropShadow dx="0" dy="0" stdDeviation="6" floodColor="#ee3042" />
        </filter>
      </defs>
      {calculatedRings.map((segment, index) => {
        return (
          <circle
            fill="transparent"
            cx={cx}
            cy={cy}
            r={radius}
            stroke={segment.color}
            filter={segment.status === "finished" ? "url(#stripes)" : ""}
            strokeLinecap="round"
            strokeWidth={strokeWidth}
            strokeDasharray={adjustedCircumference}
            strokeDashoffset={segment.offset}
            transform={segment.transform}
            key={index}
          />
        );
      })}
    </svg>
  );
}

function progressPropsAreEqual(prevIcon, nextIcon) {
  return (
    prevIcon.segments === nextIcon.segments && prevIcon.total === nextIcon.total
  );
}

export default memo(MissionProgress, progressPropsAreEqual);
