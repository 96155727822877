import highlightUrl from "./highlightUrl";

const generateUrlArr = (content) => {
  let myUrlArr = [];

  content.forEach((el) => {
    el.forEach((innerEl) => {
      if (innerEl?.type === "text") {
        const { urlArr } = highlightUrl(innerEl.value);
        myUrlArr = [...myUrlArr, ...urlArr];
      }
      if (innerEl?.type === "link") {
        const { display, subtype, value } = innerEl;
        const { urlArr } = highlightUrl(value, {
          display,
          subtype,
          value,
        });
        myUrlArr = [...myUrlArr, ...urlArr];
      }
    });
  });

  return myUrlArr;
};

export default generateUrlArr;
