import { saveCurrentStateToLS } from "../../helpers/saveStateToLS";
import {
  SET_INFO,
  ADD_INFOS,
  REMOVE_INFO,
  SET_INITIAL,
  RESET_STATE,
} from "../types/info";

const initialState = { infos: [], dict: {} };

const infoReducer = (state = initialState, action) => {
  const { info, infos, id, type, initial } = action;

  const checkIfInfoDisplayed = (newInfo) => {
    try {
      const currentDisplayed = [...state["infos"]];
      return currentDisplayed.some(
        (item) => JSON.stringify(item) === JSON.stringify(newInfo)
      );
    } catch (err) {
      console.warn("Check failed", err);
    }
  };

  switch (type) {
    case SET_INFO:
      return [...info];

    case SET_INITIAL:
      state["infos"] = initial.infos;
      state["dict"] = initial.dict;

      return [...state];

    case ADD_INFOS:
      const tmpInfos = [...state["infos"]];
      const tmpDict = { ...state["dict"] };
      const currentState = { infos: tmpInfos, dict: tmpDict };

      if (checkIfInfoDisplayed(infos[0])) {
        return currentState;
      }

      infos.forEach((element) => {
        const { value } = element;
        if (value) {
          tmpInfos.push(element);
          tmpDict[value] = true; // cyb - 1867
        }
      });

      saveCurrentStateToLS(currentState, "infos");
      return currentState;

    case REMOVE_INFO:
      return state.filter((el) => el.id !== id);
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default infoReducer;
