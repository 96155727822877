import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import ShadowedText from "../../shared/ShadowedText";

function LeaderboardExtraInfo({ missions = 5, hints = 4 }) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      paddingLeft="15px"
      paddingTop="5px"
      marginLeft="10px"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="start"
        width="100%"
        paddingLeft="3px"
      >
        <ShadowedText text={missions} size="25px" type="red" />
        <div className={classes.text}>Missions Completed</div>
      </Box>
      <div className={classes.separator} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="start"
        marginTop="2px"
        paddingLeft="3px"
        width="100%"
      >
        <ShadowedText text={hints} size="25px" type="orange" />
        <div className={classes.text}>Hints</div>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles({
  text: {
    fontFamily: "NunitoSans",
    fontSize: "12px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#ffffff",
    marginLeft: "5px",
  },
  separator: {
    width: "140px",
    height: "1px",
    background: `linear-gradient(
      to left, 
      rgba(255, 255, 255,0.2) 0%,
      rgba(255, 255, 255,0.5) 25%,
      rgba(255, 255, 255,0.8) 50%,
      rgba(255, 255, 255,0.5) 75%,
      rgba(255, 255, 255,0.2) 100%
    )
    left 
    bottom
    no-repeat`,
    borderImageSlice: "1",
  },
});

export default memo(LeaderboardExtraInfo);
