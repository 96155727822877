import { call, put, takeLatest } from "redux-saga/effects";
import {
  GetAlltraineesAvatars,
  GetAllTrainerAvatars,
  personalHallApi,
} from "./../../services/userApi";
import { setTrainingHistory } from "../actions/trainingHistory";
import { setPersonalHall } from "../actions/personalHall";
import { getUserInformation } from "../../services/Users";
import USER_TYPES from "../../constants/userTypes";
import { FetchScheduled } from "../../services/training";
import { setScheduledTraining } from "./../actions/scheduledTrainings";

function* personalHall(action) {
  try {
    const personalHall = yield call(personalHallApi);
    const scheduledTrainings = yield call(FetchScheduled, action.userId);
    const userInformation = yield call(getUserInformation, action.userId);
    const traineeAvatarResult = yield call(GetAlltraineesAvatars, "token");
    const trainerAvatarResult = yield call(GetAllTrainerAvatars, "token");

    if (
      userInformation.data &&
      personalHall?.data &&
      traineeAvatarResult.data &&
      trainerAvatarResult.data &&
      scheduledTrainings.data
    ) {
      const parsedData = JSON.parse(personalHall.data);
      const allTrainerAvatars = JSON.parse(trainerAvatarResult.data);
      const allTraineesAvatars = JSON.parse(traineeAvatarResult.data);
      const scheduled = scheduledTrainings.data;

      const { userDetails, suggestedTraining, trainingHistory,defaultRanger } = parsedData;

      const {
        name,
        email,
        id,
        organization_id,
        user_type_id,
        middle_name,
        last_name,
        job_title,
      } = userInformation.data;

      userDetails.name = name;
      userDetails.middle_name = middle_name;
      userDetails.last_name = last_name;
      userDetails.email = email;
      userDetails.id = id;
      userDetails.job_title = job_title;
      userDetails.organization_id = organization_id;
      userDetails.user_type_id = user_type_id;
      userDetails.type = USER_TYPES[user_type_id - 1];
      userDetails.defaultRanger = defaultRanger;
      const isUserRanger = user_type_id === 3 ||  user_type_id === 5;
      
      yield put(
        setScheduledTraining({
          scheduled,
        })
      );
      yield put(
        setPersonalHall({
          userDetails,
          suggestedTraining,
          allTrainerAvatars,
          allTraineesAvatars,
          isUserRanger,
        })
      );
      localStorage.setItem("traineeAvatarId", userDetails.avatarId);
      localStorage.setItem(
        "allTraineesAvatars",

        JSON.stringify(allTraineesAvatars)
      );
      yield put(setTrainingHistory(trainingHistory));
    }
  } catch (e) {
    // yield put({ type: "FETCH_FAILED", message: e.message });
    console.log("Error fetching personal hall data");
  }
}

export function* personalHallSaga() {
  yield takeLatest("LOAD_PERSONAL_HALL", personalHall);
}
