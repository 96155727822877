import React from "react";
import { makeStyles } from "@material-ui/core";
import CIcon from "@coreui/icons-react";

function TrainingPopupContainerHeader({ children, size, closeClick }) {
  const classes = useStyles({ size });

  return (
    <div className={`${classes.trainingRoot} rnd-handle`}>
      <>{children}</>
      <CIcon
        name="trainingCloseIcon"
        className={classes.icon}
        width="19px"
        height="19px"
        onClick={closeClick}
      />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  trainingRoot: {
    height: (props) => props?.size?.height || "57px",
    width: (props) => props?.size?.width || "250px",
    padding: "10px",
    borderRadius: "10px 10px 0 0",
    backgroundColor: "rgba(0,0,0,0.28)",
    boxShadow:
      "0 0 19px 0 rgba(255, 255, 255, 0.08), inset 0 -1px 3px 0 rgba(0, 0, 0, 0.18)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      cursor: "move",
    },
  },
  icon: {
    cursor: "pointer",
  },
}));

export default TrainingPopupContainerHeader;
