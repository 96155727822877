const leaderboardColors = [
  "#CBAB58",
  "#A2A2A2",
  "#C27F39",
  "#7514D6",
  "#0B4D58",
  "#0C1368",
  "#5C620F",
  "#620D10",
  "#5F146E",
  "#C27F39",
  "#20C381",
];

export default leaderboardColors;
