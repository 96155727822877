export const orangeShield = [
  "38 38",
  `
    <defs>
        <filter id="yt1iyoe0ta" color-interpolation-filters="auto">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 0.482353 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
        </filter>
        <filter id="i15b87nqzb" color-interpolation-filters="auto">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.092827 0 0 0 0 1.000000 0 0 0 0 0.413502 0 0 0 0.850000 0"/>
        </filter>
        <filter id="roevtqhqvc" color-interpolation-filters="auto">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 0.482353 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
        </filter>
        <filter id="434fktehbe" width="250%" height="228.6%" x="-75%" y="-64.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="3"/>
        </filter>
        <filter id="za034woufg" width="211.4%" height="248.6%" x="-55.7%" y="-74.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
        </filter>
        <filter id="5f18ih9bmi" width="211.4%" height="248.6%" x="-55.7%" y="-74.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
        </filter>
        <filter id="mewyqhxtck" width="211.4%" height="248.6%" x="-55.7%" y="-74.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
        </filter>
        <filter id="45ngn6lv6m" width="325%" height="300%" x="-112.5%" y="-100%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
        </filter>
        <filter id="dzkfq0aufo" width="250%" height="185.7%" x="-75%" y="-42.9%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
        </filter>
        <filter id="0vofxvh9ip" width="211.4%" height="248.6%" x="-55.7%" y="-74.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
        </filter>
        <linearGradient id="a09meyyupd" x1="0%" x2="100%" y1="52.719%" y2="52.719%">
            <stop offset="0%" stop-color="#00FF68" stop-opacity=".85"/>
            <stop offset="46.443%" stop-color="#48FF92"/>
            <stop offset="100%" stop-color="#39CA74" stop-opacity=".61"/>
        </linearGradient>
        <linearGradient id="cblb0rvhxf" x1="0%" x2="100%" y1="52.719%" y2="52.719%">
            <stop offset="0%" stop-color="#00FF68" stop-opacity="0"/>
            <stop offset="46.443%" stop-color="#4AFF94"/>
            <stop offset="100%" stop-color="#39CA74" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="zlzerw20ch" x1="0%" x2="100%" y1="52.719%" y2="52.719%">
            <stop offset="0%" stop-color="#00FF68" stop-opacity=".46"/>
            <stop offset="46.443%" stop-color="#4AFF94"/>
            <stop offset="100%" stop-color="#39CA74" stop-opacity=".58"/>
        </linearGradient>
        <linearGradient id="r3aj8fdtyj" x1="0%" x2="100%" y1="52.719%" y2="52.719%">
            <stop offset="0%" stop-color="#00FF68"/>
            <stop offset="46.443%" stop-color="#48FF92" stop-opacity=".9"/>
            <stop offset="100%" stop-color="#39CA74"/>
        </linearGradient>
        <linearGradient id="zakye6rtol" x1="0%" x2="100%" y1="52.719%" y2="52.719%">
            <stop offset="0%" stop-color="#00FF68" stop-opacity="0"/>
            <stop offset="46.443%" stop-color="#4AFF94"/>
            <stop offset="100%" stop-color="#39CA74" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="acj2h241bn" x1="0%" x2="100%" y1="52.719%" y2="52.719%">
            <stop offset="0%" stop-color="#00FF68" stop-opacity="0"/>
            <stop offset="46.443%" stop-color="#4AFF94" stop-opacity=".72"/>
            <stop offset="100%" stop-color="#39CA74" stop-opacity="0"/>
        </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g transform="translate(-691 -280) translate(691.849 281)">
                <circle cx="17.093" cy="17.093" r="17.093" fill="#000" fill-opacity=".6" stroke="#FFF" stroke-opacity=".282" stroke-width=".817"/>
                <g filter="url(#yt1iyoe0ta)">
                    <g>
                        <g filter="url(#i15b87nqzb)" transform="translate(7.53 6)">
                            <g>
                                <path fill="#16ED62" d="M8.504 1.499c-1.99 1.736-4.471 2.458-7.051 2.52-.241.007-.39.074-.402.31-.86 6.145.308 14.57 7.195 16.624.196.064.264.054.26-.17-.004-.496.259-1.201-.301-1.424-5.48-1.871-6.435-8.424-5.991-13.474.019-.234.115-.354.355-.386 2.033-.245 4.048-.8 5.759-1.971.112-.078.178-.155.177-.296-.003-.55-.001-1.101-.001-1.733m6.359 6.334c0-.42-.005-.838.002-1.257.003-.182-.045-.334-.233-.365-1.028-.26-1.535-.178-2.637-.56-1.027-.356-2.001-.815-2.907-1.413-.155-.102-.256-.112-.417-.003-.836.692-4.633 1.882-5.18 1.93-.556.08-.606.116-.6.688-.041 3.103.222 6.406 2.004 9.05.77 1.162 1.906 1.853 3.113 2.518.446.246 1.236.288 1.878-.074 1.366-.77 2.45-1.731 3.288-3.061.715-1.136 1.376-3.912 1.397-4.015.244-1.134.31-2.282.292-3.438m1.918-2.261c-.02-.625-.043-.853-.07-1.28-.012-.186-.094-.264-.276-.258-.651.023-1.291-.078-1.935-.154-1.953-.347-3.725-1.07-5.253-2.378v.88c0 1.094 0 1.094.957 1.64 1.532.825 3.195 1.252 4.906 1.472.399.053.5.267.492.593.223 3.996-.187 8.589-3.225 11.54-.807.768-1.765 1.327-2.808 1.724-.408.112-.314.457-.32.782-.002.93-.002.93.86.606 5.959-2.176 7.309-9.496 6.672-15.167M13.83 19.41c-.54.247-4.21 2.395-4.954 2.353-1.39-.078-4.268-1.894-4.883-2.352l-.031-.017.032.016c.376.678 1.13 1.182 1.778 1.625.634.454 2.333 1.389 3.104 1.33 1.69-.123 4.677-2.465 4.955-2.955M12.5 21.567c-.822.49-1.686.885-2.58 1.236-.378.151-.746.207-1.108.196-1.123-.036-2.175-.72-3.191-1.202-4.2-2.579-5.448-7.64-5.62-12.207-.005-1.027-.007-2.055.06-3.08.043-.966.25-1.928.238-2.873.155-.376.449-.453.832-.445 2.399.039 4.838-.648 6.7-2.214.348-.293.452-.44.646-.685.599-.808.953.267 1.503.697 2.01 1.565 4.546 2.208 7.018 2.272.215.001.354.088.385.31.17 1.169.349 2.336.366 3.52.153 5.215-.59 11.264-5.25 14.475" transform="translate(.622)"/>
                            </g>
                        </g>
                        <g filter="url(#roevtqhqvc)" transform="translate(7.53 6)">
                            <ellipse cx="9.67" cy="10.596" fill="url(#a09meyyupd)" filter="url(#434fktehbe)" rx="6" ry="7"/>
                            <ellipse cx="9.987" cy="2.019" fill="url(#cblb0rvhxf)" filter="url(#za034woufg)" opacity=".752" rx="2.692" ry="2.019"/>
                            <ellipse cx="5.987" cy="6.019" fill="url(#zlzerw20ch)" filter="url(#5f18ih9bmi)" rx="2.692" ry="2.019"/>
                            <ellipse cx="9.987" cy="11.019" fill="url(#r3aj8fdtyj)" fill-opacity=".6" filter="url(#mewyqhxtck)" rx="2.692" ry="2.019"/>
                            <ellipse cx="1.333" cy="3.923" fill="url(#zakye6rtol)" filter="url(#45ngn6lv6m)" opacity=".752" rx="1.333" ry="1.5"/>
                            <ellipse cx="16.841" cy="16.286" fill="url(#acj2h241bn)" filter="url(#dzkfq0aufo)" opacity=".63" rx="2" ry="3.5" transform="rotate(17 16.841 16.286)"/>
                            <ellipse cx="9.064" cy="18.904" fill="url(#cblb0rvhxf)" filter="url(#0vofxvh9ip)" rx="2.692" ry="2.019"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g> `,
];
