import axios from "axios";

const myBaseURL =
  process.env.REACT_APP_ENVIRONMENT.trim() === "DEV"
    ? process.env.REACT_APP_BASE_URL_LOCAL_DEV
    : process.env.REACT_APP_BASE_URL_CLOUD;

export function loadNetworks(token) {
  const obj = {
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(`${myBaseURL}/Content/loadNetworks`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("loadNetworks ERROR: ", err);
    });
}

export function loadNetworkItem(s_itemId) {
  const obj = {
    id: `${s_itemId}`,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(`${myBaseURL}/Content/LoadNetworkData`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("LoadNetworkData ERROR: ", err);
    });
}
export function LinkNetworkIdToScenario(scenarioId, networkId) {
  const obj = {
    ScnID: `${scenarioId}`,
    NetID: `${networkId}`,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(`${myBaseURL}/Content/LinkNetworkToScenario`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("LinkNetworkToScenario ERROR: ", err);
    });
}
