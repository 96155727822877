import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";

import TrainingPopupContainer from "../TrainingPopupContainer";
import TrainingPopupContainerHeader from "../TrainingPopupContainerHeader";
import TrainingPopupContainerBody from "../TrainingPopupContainerBody";
import TrainingHeaderTitle from "../TrainingHeaderTitle";
import Info from "./Info";
import ModalContainer from "./../../base/modals/ModalContainer";
import FileView from "./../missions/FileViewer";
import AttachmentButton from "../../buttons/AttachmentButton";

function InfoContainer({ closeClick }) {
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [attachment, setAttachment] = useState({});

  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({theme});

  const { infos } = useSelector((state) => state.infoReducer);

  const headerSize = { width: "100%", height: "35px" };

  const openAttachment = useCallback((name, type, value) => {
    setModalType("attachment");
    setAttachment({ name, type, value });
    setOpen(true);
  }, []);

  const closeAttachment = () => {
    resetState();
  };

  const resetState = () => {
    setOpen(false);
    setModalType("");
    setAttachment({});
  };

  const modalChildren = () => {
    if (modalType === "attachment") {
      return <FileView closeClick={closeAttachment} {...attachment} />;
    }
    return <div />;
  };

  return (
    <TrainingPopupContainer overflow="hidden">
      <TrainingPopupContainerHeader size={headerSize} closeClick={closeClick}>
        <TrainingHeaderTitle text="Info" />
      </TrainingPopupContainerHeader>
      <TrainingPopupContainerBody>
        <div className={classes.innerBody}>
          {infos.map((info, index) => {
            const { value, component, display, subtype } = info;
            if (component === "text") {
              return (
                <Info
                  key={index}
                  value={value}
                  openAttachment={openAttachment}
                />
              );
            }
            if (component === "link") {
              return (
                <Box
                  width="100%"
                  marginBottom="5px"
                  paddingLeft="5px"
                  key={index}
                >
                  <AttachmentButton
                    openAttachment={() =>
                      openAttachment(display, subtype, value)
                    }
                    name={display}
                  />
                </Box>
              );
            }
            return <div />;
          })}
        </div>
      </TrainingPopupContainerBody>
      <ModalContainer open={open} position="center">
        {modalChildren()}
      </ModalContainer>
    </TrainingPopupContainer>
  );
}

const useStyles = makeStyles(() => ({
  innerBody: {
    fontFamily: (props) => props.theme.TRAINING_FONT,
    backgroundColor: "rgba(0,0,0,0.46)",
    minHeight: "100%",
    width: "100%",
    borderRadius: "4px",
    padding: "10px 0 5px 3px",
    WebkitBackdropFilter: "blur(5px)",
    backdropFilter: "blur(5px)",
  },
}));

export default InfoContainer;
