import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import LeaderboardRank from "./LeaderboardRank";
import LeaderboardAvatar from "./LeaderboardAvatar";
import LeaderboardScore from "./LeaderboardScore";
import LeaderboardBadgesContainer from "./LeaderboardBadgesContainer";
import LeaderboardExtraInfo from "./LeaderboardExtraInfo";

function LeaderboardRow({
  data,
  isUser,
  background,
  userImage,
  id,
  gridSize = 12,
  isRight = false,
}) {
  const classes = useStyles({ background, gridSize });

  const { rank, score, name, badges, missions, hints } = data;

  return (
    <div className={classes.leaderboardGrid}>
      <Box
        display="flex"
        marginBottom="28px"
        paddingBottom="3px"
        width="100%"
        height="100%"
        marginLeft={isRight ? "15px" : 0}
        className={classes.leaderboardRow}
      >
        <LeaderboardRank rank={rank} />
        <LeaderboardAvatar image={userImage} imageSize={60} size={0} />
        <LeaderboardScore score={score} name={name} />
        <LeaderboardBadgesContainer id={id} badges={badges} />
        <LeaderboardExtraInfo id={id} missions={missions} hints={hints} />
      </Box>
    </div>
  );
}

const useStyles = makeStyles({
  leaderboardGrid: {
    height: (props) => `${props.gridSize}%`,
  },
  leaderboardRow: {
    width: "578px",
    height: "88px",
    backgroundImage: (props) => `url(${props.background})`,
    backgroundSize: "99%",
    backgroundRepeat: "no-repeat",
  },
});

export default LeaderboardRow;
