import React from "react";
import { makeStyles } from "@material-ui/core";

function TrainingPopupContainer({ children, width, overflow, height }) {
  const classes = useStyles({ width, overflow, height });

  return <div className={classes.menuRoot}>{children}</div>;
}

const useStyles = makeStyles(() => ({
  menuRoot: {
    display: "flex",
    flexDirection: "column",
    width: (props) => props.width, //missions container width
    height: (props) => props.height || "100%",
    border: "solid 1px rgba(255, 255, 255, 0.09)",
    borderRadius: "10px",
    boxShadow: "0 0 2px 0 rgba(255, 255, 255, 0.35)",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    overflow: (props) => props.overflow || "scroll", //Change this if missions overflowing from the page
  },
}));

export default TrainingPopupContainer;
