import {
  ADD_USER,
  REMOVE_USER,
  SEND_MESSAGE,
  MESSAGE_SENT,
  MESSAGE_RECEIVED,
  RESET_STATE,
} from "../types/chat";

export const addUser = (user) => ({
  type: ADD_USER,
  user,
});

export const removeUser = (user) => ({
  type: REMOVE_USER,
  user,
});

export const sendMessage = (message) => ({
  type: SEND_MESSAGE,
  message,
});

export const messageSent = (message) => ({
  type: MESSAGE_SENT,
  message,
});

export const messageReceived = (message) => ({
  type: MESSAGE_RECEIVED,
  message,
});

export const resetChatState = () => ({
  type: RESET_STATE,
});
