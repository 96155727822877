import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

function ConfirmButton({ text, confirm, type, size, disabled = false }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const buttonSize = {
    width: size?.width || "60px",
    height: size?.height || "24px",
    font: size?.font || "12px",
  };
  const classes = useStyles({ type, size: buttonSize, disabled, theme });

  return (
    <button
      className={classes.buttonRoot}
      onClick={confirm}
      disabled={disabled}
    >
      {text}
    </button>
  );
}

const useStyles = makeStyles({
  buttonRoot: {
    width: (props) => props.size.width,
    height: (props) => props.size.height,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: (props) => props.theme.MAIN_FONT,
    textTransform: "uppercase",
    fontWeight: 600,
    borderRadius: "5px",
    WebkitBackdropFilter: "blur(6px)",
    backdropFilter: "blur(6px)",
    border: "none",
    boxShadow: (props) => {
      if (props.type === "active")
        return "inset 0 -0.2em 0.1em 0 rgba(0,0,0,0.27)";
      if (props.type === "locked")
        return "inset 0 -2px 1px 0 rgba(0, 0, 0, 0.27)";
    },
    background: (props) => {
      if (props.type === "locked" || props.disabled)
        return "linear-gradient(to bottom, #6f727a 3%, #6f727a 97%)";
      if (props.type === "active")
        return "linear-gradient(180deg, #FF6800 0%, #b45204 100%)";
    },
    fontSize: "10px",
    color: (props) => {
      if (props.type === "active") return "#ffffff";
      if (props.type === "locked") return "rgba(255, 255, 255, 0.3)";
    },
    opacity: (props) => {
      return props.disabled ? "60%" : "100%";
    },
  },
});

export default ConfirmButton;
