import { SET_USER_AGENT } from "../types/userAgent";

const initialState = {};

const userAgentReducer = (state = initialState, action) => {
  const { userAgent } = action;
  switch (action.type) {
    case SET_USER_AGENT:
      return userAgent;
    default:
      return state;
  }
};

export default userAgentReducer;
