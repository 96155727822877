export const leaderboardTrophyFrame = [
  "38 34",
  `
  <defs>
        <filter id="lotezkefpb" width="175%" height="200%" x="-37.5%" y="-50%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
        </filter>
        <filter id="uqtqe8zrcc" width="175%" height="200%" x="-37.5%" y="-50%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
        </filter>
        <filter id="usq2kphxqd" width="175%" height="200%" x="-37.5%" y="-50%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
        </filter>
        <filter id="vnbechp6ne" width="175%" height="200%" x="-37.5%" y="-50%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
        </filter>
        <filter id="npf5hg6rug" width="140%" height="200%" x="-20%" y="-50%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation=".72"/>
        </filter>
        <filter id="yquncpuerh" width="214.4%" height="213.3%" x="-57.2%" y="-49.9%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius=".72" result="shadowSpreadOuter1"/>
            <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feMorphology in="SourceAlpha" radius="1" result="shadowInner"/>
            <feOffset dy="2" in="shadowInner" result="shadowInner"/>
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.673183718 0 0 0 0 0.560146019 0 0 0 0 0.298544488 0 0 0 1 0"/>
        </filter>
        <filter id="4nmmp92hok" width="205.3%" height="232.7%" x="-52.4%" y="-58.4%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius=".72" result="shadowSpreadOuter1"/>
            <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feMorphology in="SourceAlpha" radius="1" result="shadowInner"/>
            <feOffset dy="2" in="shadowInner" result="shadowInner"/>
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.673183718 0 0 0 0 0.560146019 0 0 0 0 0.298544488 0 0 0 1 0"/>
        </filter>
        <filter id="5cztzlfero" width="123.5%" height="151.4%" x="-11.4%" y="-25.7%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.16"/>
        </filter>
        <filter id="s02sdqo2cq" width="278%" height="326.6%" x="-89%" y="-113.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.16"/>
        </filter>
        <filter id="ff278ikg4r" width="278%" height="326.6%" x="-89%" y="-113.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.16"/>
        </filter>
        <radialGradient id="tz6la6waga" cx="51.821%" cy="47.185%" r="75.436%" fx="51.821%" fy="47.185%" gradientTransform="matrix(.61205 .57795 -.43346 .81607 .406 -.213)">
            <stop offset="0%" stop-color="#BB9847" stop-opacity=".5"/>
            <stop offset="39.009%" stop-color="#C1A050" stop-opacity=".5"/>
            <stop offset="59.591%" stop-color="#CBAB58" stop-opacity=".5"/>
            <stop offset="100%" stop-color="#BB9847" stop-opacity=".5"/>
        </radialGradient>
        <radialGradient id="rai85fxnjf" cx="51.821%" cy="47.185%" r="123.386%" fx="51.821%" fy="47.185%" gradientTransform="scale(.4 1) rotate(20.692 .907 2.6)">
            <stop offset="0%" stop-color="#BB9847" stop-opacity=".5"/>
            <stop offset="39.009%" stop-color="#C1A050" stop-opacity=".5"/>
            <stop offset="59.591%" stop-color="#CBAB58" stop-opacity=".5"/>
            <stop offset="100%" stop-color="#BB9847" stop-opacity=".5"/>
        </radialGradient>
        <radialGradient id="1kahetqfwn" cx="51.821%" cy="47.185%" r="81.279%" fx="51.821%" fy="47.185%" gradientTransform="scale(.67308 1) rotate(32.439 .644 .904)">
            <stop offset="0%" stop-color="#BB9847" stop-opacity=".5"/>
            <stop offset="39.009%" stop-color="#C1A050" stop-opacity=".5"/>
            <stop offset="59.591%" stop-color="#CBAB58" stop-opacity=".5"/>
            <stop offset="100%" stop-color="#BB9847" stop-opacity=".5"/>
        </radialGradient>
        <radialGradient id="unwdzswi8p" cx="51.821%" cy="47.185%" r="73.17%" fx="51.821%" fy="47.185%" gradientTransform="matrix(.631 .59585 -.46816 .8031 .412 -.216)">
            <stop offset="0%" stop-color="#BB9847" stop-opacity=".5"/>
            <stop offset="39.009%" stop-color="#C1A050" stop-opacity=".5"/>
            <stop offset="59.591%" stop-color="#CBAB58" stop-opacity=".5"/>
            <stop offset="100%" stop-color="#BB9847" stop-opacity=".5"/>
        </radialGradient>
        <linearGradient id="bxt7ko7fvj" x1="28.721%" x2="71.332%" y1="35.722%" y2="77.996%">
            <stop offset="0%" stop-color="#A9924F"/>
            <stop offset="34.956%" stop-color="#D1C06F"/>
            <stop offset="60.494%" stop-color="#B19B55"/>
            <stop offset="100%" stop-color="#AC9551"/>
        </linearGradient>
        <linearGradient id="9tvhdp9tkm" x1="27.643%" x2="73.814%" y1="39.068%" y2="72.448%">
            <stop offset="0%" stop-color="#A9924F"/>
            <stop offset="34.956%" stop-color="#D1C06F"/>
            <stop offset="60.494%" stop-color="#B19B55"/>
            <stop offset="96.964%" stop-color="#AC9551"/>
        </linearGradient>
        <linearGradient id="ou1poazwgs" x1="35.692%" x2="64.344%" y1="35.722%" y2="77.996%">
            <stop offset="0%" stop-color="#B39A53"/>
            <stop offset="34.956%" stop-color="#D1C06F"/>
            <stop offset="60.494%" stop-color="#B19B55"/>
            <stop offset="100%" stop-color="#C2A85B"/>
        </linearGradient>
        <path id="63qehchd1i" d="M10.56 2.16h16.32c2.688 0 4.903 2.11 5.034 4.794l.948 19.44c.136 2.78-2.008 5.144-4.788 5.28-.082.004-.164.006-.246.006H9.612c-2.784 0-5.04-2.256-5.04-5.04 0-.082.002-.164.006-.246l.948-19.44C5.657 4.27 7.872 2.16 10.56 2.16z"/>
        <path id="x3vwc5it3l" d="M11.002 2.16H26.62c2.784 0 5.04 2.256 5.04 5.04 0 .423-.053.845-.159 1.255l-3.888 15.12c-.573 2.228-2.58 3.785-4.881 3.785h-7.41c-2.25 0-4.228-1.492-4.846-3.655l-4.32-15.12c-.765-2.677.785-5.466 3.461-6.231.45-.129.916-.194 1.385-.194z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g transform="translate(-99 -126) translate(99 126)">
                    <ellipse cx="16.56" cy="2.16" fill="url(#tz6la6waga)" filter="url(#lotezkefpb)" opacity=".265" rx="2.88" ry="2.16"/>
                    <ellipse cx="18" cy="10.08" fill="url(#tz6la6waga)" filter="url(#uqtqe8zrcc)" opacity=".462" rx="2.88" ry="2.16"/>
                    <ellipse cx="19.44" cy="18" fill="url(#tz6la6waga)" filter="url(#usq2kphxqd)" opacity=".462" rx="2.88" ry="2.16"/>
                    <ellipse cx="25.2" cy="26.64" fill="url(#tz6la6waga)" filter="url(#vnbechp6ne)" opacity=".265" rx="2.88" ry="2.16"/>
                    <ellipse cx="18.36" cy="31.68" fill="url(#rai85fxnjf)" filter="url(#npf5hg6rug)" opacity=".265" rx="5.4" ry="2.16"/>
                    <g>
                        <use fill="#000" filter="url(#yquncpuerh)" xlink:href="#63qehchd1i"/>
                        <use stroke="url(#bxt7ko7fvj)" stroke-width="1.44" xlink:href="#63qehchd1i"/>
                    </g>
                    <g>
                        <use fill="#000" filter="url(#4nmmp92hok)" xlink:href="#x3vwc5it3l"/>
                        <use stroke="url(#9tvhdp9tkm)" stroke-width="1.44" xlink:href="#x3vwc5it3l"/>
                    </g>
                    <path fill="url(#1kahetqfwn)" fill-opacity=".3" d="M7.248 2.16h23.193c2.784 0 5.04 2.256 5.04 5.04 0 .543-.088 1.083-.26 1.598l-5.054 15.12c-.688 2.056-2.613 3.442-4.78 3.442H12.864c-2.106 0-3.99-1.31-4.724-3.285L2.524 8.955c-.97-2.61.36-5.51 2.97-6.48.561-.208 1.155-.315 1.754-.315z" filter="url(#5cztzlfero)" opacity=".694"/>
                    <g fill="url(#unwdzswi8p)" opacity=".462" transform="translate(13.16 5.12)">
                        <ellipse cx="4.52" cy="3.1" filter="url(#s02sdqo2cq)" rx="3.64" ry="2.86"/>
                        <ellipse cx="6.6" cy="13.5" filter="url(#ff278ikg4r)" rx="3.64" ry="2.86"/>
                    </g>
                </g>
                <g fill="url(#ou1poazwgs)" fill-rule="nonzero" transform="translate(-99 -126) translate(99 126) translate(12 6)">
                    <path d="M10.515 0c.152 0 .276.125.276.274V1.66h1.937c.305 0 .553.248.553.554v3.6c0 1.066-.868 1.933-1.937 1.933h-.553c-.001 1.529-1.239 2.767-2.767 2.767h-.83v2.767h1.108c.916 0 1.659.745 1.659 1.66v1.66H3.32v-1.66c0-.916.742-1.66 1.658-1.66h1.108v-2.767h-.83c-1.527 0-2.765-1.235-2.767-2.767h-.553C.868 7.747 0 6.88 0 5.813v-3.6c0-.305.248-.553.553-.553H2.49V.274c0-.151.124-.274.276-.274h7.749zM2.49 2.767H1.107v3.046c0 .42.317.77.726.821l.104.007h.553V2.767zm9.684 0h-1.383V6.64h.553c.458 0 .83-.373.83-.828V2.767z"/>
                </g>
            </g>
        </g>
    </g>
  `,
];
