export const leaderboardBronzeStar = [
  "20 20",
  `
  <defs>
  <filter id="o3yjvjscqb" width="264.5%" height="309.4%" x="-82.2%" y="-104.7%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation="2.592"/>
  </filter>
  <filter id="tqegck41kc" width="264.5%" height="309.4%" x="-82.2%" y="-104.7%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation="2.592"/>
  </filter>
  <filter id="yvg5my9qsd" width="256.7%" height="253%" x="-78.3%" y="-80.2%" filterUnits="objectBoundingBox">
      <feMorphology in="SourceAlpha" operator="dilate" radius=".864" result="shadowSpreadOuter1"/>
      <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
      <feMorphology in="SourceAlpha" radius="1" result="shadowInner"/>
      <feOffset in="shadowInner" result="shadowInner"/>
      <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.952941176 0 0 0 0 0.709803922 0 0 0 0 0.380392157 0 0 0 0.2 0"/>
  </filter>
  <linearGradient id="wmn0493bya" x1="23.029%" x2="73.814%" y1="28.097%" y2="68.101%">
      <stop offset="0%" stop-color="#AA7034" stop-opacity=".5"/>
      <stop offset="34.157%" stop-color="#B6742F" stop-opacity=".5"/>
      <stop offset="61.761%" stop-color="#FCBE68" stop-opacity=".5"/>
      <stop offset="100%" stop-color="#AF7A3B" stop-opacity=".5"/>
  </linearGradient>
  <linearGradient id="g72l17ip4f" x1="23.029%" x2="73.814%" y1="14.521%" y2="79.32%">
      <stop offset="0%" stop-color="#AA7034"/>
      <stop offset="34.157%" stop-color="#B6742F"/>
      <stop offset="61.214%" stop-color="#FCBE68"/>
      <stop offset="100%" stop-color="#AF7A3B"/>
  </linearGradient>
  <path id="6ubyoq0yie" d="M10.407 16.628l-3.973 2.088c-.528.278-1.181.075-1.459-.453-.11-.21-.148-.451-.108-.685l.759-4.424c.06-.35-.057-.708-.311-.956L2.1 9.065c-.427-.416-.436-1.1-.02-1.527.166-.17.383-.28.619-.315l4.441-.645c.352-.051.656-.272.813-.59l1.987-4.026c.264-.535.911-.754 1.446-.49.213.105.385.277.49.49l1.987 4.025c.157.319.461.54.813.59l4.442.646c.59.086.999.634.913 1.224-.034.235-.145.453-.315.618l-3.214 3.133c-.254.248-.37.606-.31.956l.758 4.424c.101.588-.294 1.146-.882 1.247-.234.04-.474.002-.685-.109l-3.972-2.088c-.315-.166-.69-.166-1.005 0z"/>
</defs>
<g fill="none" fill-rule="evenodd">
  <g>
      <g>
          <g transform="translate(-967 -255) translate(966 255) translate(.09)">
              <ellipse cx="9.766" cy="4.026" fill="url(#wmn0493bya)" filter="url(#o3yjvjscqb)" rx="4.727" ry="3.714"/>
              <ellipse cx="12.468" cy="17.532" fill="url(#wmn0493bya)" filter="url(#tqegck41kc)" rx="4.727" ry="3.714"/>
              <g>
                  <use fill="#000" filter="url(#yvg5my9qsd)" xlink:href="#6ubyoq0yie"/>
                  <use stroke="url(#g72l17ip4f)" stroke-width="1.728" xlink:href="#6ubyoq0yie"/>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
