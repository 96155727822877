import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import TraineeAvatarWithConnectivity from "./TraineeAvatarWithConnectivity";

function ConnectedTraineesSlider({ isChat }) {
  const classes = useStyles({ isChat });
  const [traineesListWithStatus, setTraineesListWithStatus] = useState([]);

  const { connectedTrainees, scheduledTrainees } = useSelector(
    (state) => state.trainingReducer
  );

  useEffect(() => {
    try {
      const stringifiedAvatars = localStorage.getItem("allTraineesAvatars");
      const allTraineesAvatars = JSON.parse(stringifiedAvatars);
      const updatedTraineesList = scheduledTrainees.map((trainee) => ({
        ...trainee,
        connected: connectedTrainees.includes(trainee.email),
        avatarUrl: allTraineesAvatars[trainee.avatarID],
      }));
      setTraineesListWithStatus(updatedTraineesList);
    } catch (err) {
      console.warn("traineesListWithStatus failure", err);
    }
  }, [connectedTrainees, scheduledTrainees]);

  if (traineesListWithStatus.length > 1) {
    return (
      <div className={classes.Root}>
        <Slider
          arrows={false}
          infinite={traineesListWithStatus.length > 6 ? true : false}
          slidesToShow={6}
          autoplay={traineesListWithStatus.length > 6 ? true : false}
          autoplaySpeed={1000}
          cssEase={"linear"}
        >
          {traineesListWithStatus.map((trainee) => {
            return (
              <TraineeAvatarWithConnectivity
                trainee={trainee}
                isChat={isChat}
              />
            );
          })}
        </Slider>
      </div>
    );
  } else {
    return "Loading trainees...";
  }
}

const useStyles = makeStyles(() => ({
  Root: {
    width: (props) => (props.isChat ? "250px !important" : "400px !important"),
    marginBottom: (props) => (props.isChat ? "0.6rem" : "1rem"),
    marginTop: (props) => (props.isChat ? "0.6rem" : "1rem"),
  },
}));

export default ConnectedTraineesSlider;
