import React from "react";
import Box from "@material-ui/core/Box";

import MissionHint from "./MssionHint";

function MissionHints({ missionId, hintsProps, isDots, openAttachment }) {
  const { currentHint, hints } = hintsProps;

  if (!hints || !currentHint || currentHint === 0) return null;

  return (
    <Box
      width="100%"
      paddingTop="8px"
      borderTop="solid 1px rgba(255, 255, 255, 0.05)"
      marginTop={isDots ? "30px" : "6px"}
    >
      {hints.map((hint, index) => {
        if (index >= currentHint) return null;
        return (
          <MissionHint
            key={`${missionId}-${hint?.HintID || "no-id"}`}
            missionHint={hint}
            openAttachment={openAttachment}
          />
        );
      })}
    </Box>
  );
}

export default MissionHints;
