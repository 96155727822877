export const drawer = [
  "20 17",
  `
  <g fill="none" fill-rule="evenodd">
  <g fill="#FFF" fill-rule="nonzero">
      <g>
          <g>
              <g>
                  <path d="M18.125 19.25c.52 0 .963-.182 1.328-.547.365-.364.547-.807.547-1.328V3.625c0-.52-.182-.964-.547-1.328-.365-.365-.807-.547-1.328-.547H1.875c-.52 0-.964.182-1.328.547C.182 2.66 0 3.104 0 3.625v13.75c0 .52.182.964.547 1.328.364.365.807.547 1.328.547h16.25zm-9.063-9.062H1.875V4.875h7.187v5.313zm9.063 0h-7.188V4.875h7.188v5.313zm-9.063 7.187H2.11c-.052 0-.104-.026-.156-.078-.052-.052-.078-.104-.078-.156v-5.078h7.187v5.312zm8.829 0h-6.954v-5.312h7.188v5.078c0 .052-.026.104-.078.156-.052.052-.104.078-.156.078z" transform="translate(-1658 -206) translate(708 175) translate(937 20) translate(13 10)"/>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
