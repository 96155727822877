import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import TrainingPopupContainer from "../TrainingPopupContainer";
import TrainingPopupContainerHeader from "../TrainingPopupContainerHeader";
import TrainingHeaderTitle from "../TrainingHeaderTitle";
import ModalContainer from "../../base/modals/ModalContainer";
import FileView from "./FileViewer";
import Mission from "./Mission";

function MissionsContainer({ closeClick }) {
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [attachment, setAttachment] = useState({});
  const [scrolledElementToView, setScrolledElementToView] = useState(false);
  const {
    missions,
    progress,
    hints,
    active: activeMissionId,
  } = useSelector((state) => state.missionsReducer);

  const [currentMission, totalMissions] = useMemo(() => {
    const currentMissionIndex =
      missions.findIndex((mission) => mission.id === activeMissionId) + 1;
    return [currentMissionIndex, missions.length];
  }, [progress]);

  const resetState = () => {
    setOpen(false);
    setModalType("");
    setAttachment({});
  };

  const openAttachment = useCallback((name, type, value) => {
    setModalType("attachment");
    setAttachment({ name, type, value });
    setOpen(true);
  }, []);

  const closeAttachment = () => {
    resetState();
  };

  const modalChildren = () => {
    if (modalType === "attachment") {
      return <FileView closeClick={closeAttachment} {...attachment} />;
    }
    return <div />;
  };

  useEffect(() => {
    const firstMissionId = missions[0]?.id;
    const firstMissionElement = document.getElementById(
      `mission-${String(firstMissionId)}`
    );
    const currentActiveMission = document.getElementById(
      `mission-${String(activeMissionId)}`
    );

    if (firstMissionElement && activeMissionId) {
      scrollFirstMissionToView(firstMissionElement);
      setTimeout(() => {
        scrollMissionIntoViewSmooth(currentActiveMission);
      }, 500);
    }
  }, [activeMissionId, missions]);

  const scrollFirstMissionToView = (missionElement) => {
    if (!scrolledElementToView) {
      missionElement.scrollIntoView({
        behavior: "auto",
        block: "nearest",
        inline: "start",
      });
    }
  };
  const scrollMissionIntoViewSmooth = (missionElement) => {
    missionElement.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    setScrolledElementToView(true);
  };

  const headerSize = { width: "350px", height: "35px" };

  return (
    <>
      <TrainingPopupContainerHeader size={headerSize} closeClick={closeClick}>
        <TrainingHeaderTitle
          text={`${currentMission}/${totalMissions} Missions`}
        />
      </TrainingPopupContainerHeader>
      <TrainingPopupContainer width={"350px"}>
        {missions.map((mission, index) => {
          const { id } = mission;
          const myProgress = progress[id];
          const myHints = hints[id];
          return (
            <div id={`mission-${String(id)}`}>
              <Mission
                mission={mission}
                progress={myProgress}
                hints={myHints}
                openAttachment={openAttachment}
              />
            </div>
          );
        })}
      </TrainingPopupContainer>
      <ModalContainer open={open} position="left">
        {modalChildren()}
      </ModalContainer>
    </>
  );
}

export default MissionsContainer;
