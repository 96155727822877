export const incorrectIcon = [
  "21 21",
  `
  <g fill="none" fill-rule="evenodd">
  <g>
      <g>
          <g transform="translate(-781 -220) translate(102 150) translate(679 70)">
              <circle cx="10.5" cy="10.5" r="10.5" fill="#FF4747"/>
              <g stroke="#FFF" stroke-width="1.6">
                  <path d="M4.4 -0.8L4.4 8.8" transform="translate(6 7) rotate(-45 4.4 4)"/>
                  <path d="M4.4 -0.8L4.4 8.8" transform="translate(6 7) scale(-1 1) rotate(-45 0 14.623)"/>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
