import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

function CancelButton({ cancel }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ theme });

  return (
    <div className={classes.buttonRoot} onClick={cancel}>
      CANCEL
    </div>
  );
}

const useStyles = makeStyles(() => ({
  buttonRoot: {
    width: "108px",
    height: "35px",
    margin: "0 17px 0 0",
    padding: "8px 0",
    objectFit: "contain",
    borderRadius: "5px",
    WebkitBackdropFilter: "blur(6px)",
    backdropFilter: "blur(6px)",
    boxShadow:
      "0 0 3px 0 rgba(0, 0, 0, 0.31), inset 0 -2px 1px 0 rgba(0, 0, 0, 0.27)",
    backgroundColor: "rgba(255, 255, 255, 0.09)",
    textAlign: "center",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "15px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
    cursor: "pointer",
  },
}));

export default CancelButton;
