import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Draggable from "react-draggable";
import { Scrollbars } from "react-custom-scrollbars";
import { RiFontSize } from "react-icons/ri";
import MissionWelcomeButton from "../../buttons/MissionWelcomeButton";
import MerlinContainer from "../popupMessages/MerlinContainer";
import MissionBody from "./../missions/MissionBody";
import FileView from "./../missions/FileViewer";
import ModalContainer from "./../../base/modals/ModalContainer";
import SlickDots from "../../buttons/SlickDots";
import ScrollThumb from "./../../base/scroll/ScrollThumb";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementFontSize,
  decrementFontSize,
} from "./../../../store/actions/ui";
import { FaMinus } from "react-icons/fa";
import Box from "@material-ui/core/Box";
import MissionFooterHeaderText from "../missions/MissionFooterHeaderText";
import ConfirmButton from "../buttons/ConfirmButton";
import { openAskHintPopup } from "./../../../store/actions/hint";
import ShadowedText from "../shared/ShadowedText";
import { Tooltip } from "@material-ui/core";

function FloatingPopup({
  handle,
  data,
  openAttachment,
  closeAttachment,
  attachment,
  approveClick,
  modalOpen,
  dotClick,
  currentSlide,
  setCurrentSlide,
  sliderRef,
  defaultPosition,
  setDefaultPosition,
  ButtonTxt = "Proceed",
  informerAsButton,
  fullScreen,
}) {
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.uiReducer);
  const { topic, RichText, id } = data;
  const [expended, setExpended] = useState(true);

  const myHandle = `${handle}-${id || "no-id"}`;
  const { AvatarID } = useSelector((state) => state.scenarioItemReducer);
  const { fontSize } = useSelector((state) => state.uiReducer);

  const { hints: hintsArr } = useSelector((state) => state.missionsReducer);

  const hints = hintsArr[id] ? hintsArr[id] : {};
  const currentHint = hints?.currentHint || 0;
  const totalHints = hints?.totalHints || 0;
  const price = hints?.hints?.[currentHint]?.price;
  const myHintCost = price === undefined || price === null ? "" : price;

  const hintDisabled = !totalHints;

  // console.log("uiReducer - fontSize", fontSize);
  const classes = useStyles({ informerAsButton, theme });

  const onClickHint = () => {
    dispatch(openAskHintPopup(id));
  };

  const onPopupStop = (event, data) => {
    if (handle === "informer") {
      const { x, y } = data;
      if (x && y) {
        setDefaultPosition({ x, y });
      }
    }
  };

  const handlePopupExpendMinimizeClick = () => {
    setExpended((prev) => !prev);
  };

  console.log("informerAsButton", informerAsButton);

  return (
    <>
      <Draggable
        handle={`#draggable-handle-${myHandle}`}
        bounds="#vnc-card"
        defaultPosition={defaultPosition}
        onStop={onPopupStop}
      >
        <div
          id={handle === "informer" ? "informer" : "task"}
          className={classes.welcomeRoot}
        >
          <MerlinContainer
            avatarId={AvatarID}
            isDraggable={true}
            handle={myHandle}
            handlePopupExpendMinimizeClick={handlePopupExpendMinimizeClick}
            setExpended={setExpended}
            expended={expended}
          >
            {informerAsButton ? (
              <div></div>
            ) : (
              <div>
                <div className={classes.welcomeText}>
                  <div className={classes.headerBox}>
                    <Tooltip placement="top" title={"Minimize window"}>
                      <div className={classes.minimize}>
                        <FaMinus
                          className={classes.expendIcon}
                          onClick={handlePopupExpendMinimizeClick}
                        />
                      </div>
                    </Tooltip>
                    <div className={classes.title}> {topic}</div>
                    <div className={classes.fontSizeButtons}>
                      <span style={{ marginLeft: "auto" }}>
                        <button
                          className={classes.fontSizeButton}
                          onClick={() => dispatch(decrementFontSize())}
                          disabled={fontSize === 1}
                        >
                          -
                        </button>{" "}
                        <RiFontSize />{" "}
                        <button
                          className={classes.fontSizeButton}
                          onClick={() => dispatch(incrementFontSize())}
                          disabled={fontSize === 3}
                        >
                          +
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <Scrollbars
                  autoHeight
                  autoHeightMax={200}
                  autoHeightMin={145}
                  renderThumbVertical={ScrollThumb}
                >
                  <MissionBody
                    openAttachment={openAttachment}
                    missionBody={RichText}
                    missionId={id}
                    isPopup={true}
                    sliderRef={sliderRef}
                    setCurrentSlide={setCurrentSlide}
                    isFloatingPopup={true}
                  />
                </Scrollbars>
                <SlickDots
                  dots={RichText?.length}
                  dotClick={dotClick}
                  activeDot={currentSlide}
                />
              </div>
            )}

            {informerAsButton ? (
              <MissionWelcomeButton
                approveClick={approveClick}
                text={ButtonTxt}
                isBigger={true}
              />
            ) : (
              <Box
                display="flex"
                width="100%"
                height="50px"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <div>
                  {hintDisabled ? null : (
                    <>
                      <div className={classes.flexBox}>
                        <div>
                          <MissionFooterHeaderText
                            status="active"
                            text={`${currentHint}/${totalHints} hints`}
                            isHint={true}
                          />
                          <ConfirmButton
                            text="get hint"
                            type={"active"}
                            confirm={onClickHint}
                            disabled={
                              !totalHints ||
                              totalHints === 0 ||
                              currentHint === totalHints
                            }
                            size={{
                              width: "90px",
                              height: "26px",
                              font: "13px",
                            }}
                          />
                        </div>
                        <span className={classes.hintCost}>
                          {myHintCost && !hintDisabled ? (
                            <ShadowedText
                              type="red"
                              text={`-${myHintCost}`}
                              size="15px"
                            />
                          ) : null}
                        </span>
                      </div>
                    </>
                  )}
                </div>

                <div className={classes.buttonWrapper}>
                  <MissionWelcomeButton
                    approveClick={approveClick}
                    text={ButtonTxt}
                  />
                </div>
              </Box>
            )}
          </MerlinContainer>
        </div>
      </Draggable>
      <ModalContainer open={modalOpen} position="center">
        <FileView
          fullScreen={fullScreen}
          closeClick={closeAttachment}
          {...attachment}
        />
      </ModalContainer>
    </>
  );
}

const useStyles = makeStyles({
  welcomeRoot: {
    position: "fixed",
    bottom: (props) => (props.informerAsButton ? "" : 25),
    left: 0,
    border: "none",
    display: "flex",
    justifyContent: "start",
    alignItems: "flex-end",
    pointerEvents: "none",
  },
  welcomeText: {
    // display: "flex",
    textShadow: "0 0 6px rgba(0, 0, 0, 0.35)",
    fontFamily: (props) => props.theme.TRAINING_FONT,
    fontSize: "18px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    marginBottom: "11px",
    marginLeft: "10px",
  },

  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    pointerEvents: "auto",
  },
  image: {
    marginTop: "16px",
  },

  modalWrapper: {
    pointerEvents: "auto",
    maxHeight: "500px",
  },
  fontSizeButton: {
    fontSize: "15px",
    cursor: "pointer",
    border: "none",
    borderRadius: "20px",
    color: "#BC314B",
    width: "20px",
    height: "20px",
  },
  expendIcon: {
    color: (props) => props.theme.COLOR_PRIMARY,
    cursor: "pointer",
    marginRight: "10px",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  headerBox: {
    display: "flex",
  },
  title: {
    flexGrow: "20",
  },
  minimize: {
    flexGrow: "1",
  },
  fontSizeButtons: {
    minWidth: "72px",
  },
  flexBox: {
    marginLeft: "5px",
    display: "flex",
  },
  hintCost: {
    marginLeft: "5px",
    marginBottom: "2px",
    alignSelf: "flex-end",
  },
});

export default FloatingPopup;
