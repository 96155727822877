import axios from "axios";
import { toast } from "react-toastify";
import useToken from "../hooks/useToken";
import useEmail from "../hooks/useEmail";

axios.defaults.headers.common["Authorization"] = useToken();
axios.defaults.headers.common["ClientEmail"] = useEmail();

const onResponse = (response) => {
  return response;
};

const onResponseError = (error) => {
  if (error.response.status === 401) {
    console.log("Response status 401: ", error.response.status);
    console.log(error);
    localStorage.removeItem("token");
    localStorage.clear();
  }      

  if (error.response.status === 403) {
    console.log("Response status 403: ", error.response.status);
    console.log(error);
    toast.error(
      "You are not authorized to do this action, please contact your superviser for permissions.",
      {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    toast.clearWaitingQueue();
  } else if (error.response.status > 409) {
    console.log("Somthing went wrong with that request", error);
    toast.error(
      "Something went wrong, our team is notified. Sorry for the inconvenience.",
      {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    toast.clearWaitingQueue();
  }
  return error;
};

export function setupInterceptorsTo(axiosInstance) {
  axiosInstance.interceptors.response.use(
    function (response) {
      onResponse(response);
      return response;
    },
    function (error) {
      onResponseError(error);
      return error;
    }
  );
}
