import axios from "axios";

const myBaseURL =
  process.env.REACT_APP_ENVIRONMENT.trim() === "DEV"
    ? process.env.REACT_APP_BASE_URL_LOCAL_DEV
    : process.env.REACT_APP_BASE_URL_CLOUD;


  export const getStaticMitreData = async () => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
  
    try {
      const res = await axios
        .get(`${myBaseURL}/Mitre`, axiosConfig);
      return res;
    } catch (err) {
      console.error("Mitre fetch ERROR: ", err);
    }
  };

  export const getUserMitreScores = async (userID) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
  
    return axios
      .get(`${myBaseURL}/Mitre/User/${userID}`, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("Organization Mitre Coverage fetch ERROR: ", err);
      });
  };

    export const getOrganizationMitreCoverage = async (organizationID) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios
      .get(`${myBaseURL}/Mitre/Organization/${organizationID}/Coverage`, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("Organization Mitre Coverage fetch ERROR: ", err);
      });
  };



  export const getOrganizationMitreScores = async (organizationID) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",     
        },
    };
  
    return axios
      .get(`${myBaseURL}/Mitre/Organization/${organizationID}/Scores`, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("Organization Mitre Coverage fetch ERROR: ", err);
      });
  };

  export const getUserTrainingMitreScores = async (userID, trainingID) => {
  const axiosConfig = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",    
        },
    };
  
    try {
      const res = await axios
        .get(`${myBaseURL}/Mitre/User/${userID}/Training/${trainingID}`, axiosConfig);
      return res;
    } catch (err) {
      console.error("Mitre fetch ERROR: ", err);
    }
  };
