import React, { useState, useMemo, useRef, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Document, Page } from "react-pdf";
import PerfectScrollbar from "react-perfect-scrollbar";
import GetAppIcon from "@material-ui/icons/GetApp";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CIcon from "@coreui/icons-react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";

import { downloadFile } from "./../../../services/downloadFile";
import { useSelector } from "react-redux";

function useWindowSize(divRef) {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([divRef.current.clientWidth]);
    }
    divRef.current.addEventListener("resize", updateSize);
    updateSize();
    return () => divRef.current.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function FileView({
  closeClick,
  // width = "1100",
  scale = 0.8,
  isPreview = false,
  value,
  type,
  name,
  fullScreen,
}) {
  const [numPages, setNumPages] = useState(null);
  const { theme } = useSelector((state) => state.uiReducer);

  const classes = useStyles({ isPreview, fullScreen, theme });

  const divRef = useRef();
  const [width] = useWindowSize(divRef);

  const openInNewTab = () => {
    const newWindow = window.open(value, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const ondownloadClick = () => {
    downloadFile(value, `${name}.${type}`);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(isPreview ? 1 : numPages);
  };

  const viewBody = useMemo(() => {
    if (type === "pdf") {
      return (
        <PerfectScrollbar height="100%">
          <Document
            file={value}
            className={classes.document}
            onLoadError={(error) => console.log("pdf error: ", error)}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {numPages ? (
              _.times(numPages, (index) => {
                return (
                  <div key={index}>
                    <Page
                      pageNumber={index + 1}
                      width={isPreview ? 0.8 * width : width}
                    />
                    {isPreview ? null : (
                      <div className={classes.page}>{index + 1}</div>
                    )}
                  </div>
                );
              })
            ) : (
              <div />
            )}
          </Document>
        </PerfectScrollbar>
      );
    }
    if (type === "gif" || type === "png" || type === "jpg" || type === "jpeg") {
      return (
        <img
          src={value}
          width="auto"
          height="100%"
          style={{ maxWidth: "100%" }}
        />
      );
    }

    if (type === "url") {
      return <iframe src={value} width="100%" height="100%" />;
    }
  }, [type, value, numPages]);

  if (isPreview) {
    return <div ref={divRef}>{viewBody}</div>;
  }

  return (
    <div className={classes.viewerRoot}>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        ref={divRef}
        className={classes.opacity}
      >
        <div>
          {type !== "url" ? (
            <Tooltip title="Download" placement="top">
              <IconButton aria-label="download-file" onClick={ondownloadClick}>
                <GetAppIcon className={classes.icon} fontSize="inherit" />
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip title="Open in new tab" placement="top">
            <IconButton aria-label="open-file" onClick={openInNewTab}>
              <OpenInNewIcon className={classes.icon} fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>
        <CIcon
          name="trainingCloseIcon"
          className={classes.icon}
          width="30px"
          height="30px"
          onClick={closeClick}
        />
      </Box>
      <div className={classes.viewBody}>{viewBody}</div>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  viewerRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: (props) => (props.fullScreen ? "90%" : "960px"), // "960px",
    height: (props) => (props.fullScreen ? "90%" : "50%"), // "50%",
    minWidth: "500px",
    minHeight: "450px",
    maxWidth: "100vw",
    padding: "0 15px 15px 15px",
    borderRadius: "10px",
    WebkitBackdropFilter: "blur(5px)",
    backdropFilter: "blur(5px)",
    boxShadow: "0 0 2px 0 rgba(255, 255, 255, 0.47)",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
  },
  previewWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    width: "100%",
  },
  header: {
    marginBottom: "10px",
    marginTop: "5px",
    width: "100%",
  },
  icon: {
    cursor: "pointer",
    color: "rgb(113,115,118)",
    // marginRight: "10px",
    fontSize: "25px",
  },
  document: {
    margin: "0 auto",
    width: "100%",
    height: "100%",
    paddingTop: (props) => (props.isPreview ? "8px" : ""),
    cursor: (props) => (props.isPreview ? "pointer" : "auto"),
  },
  page: {
    fontSize: "12px",
    margin: "5px 0",
    textAlign: "center",
    color: "#ffffff",
    fontFamily: (props) => props.theme.MAIN_FONT,
  },
  viewBody: {
    flex: 1,
    width: "100%",
    maxHeight: "calc(100% - 45px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  // opacity: {
  //   opacity: "0.8",
  // },
}));

export default FileView;
