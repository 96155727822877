import React, { useMemo, memo } from "react";
import { makeStyles } from "@material-ui/core";
import moment from "moment";

import RoundAvatar from "./../../avatar/Avatar";
import messageBoxBg from "../../../assets/backgrounds/training/messageBox.svg";
import messageBoxLeftBg from "../../../assets/backgrounds/training/messageBoxLeft.svg";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";

function ChatMessage({
  nameColor,
  name,
  text,
  time,
  isLeft = false,
  displayName,
  avatarId
}) {
  const { userDetails } = useSelector((state) => state.personalHallReducer);
  console.log("ChatMessage", avatarId);
  const padding = useMemo(() => {
    const len = text.length;
    if (isLeft) {
      const rightPadding = 12 + Math.round(7 * (len / 300));
      return `8px ${rightPadding}px 9px 8px `;
    } else {
      const leftPadding = 12 + Math.round(7 * (len / 300));
      return `8px 9px 8px ${leftPadding}px`;
    }
  }, [text]);

  const classes = useStyles({ nameColor, padding, isLeft });

  return (
    <div className={classes.messageRoot}>
      {isLeft ? null : (
        <RoundAvatar username={name} size={30} randomColor={true} avatarId={avatarId}/>
      )}
      <div className={classes.bodyWrapper}>
        <img
          src={isLeft ? messageBoxLeftBg : messageBoxBg}
          width="100%"
          height="100%"
          className={classes.bgImage}
        />
        <div className={classes.messageBox}>
          <div className={classes.header}>
            <Tooltip title={name}>
              <div className={classes.username}>{displayName}</div>
            </Tooltip>
            <div className={classes.time}>{moment().format("H:mm")}</div>
          </div>
          <div className={classes.messageBoxBody}>{text}</div>
        </div>
      </div>
      {isLeft ? (
        <RoundAvatar
          username={name}
          size={30}
          avatarId={userDetails?.avatarId}
        />
      ) : null}
    </div>
  );
}

const useStyles = makeStyles({
  messageRoot: {
    display: "flex",
    width: "229px",
    minHeight: "60px",
    marginBottom: "8px",
  },
  bodyWrapper: {
    position: "relative",
    width: "200px",
    flexGrow: 1,
  },
  messageBox: {
    width: "100%",
    height: "100%",
    padding: (props) => props.padding,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  messageBoxHeader: {
    height: "12px",
  },
  bgImage: {
    position: "absolute",
    zIndex: -1,
  },
  username: {
    color: (props) => props.nameColor || "#CE496B",
    fontFamily: "NunitoSans",
    fontSize: "16px",
    fontWeight: "600",
    letterSpacing: "0",
    textAlign: "center",
  },
  time: {
    color: "rgba(255,255,255,0.5)",
    fontFamily: "NunitoSans",
    fontSize: "12px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "12px",
  },
  messageBoxBody: {
    fontFamily: "NunitoSans",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.2",
    letterSpacing: "normal",
    color: "#ffffff",
    marginTop: "3px",
  },
});

export default memo(ChatMessage);
