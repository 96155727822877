import React, { memo } from "react";
import { makeStyles } from "@material-ui/core";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import Box from "@material-ui/core/Box";

import locked_task_badge from "../../../assets/icons/training/missions/locked_task.png";
import active_task_badge from "../../../assets/icons/training/missions/task.png";
import finished_task_badge from "../../../assets/icons/training/missions/finished_task.png";
import locked_informer from "../../../assets/icons/training/missions/locked_informer.png";
import informer from "../../../assets/icons/training/missions/informer.png";
import locked_question_badge from "../../../assets/icons/training/missions/locked_question.png";
import active_question_badge from "../../../assets/icons/training/missions/question.png";
import finishedQuestionBadge from "../../../assets/icons/training/missions/finished_question.png";

function MissionBadge({ status, type, progress = 50 }) {
  const classes = useStyles();

  if (status === "locked") {
    if (type === "task") {
      return (
        <div className={classes.missionBadge}>
          <img
            src={locked_task_badge}
            alt="locked_task_badge"
            height="40"
            width="40"
          />
        </div>
      );
    }
    if (type === "question") {
      return (
        <div className={classes.missionBadge}>
          <img
            src={locked_question_badge}
            alt="locked_question_badge"
            height="40"
            width="40"
          />
        </div>
      );
    }
    if (type === "informer") {
      return (
        <div className={classes.missionBadge}>
          <img
            src={locked_informer}
            alt="locked_informer"
            height="40"
            width="40"
          />
        </div>
      );
    }
  }

  if (status === "active") {
    if (type === "task") {
      return (
        <div className={classes.missionBadge}>
          <img
            src={active_task_badge}
            alt="active_task_badge"
            height="40"
            width="40"
          />
        </div>
      );
    }
    if (type === "question") {
      return (
        <div className={classes.missionBadge}>
          <img
            src={active_question_badge}
            alt="active_question_badge"
            height="40"
            width="40"
          />
        </div>
      );
    }
    if (type === "informer") {
      return (
        <div className={classes.missionBadge}>
          <img src={informer} alt="informer" height="40" width="40" />
        </div>
      );
    }
  }

  if (status === "finished") {
    if (type === "task") {
      return (
        <div className={classes.missionBadge}>
          <img
            src={finished_task_badge}
            alt="active_task_badge"
            height="40"
            width="40"
          />
        </div>
      );
    }
    if (type === "question") {
      return (
        <div className={classes.missionBadge}>
          <img
            src={finishedQuestionBadge}
            alt="active_question_badge"
            height="40"
            width="40"
          />
        </div>
      );
    }
    if (type === "informer") {
      return (
        <div className={classes.missionBadge}>
          <img src={informer} alt="informer" height="40" width="40" />
        </div>
      );
    }
  } else if (status === "debrief") {
    if (type === "task") {
      return (
        <div className={classes.finished}>
          <img
            src={finished_task_badge}
            width="37px"
            height="34px"
            alt="finished-badge"
          />
        </div>
      );
    }
    if (type === "informer") {
      return (
        <div className={classes.missionBadge}>
          <img src={informer} alt="informer" height="40" width="40" />
        </div>
      );
    }
    if (type === "question") {
      return (
        <div className={classes.finished}>
          <img
            src={finishedQuestionBadge}
            width="37px"
            height="34px"
            alt="finished-badge"
          />
        </div>
      );
    }
  }

  // if (status === "active") {
  //   return (
  //     <Box position="relative" display="inline-flex" justifyContent="center">
  //       <CircularProgress
  //         className={classes.top}
  //         size={34}
  //         thickness={0.7}
  //         variant="determinate"
  //         value={0}
  //       />
  //       <img
  //         src={active_task_badge}
  //         width="36px"
  //         height="32px"
  //         className={classes.image}
  //         alt="active-badge"
  //       />
  //     </Box>
  //   );
  // }
  return <div />;
}

const useStyles = makeStyles({
  bottom: {
    color: "gray",
  },
  top: {
    // position: "absolute",
    color: "#15ec61",
    // transform: "scaleX(0.9)",
  },
  // finished: {
  //   paddingTop: "8px",
  // },
  image: {
    position: "absolute",
    paddingLeft: "1px",
    paddingTop: "1px",
  },
});

export default memo(MissionBadge);
