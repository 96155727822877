import React from "react";
import { Box, makeStyles } from "@material-ui/core";

import MissionFooterScore from "./MissionFooterScore";
import MissionFooterRings from "./MissionFooterRings";
import MissionFooterHints from "./MissionFooterHints";
import { useSelector } from "react-redux";

function MissionFooter({ missionId, progress = {}, hints, type = "task" }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const { currentRings, currentScore, status, totalRings, totalScore } =
    progress;

  const classes = useStyles({ status, theme });

  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="space-between"
      className={classes.footerRoot}
    >
      <MissionFooterScore
        currentScore={currentScore}
        totalScore={totalScore}
        status={status}
      />
      <MissionFooterRings
        currentRings={currentRings}
        totalRings={totalRings}
        status={status}
      />
      <MissionFooterHints
        status={status}
        hints={hints}
        type={type}
        id={missionId}
      />
    </Box>
  );
}

const useStyles = makeStyles({
  footerRoot: {
    fontFamily: (props) => props.theme.MAIN_FONT,
    height: "60px",
    textAlign: "center",
    padding: "7px 0",
    // padding: (props) => (props.status === "finished" ? "0" : "7px 0"),
    marginTop: "4px",
    width: "100%",
    // maxWidth: (props) => (props.status === "finished" ? "80%" : "100%"),
  },
});

export default MissionFooter;
