const saveCurrentStateToLS = (currentState, type) => {
  try {
    localStorage.setItem(type, JSON.stringify(currentState));
  } catch (err) {
    console.warn("Could not save state to LocalStorage", err);
  }
};

module.exports = {
  saveCurrentStateToLS,
};
