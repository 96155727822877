import React, { useEffect, useRef, memo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import CIcon from "@coreui/icons-react";
import { BiExit } from "react-icons/bi";
import Timer from "react-compound-timer";
import { useSelector, useDispatch } from "react-redux";
import { setTimerWarnings } from "./../../store/actions/training";

function TimerBox({
  time = 0,
  start = true,
  margin = "-3px 0px 11px 115px",
  padding = "10px",
  fontSize = "13.2px",
  width = "120px",
  hourglassSize = [],
  onTimerStop,
  isTimeline = false,
  OpenExitTrainingModal,
}) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ margin, fontSize, width, padding, theme });
  const dispatch = useDispatch();
  const timerRef = useRef(null);

  const { trainingStatus, trainingTimer } = useSelector((state) => state.trainingReducer);
  const { ScenarioDuration } = useSelector(
    (state) => state.scenarioItemReducer
  );
  const [timerSeconds, setTimerSeconds] = useState(null);

  const MINUTES_TO_TIMER_FORMAT = 60000;
  const SECONDES_TO_TIMER_FORMAT = 1000;
  const type = time === 0 ? "backward" : "forward";

  useEffect(() => {
    if (ScenarioDuration && trainingTimer) {
      setTimerSeconds(
        ScenarioDuration * MINUTES_TO_TIMER_FORMAT -
          trainingTimer * SECONDES_TO_TIMER_FORMAT
      );
    }
  }, [trainingTimer, ScenarioDuration]);

  useEffect(() => {
    if (trainingStatus === "started") {
      toggleTimer("start");
    }
    if (trainingStatus === "finished") {
      toggleTimer("end");
    }
  }, [trainingStatus, timerSeconds]);

  const toggleTimer = (type) => {
    if (timerRef?.current) {
      if (type === "start") {
        timerRef.current.start();
      } else {
        timerRef.current.stop();
      }
    }
  };

  const timerValueFormat = (value) => {
    let myValue = value < 10 ? `0${value}` : value;
    return <div className={classes.time}>{myValue}</div>;
  };

  const handleTimerStop = () => {
    onTimerStop();
  };

  const handleTimerWarnings = (minutesLeft) => {
    console.log(`set ${minutesLeft} minutes left`);
    dispatch(setTimerWarnings(minutesLeft));
  };

  return (
    <div className={classes.timer}>
      <div className={classes.boxRoot}>
        <CIcon
          name={isTimeline ? "hourglassRed" : "hourglass"}
          width={hourglassSize[0] || "23px"}
          height={hourglassSize[1] || "33px"}
        />

        {timerSeconds ? (
          <Timer
            formatValue={timerValueFormat}
            initialTime={timerSeconds}
            checkpoints={[
              {
                time: 0,
                callback: () => handleTimerStop(),
              },
              {
                time: 15 * MINUTES_TO_TIMER_FORMAT,
                callback: () => handleTimerWarnings(15),
              },
              {
                time: 5 * MINUTES_TO_TIMER_FORMAT,
                callback: () => handleTimerWarnings(5),
              },
              {
                time: 1 * MINUTES_TO_TIMER_FORMAT,
                callback: () => handleTimerWarnings(1),
              },
            ]}
            direction={type}
            startImmediately={start}
            ref={timerRef}
          >
            {({ start, resume, pause, stop, reset, timerState }) => (
              <>
                <Timer.Hours />:
                <Timer.Minutes />:
                <Timer.Seconds />
              </>
            )}
          </Timer>
        ) : (
          <div className={classes.time}>00:00:00</div>
        )}
        <div
          style={{
            paddingLeft: "4px",
          }}
        >
          <span
            className={classes.exitButton}
            onClick={() => OpenExitTrainingModal()}
          >
            <BiExit size={16} />
          </span>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  boxRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: (props) => props.width,
    height: "32px",
    margin: (props) => props.margin,
    padding: (props) => props.padding,
    opacity: "0.7",
    borderRadius: "16.2px",
    boxShadow: "inset 0 0 15px 0 rgba(0, 0, 0, 0.82)",
    backgroundColor: "#000000",
  },
  time: {
    textShadow: "0 0 7px #0054c3",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: (props) => props.fontSize,
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#ffffff",
  },
  timer: {
    display: "flex",
  },
  exitButton: {
    pointerEvents: "auto",
    cursor: "pointer",
    color: "#ed174e",
    "&:hover": {
      color: "#f35e84",
    },
    display: "flex",
  },
}));

export default memo(TimerBox);
