import { SET_INFORMER, REMOVE_INFORMER, RESET_STATE } from "../types/informer";

const initialState = [];

const informerReducer = (state = initialState, action) => {
  const { informer, id, type } = action;

  switch (type) {
    case SET_INFORMER:
      return [...state, { ...informer }];
    case REMOVE_INFORMER:
      return state.filter((el) => el.id !== id);
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default informerReducer;
