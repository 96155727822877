import { fork } from "redux-saga/effects";
import { ReadWrite } from "./read";
import { scenarioListSaga } from "./scenarioList";
import { scenarioSaga } from "./scenario";
import { personalHallSaga } from "./personalHall";
import { scheduledSaga } from "./scheduled";
import { userSaga } from "./user";
import { mitreDBSaga } from "./mitreDB";
import { organizationTokensSummarySaga } from "./organizationTokensSummary"; 

export default function* rootSaga() {
  yield fork(ReadWrite);
  yield fork(scenarioListSaga);
  yield fork(scenarioSaga);
  yield fork(personalHallSaga);
  yield fork(scheduledSaga);
  yield fork(mitreDBSaga);
  yield fork(userSaga);
  yield fork(organizationTokensSummarySaga);
}
