import {
  SET_MODAL_STATE,
  SET_ACTIVE_MENU,
  SET_ACTIVE_HALL,
  INCREMENT_POPUP_COUNT,
  DECREMENT_POPUP_COUNT,
  UPDATE_SCORE,
  UPDATE_TIME,
  UPDATE_RINGS,
  DECREMENT_FONT_SIZE,
  INCREMENT_FONT_SIZE,
  RESET_TRAINING_STATE,
  RESET_POPUP_COUNT,
  SET_THEME
} from "../types/ui";

export const setModalState = (modalState, modalType) => {
  return { type: SET_MODAL_STATE, modalState, modalType };
};

export const setActiveMenu = (activeMenu) => {
  return { type: SET_ACTIVE_MENU, activeMenu };
};
export const setActiveHall = (activeHall) => {
  return { type: SET_ACTIVE_HALL, activeHall };
};

export const incrementPopupCount = (popup, number) => {
  return { type: INCREMENT_POPUP_COUNT, popup, number };
};

export const decrementPopupCount = (popup, number) => {
  return { type: DECREMENT_POPUP_COUNT, popup, number };
};

export const resetPopupCount = (popup) => {
  return { type: RESET_POPUP_COUNT, popup };
};
export const setTheme = (theme) => {
  return { type: SET_THEME, theme };
};
export const updateScore = (score) => {
  return { type: UPDATE_SCORE, score };
};

export const updateTime = (time) => {
  return { type: UPDATE_TIME, time };
};
export const updateRings = (rings) => {
  return { type: UPDATE_RINGS, rings };
};
export const decrementFontSize = () => {
  return { type: DECREMENT_FONT_SIZE };
};

export const incrementFontSize = () => {
  return { type: INCREMENT_FONT_SIZE };
};

export const resetTrainingUiState = () => {
  return { type: RESET_TRAINING_STATE };
};
