import { SET_QUESTION, REMOVE_QUESTION, RESET_STATE } from "../types/quiz";

const initialState = [];

const quizReducer = (state = initialState, action) => {
  const { question, id, type } = action;

  switch (type) {
    case SET_QUESTION:
      return [...state, { ...question }];
    case REMOVE_QUESTION:
      return state.filter((el) => el.id !== id);
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
export default quizReducer;
