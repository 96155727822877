export const sendMessage = [
  "25 25",
  `
  <defs>
  <linearGradient x1="50%" y1="0%" x2="50%" y2="99.6245698%" id="linearGradient-1">
      <stop stop-color="#ED164D" offset="0%"></stop>
      <stop stop-color="#BC0C39" offset="100%"></stop>
  </linearGradient>
  <circle id="path-2" cx="12.5" cy="12.5" r="12.5"></circle>
  <filter x="-6.0%" y="-6.0%" width="112.0%" height="112.0%" filterUnits="objectBoundingBox" id="filter-333">
      <feGaussianBlur stdDeviation="0.5" in="SourceAlpha" result="shadowBlurInner1"></feGaussianBlur>
      <feOffset dx="0" dy="-2" in="shadowBlurInner1" result="shadowOffsetInner1"></feOffset>
      <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"></feComposite>
      <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.267313497 0" type="matrix" in="shadowInnerInner1"></feColorMatrix>
  </filter>
  <path d="M5.22666667,10.5202347 L7.65602133,11.350528 L6.035456,13.5558827 C5.94944,13.67296 5.81444267,13.7386667 5.67466667,13.7386667 C5.62807467,13.7386667 5.58088533,13.7314987 5.53489067,13.7165653 C5.350912,13.6556373 5.22666667,13.4842027 5.22666667,13.2906667 L5.22666667,13.2906667 L5.22666667,10.5202347 Z M13.6807253,0.0507733333 C13.8300587,-0.0274773333 14.0104533,-0.014336 14.14784,0.0830293333 C14.2852267,0.180992 14.355712,0.347050667 14.3312213,0.514304 L14.3312213,0.514304 L12.6885547,11.5649707 C12.6688427,11.6957867 12.5917867,11.8122667 12.4782933,11.8815573 C12.4072107,11.9245653 12.3265707,11.9466667 12.2453333,11.9466667 C12.196352,11.9466667 12.1473707,11.938304 12.1007787,11.9227733 L12.1007787,11.9227733 L5.83893333,9.782528 L11.9962453,2.36424533 L4.039168,9.167872 L0.302848,7.89077333 C0.133802667,7.832832 0.0149333333,7.67991467 0.00119466666,7.50071467 C-0.0119466667,7.322112 0.0818346667,7.15246933 0.240725333,7.06944 L0.240725333,7.06944 Z" id="path-4"></path>
  <filter x="-7.0%" y="-7.3%" width="114.0%" height="114.6%" filterUnits="objectBoundingBox" id="filter-555">
      <feGaussianBlur stdDeviation="0.5" in="SourceAlpha" result="shadowBlurInner1"></feGaussianBlur>
      <feOffset dx="0" dy="-1" in="shadowBlurInner1" result="shadowOffsetInner1"></feOffset>
      <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"></feComposite>
      <feColorMatrix values="0 0 0 0 0.91372549   0 0 0 0 0.0862745098   0 0 0 0 0.298039216  0 0 0 0.456428581 0" type="matrix" in="shadowInnerInner1"></feColorMatrix>
  </filter>
</defs>
<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="Cybring-training-flow-(4.1)" transform="translate(-1319.000000, -587.000000)">
      <g id="Group-9" transform="translate(1104.000000, 251.000000)">
          <g id="Group-13" transform="translate(215.000000, 336.000000)">
              <g id="Oval">
                  <use fill="url(#linearGradient-1)" fill-rule="evenodd" xlink:href="#path-2"></use>
                  <use fill="black" fill-opacity="1" filter="url(#filter-333)" xlink:href="#path-2"></use>
              </g>
              <g id="paper-plane" transform="translate(4.000000, 6.000000)" fill-rule="nonzero">
                  <g id="Combined-Shape">
                      <use fill="#FFFFFF" xlink:href="#path-4"></use>
                      <use fill="black" fill-opacity="1" filter="url(#filter-555)" xlink:href="#path-4"></use>
                  </g>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
