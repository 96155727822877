export const pinkMission = [
  "34 34",
  `
  <defs>
  <filter id="nx4syk4r7a">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.929412 0 0 0 0 0.086275 0 0 0 0 0.301961 0 0 0 1.000000 0"/>
  </filter>
  <filter id="o98z36bvlb" width="250%" height="250%" x="-75%" y="-75%" filterUnits="objectBoundingBox">
      <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.5"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.929411765 0 0 0 0 0.0862745098 0 0 0 0 0.301960784 0 0 0 0.8 0"/>
  </filter>
  <circle id="b4cvgz1b6c" cx="7.92" cy="10" r="7"/>
</defs>
<g fill="none" fill-rule="evenodd">
  <g>
      <g>
          <g>
              <g>
                  <g transform="translate(-1119 -659) translate(1104 251) translate(7 48) translate(0 256) translate(9 105)">
                      <g filter="url(#nx4syk4r7a)" transform="translate(8 7)">
                          <g fill="#000" fill-rule="nonzero" transform="translate(0 .92)">
                              <use filter="url(#o98z36bvlb)" xlink:href="#b4cvgz1b6c"/>
                              <path d="M14.074 1.734H12.743V.673c0-.424-.405-.674-.828-.674h-1.137c-.423 0-.732.25-.732.674v1.06H5.424V.673C5.424.25 5.116 0 4.692 0H3.556c-.424 0-.829.25-.829.674v1.06H1.495C.708 1.711.052 2.331.03 3.117V14.35c0 .848.617 1.637 1.465 1.637h12.48c.848 0 1.464-.79 1.464-1.637V3.217c.033-.787-.579-1.45-1.365-1.483zM10.778.77h1.195v2.12h-1.195V.77zm-7.222 0h1.097v2.12H3.556V.77zm11.113 13.58c0 .423-.27.866-.693.866H1.495c-.424 0-.694-.443-.694-.867V6.163H14.67v8.186zm0-11.133v2.176H.801V3.217c-.032-.36.233-.68.593-.712.034-.003.067-.003.1-.001h1.233v.424c0 .423.405.732.829.732h1.136c.393.01.721-.3.732-.693V2.504h4.622v.424c-.01.393.3.72.693.732h1.176c.424 0 .828-.309.828-.732v-.424h1.233c.36-.023.672.251.694.612.003.034.002.067 0 .1z"/>
                              <path d="M5.597 11.422l-.346 1.926c-.014.082 0 .167.038.241.099.189.332.262.52.163l1.734-.905 1.733.905.173.039c.084.001.165-.026.231-.077.116-.084.175-.225.155-.366l-.347-1.926 1.406-1.349c.1-.108.137-.262.096-.404-.052-.132-.169-.227-.308-.25l-1.926-.29-.867-1.752c-.038-.078-.1-.141-.179-.179-.191-.093-.421-.013-.514.179L6.329 9.13l-1.926.289c-.14.023-.256.118-.308.25-.041.142-.004.296.096.404l1.406 1.349zm1.06-1.56c.12-.019.223-.1.27-.212l.616-1.233.616 1.233c.046.113.149.193.27.212l1.367.192-.982.944c-.092.09-.135.22-.116.347l.231 1.367-1.213-.655-.174-.038-.173.038-1.213.655.23-1.367c.02-.128-.023-.257-.115-.347l-.982-.944 1.368-.192z"/>
                          </g>
                      </g>
                      <circle cx="15.907" cy="15.907" r="15.907" stroke="#FFF" stroke-opacity=".282" stroke-width=".75"/>
                  </g>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
