export const leaderboardSilverStar = [
  "20 20",
  `
  <defs>
  <filter id="l3dwoya41b" width="264.5%" height="309.4%" x="-82.2%" y="-104.7%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation="2.592"/>
  </filter>
  <filter id="j4vwwpk60c" width="264.5%" height="309.4%" x="-82.2%" y="-104.7%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation="2.592"/>
  </filter>
  <filter id="p1jmy5xxid" width="256.7%" height="253%" x="-78.3%" y="-80.2%" filterUnits="objectBoundingBox">
      <feMorphology in="SourceAlpha" operator="dilate" radius=".864" result="shadowSpreadOuter1"/>
      <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
      <feMorphology in="SourceAlpha" radius="1" result="shadowInner"/>
      <feOffset in="shadowInner" result="shadowInner"/>
      <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"/>
  </filter>
  <linearGradient id="nvmld3iica" x1="27.643%" x2="73.814%" y1="41.186%" y2="68.101%">
      <stop offset="0%" stop-color="#F2F2F2"/>
      <stop offset="100%" stop-color="#C8C8C8"/>
  </linearGradient>
  <linearGradient id="ekb1in4bdf" x1="27.643%" x2="73.814%" y1="35.722%" y2="79.32%">
      <stop offset="0%" stop-color="#505050"/>
      <stop offset="42.313%" stop-color="#F2F2F2"/>
      <stop offset="51.215%" stop-color="#C8C8C8"/>
      <stop offset="100%" stop-color="#4E4E4E"/>
  </linearGradient>
  <path id="aopk13fuxe" d="M10.407 16.628l-3.973 2.088c-.528.278-1.181.075-1.459-.453-.11-.21-.148-.451-.108-.685l.759-4.424c.06-.35-.057-.708-.311-.956L2.1 9.065c-.427-.416-.436-1.1-.02-1.527.166-.17.383-.28.619-.315l4.441-.645c.352-.051.656-.272.813-.59l1.987-4.026c.264-.535.911-.754 1.446-.49.213.105.385.277.49.49l1.987 4.025c.157.319.461.54.813.59l4.442.646c.59.086.999.634.913 1.224-.034.235-.145.453-.315.618l-3.214 3.133c-.254.248-.37.606-.31.956l.758 4.424c.101.588-.294 1.146-.882 1.247-.234.04-.474.002-.685-.109l-3.972-2.088c-.315-.166-.69-.166-1.005 0z"/>
</defs>
<g fill="none" fill-rule="evenodd">
  <g>
      <g>
          <g transform="translate(-967 -206) translate(966 206) translate(.09)">
              <ellipse cx="9.766" cy="4.026" fill="url(#nvmld3iica)" fill-opacity=".3" filter="url(#l3dwoya41b)" opacity=".294" rx="4.727" ry="3.714"/>
              <ellipse cx="12.468" cy="17.532" fill="url(#nvmld3iica)" fill-opacity=".3" filter="url(#j4vwwpk60c)" opacity=".294" rx="4.727" ry="3.714"/>
              <g>
                  <use fill="#000" filter="url(#p1jmy5xxid)" xlink:href="#aopk13fuxe"/>
                  <use stroke="url(#ekb1in4bdf)" stroke-width="1.728" xlink:href="#aopk13fuxe"/>
              </g>
              <path fill="url(#ekb1in4bdf)" fill-rule="nonzero" d="M10.407 13.77l-1.025.54c-.416.218-.93.058-1.15-.358-.086-.166-.116-.356-.085-.54l.196-1.14c.06-.351-.056-.708-.31-.957l-.83-.807c-.336-.328-.343-.867-.015-1.204.131-.134.302-.221.487-.248l1.145-.167c.352-.05.656-.272.814-.59l.512-1.038c.208-.422.718-.595 1.14-.387.168.083.304.22.386.387l.513 1.038c.157.318.46.54.813.59l1.145.167c.465.067.787.5.72.965-.027.185-.114.356-.248.487l-.829.807c-.255.249-.37.606-.31.956l.195 1.14c.08.464-.232.904-.695.984-.185.031-.374.001-.54-.086l-1.024-.538c-.315-.166-.69-.166-1.005 0z"/>
          </g>
      </g>
  </g>
</g>
  `,
];
