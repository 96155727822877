import React, { useMemo, memo } from "react";
import { makeStyles } from "@material-ui/core";
import MissionFooterHeaderText from "./MissionFooterHeaderText";
import ShadowedText from "../shared/ShadowedText";

function MissionFooterScore({ currentScore, totalScore, status }) {
  const classes = useStyles({ status });

  const scoreDisplay = useMemo(() => {
    // if (status === "finished") {
    //   return (
    //     <div className={classes.footerText}>
    //       <span>
    //         <ShadowedText type="green" text={currentScore} size="15px" />
    //       </span>
    //       <span className={classes.finishedLower}>/{totalScore}</span>
    //     </div>
    //   );
    // }
    return (
      <div className={`${classes.footerText} ${classes.score}`}>
        {totalScore}
        {/* {status === "locked" ? totalScore : currentScore} */}
      </div>
    );
  }, [currentScore, totalScore, status]);

  return (
    <div>
      <MissionFooterHeaderText status={status} text="Score" />
      {scoreDisplay}
    </div>
  );
}

const useStyles = makeStyles({
  footerText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    minWidth: "50px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  score: {
    textShadow: (props) => {
      if (props.status === "active") return "0 0 6px #ed164d";
      if (props.status === "locked") return "0 0 6px #6f727a";
    },
    color: (props) => {
      if (props.status === "active") return "#ed164d";
      if (props.status === "locked") return "#6f727a";
    },
  },
  finishedLower: {
    fontSize: "10px",
    opacity: 0.77,
    color: "#ffffff",
  },
});

export default memo(MissionFooterScore);
