import { SET_TIMELINE, UPDATE_TIMELINE, RESET_STATE } from "../types/timeline";

export const setTimeline = (graph, dots) => {
  return { type: SET_TIMELINE, graph, dots };
};

export const updateTimeline = (timelineUpdate) => {
  return { type: UPDATE_TIMELINE, timelineUpdate };
};

export const resetTimelineState = () => ({
  type: RESET_STATE,
});
