export const starBadgeRed = [
  "45 45",
  `
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="Cybering-UI---Scenario-result-1-V2" transform="translate(-122.000000, -851.000000)" fill="#ED2717">
      <g id="Group-14-Copy-4" transform="translate(102.000000, 583.000000)">
          <g id="Group-28" transform="translate(20.000000, 268.000000)">
              <circle id="Oval-Copy" fill-opacity="0.15" cx="22.4" cy="22.4" r="22.4"></circle>
              <path d="M29.8471202,31.837613 C30.2516966,31.837613 30.4191075,31.5027912 30.4191075,31.1400675 C30.4191075,31.0424111 30.4191075,30.9587057 30.4051566,30.8610493 L29.2053783,23.8855946 L34.2695585,18.9469726 C34.4509203,18.7656108 34.6322821,18.5284453 34.6322821,18.277329 C34.6322821,17.8588017 34.185853,17.6913908 33.8510312,17.6355871 L26.8476746,16.6171707 L23.70872,10.2695069 C23.5831618,10.0044396 23.3459964,9.69751963 23.0251254,9.69751963 C22.7042545,9.69751963 22.4670891,10.0044396 22.3415309,10.2695069 L19.2025762,16.6171707 L12.1992197,17.6355871 C11.8504469,17.6913908 11.4179688,17.8588017 11.4179688,18.277329 C11.4179688,18.5284453 11.5993306,18.7656108 11.7667415,18.9469726 L16.8448725,23.8855946 L15.6450943,30.8610493 C15.6311434,30.9587057 15.6171925,31.0424111 15.6171925,31.1400675 C15.6171925,31.5027912 15.7985543,31.837613 16.2031307,31.837613 C16.3984434,31.837613 16.5798053,31.7678584 16.7611671,31.6702021 L23.0251254,28.3777874 L29.2890838,31.6702021 C29.4564947,31.7678584 29.6518075,31.837613 29.8471202,31.837613 Z" id="" fill-rule="nonzero"></path>
          </g>
      </g>
  </g>
</g>
  `,
];
