import { SET_SCHEDULED_TRAINING } from "../types/ScheduledTrainings.js";

const initialState = {};

const scheduledTrainingsReducer = (state = initialState, action) => {
  const { type, scheduled } = action;

  switch (type) {
    case SET_SCHEDULED_TRAINING:
      return { ...state, scheduledTraining: scheduled.scheduled };
    default:
      return state;
  }
};
export default scheduledTrainingsReducer;
