export const graphFlagFinish = [
  "500 500",
  `

  <path style="fill:#FC495C;" d="M427.155,206l37.772-94.429c1.849-4.622,1.284-9.859-1.506-13.98C460.63,93.469,455.977,91,451,91
	H261c-8.284,0-15,6.716-15,15v135h-35c-8.284,0-15,6.716-15,15v50c0,8.284,6.716,15,15,15h240c4.978,0,9.63-2.469,12.421-6.591
	c2.791-4.121,3.355-9.358,1.506-13.98L427.155,206z"/>
<path style="fill:#D12657;" d="M210.997,321c-1.932,0-3.881-0.373-5.737-1.142c-5.605-2.322-9.26-7.791-9.26-13.858v-50
	c0-8.284,6.716-15,15-15h50c6.067,0,11.537,3.654,13.858,9.26c2.321,5.605,1.038,12.057-3.252,16.347l-50,50
	C218.737,319.477,214.9,321,210.997,321z"/>
<path style="fill:#FD6B82;" d="M261,41H61c-8.284,0-15,6.716-15,15v200c0,8.284,6.716,15,15,15h200c8.284,0,15-6.716,15-15V56
	C276,47.716,269.284,41,261,41z"/>
<path style="fill:#A4B0BE;" d="M61,512c-8.284,0-15-6.716-15-15V15c0-8.284,6.716-15,15-15s15,6.716,15,15v482
	C76,505.284,69.284,512,61,512z"/>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>  `,
];
