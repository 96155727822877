export const popupInfo = [
  "69 69",
  `
  <defs>
  <filter id="kltnbblr1b" width="175%" height="200%" x="-37.5%" y="-50%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
  </filter>
  <filter id="ftk3wwplkc" width="175%" height="200%" x="-37.5%" y="-50%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
  </filter>
  <filter id="ac1454a2xd" width="175%" height="200%" x="-37.5%" y="-50%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
  </filter>
  <filter id="h3ad8fmlpe" width="175%" height="200%" x="-37.5%" y="-50%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
  </filter>
  <filter id="yscdoavo7g" width="140%" height="200%" x="-20%" y="-50%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
  </filter>
  <filter id="h3gwqketbh" width="205.8%" height="204.9%" x="-52.9%" y="-47.6%" filterUnits="objectBoundingBox">
      <feMorphology in="SourceAlpha" operator="dilate" radius="1" result="shadowSpreadOuter1"/>
      <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
      <feMorphology in="SourceAlpha" radius="1" result="shadowInner"/>
      <feOffset dy="2" in="shadowInner" result="shadowInner"/>
      <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6.5"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.922818065 0 0 0 0 0.425234497 0 0 0 0 0 0 0 0 1 0"/>
  </filter>
  <filter id="iy6zwmz01j" width="196.7%" height="222.9%" x="-48.1%" y="-55.7%" filterUnits="objectBoundingBox">
      <feMorphology in="SourceAlpha" operator="dilate" radius="1" result="shadowSpreadOuter1"/>
      <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
      <feMorphology in="SourceAlpha" radius="1" result="shadowInner"/>
      <feOffset dy="2" in="shadowInner" result="shadowInner"/>
      <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6.5"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.922818065 0 0 0 0 0.425234497 0 0 0 0 0 0 0 0 1 0"/>
  </filter>
  <filter id="cncorx76bm" width="123.5%" height="151.4%" x="-11.4%" y="-25.7%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation="3"/>
  </filter>
  <filter id="zct5lvsjqn" width="392.9%" height="278.3%" x="-146.4%" y="-80.4%" filterUnits="objectBoundingBox">
      <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6.5"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.922818065 0 0 0 0 0.425234497 0 0 0 0 0 0 0 0 1 0"/>
  </filter>
  <radialGradient id="8kqgbnc37a" cx="50%" cy="50%" r="50%" fx="50%" fy="50%" gradientTransform="matrix(0 1 -.75 0 .875 0)">
      <stop offset="0%" stop-color="#FF8E3D"/>
      <stop offset="100%" stop-color="#FA7200"/>
  </radialGradient>
  <radialGradient id="zzaqqi975f" cx="50%" cy="50%" r="50%" fx="50%" fy="50%" gradientTransform="matrix(0 1 -.4 0 .7 0)">
      <stop offset="0%" stop-color="#FF8E3D"/>
      <stop offset="100%" stop-color="#FA7200"/>
  </radialGradient>
  <radialGradient id="5a14kpnwxl" cx="50%" cy="56.285%" r="43.468%" fx="50%" fy="56.285%" gradientTransform="matrix(0 1 -.67308 0 .879 .063)">
      <stop offset="0%" stop-color="#EB7928"/>
      <stop offset="100%" stop-color="#EB7928" stop-opacity=".28"/>
  </radialGradient>
  <path id="dnbwdnkhdi" d="M14.667 3h22.666c3.734 0 6.81 2.93 6.992 6.659l1.317 27c.188 3.861-2.79 7.144-6.65 7.333-.114.005-.228.008-.342.008h-25.3c-3.866 0-7-3.134-7-7 0-.114.003-.227.008-.341l1.317-27C7.857 5.93 10.933 3 14.667 3z"/>
  <path id="mvxptpsluk" d="M15.28 3h21.692c3.866 0 7 3.134 7 7 0 .588-.074 1.174-.22 1.743l-5.4 21C37.556 35.837 34.767 38 31.572 38H21.28c-3.125 0-5.872-2.072-6.73-5.077l-6-21c-1.063-3.717 1.09-7.592 4.807-8.654C13.982 3.091 14.63 3 15.28 3z"/>
  <path id="bf954qzpao" d="M27.242 22.832c.504 0 .755-.251.755-.753v-.931c0-.573.312-1.146.936-1.72l2.447-1.97c1.08-.932 1.62-1.982 1.62-3.153v-.501c0-1.863-.516-3.296-1.548-4.3C30.421 8.503 28.957 8 27.062 8h-2.124c-1.895 0-3.359.502-4.39 1.505C19.516 10.508 19 11.94 19 13.804v.824c0 .525.252.788.756.788h3.059c.504 0 .756-.263.756-.788v-.753c0-.764.162-1.301.486-1.612.323-.31.857-.465 1.601-.465h.648c.792 0 1.362.16 1.71.483.347.323.521.854.521 1.594v.215c0 .717-.384 1.374-1.151 1.97L25.37 17.71c-.624.501-1.068.997-1.331 1.486-.264.49-.396 1.129-.396 1.917v.967c0 .502.252.753.755.753h2.844zM27.35 31c.455 0 .683-.25.683-.752V25.84c0-.454-.228-.68-.683-.68h-3.203c-.48 0-.72.238-.72.716v4.335c0 .525.24.788.72.788h3.203z"/>
  <linearGradient id="7onsbpthbp" x1="50%" x2="50%" y1="19.351%" y2="100%">
      <stop offset="0%" stop-color="#FF852E"/>
      <stop offset="100%" stop-color="#EB7826"/>
  </linearGradient>
</defs>
<g fill="none" fill-rule="evenodd">
  <g>
      <g transform="translate(-647 -204) translate(655 213)">
          <ellipse cx="23" cy="3" fill="url(#8kqgbnc37a)" filter="url(#kltnbblr1b)" opacity=".265" rx="4" ry="3"/>
          <ellipse cx="25" cy="14" fill="url(#8kqgbnc37a)" filter="url(#ftk3wwplkc)" opacity=".462" rx="4" ry="3"/>
          <ellipse cx="27" cy="25" fill="url(#8kqgbnc37a)" filter="url(#ac1454a2xd)" opacity=".462" rx="4" ry="3"/>
          <ellipse cx="35" cy="37" fill="url(#8kqgbnc37a)" filter="url(#h3ad8fmlpe)" opacity=".265" rx="4" ry="3"/>
          <ellipse cx="25.5" cy="44" fill="url(#zzaqqi975f)" filter="url(#yscdoavo7g)" opacity=".265" rx="7.5" ry="3"/>
          <g>
              <use fill="#000" filter="url(#h3gwqketbh)" xlink:href="#dnbwdnkhdi"/>
              <use stroke="#EB7928" stroke-width="2" xlink:href="#dnbwdnkhdi"/>
          </g>
          <g>
              <use fill="#000" filter="url(#iy6zwmz01j)" xlink:href="#mvxptpsluk"/>
              <use stroke="#EB7928" stroke-width="2" xlink:href="#mvxptpsluk"/>
          </g>
          <path fill="url(#5a14kpnwxl)" d="M10.067 3H42.28c3.866 0 7 3.134 7 7 0 .754-.122 1.504-.36 2.22l-7.02 21c-.955 2.855-3.629 4.78-6.64 4.78H17.867c-2.926 0-5.543-1.82-6.562-4.563l-7.8-21c-1.346-3.624.5-7.653 4.125-8.999C8.41 3.148 9.235 3 10.067 3z" filter="url(#cncorx76bm)" opacity=".694"/>
          <g>
              <use fill="#000" filter="url(#zct5lvsjqn)" xlink:href="#bf954qzpao"/>
              <use fill="url(#7onsbpthbp)" xlink:href="#bf954qzpao"/>
          </g>
      </g>
  </g>
</g>
  `,
];
