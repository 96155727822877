import {
  SET_TRAINING_STATUS,
  SET_REJOIN_STATUS,
  SET_TRAINING_TIMER,
  SET_TIMER_WARNINGS,
  SET_TRAINING_TOKEN,
  SET_TRAINING_RANGER,
  SET_TRAINING_ID,
  USER_LOGIN,
  USER_LOGOUT,
  SET_TRAINING_SCHEDULED_ID,
  SET_CONNECTED_TRAINEES,
  UPDATE_DISCONNECTED_TRAINEE,
  SET_SCHEDULED_TRAINEES,
  RESET_STATE,
} from "./../types/training";

export const setTrainingId = (id) => ({
  type: SET_TRAINING_ID,
  id,
});
export const setTrainingStatus = (status) => ({
  type: SET_TRAINING_STATUS,
  status,
});
export const setRejoinStatus = (rejoinStatus) => ({
  type: SET_REJOIN_STATUS,
  rejoinStatus,
});
export const setTrainingTimer = (time) => ({
  type: SET_TRAINING_TIMER,
  time,
});
export const setTimerWarnings = (timeLeft) => ({
  type: SET_TIMER_WARNINGS,
  timeLeft,
});
export const setVncTrainingToken = (vncToken) => ({
  type: SET_TRAINING_TOKEN,
  vncToken,
});
export const setTrainingRanger = (rangerName, rangerId) => ({
  type: SET_TRAINING_RANGER,
  rangerName,
  rangerId,
});
export const setTrainingScheduledId = (scheduledID) => ({
  type: SET_TRAINING_SCHEDULED_ID,
  scheduledID,
});
export const setConnectedTrainees = (connectedTrainees) => ({
  type: SET_CONNECTED_TRAINEES,
  connectedTrainees,
});
export const setScheduledTrainees = (scheduledTrainees) => ({
  type: SET_SCHEDULED_TRAINEES,
  scheduledTrainees,
});
export const updateDisconnectedTrainee = (traineeEmail) => ({
  type: UPDATE_DISCONNECTED_TRAINEE,
  traineeEmail,
});

export const userLogin = (user, rangerId, rangerName, userIp, avatarId) => ({
  type: USER_LOGIN,
  user,
  rangerId,
  rangerName,
  userIp,
  avatarId,
});

export const userLogout = () => ({
  type: USER_LOGOUT,
});

export const resetTrainingState = () => ({
  type: RESET_STATE,
});
