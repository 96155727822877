export const goldTrophy = [
  "34 34",
  `
  <defs>
  <linearGradient id="ryj5qtoimc" x1="18%" x2="64.124%" y1="14.691%" y2="100%">
      <stop offset="0%" stop-color="#BB9847"/>
      <stop offset="34.956%" stop-color="#C1A050"/>
      <stop offset="60.494%" stop-color="#CBAB58"/>
      <stop offset="100%" stop-color="#BB9847"/>
  </linearGradient>
  <ellipse id="x1v68tx7ob" cx="15.92" cy="18.031" rx="8.242" ry="8.5"/>
  <filter id="h73i95f7ka" width="227.4%" height="223.5%" x="-63.7%" y="-61.8%" filterUnits="objectBoundingBox">
      <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.5"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.737254902 0 0 0 0 0.603921569 0 0 0 0 0.28627451 0 0 0 1 0"/>
  </filter>
</defs>
<g fill="none" fill-rule="evenodd">
  <g>
      <g>
          <g>
              <g>
                  <g>
                      <g transform="translate(-1119 -458) translate(1104 251) translate(7 48) translate(9 159) translate(0 .219) translate(0 .949)">
                          <use fill="#000" fill-rule="nonzero" filter="url(#h73i95f7ka)" xlink:href="#x1v68tx7ob"/>
                          <ellipse cx="15.907" cy="17.237" stroke="#FFF" stroke-opacity=".282" stroke-width=".75" rx="15.907" ry="16.404"/>
                          <path fill="url(#ryj5qtoimc)" fill-rule="nonzero" d="M19.515 8.832c.152 0 .276.125.276.275v1.386h1.937c.305 0 .553.247.553.553v3.6c0 1.066-.868 1.934-1.937 1.934h-.553c-.001 1.528-1.239 2.767-2.767 2.767h-.83v2.767h1.108c.916 0 1.659.745 1.659 1.66v1.66h-6.64v-1.66c0-.917.742-1.66 1.658-1.66h1.108v-2.767h-.83c-1.527 0-2.765-1.236-2.767-2.767h-.553C9.868 16.58 9 15.712 9 14.646v-3.6c0-.306.248-.553.553-.553h1.937V9.107c0-.152.124-.274.276-.274h7.749zM11.49 11.6h-1.383v3.047c0 .42.317.77.726.82l.104.007h.553V11.6zm9.684 0h-1.383v3.874h.553c.458 0 .83-.372.83-.827v-3.047z"/>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
