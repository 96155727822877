import React, { useMemo, memo } from "react";
// import { useUpdateNodeInternals } from "reactflow";
import Tooltip from "@material-ui/core/Tooltip";
import { SiCountingworkspro } from "react-icons/si";
import { GiStoneWall } from "react-icons/gi";
import { HiOutlineServer, HiOutlineDatabase } from "react-icons/hi";
import { AiFillWindows } from "react-icons/ai";
import { VscTerminalLinux } from "react-icons/vsc";
import { FaNetworkWired } from "react-icons/fa";
import { useSelector } from "react-redux";

function FlowChartNetworkNode({ label, selected, data, powerState }) {
  const { theme } = useSelector((state) => state.uiReducer);
  // const updateNodeInternals = useUpdateNodeInternals();

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log("updateNodeInternals", data);
  //     updateNodeInternals(String(data.chart_id));
  //   }, 500);
  // }, [data]);

  const customNodeStyles = selected
    ? {
        borderRadius: "8px",
        boxShadow: "0 2px 14px 0 #1d2431",
        background: "linear-gradient(270deg, #3D4961 0%, #303849 100%)",
        border: "2px dotted #6197ff",
        fontFamily: theme.MAIN_FONT,
        fontSize: "12px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.64",
        letterSpacing: "-0.22px",
        color: "#ffffff",
      }
    : {
        borderRadius: "8px",
        boxShadow: "0 2px 4px 0 #1d2431",
        background: "linear-gradient(270deg, #3D4961 0%, #303849 100%)",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        fontFamily: theme.MAIN_FONT,
        fontSize: "12px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.64",
        letterSpacing: "-0.22px",
        color: "#ffffff",
      };

  const nodeType = useMemo(() => {
    if (data) {
      return data.device.Type;
    }
  }, [data]);

  const renderNodeType = (nodeType) => {
    if (nodeType === "SERVER") {
      return (
        <Tooltip title={nodeType} placement="top">
          <div style={{ ...customNodeStyles, padding: "5px 10px" }}>
            <div style={{ marginLeft: "auto", marginTop: "5px" }}>
              <HiOutlineServer
                // color={"#41729f"}
                size={22}
                style={{ display: "block", margin: "auto" }}
              />
            </div>
            <div
              style={{
                // display: "block",
                margin: "auto",
                textAlign: "center",
                display: "flex",
              }}
            >
              {label}
            </div>
            {data.device.IP ? (
              <div className="node-ip">{data.device.IP}</div>
            ) : null}
          </div>
        </Tooltip>
      );
    }
    if (nodeType === "COOPERATOR") {
      // const title = stringTrimmer(JSON.parse(content).topic, 50);

      return (
        <Tooltip title={nodeType} placement="top">
          <div style={{ ...customNodeStyles, padding: "5px 10px" }}>
            <div style={{ marginLeft: "auto", marginTop: "5px" }}>
              <SiCountingworkspro
                color={"#41729f"}
                size={22}
                style={{ display: "block", margin: "auto" }}
              />
            </div>
            <div
              style={{ display: "block", margin: "auto", textAlign: "center" }}
            >
              {label}
            </div>
            {data.device.IP ? (
              <div className="node-ip">{data.device.IP}</div>
            ) : null}
          </div>
        </Tooltip>
      );
    }
    if (nodeType === "DB") {
      // const title = stringTrimmer(JSON.parse(content).topic, 50);

      return (
        <Tooltip title={nodeType} placement="top">
          <div style={{ ...customNodeStyles, padding: "5px 10px" }}>
            <div style={{ marginLeft: "auto", marginTop: "5px" }}>
              <HiOutlineDatabase
                // color={"#274472"}
                size={22}
                style={{ display: "block", margin: "auto" }}
              />
            </div>
            <div
              style={{ display: "block", margin: "auto", textAlign: "center" }}
            >
              {label}
            </div>
            {data.device.IP ? (
              <div className="node-ip">{data.device.IP}</div>
            ) : null}
          </div>
        </Tooltip>
      );
    }
    if (nodeType === "USER_WIN") {
      // const title = stringTrimmer(JSON.parse(content).topic, 50);

      return (
        <Tooltip title={nodeType} placement="top">
          <div style={{ ...customNodeStyles, padding: "5px 10px" }}>
            <div style={{ marginLeft: "auto", marginTop: "5px" }}>
              <AiFillWindows
                color={"#74BDCB"}
                size={22}
                style={{ display: "block", margin: "auto" }}
              />
            </div>
            <div
              style={{ display: "block", margin: "auto", textAlign: "center" }}
            >
              {label}
            </div>
            {data.device.IP ? (
              <div className="node-ip">{data.device.IP}</div>
            ) : null}
          </div>
        </Tooltip>
      );
    }
    if (nodeType === "ATTACK") {
      // const title = stringTrimmer(JSON.parse(content).topic, 50);

      return (
        <Tooltip title={nodeType} placement="top">
          <div style={{ ...customNodeStyles, padding: "5px 10px" }}>
            <div style={{ marginLeft: "auto", marginTop: "5px" }}>
              <VscTerminalLinux
                color={"#FFA384"}
                size={22}
                style={{ display: "block", margin: "auto" }}
              />
            </div>
            <div
              style={{ display: "block", margin: "auto", textAlign: "center" }}
            >
              {label}
            </div>
            {data.device.IP ? (
              <div className="node-ip">{data.device.IP}</div>
            ) : null}
          </div>
        </Tooltip>
      );
    }
    if (nodeType === "FIREWALL") {
      // const title = stringTrimmer(JSON.parse(content).topic, 50);

      return (
        <Tooltip title={nodeType} placement="top">
          <div style={{ ...customNodeStyles, padding: "5px 10px" }}>
            <div style={{ marginLeft: "auto", marginTop: "5px" }}>
              <GiStoneWall
                color={"#FF7376"}
                size={22}
                style={{ display: "block", margin: "auto" }}
              />
            </div>
            <div
              style={{ display: "block", margin: "auto", textAlign: "center" }}
            >
              {label}
            </div>
            {data.device.IP ? (
              <div className="node-ip">{data.device.IP}</div>
            ) : null}
          </div>
        </Tooltip>
      );
    }
    if (nodeType === "SUBLAN") {
      // const title = stringTrimmer(JSON.parse(content).topic, 50);

      return (
        <Tooltip title={nodeType} placement="top">
          <div
            style={{
              ...customNodeStyles,
              padding: "5px 10px",
              width: "400px",
              height: "25px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div>
                <FaNetworkWired
                  color={"#7E5CAC"}
                  size={15}
                  style={{ display: "block", margin: "auto" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "5px",
                  textAlign: "center",
                }}
              >
                {label}
              </div>
              {data.device.IP ? (
                <div style={{ marginLeft: "5px" }} className="sublan-ip">
                  {data.device.IP}
                </div>
              ) : null}
            </div>
          </div>
        </Tooltip>
      );
    }

    return (
      <div style={customNodeStyles}>
        <div style={{ padding: "5px 10px" }}>{data.device.Name}</div>
      </div>
    );
  };

  return (
    <div className={powerState ? "VmOn" : powerState === false ? "VmOff" : ""}>
      {renderNodeType(nodeType)}
    </div>
  );
}

export default memo(FlowChartNetworkNode);
