export const redHat = [
  "34 34",
  `
  <defs>
  <filter id="wwawoue0va">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 0.174921 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
  </filter>
  <filter id="r23857bktb" width="250%" height="250%" x="-75%" y="-75%" filterUnits="objectBoundingBox">
      <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.5"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 1 0 0 0 0 0.121568627 0 0 0 0 0.121568627 0 0 0 1 0"/>
  </filter>
  <filter id="jae03n71od">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.921569 0 0 0 0 0.086275 0 0 0 0 0.086275 0 0 0 1.000000 0"/>
  </filter>
  <circle id="8sdtopcqvc" cx="10" cy="9" r="7"/>
</defs>
<g fill="none" fill-rule="evenodd">
  <g>
      <g>
          <g>
              <g>
                  <g>
                      <g filter="url(#wwawoue0va)" transform="translate(-1121 -558) translate(1104 251) translate(7 48) translate(0 256) translate(9) translate(5 8)">
                          <g>
                              <g transform="translate(0 .92) translate(.92)">
                                  <use fill="#000" fill-rule="nonzero" filter="url(#r23857bktb)" xlink:href="#8sdtopcqvc"/>
                                  <g filter="url(#jae03n71od)">
                                      <g fill="#000" fill-rule="nonzero">
                                          <path d="M10.214.564l9.129 3.572c.19.067.32.242.332.444.01.201-.1.39-.282.477h-.029l-1.243.657v5.522c0 .28-.227.507-.507.507s-.507-.227-.507-.507v-5l-1.014.507v4.286c-.044.67-.43 1.272-1.022 1.592-1.558.826-3.31 1.213-5.071 1.122-1.768.067-3.521-.348-5.071-1.2-.592-.32-.978-.922-1.022-1.593V6.664L.636 5.057c-.18-.093-.287-.285-.27-.487.018-.202.155-.373.348-.434L9.843.564c.12-.043.251-.043.371 0zM15.071 7.2L12.63 8.429l-1.579.785-.821.407c-.143.077-.315.077-.458 0l-.821-.407-1.579-.785L4.93 7.2v3.75c0 .736 1.928 1.771 5.071 1.771s5.071-1.057 5.071-1.771V7.2z" transform="translate(.08 .08)"/>
                                          <path d="M10 1.593L17.886 4.657 10.836 8.186 10 8.607 9.164 8.186 2.114 4.657z" transform="translate(.08 .08)"/>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
