import React, { useEffect, useState, memo } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import MissionProgress from "./MissionProgress";
import AnimatedScore from "./shared/AnimatedScore";
import defaultProfilePic from "../../assets/avatar.png";
import { finishedMission } from "../../store/actions/missions";
import { incrementPopupCount } from "../../store/actions/ui";

function TrainingAvatar() {
  const dispatch = useDispatch();

  const [segments, setSegments] = useState([]);
  const [total, setTotal] = useState(0);

  const { progress, active, missions } = useSelector(
    (state) => state.missionsReducer
  );
  const { theme } = useSelector((state) => state.uiReducer);
  const { rejoinStatus } = useSelector((state) => state.trainingReducer);
  const { score } = useSelector((state) => state.uiReducer);
  const { allTraineesAvatars } = useSelector(
    (state) => state.personalHallReducer
  );
  const userDetails =
    useSelector((state) => state.personalHallReducer?.userDetails) || {};

  const profilePic =
    allTraineesAvatars && userDetails?.avatarId !== 100
      ? allTraineesAvatars[userDetails?.avatarId]
      : defaultProfilePic;

  const defaultAvatar = userDetails?.avatarId !== 100 ? false : true;

  useEffect(() => {
    if (active) {
      for (const [key, value] of Object.entries(missions)) {
        if (Number(missions[key].id) != active) {
          dispatch(finishedMission(missions[key].id));
          dispatch(incrementPopupCount("missions", 1));
        } else {
          break;
        }
      }
    }
  }, [active, rejoinStatus]);

  useEffect(() => {
    const len = Object.keys(progress).length;
    if (len > 0) {
      let tmpTotal = 0;
      const tmpSegments = new Array(len);
      for (const [key, value] of Object.entries(progress)) {
        const { totalScore, status, priority } = value;
        const color =
          status === "finished" || status === "active"
            ? "rgba(255, 255, 255, 0.8)"
            : "rgba(255, 255, 255, 0.1)";
        // const dropShadow = status === "active" ? "0 0 6px 0 #ee3042" : "";
        tmpTotal += totalScore;

        tmpSegments[priority] = {
          color,
          status,
          value: totalScore,
          id: key,
        };
      }
      setSegments(tmpSegments);
      setTotal(Object.keys(progress).length);
    }
  }, [progress]);

  // const profilePic = images[userImage];
  // const classes = useStyles({ profilePic });
  const classes = useStyles({ profilePic, defaultAvatar, theme });

  return (
    <div className={classes.planet}>
      <div style={{ width: "100%", position: "relative" }}>
        <MissionProgress segments={segments} total={total} active={false} />
        <div className={classes.avatar}>
          <div className={classes.image}>
            <div className={classes.score}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <AnimatedScore score={score} size={14} />
              </Box>
              {/* <div className={classes.scoreLower}>SCORE</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  planet: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 120,
    width: 120,
    position: "relative",
    zIndex: 3,
    pointerEvents: "auto",
  },
  avatar: {
    position: "absolute",
    left: 6.5,
    top: 6.5,
    cursor: "pointer",
    width: "95px",
    height: "95px",
    borderRadius: "50%",
    padding: "5px",
  },
  image: {
    width: "95px",
    height: "95px",
    backgroundImage: (props) => `url(${props.profilePic})`,
    backgroundSize: "100%",
    borderRadius: "50%",
    objectPosition: "center",

    // "&:hover": {
    //   boxShadow: "0px 0px 10px 10px #ff4081",
    // },
  },
  score: {
    position: "absolute",
    top: (props) => (props.defaultAvatar ? 65 : "75px"),
    left: "28px",
    width: "50px",
    textAlign: "center",
    backgroundColor: (props) => (props.defaultAvatar ? null : "#3F3F3F"),
    opacity: "0.7",
    borderRadius: "15px",
  },

  scoreLower: {
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "9px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "rgba(255, 255, 255, 0.9)",
    textAlign: "center",
  },
});

export default memo(TrainingAvatar);