import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PerfectScrollbar from "react-perfect-scrollbar";

import HintContainer from "./HintContainer";
import HintHeader from "./HintHeader";
import ConfirmButton from "../buttons/ConfirmButton";
import HintCounter from "./HintCounter";

function HintPopup({
  hint,
  confirm,
  currentHint,
  totalHints,
  openAttachment,
  id,
}) {
  const classes = useStyles();
  const size = { width: "108px", height: "35px", font: "15px" };

  const HintBody = () => {
    return hint.map((el, index) => {
      if (el?.text) {
        if (el?.type === "text") {
          return <div key={index}>{el.text}</div>;
        }
        let myValue = "";
        if (el?.text && typeof el.text === "string") {
          myValue =
            el.text.length > 30 ? el.text.substring(0, 30) + "..." : el.text;
        }

        return (
          <div
            className={classes.missionUrl}
            onClick={() => openAttachment(el?.text, "url", el?.text)}
            key={index}
          >
            {myValue}
          </div>
        );
      }
      return <div />;
    });
  };

  return (
    <HintContainer id={id}>
      <HintCounter hint={currentHint} total={totalHints} />
      <div id="hint-scroll">
        <PerfectScrollbar className={classes.scrollbarContainer}>
          <HintHeader text="" fontSize="20px">
            <HintBody />
          </HintHeader>
        </PerfectScrollbar>
      </div>
      <div className={classes.footer}>
        <ConfirmButton
          text="got it"
          confirm={confirm}
          size={size}
          type="active"
        />
      </div>
    </HintContainer>
  );
}

const useStyles = makeStyles(() => ({
  footer: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
  },
  scrollbarContainer: {
    width: "100%",
    height: "100%",
  },
  missionUrl: {
    cursor: "pointer",
    color: "#FF2166",
  },
}));

export default HintPopup;
