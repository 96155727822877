// Theme: CYBRING
export const CYBRING = {
  COMPANY_NAME: "CYBRING",
  COMPANY_DISPLAY_NAME: "CyberMind",
  MAIN_FONT: "Rajdhani",
  TRAINING_FONT: "OpenSans",
  MAIN_HEADER_FONT_SIZE: "28px",

  COLOR_PRIMARY: "#ed164d",
  COLOR_BACKGROUND: "#2B2C3F",
  COLOR_BACKGROUND_SECONDARY: "#1C1E31",
  COLOR_BACKGROUND_SELECTED: "#4A4967",
  GRADIENT_BACKGROUND_COLOR: "linear-gradient(to left, #25273a, #25273a 0%)",
  COLOR_DARK: "#1E1E2D",
  HEADER_COLOR: "rgba(0, 0, 0, 0.28)",
  TOGGLER_COLOR: "linear-gradient(to bottom, #ed164d, #bc0c39 100%)",

  BOX_BORDER_RADIUS: "24px",
  HEADER_BORDER_RADIUS: "24px 24px 0 0",
  BUTTON_BORDER: "solid 1px rgba(0,0,0,0.42)",
  SELECTED_SCENARIO_BORDER_COLOR: "rgba(237, 22, 77, 0.5)",
  BOX_SHADOW:
    "0 7px 9px 3px #26283a, inset 0 1px 10px 0 rgba(255, 255, 255, 0.27)",
  BUTTON_ICON_COLOR: "white",
  TRAINER_LOGO:
    "https://res.cloudinary.com/cybring/image/upload/c_scale,w_85/v1634712054/V1_w_cvtoij.svg",
  COMPANY_LOGO:
    "https://res.cloudinary.com/cybring/image/upload/c_scale,w_85/v1634712054/V1_w_cvtoij.svg",
  INTRO_VIDEO:
    "https://res.cloudinary.com/cybring/video/upload/v1719972847/CyberMind_Intro_Clip_dsgjw4.mp4",
  COMPANY_LOGO_WIDTH: "100px",
  COMPANY_LOGO_BORDERS_RADIUS: "0%",
};

export const ISC2 = {
  COMPANY_NAME: "ISC2",
  COMPANY_DISPLAY_NAME: "ISC2 Cyber Range",
  MAIN_FONT: "OpenSans",
  TRAINING_FONT: "OpenSans",
  MAIN_HEADER_FONT_SIZE: "25px",

  COLOR_PRIMARY: "#488F46",
  COLOR_BACKGROUND: "#33333A",
  COLOR_BACKGROUND_SECONDARY: "#4B4B54",
  COLOR_BACKGROUND_SELECTED: "#4A4967",
  GRADIENT_BACKGROUND_COLOR: "linear-gradient(to left, #34343B, #34343B 0%)",
  COLOR_DARK: "#1B1B1C",
  HEADER_COLOR: "#488F46",
  TOGGLER_COLOR: "linear-gradient(to bottom, #488F46, #488F46 100%)",

  BOX_SHADOW: "",
  BOX_BORDER_RADIUS: "8px",
  HEADER_BORDER_RADIUS: "8px 8px 0 0",
  BUTTON_ICON_COLOR: "white",
  TRAINER_LOGO:
    "https://res.cloudinary.com/cybring/image/upload/v1717339621/ISC2/ISC2_avatar_duqu7q.png",
  COMPANY_LOGO:
    "https://res.cloudinary.com/cybring/image/upload/v1715179564/ISC2/ISC2_Logo_White_p8cofi.png",
  INTRO_VIDEO:
    "https://res.cloudinary.com/cybring/video/upload/v1719126656/ISC2_Cyber_Range_Intro_Video_n2scm8.mp4",

  COMPANY_LOGO_WIDTH: "200px",
  COMPANY_LOGO_BORDERS_RADIUS: "0%",
  BUTTON_BORDER: "solid 1px white",
  SELECTED_SCENARIO_BORDER_COLOR: "rgba(72, 142, 69, 0.5)",
};

// Theme: TAME
export const TAME = {
  COMPANY_NAME: "TAME",
  COMPANY_DISPLAY_NAME: "Tame",
  MAIN_FONT: "Rajdhani",
  TRAINING_FONT: "OpenSans",
  MAIN_HEADER_FONT_SIZE: "28px",
  COLOR_BACKGROUND_SELECTED: "#3e8c8ec4",
  COLOR_PRIMARY: "#18E2E6",
  COLOR_BACKGROUND: "#212429",
  COLOR_BACKGROUND_SECONDARY: "#000",
  GRADIENT_BACKGROUND_COLOR: "linear-gradient(to left, #34343B, #34343B 0%)",
  COLOR_DARK: "#1B1B1C",
  HEADER_COLOR: "#18E2E6",
  TOGGLER_COLOR: "linear-gradient(to bottom, #18E2E6, #18E2E6 100%)",

  BOX_SHADOW: "",
  BOX_BORDER_RADIUS: "24px",
  HEADER_BORDER_RADIUS: "8px 8px 0 0",
  BUTTON_ICON_COLOR: "black",
  TRAINER_LOGO:
    "https://res.cloudinary.com/cybring/image/upload/v1723975388/tame_logo_hpncuk.png",
  COMPANY_LOGO:
    "https://res.cloudinary.com/cybring/image/upload/v1723975388/tame_logo_hpncuk.png",
  INTRO_VIDEO:
    "https://res.cloudinary.com/cybring/video/upload/v1719126656/ISC2_Cyber_Range_Intro_Video_n2scm8.mp4",

  COMPANY_LOGO_WIDTH: "100px",
  COMPANY_LOGO_BORDERS_RADIUS: "0%",
  BUTTON_BORDER: "solid 1px white",
  SELECTED_SCENARIO_BORDER_COLOR: "rgba(14,128,130, 0.7)",
};

export const themes = {
  CYBRING,
  ISC2,
  TAME,
};
