export const graphIf = [
  "20 14",
  `
  <defs>
  <filter id="filter-1">
      <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"></feColorMatrix>
  </filter>
</defs>
<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="timeline-icons" transform="translate(-413.000000, -76.000000)">
      <g id="switch" transform="translate(402.000000, 63.000000)" filter="url(#filter-1)">
          <g transform="translate(11.000000, 13.000000)">
              <path d="M0.8,4.4 L16.4688,4.4 L15.0344,5.8344 C14.722,6.1468 14.722,6.6532 15.0344,6.9656 C15.1908,7.122 15.3952,7.2 15.6,7.2 C15.8048,7.2 16.0092,7.122 16.1656,6.9656 L18.9656,4.1656 C19.278,3.8532 19.278,3.3468 18.9656,3.0344 L16.1656,0.2344 C15.8532,-0.078 15.3468,-0.078 15.0344,0.2344 C14.722,0.5468 14.722,1.0532 15.0344,1.3656 L16.4688,2.8 L0.8,2.8 C0.3584,2.8 0,3.1584 0,3.6 C0,4.0416 0.3584,4.4 0.8,4.4 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
              <path d="M18.4,9.2 L2.7312,9.2 L4.1656,7.7656 C4.478,7.4532 4.478,6.9468 4.1656,6.6344 C3.8536,6.322 3.3464,6.322 3.0344,6.6344 L0.2344,9.4344 C-0.078,9.7468 -0.078,10.2532 0.2344,10.5656 L3.0344,13.3656 C3.1904,13.522 3.3952,13.6 3.6,13.6 C3.8048,13.6 4.0096,13.522 4.1656,13.3656 C4.478,13.0532 4.478,12.5468 4.1656,12.2344 L2.7312,10.8 L18.4,10.8 C18.8416,10.8 19.2,10.4416 19.2,10 C19.2,9.5584 18.8416,9.2 18.4,9.2 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
          </g>
      </g>
  </g>
</g>  `,
];
