import React from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

function TrainingHeaderTitle({ text }) {
  const { theme } = useSelector((state) => state.uiReducer);

  const classes = useStyles({ theme });

  const preventDragHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div className={classes.title} onDragStart={preventDragHandler}>
      {text}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  title: {
    color: "#FFFFFF",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "18px",
    textShadow: "0 0 6px 0 rgba(0,0,0,0.35)",
    textTransform: "uppercase",
  },
}));

export default TrainingHeaderTitle;
