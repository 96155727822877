const savePopupDimensionsToLocalStorage = (width) => {
  localStorage.setItem("informer_width", width.toString());
}

// Example usage:
// saveDimensionsToLocalStorage(width);

const getPopupDimensionsFromLocalStorage = () => {
  const width = localStorage.getItem("informer_width");

  return {
    informer_width: parseInt(width),
  };
}

// Example usage:
// const dimensions = getDimensionsFromLocalStorage();

module.exports = {
  savePopupDimensionsToLocalStorage,
  getPopupDimensionsFromLocalStorage,
};
