import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CIcon from "@coreui/icons-react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useDispatch, useSelector } from "react-redux";
import PopupCard from "../shared/PopupCard";
import SubHeaderNormal from "../shared/SubHeaderNormal";
import MainHeader from "../shared/MainHeader";
import DescriptionText from "../shared/DescriptionText";
import FeedbackSlider from "../FeedbackSlider";
import PopupApprovalButton from "../../buttons/PopupApprovalButton";
import FeedbackInputBox from "./FeedbackInputBox";
import { setTrainingStatus } from "./../../../store/actions/training";
import { SaveFeedback } from "../../../services/userApi";


function FeedbackContainer() {
  const { theme } = useSelector((state) => state.uiReducer);
  const { token } = useSelector((state) => state.userReducer);

  const [slider, setSlider] = useState(null);
  const [input, setInput] = useState("");
  const [loader, setLoader] = useState(false);
  const classes = useStyles();

  const logo = theme.COMPANY_LOGO

  const dispatch = useDispatch();

  const { trainingId, scheduledID } = useSelector(
    (state) => state.trainingReducer
  );



  const handleSliderChange = (e, value) => {
    setSlider(value);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const sendFeedback = async () => {
    setLoader(true);

    try {
      await SaveFeedback(token, trainingId, slider, input, scheduledID);
      dispatch(setTrainingStatus("feedback-finished"));
    } catch (error) {
      console.log(error);
    }
  };

  const isDisabled = slider === null;

  return (
    <div className={classes.completeRoot}>
      <PopupCard padding="20px 0 0">
        <Box display="flex" justifyContent="start" width="100%">
          <img
            src={logo}
            width={theme.COMPANY_LOGO_WIDTH}
            height="auto"
            className={classes.logo}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <CIcon
            name="feedback"
            width="22px"
            height="22px"
            className={classes.icon}
          />
          <MainHeader text="How was it?" />
        </Box>
        <SubHeaderNormal text="How likely would you recommend this training to a friend?" />
        <div className="mb-4">
          <FeedbackSlider
            handleSliderChange={handleSliderChange}
            sliderValue={slider}
          />
        </div>
        <div className="mt-4">
          <DescriptionText text="Please share your feedback with us (optional)" />
        </div>
        <div className="mb-3">
          <FeedbackInputBox
            placeholder="Your feedback..."
            onChange={handleInputChange}
          />
        </div>

        {loader ? (
          <PopupApprovalButton
            text="Calculating score..."
            approveClick={sendFeedback}
            children={<NavigateNextIcon />}
            disabled={true}
          />
        ) : (
          <PopupApprovalButton
            text="Let's debrief"
            approveClick={sendFeedback}
            children={<NavigateNextIcon />}
            disabled={isDisabled}
          />
        )}
      </PopupCard>
    </div>
  );
}

const useStyles = makeStyles({
  completeRoot: {
    border: "none",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: "5px",
  },
  logo: {
    marginLeft: "20px",
  },
});

export default FeedbackContainer;
