import axios from "axios";

const myBaseURL =
  process.env.REACT_APP_ENVIRONMENT.trim() === "DEV"
    ? process.env.REACT_APP_BASE_URL_LOCAL_DEV
    : process.env.REACT_APP_BASE_URL_CLOUD;

export function connectToVncApi(scnid, token) {
  const obj = {
    scnid: `${scnid}`,
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  // console.log("connectToVncApi request", postData);

  return axios
    .post(`${myBaseURL}/Training/StartSelfPaced`, postData, axiosConfig)
    .then((res) => {
      // console.log("connectToVncApi response", res);
      return res;
    })
    .catch((err) => {
      // console.log("connectToVncApi ERROR: ", err);
    });
}

export function startSelfPaced(scenarioPermissionId, token) {
  const obj = {
    scenarioPermissionId: `${scenarioPermissionId}`,
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  // console.log("startSelfPaced request", postData);

  return axios
    .post(`${myBaseURL}/Training/StartSelfPaced`, postData, axiosConfig)
    .then((res) => {
      // console.log("startSelfPaced response", res);
      return res;
    })
    .catch((err) => {
      // console.log("startSelfPaced ERROR: ", err);
    });
}
export function GetSelfPacedTrainingStatus() {
  const obj = {};

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(`${myBaseURL}/Training/GetSelfPacedTrainingStatus`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.warn("GetSelfPacedTrainingStatus ERROR: ", err);
    });
}

export function GetScheduledTrainingStatus() {
  const obj = {};

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(`${myBaseURL}/Training/GetJoinTrainingStatus`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.warn("GetScheduledTrainingStatus ERROR: ", err);
    });
}
export function joinScheduledScenario(scheduledID, token) {
  const obj = {
    ScheduledID: `${scheduledID}`,
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(`${myBaseURL}/Training/joinScheduledScenario`, postData, axiosConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("joinScheduledScenario ERROR: ", err);
    });
}

export function getAllRangers(token) {
  const obj = {
    token,
  };

  const postData = JSON.stringify(JSON.stringify(obj));

  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  // console.log("GetAllRangers request", postData);

  return axios
    .post(`${myBaseURL}/DataBase/GetAllRangers`, postData, axiosConfig)
    .then((res) => {
      // console.log("GetAllRangers response", res);
      return res;
    })
    .catch((err) => {
      // console.log("GetAllRangers ERROR: ", err);
    });
}
