import React, { useState, useEffect } from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import activeMissionBg from "../../../assets/backgrounds/training/activeMission.svg";
import MissionBadge from "./MissionBadge";
import MissionHeader from "./MissionHeader";
import MissionBody from "./MissionBody";
import MissionFooter from "./MissionFooter";
import MissionHints from "./MissionHints";
import DebriefHeaderStats from "./DebriefHeaderStats";
import QuestionPreview from "./../popupMessages/QuestionPreview";
import { useSelector } from "react-redux";

function Mission({ openAttachment, mission, progress, hints }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const [bodyOpen, setBodyOpen] = useState(true);
  const { status, topic, RichText, id, type, MitreTechniques } = mission;

  const classes = useStyles({ status, bodyOpen, theme });

  useEffect(() => {
    if (status === "finished") {
      setBodyOpen(false);
    }
    if (type === "informer") {
      setBodyOpen(false);
    }
  }, [status, type]);

  const CalculateScoreWithHints = () => {
    const usedHintCount = hints?.currentHint;
    for (let i = 0; i < usedHintCount; i++) {
      progress.currentScore -= hints.hints[i].price;
    }
    if (progress.currentScore < 0) {
      progress.currentScore = 0;
    }
  };

  if (hints.currentHint > 0) {
    CalculateScoreWithHints();
  }

  function isPartial() {
    // TODO: remove at 2025, enabls old user review response message
    const isPartScore =
      progress.currentScore > 0 && progress.currentScore < progress.totalScore;
    const isHintsTaken = hints.currentHint > 0;
    const isCorrect = mission.userReviewDict.IsCorrect;

    return isPartScore && !isHintsTaken && isCorrect;
  }

  if (status === "locked") {
    return (
      <div className={`${classes.missionBody} ${classes.missionBodyLocked}`}>
        <div className={`${classes.innerbody} pb-2 `}>
          <MissionBadge type={type} status={status} />
          <div className={classes.locked}>Locked</div>
        </div>
        {type !== "informer" && (
          <MissionFooter progress={progress} hints={hints} />
        )}
      </div>
    );
  }

  if (status === "active" || status === "finished" || status === "debrief") {
    return (
      <div className={`${classes.missionBody} ${classes.missionBodyActive}`}>
        <Grid container direction="row" className={classes.missionContainer}>
          <Grid container direction="row" className={classes.missionHeader}>
            <Grid></Grid>
            <Grid className={classes.missionHeader}>
              <MissionBadge type={type} status={status} progress={50} />
              <MissionHeader
                text={topic}
                bodyOpen={bodyOpen}
                setBodyOpen={setBodyOpen}
                status={status}
              >
                {status === "debrief" ? (
                  <DebriefHeaderStats
                    missionProgress={progress}
                    hintProgress={hints}
                  />
                ) : null}
              </MissionHeader>
            </Grid>
          </Grid>

          <Grid className={classes.innerRight}>
            {bodyOpen ? (
              <MissionBody
                openAttachment={openAttachment}
                missionBody={RichText}
                missionId={id}
                isPopup={false}
                isDebrief={status === "debrief"}
              >
                {status === "debrief" && mission?.type === "question" ? (
                  <QuestionPreview
                    status={status}
                    questionResults={mission.userReviewDict}
                    quiz={{
                      title: mission.topic,
                      type: "options",
                      options: mission.details.options,
                    }}
                    isPartialFunc={isPartial} // TODO: remove at 2025, enabls old user review response message
                  />
                ) : null}

                <MissionHints
                  hintsProps={hints}
                  missionId={id}
                  isDots={RichText.length > 1}
                  openAttachment={openAttachment}
                />
              </MissionBody>
            ) : null}
          </Grid>
          {MitreTechniques ? (
            <Grid
              container
              direction="row"
              className={classes.mitreTechniquesContainer}
            >
              <Grid style={{ margin: "0 0.5rem 0 0" }}>
                <img
                  src="https://attack.mitre.org/theme/images/mitre_attack_logo.png"
                  width="120px"
                  alt="MITRE ATT&CK logo"
                />
              </Grid>
              {MitreTechniques.map((Technique) => {
                const { mitreTechName } = Technique;
                return (
                  <Chip
                    className={classes.mitreTechnique}
                    label={mitreTechName}
                    size="small"
                  />
                );
              })}
            </Grid>
          ) : null}
        </Grid>

        {status === "debrief" ? null : type === "informer" ? null : (
          <MissionFooter progress={progress} missionId={id} hints={hints} />
        )}
      </div>
    );
  }

  if (status === "finished") {
    return (
      <div className={classes.finishedWrapper}>
        <MissionBadge type={type} status={status} />
        <div className={`${classes.missionBody} pl-1 `}>
          <MissionHeader text={topic} status={status} />
          <MissionFooter progress={progress} hints={hints} />
        </div>
      </div>
    );
  }
  return <div />;
}

const useStyles = makeStyles({
  finishedWrapper: {
    display: "flex",
    background: "linear-gradient(270deg, #000000 0%, rgba(0,0,0,0.46) 100%)",
    border: "solid 0.5px rgba(255, 255, 255, 0.28)",
    padding: "1px 0 0 8px",
    borderRadius: "4px",
    margin: "0 0 5px",
    width: "100%",
  },
  missionBody: {
    display: "flex",
    justifyContent: "spaceBetween",
    flexDirection: "column",
    borderRadius: "4px",
    padding: (props) =>
      props.status === "finished" ? "10px 0 0 10px" : "10px 8px 8px 8px",
    marginBottom: (props) => (props.status === "finished" ? "0" : "5px"),
    fontFamily: (props) => props.theme.TRAINING_FONT,
    width: "100%",
    // border: "#0b422c 1px solid",
  },
  missionBodyActive: {
    background: `url(${activeMissionBg})`,
    backgroundSize: `100% 100%`,
    backgroundRepeat: "no-repeat",
  },
  missionBodyLocked: {
    backgroundColor: "rgba(8,12,25,0.8)",
    border: "solid 0.5px rgba(255, 255, 255, 0.28)",
  },
  innerbody: {
    display: "flex",
    borderBottom: "solid 1px rgba(255, 255, 255, 0.05)",
    paddingBottom: "5px",
    maxWidth: "100%",
  },
  locked: {
    // textShadow: "0 0 0.5px #6f727a",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.15",
    letterSpacing: "normal",
    color: "#6f727a",
    margin: "15px 0 0 5px",
  },
  innerRight: {
    padding: "0 4px 4px 8px",
    width: "calc(100% - 20px)",
  },
  mitreTechniquesContainer: {
    padding: "0 0 0 8px",
    alignItems: "baseline",
  },
  mitreTechnique: {
    background: "linear-gradient(270deg, #2b1e306b 0%, rgba(0,0,0,0.46) 100%)",
    fontFamily: (props) => props.theme.TRAINING_FONT,
    border: "solid 0.5px rgba(255, 255, 255, 0.28)",
    margin: "0 0 0 5px",
    padding: "0.4rem",
    borderRadius: "8px",
    color: "white",
  },
  missionHeader: {
    width: "calc(100% - 20px)",
    padding: "0 4px 4px 2px",
    display: "flex",
    justifyContent: "spaceBetween",
  },
  missionContainer: {
    borderBottom: "solid 1px rgba(255, 255, 255, 0.05)",
    paddingBottom: "4px",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default Mission;
