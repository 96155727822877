import React from "react";
import { makeStyles } from "@material-ui/core/styles";

function DescriptionText({ text }) {
  const classes = useStyles();

  return <div className={classes.descriptionRoot}>{text}</div>;
}

const useStyles = makeStyles(() => ({
  descriptionRoot: {
    fontFamily: "NunitoSans",
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal",
    color: "#ffffff",
    whiteSpace: "pre-wrap",
    textAlign: "center",
  },
}));

export default DescriptionText;
