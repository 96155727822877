import React from "react";
import { makeStyles } from "@material-ui/core";
import CIcon from "@coreui/icons-react";
import Box from "@material-ui/core/Box";
import TrainingTime from "./../../base/time/TrainingTime";
import { useSelector } from "react-redux";

function Event({ topic, username, icon, time }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ theme });

  return (
    <Box
      display="flex"
      width="100%"
      borderBottom="1px solid rgba(255,255,255,0.05)"
      padding="7px 8px"
      alignItems="center"
    >
      {/* <CIcon
        name="eventsCircle"
        width="34px"
        height="34px"
        content={}
      /> */}
      <CIcon name={icon} width="38px" height="38px" />
      <div className={classes.details}>
        <div className={classes.topic}>{topic}</div>
        <div className={classes.username}>{username}</div>
      </div>
      <Box className={classes.time} display="flex" justifyContent="end">
        <TrainingTime time={time} />
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  topic: {
    textShadow: "0 0 6px rgba(0, 0, 0, 0.35)",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "13px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.15",
    letterSpacing: "normal",
    color: "#ffffff",
  },
  username: {
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "10px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "rgba(255, 255, 255, 0.6)",
  },
  details: {
    flexGrow: 1,
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  time: {
    width: "30px",
    padding: "8px",
  },
}));

export default Event;
