import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Score from "../../shared/Score";

function TimelineScore({ score }) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="end"
      alignItems="center"
      height="100%"
      width="calc(100% - 90px)"
    >
      <Score score={score} />
      <div className={classes.scoreLower}>SCORE</div>
    </Box>
  );
}

const useStyles = makeStyles({
  scoreLower: {
    fontSize: "13px",
    fontWeight: "600",
    lineHeight: "16px",
    color: "rgba(255, 255, 255, 0.4)",
  },
});

export default TimelineScore;
