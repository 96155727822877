export const goTo = [
  "24 26",
  `
  <defs>
  <filter id="u77phkkmka" width="275%" height="182.4%" x="-87.5%" y="-29.4%" filterUnits="objectBoundingBox">
      <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.0530849194 0 0 0 0 0.271690645 0 0 0 0 0.734290082 0 0 0 0.606042395 0"/>
  </filter>
  <text id="dfy3t2v90b" font-family="Lato-Medium, Lato" font-size="14" font-weight="400" letter-spacing="-.253">
      <tspan x="8" y="17">&gt;</tspan>
  </text>
</defs>
<g fill="none" fill-rule="evenodd">
  <g fill-rule="nonzero">
      <g>
          <g>
              <g transform="translate(-1786.000000, -750.000000) translate(59.000000, 603.000000) translate(1.000000, 123.000000) translate(1726.000000, 24.000000)">
                  <circle cx="12" cy="12" r="12" fill="#3A3C4E"/>
                  <g fill="#FFF">
                      <use filter="url(#u77phkkmka)" xlink:href="#dfy3t2v90b"/>
                      <use xlink:href="#dfy3t2v90b"/>
                      <use xlink:href="#dfy3t2v90b"/>
                  </g>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
