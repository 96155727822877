import React, { useMemo, memo } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useDispatch } from "react-redux";

import MissionFooterHeaderText from "./MissionFooterHeaderText";
import ConfirmButton from "./../buttons/ConfirmButton";
import ConfirmButtonMotion from "./../buttons/ConfirmButtonMotion";
import ShadowedText from "../shared/ShadowedText";
import { openAskHintPopup } from "./../../../store/actions/hint";

function MissionFooterHints({ status, hints, id, type }) {
  const classes = useStyles({ status });

  const currentHint = hints?.currentHint || 0;
  const totalHints = hints?.totalHints || 0;

  const dispatch = useDispatch();

  const onClickHint = () => {
    dispatch(openAskHintPopup(id));
  };

  const hintsDisplay = useMemo(() => {
    // if (status === "finished") {
    //   return (
    //     <div>
    //       <MissionFooterHeaderText
    //         status={status}
    //         text="Hints"
    //         isHint={false}
    //       />
    //       <div className={classes.footerText}>
    //         <span>
    //           <ShadowedText type="green" text={currentHint} size="15px" />
    //         </span>
    //         <span className={classes.finishedLower}>/{totalHints}</span>
    //       </div>
    //     </div>
    //   );
    // }
    if (status === "locked" || status === "finished") {
      return (
        <div>
          <MissionFooterHeaderText
            status={status}
            text={`${currentHint}/${totalHints} hints`}
            isHint={true}
          />
          <ConfirmButton
            text="get hint"
            type={"locked"}
            confirm={() => {}}
            disabled={true}
          />
        </div>
      );
    }
    if (status === "active") {
      return (
        <div>
          <MissionFooterHeaderText
            status={status}
            text={`${currentHint}/${totalHints} hints`}
            isHint={true}
          />
          {/* {type === "task" ? (
            <ConfirmButtonMotion
              text="get hint"
              disabled={totalHints === 0 || currentHint === totalHints}
              confirm={onClickHint}
              id={id}
            />
          ) : ( */}
          <ConfirmButton
            text="get hint"
            type={
              totalHints === 0 || currentHint === totalHints ? "locked" : status
            }
            confirm={onClickHint}
            disabled={totalHints === 0 || currentHint === totalHints}
          />
          {/* )} */}
        </div>
      );
    }
    return <div />;
  }, [currentHint, totalHints, status]);

  const hintCost = useMemo(() => {
    if (status === "locked") {
      return <div className={classes.hintCostLocked}>-</div>;
    }
    if (status === "active" || status === "finished") {
      const price = hints?.hints?.[currentHint]?.price;
      const myHintCost = price === undefined || price === null ? "" : price;

      return (
        <>
          <MissionFooterHeaderText status={status} text={` `} />
          <div className={`${classes.footerText} ${classes.hintCostUpper}`}>
            <ShadowedText type="red" text={`-${myHintCost}`} size="15px" />
          </div>
          <div className={classes.hintCostLower}>points</div>
        </>
      );
    }
    return <div />;
  }, [currentHint, totalHints, status]);

  return (
    <Box display="flex" justifyContent="space-between" alignContent="start">
      {hintsDisplay}
      <div className={classes.hintCost}>{hintCost}</div>
    </Box>
  );
}

const useStyles = makeStyles({
  footerText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "15px",
    minWidth: "50px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  finishedLower: {
    fontSize: "10px",
    opacity: 0.77,
    color: "#ffffff",
  },

  hintCost: {
    // marginLeft: "-15px",
  },
  hintCostUpper: {
    margin: "12px 0 -3px 0",
  },
  hintCostLower: {
    textShadow: "0 0 6px rgba(0, 0, 0, 0.35)",
    fontFamily: "NunitoSans",
    fontSize: "10px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ff2d00",
  },
  hintCostLocked: {
    width: "30px",
    paddingTop: "15px",
    textShadow: "0 0 6px #16a3ed",
    color: "#6f727a",
  },
});

export default memo(MissionFooterHints);
