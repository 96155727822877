import { SET_ORGANIZATION_TOKENS_SUMMARY, UPDATE_ORGANIZATION_SCENARIO_TOKENS_DATA, FETCH_ORGANIZATION_SCENARIO_TOKENS_SUMMARY } from "../types/organizationTokensSummary";

export const fetchOrganizationTokensSummary = (organizationID) => {
  return { type: FETCH_ORGANIZATION_SCENARIO_TOKENS_SUMMARY, organizationID };
};

export const setOrganizationTokensSummary = (orgTokensSummary) => {
  return { type: SET_ORGANIZATION_TOKENS_SUMMARY, orgTokensSummary };
};

export const updateOrganizationTokensData = (organizationTokensData) => {
  return { type: UPDATE_ORGANIZATION_SCENARIO_TOKENS_DATA, organizationTokensData };
};