import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import TimerBox from "../../TimerBox";
import CIcon from "@coreui/icons-react";
import { useSelector } from "react-redux";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 100,
      width: "100%",
      backgroundColor: "#ed164d",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textShadow: "0 0 6px rgba(0, 0, 0, 0.35)",
    fontFamily: "Rajdhani",
    fontSize: "25px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.2",
    letterSpacing: "normal",
    textTransform: "none",
    color: "#ffffff",
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

function LeaderboardTimelineHeader({ activeTab, setActiveTab, closeClick }) {
  const classes = useStyles();

  const { time } = useSelector((state) => state.uiReducer);

  const handleTabChange = (e, value) => {
    setActiveTab(value);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      height="64px"
      className={classes.headerRoot}
    >
      {/* omri disabled until can connect to other timer 
      <TimerBox
        start={false}
        time={time}
        margin="0 30px 0 10px"
        padding="10px 15px 10px 0"
        fontSize="23px"
        width="130px"
        hourglassSize={["34.5px", "49.5px"]}
        isTimeline={true}
      /> */}
      <AppBar position="static" className={classes.appBar}>
        <StyledTabs value={activeTab} onChange={handleTabChange} centered>
          <StyledTab label="Leaderboard" id="Leaderboard" />
          <StyledTab label="Timeline" id="Timeline" />
        </StyledTabs>
      </AppBar>
      {/* <CIcon
        name="timelineSettingsIcon"
        className={classes.icon}
        width="30px"
        height="30px"
        onClick={closeClick}
      /> */}
      <CIcon
        name="trainingCloseIcon"
        className={classes.icon}
        width="30px"
        height="30px"
        onClick={closeClick}
      />
    </Box>
  );
}

const useStyles = makeStyles({
  headerRoot: {
    backgroundColor: "rgba(0,0,0,0.44)",
    borderRadius: "4px 4px 0 0",
  },
  appBar: {
    flex: 1,
    background: "transparent",
    boxShadow: "none",
  },
  icon: {
    marginRight: "20px",
    cursor: "pointer",
  },
});

export default LeaderboardTimelineHeader;
