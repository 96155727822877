import { call, put, takeLatest } from "redux-saga/effects";
import { FetchScheduled } from "../../services/training";
import { setScheduledTraining } from "./../actions/scheduledTrainings";

function* getScheduledTrainings(action) {
  try {
    const scheduledTraining = yield call(FetchScheduled, action.userId);
    const scheduledArr = scheduledTraining?.data;

    yield put(
      setScheduledTraining({
        scheduled: scheduledArr,
      })
    );
    if (scheduledArr?.length > 0) {
      yield put({
        type: "LOAD_SCENARIO",
        itemId: `${scheduledArr[0].scnId}`,
        token: "token",
      });
    }
  } catch (err) {
    console.warn("Failed to fetch scheduled trainings", err);
  }
}

export function* scheduledSaga() {
  yield takeLatest("FETCH_SCHEDULED", getScheduledTrainings);
}
