import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import ShadowedText from "../../shared/ShadowedText";
import AnimatedScore from "../../shared/AnimatedScore";
import { Tooltip } from "@material-ui/core";

function LeaderboardScore({ name, score }) {
  const classes = useStyles();
  let displayName = name.split("@")[0].slice(0, 22);

  return (
    <Tooltip title={name}>
      <div className={classes.LeaderboardScore}>
        <ShadowedText text={displayName} size="22px" type="white" />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop="-8px"
        >
          <AnimatedScore score={score} size="28px" />
        </Box>
      </div>
    </Tooltip>
  );
}

const useStyles = makeStyles({
  LeaderboardScore: {
    height: "100%",
    width: "120px",
    textAlign: "left",
    padding: "10px 0 8px 10px",
  },
});

export default memo(LeaderboardScore);
