import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import CIcon from "@coreui/icons-react";
import { useSelector } from "react-redux";

function NetworkViewHeader({ closeClick }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ theme });

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      height="64px"
      className={classes.headerRoot}
    >
      <AppBar position="static" className={classes.appBar}>
        <div className={classes.headerTitle}>Network Diagram</div>
      </AppBar>

      <CIcon
        name="trainingCloseIcon"
        className={classes.icon}
        width="30px"
        height="30px"
        onClick={closeClick}
      />
    </Box>
  );
}

const useStyles = makeStyles({
  headerRoot: {
    backgroundColor: "rgba(0,0,0,0.44)",
    borderRadius: "4px 4px 0 0",
  },
  appBar: {
    flex: 1,
    background: "transparent",
    boxShadow: "none",
  },
  headerTitle: {
    fontSize: "18px",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontWeight: "bold",
    marginLeft: "25px",
  },
  icon: {
    marginRight: "20px",
    cursor: "pointer",
  },
});

export default NetworkViewHeader;
