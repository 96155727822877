export const graphInfo = [
  "21 20",
  `
  <defs>
  <filter id="filter-1">
      <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"></feColorMatrix>
  </filter>
</defs>
<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linejoin="round">
  <g id="timeline-icons" transform="translate(-310.000000, -123.000000)">
      <g id="email-send-1" transform="translate(300.000000, 113.000000)" filter="url(#filter-1)">
          <g transform="translate(11.000000, 11.000000)">
              <polygon id="Stroke-1" stroke="#000000" stroke-width="1.5" points="18.8 0.4 0.4 8 7.6 10.8"></polygon>
              <polygon id="Stroke-3" stroke="#000000" stroke-width="1.5" points="18.8 0.4 15.6 15.2 7.6 10.8"></polygon>
              <polyline id="Stroke-5" stroke="#000000" stroke-width="1.5" points="7.6 10.8 7.6 17.2 10.4 12.4"></polyline>
          </g>
      </g>
  </g>
</g>  `,
];
