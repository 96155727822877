export const trainingCloseIcon = [
  "19 19",
  `
  <defs>
  <filter id="xjdl9e2swa">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
  </filter>
</defs>
<g fill="none" fill-rule="evenodd">
  <g>
      <g>
          <g transform="translate(-1325 -259) translate(1104 251) translate(221 8)">
              <circle cx="9.5" cy="9.5" r="9.5" fill="#FFF" fill-opacity=".344"/>
              <g filter="url(#xjdl9e2swa)">
                  <g>
                      <path fill="#000" fill-rule="nonzero" d="M7.068 1.44L4.85 3.658l2.217 2.217c.33.33.33.864 0 1.194-.165.165-.38.247-.597.247-.216 0-.432-.082-.597-.247L3.658 4.85 1.44 7.068c-.165.165-.38.247-.597.247-.216 0-.432-.082-.597-.247-.33-.33-.33-.864 0-1.194l2.217-2.217L.247 1.441c-.33-.33-.33-.864 0-1.194.33-.33.864-.33 1.194 0l2.216 2.217L5.874.247c.33-.33.864-.33 1.194 0 .33.33.33.864 0 1.194z" transform="translate(6 6)"/>
                  </g>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
