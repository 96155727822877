import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import importImages from "./../../../../helpers/importImages";

function TimelineRank({ rank }) {
  const classes = useStyles();

  const ranks = importImages(
    require.context(
      "../../../../assets/icons/training/leaderboard/ranks",
      false,
      /\.(png)$/
    )
  );

  const icon = useMemo(() => {
    if (rank === 1) return ranks["leaderboard-trophy-frame.png"];
    if (rank === 2) return ranks["leaderboard-silver-star-frame.png"];
    if (rank === 3) return ranks["leaderboard-bronze-star-frame.png"];
    return ranks["leaderboard-badge-frame.png"];
  }, [rank]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="100%"
    >
      <img src={icon} width="35px" height="30px" className={classes.icon} />
      <div className={classes.rank}>{rank}</div>
    </Box>
  );
}

const useStyles = makeStyles({
  rank: {
    width: "90px",
    fontSize: "25px",
    fontWeight: "bold",
    paddingLeft: "5px",
  },
  icon: {
    marginBottom: "5px"
  }
});

export default TimelineRank;
