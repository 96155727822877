import { combineReducers } from "redux";
import sidebarReducer from "./sidebar";
import userReducer from "./user";
import userAgentReducer from "./userAgent";
import chatReducer from "./chat";
import missionsReducer from "./missions";
import eventsReducer from "./events";
import uiReducer from "./ui";
import quizReducer from "./quiz";
import quizResultsReducer from "./quizResults";
import effectsReducer from "./effects";
import informerReducer from "./informer";
import infoReducer from "./info";
import trainingReducer from "./training";
import hintReducer from "./hint";
import leaderboardReducer from "./leaderboard";
import timelineReducer from "./timeline";
import scenarioItemReducer from "./scenarioItem";
import scenarioListReducer from "./scenarioList";
import trainingHistoryReducer from "./trainingHistory";
import personalHallReducer from "./personalHall";
import scheduledTrainingsReducer from "./scheduledTrainings";
import mitreDBReducer from "./mitreDB";
import organizationTokensSummaryReducer from "./organizationTokensSummary";

const appReducer = combineReducers({
  sidebarReducer,
  userReducer,
  userAgentReducer,
  // start of training reducers
  chatReducer, // Reset-state
  missionsReducer, // Reset-state
  eventsReducer,
  uiReducer, // Reset-State
  quizReducer, // Reset-State
  quizResultsReducer, // Reset-State
  effectsReducer,
  informerReducer, // Reset-State
  infoReducer, // Reset-State
  trainingReducer, // Reset-State
  hintReducer, // Reset-State
  leaderboardReducer, // Reset-State
  timelineReducer, // Reset-State
  // end of training reducers
  mitreDBReducer,
  scenarioItemReducer,
  scenarioListReducer,
  trainingHistoryReducer,
  personalHallReducer,
  scheduledTrainingsReducer,
  organizationTokensSummaryReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    const { userReducer, userAgentReducer, sidebarReducer } = state;
    state = { userReducer, userAgentReducer, sidebarReducer };
  }
  return appReducer(state, action);
};

export default rootReducer;
