import React, { useEffect, useRef, useState } from "react";
import { MdBuildCircle, MdContentPasteGo } from "react-icons/md";
import { VncScreen } from "react-vnc";
import Tooltip from "@material-ui/core/Tooltip";
import { TbCopy } from "react-icons/tb";

function VncIframe({ url, classes }) {
  const vncRef = useRef();
  const [isSafari, setIsSafari] = useState(false);
  const [clipboard, setClipboard] = useState("");

  // const testUrl = "wss://"

  useEffect(() => {
    const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(
      navigator.userAgent
    );
    setIsSafari(isSafariBrowser);

    if (isSafariBrowser) return;

    const canvasElement = document.querySelector("canvas");

    if (!canvasElement) {
      console.error("Canvas element not found");
      return;
    }

    const handleClipboardFocus = (event) => {
      navigator.clipboard
        .readText()
        .then((text) => {
          vncRef.current.clipboardPaste(text);
        })
        .catch((err) => {
          console.log("Something went wrong while accessing clipboard", err);
        });
    };

    canvasElement.addEventListener("focus", handleClipboardFocus);

    return () => {
      canvasElement.removeEventListener("focus", handleClipboardFocus);
    };
  }, []);

  const handlePasteToVnc = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        vncRef.current.clipboardPaste(text);
      })
      .catch((err) => {
        console.log("Something went wrong while accessing clipboard", err);
      });
  };

  const clipboardReceived = (e) => {
    setClipboard(e.detail.text);
    navigator.clipboard
      .writeText(e.detail.text)
      .then(() => {
        console.log(e.detail.text);
      })
      .catch((err) => {
        console.log("Something went wrong while copying to clipboard.", err);
      });
  };

  const copyFromClipboard = () => {
    navigator.clipboard.writeText(clipboard).then(() => {
      console.log("copied!");
    });
  };

  return url !== "" ? (
    <>
      <VncScreen
        url={url}
        scaleViewport
        background="#000000"
        onConnect={(rfb) => vncRef}
        ref={vncRef}
        showDotCursor={true}
        style={{
          width: "100%",
          height: "100%",
        }}
        onClipboard={(e) => clipboardReceived(e)}
        focusOnClick={true}
      />
      {isSafari && (
        <div className={classes.copyPasteBox}>
          <div className={classes.buttonStack}>
            <Tooltip title={"Paste to VM clipboard (Click then Ctrl + V"}>
              <button
                className={classes.pasteButton}
                onClick={handlePasteToVnc}
              >
                <MdContentPasteGo size={20} />
              </button>
            </Tooltip>

            <Tooltip title={"Copy from VM clipboard (Ctrl + C then click)"}>
              <button
                className={classes.copyButton}
                onClick={copyFromClipboard}
              >
                <TbCopy size={20} />
              </button>
            </Tooltip>

            <button className={classes.toolsButton}>
              <MdBuildCircle size={20} />
            </button>
          </div>
        </div>
      )}
    </>
  ) : null;
}

export default VncIframe;
