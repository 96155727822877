import React from "react";
import { CCol, CRow, CForm, CFormText, CFormGroup } from "@coreui/react";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import PopupApprovalButton from "../../buttons/PopupApprovalButton";
import Grid from "@material-ui/core/Grid";
import { Formik, Field } from "formik";
import { useSelector } from "react-redux";

function SetUsernamePassForm({ handleLogin, handleCancelAndClose }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({theme});

  const handleLoginWithCred = async (values) => {
    const { username, password } = values;
    handleLogin(username, password);
  };

  const handleCancel = () => {
    handleCancelAndClose();
  };

  const size = {
    borderRadius: "11px",
    font: "14px",
    height: "42px",
    width: "100px",
  };

  return (
    <div className={classes.root}>
      <CCol xl={12} lg={12} md={12} sm={12} xs={12}>
        <CRow>
          <Grid container direction="row" alignItems="center">
            <CancelIcon
              className={classes.closeButton}
              onClick={handleCancel}
            />
            <div className={classes.title}>Log in with credentials</div>
          </Grid>
        </CRow>
        <Formik
          initialValues={{ username: "", password: "" }}
          //   validationSchema={newDuplicatedScenarioValidateSchema}
          onSubmit={(values) => handleLoginWithCred(values)}
        >
          {(props) => {
            const { handleSubmit, isValid } = props;

            return (
              <div className={`${classes.body}`}>
                <CRow>
                  <CCol>
                    <CForm onSubmit={handleSubmit}>
                      <CFormGroup>
                        <Field
                          className={classes.flexColumn}
                          name="username"
                          placeholder="username"
                        />
                        <Field
                          className={classes.flexColumn}
                          name="password"
                          placeholder="password"
                        />
                      </CFormGroup>

                      <CRow>
                        <CCol xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className={`${classes.footer}`}>
                            <PopupApprovalButton
                              disabled={!isValid}
                              color={!isValid ? "grey" : "pink"}
                              type="submit"
                              text={"Login"}
                              size={size}
                            />
                          </div>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCol>
                </CRow>
              </div>
            );
          }}
        </Formik>
      </CCol>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    backgroundColor: "#0C1119",
    //height: "min-height: 51vh",
    width: "32vw",
    display: "flex",
    flexDirection: "column",
    borderRadius: "11px",
    padding: "19px 15px 5px 22px",
    // boxShadow: "inset 2px -1px 13px 3px rgba(255, 255, 255, 0.13)",
    overflow: "hidden",
  },

  paper: {
    borderRadius: "12px",
    backgroundColor: "#1F2430",
    boxShadow: "3px 2px 29px 0 #181C27",
  },
  flexColumn: {
    fontFamily: (props) => props.theme.MAIN_FONT,
    width: "100%",
    color: "black",
    // maxWidth: "370px",
    height: "50px",
    fontSize: "16px",
    borderRadius: "7px",
    marginBottom: "9px",
    marginTop: "9px",
    paddingLeft: "20px",
    fontColor: "#898b8d",
    // backgroundColor: "rgba(255, 255, 255, 0.14)",
    borderColor: "rgba(255, 255, 255, 0.14)",
    "& .MuiInputBase-input": {
      color: "black",
      fontFamily: "NunitoSans",
    },
    "& label.Mui-focused": {
      color: "black",
    },
  },
  title: {
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontWeight: "bold",
    color: "white",
    fontSize: "25px",
    marginLeft: "10px",
  },
  closeButton: {
    color: "white",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  body: {
    marginBottom: "10px",
    width: "100%",
    paddingTop: "3%",
    // lineHeight: "1.3"
  },
  footer: {
    // border: "1px solid red",
    display: "flex",
    justifyContent: "flex-end",
    // marginTop: "20px",
    padding: "8px 0px 5px 15px",
  },
  errorText: {
    fontSize: "12px",
    color: "#ff4747",
  },
}));

export default SetUsernamePassForm;
