import React from "react";
import Fab from "@material-ui/core/Fab";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector } from "react-redux";

export default function AlertDialog({
  open,
  title,
  message,
  handleCancel,
  handleConfirm,
  icon,
  ConfirmButtonText = "",
  cancelButtonText = "",
}) {
  const { theme } = useSelector((state) => state.uiReducer);
  return (
    <div>
      <Dialog
        open={open}
        onClose={(e) => handleCancel(e)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {icon} {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Fab
            onClick={(e) => handleCancel(e)}
            variant="extended"
            size="medium"
            color="default"
          >
            {cancelButtonText || "Cancel"}
          </Fab>
          <Fab
            className={"yes"}
            onClick={(e) => handleConfirm(e)}
            variant="extended"
            size="medium"
            style={{
              backgroundImage: `linear-gradient(to bottom, ${theme.COLOR_PRIMARY}, ${theme.COLOR_PRIMARY} 100%)`,
              color: theme.BUTTON_ICON_COLOR,
            }}
            autoFocus
          >
            {ConfirmButtonText || "Confirm"}
          </Fab>
        </DialogActions>
      </Dialog>
    </div>
  );
}
