import React, { useState, useEffect } from "react";
import ReactFlow, {
  Controls,
  useZoomPanHelper,
  Background,
} from "react-flow-renderer";
import networkFlowBuilder from "../../../helpers/networkFlowBuilder";
import CustomNetworkFlowChart from "./CustomNetworkFlowChart";
import CustomEdge from "./CustomFlowLine";
import { GetVmPowerState, startVm, stopVm } from "../../../services/networkApi";
import ModalContainer from "../modals/ModalContainer";
import VmMonitor from "../../scenarios/sharedComponents/VmMonitor";
import { toast } from "react-toastify";
import rdpFileDownloader from "../../../services/rdpOfVmDownloader";
import SetUsernamePassForm from "../forms/SetUsernamePasswordForm";

function NetworkViewerAnimated({
  navigation,
  isEditing = false,
  onLoad,
  chartData,
  elementsClickable = false,
}) {
  const [graphData, setGraphData] = useState([]);
  const [openVmModal, setOpenVmModal] = useState(false);
  const [selectedVm, setSelectedVm] = useState(false);
  const [configureUsernamePassModal, setConfigureUsernamePassModal] =
    useState(false);
  const { fitView } = useZoomPanHelper();

  const handleCancelAndClose = () => {
    setConfigureUsernamePassModal(false);
  };
  const handleLogin = (username, password) => {
    console.log(username, password);
    handleLogInVm(username, password, selectedVm.InstanceID);
    setConfigureUsernamePassModal(false);
    setOpenVmModal(false);
  };

  const handleStartVm = async () => {
    console.log("Start VM", selectedVm);
    const { data } = await startVm("token", selectedVm.InstanceID);
    const response = JSON.parse(data);
    toast.info(response.message);
  };
  const handleStopVm = async () => {
    console.log("Stop VM", selectedVm);
    const { data } = await stopVm("token", selectedVm.InstanceID);
    const response = JSON.parse(data);
    toast.info(response.message);
  };

  const handleLogInVm = async (pass, username, InstanceID) => {
    console.log(pass, username, InstanceID);
    if (pass && username && InstanceID) {
      try {
        await rdpFileDownloader(pass, username, InstanceID);
      } catch (error) {
        console.log("loginToMachine error", error);
      }
    } else {
      setConfigureUsernamePassModal(true);
    }
  };

  // const RefreshPowerState_single = async (event, element) => {
  //   const vms = [element.data.device.InstanceID];
  //   try {
  //     const result = await GetVmPowerState(vms);
  //     const parsedResults = JSON.parse(result?.data);
  //     const parsedData = JSON.parse(parsedResults?.data);

  //     if (parsedData) {
  //       const newGraphData = AddAnimationToGraphData(graphData, parsedData);
  //       setGraphData(newGraphData);
  //     }
  //   } catch (e) {
  //     console.warn("RefreshPowerState_single failed");
  //   }
  // };

  const handleVmClick = (event, element) => {
    setOpenVmModal(true);
    setSelectedVm(element.data.device);
  };

  const RefreshPowerState_multiple = async (vms) => {
    // vms needs to contains all instance ids from network devices, as a string array.

    const result = await GetVmPowerState(vms);
    const parsedResults = JSON.parse(result?.data);
    const parsedData = JSON.parse(parsedResults?.data);

    if (parsedData) {
      const newGraphData = AddAnimationToGraphData(graphData, parsedData);

      setGraphData(newGraphData);
    }
  };

  const AddAnimationToGraphData = (chart, powerState) => {
    let grpahData = [];
    chart.forEach((device) => {
      if (Object.keys(powerState).includes(device.data.device.InstanceID)) {
        device.data.device.PowerState =
          powerState[device.data.device.InstanceID];
        //   switch (msg) {
        //     case "AtcCompStarted":
        //       console.log("AtcCompStarted: ", comp.chart_id);
        //       comp["run_status"] = 1;
        //       break;
        //     case "AtcCompStoped":
        //       console.log("AtcCompStoped: ", comp.chart_id);
        //       comp["run_status"] = 0;
        //       break;
        //     case "TimerCount":
        //       console.log("TimerCount from animated: ", comp.chart_id,msg,chartid,count);
        //       comp["display"] = count;
        //       break;
        //   }
      }
      grpahData.push(device);
    });

    return grpahData;
  };

  const chart = chartData;

  const nodeTypes = {
    special: CustomNetworkFlowChart,
  };

  const edgeTypes = {
    custom: CustomEdge,
  };

  const graphStyles = {
    width: "100%",
    height: "100%",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    boxShadow: "inset 0 1px 16px 0 rgba(18, 22, 33, 0.43)",
    backgroundColor: "#4778b1",
  };

  useEffect(() => {
    if (chart) {
      if (chart.devices && chart.connections) {
        const myFlowchart = networkFlowBuilder(
          chart.devices,
          chart.connections
        );

        let counter = 0;
        const fitViewOnStart = () => {
          fitView({ padding: 0.1 });
          if (counter >= 10) {
            clearInterval(fitViewInterval);
          }
          counter++;
        };
        // setGraphData([]);
        setTimeout(() => {
          setGraphData(myFlowchart);
        }, 10);
        let fitViewInterval = setInterval(fitViewOnStart, 50);
      }
    }
  }, [chart, setGraphData, isEditing, fitView]);

  return (
    <>
      <ReactFlow
        role={"Dustbin"}
        elements={graphData}
        style={graphStyles}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        minZoom={0}
        nodesDraggable={false}
        nodesConnectable={false}
        elementsSelectable={true}
        paneMoveable={navigation}
        zoomOnScroll={navigation}
        zoomOnDoubleClick={navigation}
        snapToGrid={false}
        snapGrid={[10, 10]}
        onElementClick={(event, element) => handleVmClick(event, element)}
        onLoad={onLoad}
      >
        <Controls showInteractive={false} />
        <Background color="#ffffff" gap={16} />
      </ReactFlow>
      <ModalContainer
        open={openVmModal}
        setOpen={setOpenVmModal}
        position="center"
        element="dashboard"
      >
        <VmMonitor
          setOpen={setOpenVmModal}
          size={{ width: "35%", height: "80%" }}
          selectedVm={selectedVm}
          handleStartVm={handleStartVm}
          handleStopVm={handleStopVm}
          handleLogInVm={handleLogInVm}
        />
      </ModalContainer>
      <ModalContainer
        open={configureUsernamePassModal}
        setOpen={setConfigureUsernamePassModal}
        position="center"
        element="dashboard"
      >
        <SetUsernamePassForm
          handleLogin={handleLogin}
          handleCancelAndClose={handleCancelAndClose}
        />
      </ModalContainer>
    </>
  );
}

export default NetworkViewerAnimated;
