export const pinkAttachment = [
  "18 18",
  `
  <defs>
  <filter x="-81.3%" y="-81.2%" width="262.5%" height="262.5%" filterUnits="objectBoundingBox" id="filter-1">
      <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
      <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
      <feColorMatrix values="0 0 0 0 0.929411765   0 0 0 0 0.0862745098   0 0 0 0 0.301960784  0 0 0 0.460751407 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
      <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
  </filter>
</defs>
<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="Cybring-training-flow-(5.1)" transform="translate(-1159.000000, -444.000000)" fill="#ED164D" fill-rule="nonzero">
      <g id="Group-9-Copy-2" transform="translate(1104.000000, 251.000000)">
          <g id="Group-25" transform="translate(7.000000, 121.000000)">
              <g id="Group-24" transform="translate(48.000000, 10.000000)">
                  <g id="Attached-file" transform="translate(0.000000, 62.000000)">
                      <g id="Group-15" transform="translate(5.000000, 3.000000)">
                          <g id="attach-interface-clip-symbol" filter="url(#filter-1)" transform="translate(0.000000, 2.000000)">
                              <path d="M5.20430241,3.4932646 C5.20430241,3.4932646 5.60967698,3.12522337 5.2262543,2.74180069 C4.84283162,2.35837801 4.45265979,2.74160825 4.45265979,2.74160825 L3.78412371,3.41019931 C3.11061168,4.08397251 3.02747766,5.09654983 3.70092096,5.7697457 C4.3747079,6.4435189 5.41087285,6.38391753 6.08412371,5.71019931 L7.21204124,4.58280412 C8.24808247,3.54676289 8.24808247,1.860811 7.21204124,0.824783505 L7.16669416,0.779436426 C6.13013058,-0.257127148 4.44417869,-0.257127148 3.40812371,0.779436426 L0.777223368,3.41000687 C-0.259079038,4.44603436 -0.259079038,6.13198625 0.777223368,7.16856357 L0.822570447,7.21391065 C1.62930584,8.02045361 2.84239175,8.21275601 3.86626804,7.71156014 C4.14112715,7.57703093 4.43983505,7.3222268 4.24266667,6.96483849 C4.03406186,6.5867354 3.66760137,6.66908591 3.47384192,6.75741581 C2.89562887,6.99565636 2.04321649,6.93105155 1.5742268,6.46232302 L1.52887973,6.41643986 C0.907024055,5.79512027 0.907024055,4.78345017 1.52887973,4.16160825 L4.15978007,1.53102405 C4.78163574,0.909168385 5.7932921,0.909168385 6.41462543,1.53102405 L6.46049485,1.57637113 C7.08182818,2.1982268 7.08182818,3.20936082 6.46049485,3.83121649 L5.33250859,4.95861168 C5.07349828,5.21762199 4.71157388,5.27723711 4.45256357,5.01815808 C4.19355326,4.75907904 4.27675601,4.42087973 4.53576632,4.16180069 L5.20430241,3.4932646 Z" id="Path"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </g>
</g>
  `,
];
