import { SET_QUESTION_RESULTS, RESET_STATE } from "../types/quizResults";

export const setQuestionResults = (id, question) => {
  return {
    type: SET_QUESTION_RESULTS,
    id,
    question,
  };
};

export const resetQuestionResultsState = () => ({
  type: RESET_STATE,
});
