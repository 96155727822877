export const deviceHub = [
  "55 55",
  `
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Cybering-UI---Scenarios" transform="translate(-25.000000, -75.000000)" fill="#FFFFFF" fill-rule="nonzero">
          <g id="Group-4" transform="translate(0.000000, 50.000000)">
              <g id="device_hub" transform="translate(25.000000, 25.000000)">
                  <path d="M21,19.5 L15,13.5 L15,8.73 C16.74,8.1 18,6.45 18,4.5 C18,2.01 15.99,0 13.5,0 C11.01,0 9,2.01 9,4.5 C9,6.45 10.26,8.1 12,8.73 L12,13.5 L6,19.5 L0,19.5 L0,27 L7.5,27 L7.5,22.425 L13.5,16.125 L19.5,22.425 L19.5,27 L27,27 L27,19.5 L21,19.5 Z" id="Path"></path>
              </g>
          </g>
      </g>
  </g>
`,
];
