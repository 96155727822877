import React, { memo } from "react";
import OrangeTooltip from "../OrangeTooltip";
import user_connected from "../../../../assets/icons/training/missions/user_connected.png";
import user_disconnected from "../../../../assets/icons/training/missions/user_disconnected.png";
import question_icon from "../../../../assets/icons/training/missions/question.png";
import task_icon from "../../../../assets/icons/training/missions/task.png";
import hint_icon from "../../../../assets/icons/training/missions/hint.png";
import informer_icon from "../../../../assets/icons/training/missions/informer.png";
import finish_flag from "../../../../assets/icons/training/missions/finish_flag.png";
import start_flag from "../../../../assets/icons/training/missions/start_flag.png";

function TimelineIcons(props) {
  const { cx, cy, payload, dataKey, dots } = props;

  const path = dots?.[dataKey]?.[payload?.name];

  if (path) {
    const { message, icon } = path;
    const title = message.charAt(0).toUpperCase() + message.slice(1);
    let myIcon;

    switch (icon) {
      case "question":
        myIcon = question_icon;
        break;
      case "task":
        myIcon = task_icon;
        break;
      case "hint":
        myIcon = hint_icon;
        break;
      case "informer":
        myIcon = informer_icon;
        break;
      case "connected":
        myIcon = user_connected;
        break;
      case "disconnected":
        myIcon = user_disconnected;
        break;
      case "start":
        myIcon = start_flag;
        break;
      case "finish":
        myIcon = finish_flag;
        break;
    }

    return (
      <OrangeTooltip title={title}>
        <image
          href={myIcon}
          height="38"
          width="38"
          x={cx - 19}
          y={cy - 19}
          style={{ cursor: "pointer" }}
        />
      </OrangeTooltip>
    );
  }

  return null;
}

export default memo(TimelineIcons);
