import { call, put, takeEvery } from "redux-saga/effects";
import { loadFamiliesApi } from "../../services/scenarios";
import {
  scenarioListFetchingState,
  setScenarioCategoryList,
  setScenarioList,
} from "./../actions/scnerioList";
import { getUserPermissionsDisplay } from "../../services/Users";
import { getOrganizationPermissionsDisplay } from "../../services/Organizations";

function* fetchScenarioList({ payload: { userInfo, activeUserOrganizationId } }) {
  let scenariosFamiliesLists;

  if (userInfo) {
    const isUserHighAuth =
      userInfo.role === "admin" || userInfo.role === "ranger";
    try {
      if (isUserHighAuth) {
        scenariosFamiliesLists = yield call(
          getOrganizationPermissionsDisplay,
          activeUserOrganizationId
        );
      } else {
        scenariosFamiliesLists = yield call(
          getUserPermissionsDisplay,
          userInfo.id
        );
      }
      if (scenariosFamiliesLists) {
        yield put(scenarioListFetchingState(true));
        yield put(setScenarioList(scenariosFamiliesLists));
        yield put(scenarioListFetchingState(false));
      }
      const scnCategories = yield call(loadFamiliesApi);
      if (scnCategories?.data) {
        const parsedArr = JSON.parse(scnCategories.data);
        const parsedData = JSON.parse(parsedArr.data);
        yield put(setScenarioCategoryList(parsedData));
      }
    } catch (e) {
      console.warn(e);
    }
  }
}

export function* scenarioListSaga() {
  yield takeEvery("LOAD_SCENARIO_LIST", fetchScenarioList);
}
