import React from "react";
import { makeStyles } from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";

import ScrollThumb from "./../base/scroll/ScrollThumb";

function TrainingPopupContainerBody({ children, scrollRef }) {
  const classes = useStyles();

  return (
    <div className={classes.bodyRoot}>
      <Scrollbars
        ref={scrollRef}
        autoHide={true}
        renderThumbVertical={ScrollThumb}
        renderTrackVertical={(props) => (
          <div {...props} className={classes.trackVertical} />
        )}
      >
        {children}
      </Scrollbars>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  bodyRoot: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "7px",
    WebkitBackdropFilter: "blur(5px)",
    backdropFilter: "blur(5px)",
  },
  trackVertical: {
    top: "0px",
    bottom: "0px",
    right: "0px",
    WebkitBackdropFilter: "blur(5px)",
    backdropFilter: "blur(5px)",
    boxShadow:
      "0 0 19px 0 rgba(255, 255, 255, 0.08), inset 0 -1px 3px 0 rgba(0, 0, 0, 0.18)",
    backgroundColor: "rgba(0, 0, 0, 0.28)",
  },
}));

export default TrainingPopupContainerBody;
