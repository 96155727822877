import { SET_SIDEBAR } from "../types/sidebar";
const initialState = {
  sidebarShow: "responsive",
};

const sidebarReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case SET_SIDEBAR:
      return { ...state, ...rest };
    default:
      return state;
  }
};

export default sidebarReducer;
