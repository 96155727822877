import React from "react";
import { makeStyles } from "@material-ui/core/styles";

function LeaderboardRank({ rank, isUser }) {
  const classes = useStyles({ isUser });

  return <div className={classes.rank}>{rank}</div>;
}

const useStyles = makeStyles({
  rank: {
    textShadow: "0 0 6px rgba(0, 0, 0, 0.35)",
    width: "20px",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "1",
    marginLeft: "10px",
    color: (props) => (props.isUser ? "#0eff9c" : "#ffffff"),
    textAlign: "center",
  },
});

export default LeaderboardRank;
