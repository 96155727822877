import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import VncCard from "./userComponents/Remotes/VncCard";
import { useLocation, useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";

function Training() {
  const ref = useRef(null);
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const scenarioListItem = useSelector((state) => state.scenarioItemReducer);

  const [continueToTraining, setContinueToTraining] = useState(false);

  useEffect(() => {
    const { location } = history;
    if (location?.state?.fromDashboard) {
      setContinueToTraining(true);
    } else {
      // setContinueToTraining(true);
      // preventing from go inside training from url / not logged in
      history.push("/personal-hall");
    }
  }, [location]);

  if (continueToTraining) {
    console.log("scenarioListItem", scenarioListItem);
    console.log("scenarioListItem?.ListDetails", scenarioListItem?.ListDetails);
    console.log("scenarioListItem?.ListDetails?.External_URL", scenarioListItem?.ListDetails?.External_URL);
    return ( <div>{scenarioListItem?.scenarioType === "external" ? (        <iframe 
      src={scenarioListItem?.ListDetails?.External_URL[0]} 
      width="100%" 
      height="100%" 
      frameBorder="0" 
      allowFullScreen>
    </iframe>
    ) : ( <div className={classes.trainingRoot} ref={ref} id="vnc-card">
    <VncCard location={location} />
  </div>)}
  </div>
    );
  } else {
    return null;
  }
}

const useStyles = makeStyles({
  trainingRoot: {
    width: "100vw",
    height: "100vh",
  },
});

export default Training;


