import React from "react";

import LeaderboardBadge from "./LeaderboardBadge";

function LeaderboardBadges({ badges = [], setOpen }) {
  return badges.map((badge, index) => (
    <LeaderboardBadge
      badge={badge?.name}
      description={badge?.description}
      index={index}
      setOpen={setOpen}
      key={badge?.name || `no-id-${index}`}
    />
  ));
}

export default LeaderboardBadges;
