import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import VmMonitorHeader from "./VmMonitorHeader";
import { Formik, Form } from "formik";
import { FormikTextInputDisabled } from "../../base/forms/inputFields";
import Select from "react-select";
import { useSelector } from "react-redux";

function VmMonitor({
  setOpen,
  size,
  selectedVm,
  handleStartVm,
  handleStopVm,
  handleLogInVm,
}) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ size, theme });
  const formRef = useRef();

  const snapshotsArray = selectedVm?.device?.snapshots
    ? Object.values(selectedVm?.device?.snapshots).map((snapshot) => {
        return {
          value: snapshot.amiID,
          label: snapshot.amiID,
        };
      })
    : [];

  return (
    <div id="VmMonitor" spacing={0} className={classes.root}>
      <VmMonitorHeader
        classes={classes}
        setOpen={setOpen}
        selectedVm={selectedVm}
        handleStartVm={handleStartVm}
        handleStopVm={handleStopVm}
        handleLogInVm={handleLogInVm}
      />
      <Grid container className={classes.previewBody}>
        <Grid item sm={12} className={classes.chatBox}>
          <div className={classes.formBody}>
            <Formik
              innerRef={formRef}
              enableReinitialize={true}
              initialValues={selectedVm}
            >
              {({ values }) => (
                <div className="editorForm">
                  {/*Topic*/}
                  <Form>
                    <div className={classes.inputTitle}>{"Title"}</div>
                    <FormikTextInputDisabled
                      style={classes.inputBox}
                      name="Name"
                      type="text"
                    />
                    <div className={classes.inputTitle}>
                      {"Operating System"}
                    </div>
                    <FormikTextInputDisabled
                      style={classes.inputBox}
                      name="OS"
                      type="text"
                    />
                    {values.InstanceID ? (
                      <>
                        <div className={classes.inputTitle}>
                          {"Instance Id"}
                        </div>
                        <FormikTextInputDisabled
                          style={classes.inputBox}
                          name="InstanceID"
                          type="text"
                        />
                      </>
                    ) : (
                      ""
                    )}

                    {/* {values?.snapshots?.length > 0 ? (
                      <>
                        <div className={classes.inputTitle}>
                          {"Selected Snapshot"}
                        </div>
                        <div>
                          <Select
                            isSearchable={false}
                            options={snapshotsArray}
                            isClearable={false}
                            value={snapshotsArray.find(
                              (snapshot) =>
                                snapshot.value === values.selectedSnapshot
                            )}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )} */}

                    <div className={classes.inputTitle}>{"Version"}</div>
                    <FormikTextInputDisabled
                      style={classes.inputBox}
                      name="Version"
                      type="text"
                    />
                    <div className={classes.inputTitle}>{"IP"}</div>
                    <FormikTextInputDisabled
                      style={classes.inputBox}
                      name="IP"
                      type="text"
                    />
                    <div className={classes.inputTitle}>{"Username"}</div>
                    <FormikTextInputDisabled
                      style={classes.inputBox}
                      name="UserName"
                      type="text"
                    />
                    <div className={classes.inputTitle}>{"Password"}</div>
                    <FormikTextInputDisabled
                      style={classes.inputBox}
                      name="Password"
                      type="text"
                    />
                    <div className={classes.inputTitle}>{"Comments"}</div>
                    <FormikTextInputDisabled
                      style={classes.inputBox}
                      name="Comments"
                      type="text"
                    />
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    width: (props) => props.size.width,
    height: (props) => props.size.height,
    padding: "15px",
    borderRadius: "29px",
    WebkitBackdropFilter: "blur(1px)",
    backdropFilter: "blur(1px)",
    boxShadow: "10px 10px 33px 0 rgba(230, 230, 230, 0.1)",
    backgroundColor: "rgba(9, 12, 18, 0.9)",
    display: "flex",
    flexDirection: "column",
  },
  formBody: {
    padding: "10px 10px",
    maxWidth: "540px",
    width: "100%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  previewBody: {
    height: "100%",
    padding: "20px",
  },
  chatBox: {
    height: "100%",
    zIndex: "100",
  },
  accordionContainer: {
    color: "#FFFFFF",
  },
  accordionCard: {
    marginBottom: 0,
    padding: "27.5px 0 12px 0",
  },
  accordionRow: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    borderBottom: "none",
    alignItems: "center",
    padding: "0",
  },
  accordionHeader: {
    opacity: "0.5",
    fontSize: "13px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    textTransform: "uppercase",
    margin: "0",
  },
  accordionBody: {
    color: "#FFFFFF",
    fontSize: "16px",
    letterSpacing: "-0.25px",
    lineHeight: "18px",
    padding: 0,
    marginTop: "10px",
  },
  accordionIconRegular: {
    fontSize: "11px",
    marginRight: "10px",
    marginTop: "10px",
    color: "#de1d66",
  },
  accordionIconSuccess: {
    fontSize: "11px",
    marginRight: "10px",
    marginTop: "10px",
    color: "#20c381",
  },
  listItemText: {
    fontFamily: "Sora-Regular_",
    fontSize: "16px",
    fontWeight: "300",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.25px",
    color: "#ffffff",
    paddingLeft: "5px",
    paddingRight: "10px",
  },
  warningText: {
    paddingTop: "15px",
    color: "white",
    textAlign: "center",
  },
  mainTitle: {
    color: "#FFFFFF",
    backgroundColor: "inherit",
    fontSize: "25px",
    letterSpacing: "-0.38px",
    lineHeight: "29px",
    display: "flex",
    alignItems: "center",
  },
  mainTitleText: {
    fontFamily: (props) => props.theme.MAIN_FONT,
    marginLeft: "12px",
  },
  mainTitleIcon: {
    cursor: "pointer",
  },
  innerContainer: {
    height: "77px",
    padding: "0",
    borderBottom: "1px solid rgba(255,255,255,0.07)",
  },
  dot: {
    marginLeft: "8px",
  },
  buttonIcon: {
    margin: "auto",
  },
  buttonText: {
    margin: "auto",
  },
  runIcon: {
    marginLeft: "8px",
    paddingBottom: "2px",
  },
  runButton: {
    display: "flex",
    width: "120px",
    height: "35px",
    borderRadius: "11px",
    // boxShadow: "0 3px 7px 0 rgba(219, 30, 100, 0.45)",
    backgroundImage: "linear-gradient(to right, #ed174e 0%, #f37f1c 195%)",
    fontSize: "14px",
    fontFamily: (props) => props.theme.MAIN_FONT,
    letterSpacing: "-0.22px",
    color: "#ffffff",
  },
  exportButton: {
    width: "118px",
    height: "40px",
    borderRadius: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    border: "none",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.22px",
    color: "#ffffff",
    marginRight: "15px",
  },
  threeDotIcon: {
    color: "white",
    fontSize: "30px",
    cursor: "pointer",
  },
  flowGraph: {
    height: "70%",
    padding: "10px",
  },
  outputBox: {
    padding: "10px",
    fontSize: "14px",
    marginTop: "30px",
    height: "25%",
    border: "2px dotted gray",
    borderRadius: "10px",
    marginRight: "10px",
  },
  sectionTitle: {
    color: "white",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "18px",
  },
  sectionTitleBold: {
    color: "white",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "18px",
    fontWeight: "bold",
  },
  sectionText: {
    color: "white",
    opacity: "0.8",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "14px",
  },
  runningNow: {
    color: "white",
    fontSize: "18px",
    fontFamily: (props) => props.theme.MAIN_FONT,
    display: "flex",
    alignItems: "baseline",
  },
  eventsBox: {
    display: "flex",
    height: "100%",
  },
  inputTitle: {
    width: "100%",
    color: "white",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },
  inputBox: {
    width: "100%",
    height: "30px",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "16px",
    borderRadius: "8px",
    border: "none",
    marginBottom: "5px",
    marginTop: "5px",
    paddingLeft: "10px",
    // color: "#1b202c",
    backgroundColor: "white",
    "& .MuiInputBase-input": {
      fontFamily: (props) => props.theme.MAIN_FONT,
      fontSize: "18px",
      color: "#1b202c",
    },
    "& .MuiInput-underline": {
      borderBottom: "0px solid red",
    },
    "& .MuiInput-underline::before": {
      borderBottom: "transparent",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "transparent",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: "transparent",
    },
    "& label.Mui-focused": {
      color: "rgba(27, 32, 44, 0.4)",
    },
    "& .MuiInputLabel-formControl": {
      top: "-7px",
      left: "25px",
    },
    "&  .MuiInputLabel-shrink ": {
      top: "5px",
      left: "20px",
    },
  },
});

export default VmMonitor;
