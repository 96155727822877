import {
  SET_MODAL_STATE,
  SET_ACTIVE_MENU,
  SET_ACTIVE_HALL,
  INCREMENT_POPUP_COUNT,
  DECREMENT_POPUP_COUNT,
  UPDATE_SCORE,
  UPDATE_TIME,
  UPDATE_RINGS,
  INCREMENT_FONT_SIZE,
  DECREMENT_FONT_SIZE,
  RESET_TRAINING_STATE,
  SET_THEME
} from "../types/ui";

const initialState = {
  activeMenu: "",
  activeHall: "",
  modalOpen: true,
  modalType: "welcome",
  theme: {},
  score: 0,
  time: 0,
  rings: [0, 0],
  events: 0,
  missions: 0,
  timeline: 0,
  leaderboard: 0,
  chat: 0,
  info: 0,
  fontSize: 1,
};

const uiReducer = (state = initialState, action) => {
  const {
    activeMenu,
    modalType,
    modalState,
    popup,
    number,
    type,
    score,
    time,
    rings,
    activeHall,
    theme
  } = action;

  switch (type) {
    case SET_MODAL_STATE:
      return { ...state, modalOpen: modalState, modalType };
    case SET_ACTIVE_MENU:
      return { ...state, activeMenu, [activeMenu]: 0 };
    case SET_ACTIVE_HALL:
      return { ...state, activeHall };
    case UPDATE_SCORE:
      return { ...state, score };
    case SET_THEME:
      return { ...state, theme: theme };
    case UPDATE_TIME:
      return { ...state, time };
    case UPDATE_RINGS:
      return { ...state, rings };
    case INCREMENT_POPUP_COUNT:
      if (popup !== state.activeMenu) {
        const popupCount = state[popup];
        return { ...state, [popup]: popupCount + 1 };
      } else {
        return state;
      }
    case DECREMENT_POPUP_COUNT:
      if (popup !== state.activeMenu) {
        const popupCount = state[popup];
        return { ...state, [popup]: Math.max(0, popupCount - number) };
      } else {
        return state;
      }
    case INCREMENT_FONT_SIZE:
      return { ...state, fontSize: state.fontSize + 1 };
    case DECREMENT_FONT_SIZE:
      return { ...state, fontSize: state.fontSize - 1 };
    case RESET_TRAINING_STATE:
      return { ...initialState, fontSize: state.fontSize };
    default:
      return state;
  }
};
export default uiReducer;
