import React, { useMemo, memo } from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ShadowedText from "../shared/ShadowedText";
import Score from "../shared/Score";
import { useSelector } from "react-redux";

function DebriefHeaderStats({ missionProgress = {}, hintProgress = {} }) {
  const { theme } = useSelector((state) => state.uiReducer);
  const classes = useStyles({ theme });

  const { time_seconds, currentScore, totalScore, currentRings } =
    missionProgress;
  const { currentHint } = hintProgress;

  // const flooredMinutes = Math.floor(time_seconds / 60);
  // const seconds = time_seconds > 10 ? time_seconds : `0${time_seconds}`;
  // const timeToSolve = `${flooredMinutes < 10 ? "0" : ""}${flooredMinutes}:${
  //   time_seconds % 60
  // }`;

  let date = new Date(0);
  date.setSeconds(time_seconds);
  let timeInSecondes = date.toISOString().substr(11, 8).slice(3);

  const timeToSolve = timeInSecondes;

  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="space-between"
      minWidth="200px"
      textAlign="center"
      paddingBottom="5px"
    >
      <div>
        <div className={classes.statName}>Time to solve</div>
        <ShadowedText text={timeToSolve} type="white" size="15px" />
      </div>
      <div>
        <div className={classes.statName}>Rings</div>
        <ShadowedText text={currentRings} type="green" size="15px" />
      </div>
      <div>
        <div className={classes.statName}>Hints</div>
        <ShadowedText text={currentHint} type="white" size="15px" />
      </div>
      <div>
        <div className={classes.statName}>Score</div>
        <Score
          score={`${currentScore}/${totalScore}`}
          size="15px"
          lineHeight="0"
        />
      </div>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  statName: {
    opacity: "0.4",
    fontFamily: (props) => props.theme.MAIN_FONT,
    fontSize: "14px",
    fontWeight: "500",
    color: "#ffffff",
    padding: "0px 4px",
  },
}));

export default memo(DebriefHeaderStats);
