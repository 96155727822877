import {
  SET_INFORMER,
  REMOVE_INFORMER,
  RESET_STATE,
} from "./../types/informer";

export const setInformer = (informer) => ({
  type: SET_INFORMER,
  informer,
});

export const removeInformer = (id) => {
  return { type: REMOVE_INFORMER, id };
};

export const resetInformersState = () => ({
  type: RESET_STATE,
});
